<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'email'">
        <lib-text-table-column>
            {{ element.email }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'firstName'">
        <lib-text-table-column>
            {{ element.firstName }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'lastName'">
        <lib-text-table-column>
            {{ element.lastName }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'lockedStatus'">
        <lib-user-status-icon
            [isLocked]="element.isLockedOut">
        </lib-user-status-icon>
        <lib-table-column-sort [key]="sortingKeyForLockedStatus"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





