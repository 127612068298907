import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef } from "shared-lib";
import { WebhookDto } from "@merchant_api/models/webhook-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

@Component({
    selector: "app-webhook-log",
    templateUrl: "./webhook-log.component.html",
    styleUrls: [ "./webhook-log.component.less" ]
})
export class WebhookLogComponent {

    @Input() webhooks: WebhookDto[];
    @Input() loading = false;
    @Input() error: Error;
    @Input() toggleWebhooksLoading: Array<number> = [];
    @Output() activeToggled = new EventEmitter<{webhook: WebhookDto; undo: () => void}>();

    get webhookColumnDefs(): ColumnDef[] {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        const retVal: ColumnDef[] = [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: "id", title: "ID", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true},
            { id: "url", title: "URL", flexWidthBasisInPixels: 700, flexWidthGrow: 0, canSort: true},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
        if (this.hasTogglePermission) {
            const activeColumn: ColumnDef = { id: "active", title: "Active", flexWidthBasisInPixels: 80, flexWidthGrow: 1};
            retVal.splice(3, 0, activeColumn);
        }
        return retVal;
    }

    onIsActiveToggled(payload: {webhook: WebhookDto; undo: () => void}) {
        this.activeToggled.emit(payload);
    }

    onClickCreate() {
        if (this.hasCreatePermission) {
            this.router.navigate([`/dashboard/developer/webhooks/configure/create`]);
        }
    }

    onRowSelected(id: string) {
        if (this.hasGetPermission) {
            this.router.navigate([`/dashboard/developer/webhooks/configure/${id}`]);
        }
    }

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {}

    get hasCreatePermission(): boolean {
        return this.currPerms.has(["core.webhooks.create"]);
    }

    get hasGetPermission(): boolean {
        return this.currPerms.has(["core.webhooks.get"]);
    }

    get hasTogglePermission(): boolean {
        return this.currPerms.has(["core.webhooks.toggle"]);
    }

}
