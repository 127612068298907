import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActionButtonComponent } from "./action-button.component";
import { StopPropagationModule } from "../stop-propagation/stop-propagation.module";

@NgModule({
    declarations: [
        ActionButtonComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        StopPropagationModule
    ],
    exports: [
        ActionButtonComponent
    ]
})
export class ActionButtonModule { }
