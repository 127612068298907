import { Action } from "@ngrx/store";
import { UserDto } from "@merchant_api/models/user-dto";
import { WebhookDto } from "@merchant_api/models/webhook-dto";

export enum ActionTypes {
    GetWebhooks = "[Webhooks] GetWebhooks",
    GetWebhooksSucceed = "[Webhooks] GetWebhooksSucceed",
    GetWebhooksFail = "[Webhooks] GetWebhooksFail",
    GenerateWebhooksSecret = "[Webhooks] GenerateWebhooksSecret",
    GenerateWebhooksSecretSucceed = "[Webhooks] GenerateWebhooksSecretSucceed",
    GenerateWebhooksSecretFail = "[Webhooks] GenerateWebhooksSecretFail",
    ResetWebhooksSecret = "[Webhooks] ResetWebhooksSecret"
}

export class GetWebhooksAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetWebhooks;
}

export class GetWebhooksSucceedAction implements Action {

    constructor(public webhooks: Array<WebhookDto>) {}

    readonly type = ActionTypes.GetWebhooksSucceed;
}

export class GetWebhooksFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetWebhooksFail;
}

export class GenerateWebhooksSecretAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GenerateWebhooksSecret;
}

export class GenerateWebhooksSecretSucceedAction implements Action {

    constructor(public secret: string) {}

    readonly type = ActionTypes.GenerateWebhooksSecretSucceed;
}

export class GenerateWebhooksSecretFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GenerateWebhooksSecretFail;
}

export class ResetWebhooksSecretAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetWebhooksSecret;
}
