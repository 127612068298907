import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Pager } from "shared-lib";

import {
    PageChangedPaymentsAction,
    ExportPaymentsToCsvAction,
    FilterPaymentsAction,
    SortPaymentsAction
} from "@merchant_app/storage/payments/payments.actions";

import { Sorting, Filter } from "@merchant_app/storage/payments/payments.state";
import { getPaymentsState } from "@merchant_app/storage/payments/payments.selectors";
import { Payment } from "@merchant_api/models/payment";
import * as CurrentUserSelectors from "@merchant_app/storage/current-user/current-user.selectors";

type RowsData = Payment[];

@Component({
    selector: "app-payments",
    templateUrl: "./payments.component.html",
    styleUrls: ["./payments.component.less"],
})
export class PaymentsComponent {

    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);

    get rowsData$(): Observable<RowsData> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.rows));
    }

    get sorting$(): Observable<Sorting> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.sorting));
    }

    get pager$(): Observable<Pager> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.pager));
    }

    get loading$(): Observable<boolean> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.loading));
    }

    get error$(): Observable<Error> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.error));
    }

    get filter$(): Observable<Filter> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.filter));
    }

    get exportLoading$(): Observable<boolean> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.exportLoading));
    }

    constructor(private store: Store<IStore>) {}

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterPaymentsAction(filter));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortPaymentsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedPaymentsAction(page));
    }

    exportToCsv() {
        this.store.dispatch(new ExportPaymentsToCsvAction());
    }
}
