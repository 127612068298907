import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import {
    GetMerchantSucceedAction,
    GetMerchantAction,
    ActionTypes
} from "./merchant.actions";
import { MerchantService } from "@merchant_api/services/merchant.service";
import { GetMerchantFailAction } from "@merchant_app/storage/merchant/merchant.actions";

@Injectable()
export class MerchantEffects {
    constructor(
        private actions: Actions,
        private merchantService: MerchantService
    ) {}

    get = createEffect(() => this.actions.pipe(
            ofType<GetMerchantAction>(ActionTypes.GetMerchant),
            switchMap(() => this.getMerchant().pipe(
                    switchMap(result => of(new GetMerchantSucceedAction(result))),
                    catchError((error) => of(new GetMerchantFailAction(error)))
                ))
        ));

    getMerchant() {
        return this.merchantService.merchantGet();
    }

}
