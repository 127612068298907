import { EventsState } from "./events.state";
import {
    ActionTypes,
} from "./events.actions";

export const EventsReducer = (state: EventsState = new EventsState(), action: any): EventsState => {

    switch (action.type) {

        case ActionTypes.FilterEvents:
        case ActionTypes.SortEvents:
        case ActionTypes.PageChangedEvents:
        case ActionTypes.GetEvents: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetEventsSucceed: {
            return {
                ...state,
                rows: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: action.response.pages,
                    morePagesMode: true
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetEventsFail: {
            return {
                ...state,
                rows: [],
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
