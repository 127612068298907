
export class DateTimeLabelUtils {

    static ensureTimeZone(dateTime: string): string {
        let dateTimeWithTimeZone = dateTime;

        // Add a trailing zulu time zone char if there is no timezone detected.
        // eslint-disable-next-line security/detect-unsafe-regex
        const withoutTimeZone = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?$/i;
        if (withoutTimeZone.test(dateTimeWithTimeZone)) {
            dateTimeWithTimeZone += "Z";
        }

        return dateTimeWithTimeZone;
    }

}
