import { createAction, props } from "@ngrx/store";
import { Capture } from "@merchant_api/models/capture";
import { TransactionDetails } from "@merchant_api/models/transaction-details";

export const GetTransactionDetailAction = createAction(
    "[Transaction] Get Transaction Detail",
    props<{
        token: string;
        getRelations: boolean;
    }>(),
);

export const GetTransactionDetailSucceedAction = createAction(
    "[Transaction] Get Transaction Detail Succeed",
    props<{
        record: TransactionDetails;
        getRelations: boolean;
    }>(),
);

export const GetTransactionDetailFailAction = createAction(
    "[Transaction] Get Transaction Detail Fail",
    props<{
        error: any;
    }>(),
);

export const CaptureTransactionAction = createAction(
    "[Transaction] Capture Transaction",
    props<{ transaction: Capture }>(),
);

export const UpdateTransactionDetailAction = createAction(
    "[Transaction] Update Transaction Detail",
    props<{ record: TransactionDetails }>(),
);

export const DownloadReceiptAction = createAction(
    "[Transaction] Download Receipt",
    props<{ transaction: TransactionDetails }>(),
);
