import { Component, Input } from "@angular/core";

@Component({
    selector: "app-funding-item-mid-fees-table-cell-content",
    templateUrl: "./funding-item-mid-fees-table-cell-content.component.html",
    styleUrls: ["./funding-item-mid-fees-table-cell-content.component.less"],
})
export class FundingItemMidFeesTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

}
