<div class="outer-container" [ngClass]="{ 'disabled': disabled, 'large-button': largeButton }">
    <button [type]="type" mat-button [disableRipple]="disabled" (click)="onClick()" libStopPropagation
        [ngClass]="{'large-button': largeButton, 'disabled': disabled, 'primary-kind': (kind === ActionButtonKind.PRIMARY), 'secondary-kind': (kind === ActionButtonKind.SECONDARY), 'alert-kind': (kind === ActionButtonKind.ALERT), 'alert-tone-positive': (alertTone === ActionButtonAlertTone.Positive), 'alert-tone-neutral': (alertTone === ActionButtonAlertTone.Neutral), 'stroked-kind': (kind === ActionButtonKind.STROKED), 'filter-kind': (kind === ActionButtonKind.FILTER) }"
        [ngStyle]="{'min-width': minWidthInPixels + 'px'}">
            <div class="inner-container" [ngClass]="{ 'icon-after-text': iconAfterText, 'large-button': largeButton }">
                <div *ngIf="image && !fontImage" class="vertical-center">
                    <img [src]="image" />
                </div>
                <div *ngIf="!image && fontImage" class="vertical-center">
                    <i [class]="fontImage"></i>
                    <i *ngIf="fontImageExtra" [class]="fontImageExtra"></i>
                </div>
                <div *ngIf="label && ((image && !fontImage) || (!image && fontImage))" class="spacer"></div>
                <div class="label vertical-center">{{ label }}</div>
            </div>
    </button>
    <span *ngIf="badge" class="badge">
        {{ badge }}
    </span>
</div>
