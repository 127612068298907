import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActionButtonKind } from "../action-button/action-button.component";

export interface ConfirmationModalData {
    title: string;
    subtitle: string;
    subtitle2: string;
    confirmButtonText: string;
    secondButtonText: string;
    thirdButtonText: string;
    noCancel?: boolean;
    copySubtitle?: boolean;
}

@Component({
    selector: "lib-confirmation-modal",
    templateUrl: "./confirmation-modal.component.html",
    styleUrls: ["./confirmation-modal.component.less"],
})
export class ConfirmationModalComponent {

    @Input() kind = ActionButtonKind.PRIMARY;

    noCancel = false;
    secondButtonText: string;
    thirdButtonText: string;
    copySubtitle = false;

    ActionButtonKind = ActionButtonKind;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData,
        private dialogRef: MatDialogRef<ConfirmationModalComponent>
    ) {
        if (data?.noCancel) {
            this.noCancel = true;
            dialogRef.disableClose = true;
        }
        if (!data?.confirmButtonText) {
            data.confirmButtonText = "OK";
        }
        if (data?.secondButtonText) {
            this.secondButtonText = data.secondButtonText;
        }
        if (data?.thirdButtonText) {
            this.thirdButtonText = data.thirdButtonText;
        }
        if (data?.copySubtitle) {
            this.copySubtitle = data.copySubtitle;
        }
    }

    onCancel() {
        this.dialogRef.close(0);
    }

    onSubmit() {
        this.dialogRef.close(1);
    }

    onSecondButton() {
        this.dialogRef.close(2);
    }

    onThirdButton() {
        this.dialogRef.close(3);
    }

}
