import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageFieldComponent } from "./image-field.component";
import { TextCommonModule } from "../text-common/text-common.module";

@NgModule({
    declarations: [
        ImageFieldComponent
    ],
    imports: [
        CommonModule,
        TextCommonModule
    ],
    exports: [
        ImageFieldComponent
    ]
})
export class ImageFieldModule { }
