import { DisputeDto } from "@merchant_api/models/dispute-dto";
import { DisputeOrderBy } from "@merchant_api/models/dispute-order-by";
import { Filter, Sorting } from "@merchant_app/storage/disputes/disputes.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, LocalTimePoint, Pager, TableSorting } from "shared-lib";

@Component({
    selector: "app-disputes-log",
    templateUrl: "./disputes-log.component.html",
    styleUrls: ["./disputes-log.component.less"],
})
export class DisputesLogComponent {

    @Input() disputes: Array<DisputeDto>;
    @Input() disputesLoading = false;
    @Input() disputesError: Error;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    readonly columnDefs: ColumnDef[];

    constructor(private router: Router) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: DisputeOrderBy.RaiseDateUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 0, canSort: true},
            { id: DisputeOrderBy.CaseNumber, title: "Case", flexWidthBasisInPixels: 210, flexWidthGrow: 0, canSort: true},
            { id: "disputeType", title: "Type", flexWidthBasisInPixels: 110, flexWidthGrow: 0},
            { id: "processor", title: "Processor", flexWidthBasisInPixels: 90, flexWidthGrow: 0},
            { id: "mid", title: "MID", flexWidthBasisInPixels: 120, flexWidthGrow: 1},
            { id: DisputeOrderBy.CaseReason, title: "Reason Type", flexWidthBasisInPixels: 100, flexWidthGrow: 6, canSort: true},
            { id: "amount", title: "Amount", flexWidthBasisInPixels: 80, flexWidthGrow: 1},
            { id: "statusDescription", title: "Status", flexWidthBasisInPixels: 60, flexWidthGrow: 3, canSort: false},
            { id: "disputeOutcome", title: "Outcome", flexWidthBasisInPixels: 60, flexWidthGrow: 1},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(token: string) {
        this.router.navigate([`/dashboard/disputes/${token}`]);
    }

}
