import { TidsState } from "./tids.state";
import { ActionTypes } from "./tids.actions";

export const TidsReducer = (
    state: TidsState = new TidsState(),
    action: any,
  ): TidsState => {

    switch (action.type) {

        case ActionTypes.ResetTids: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetTids: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetTidsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetTidsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
