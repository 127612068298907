<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Create Refund</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <div class="first-row">

            <div>
                <div class="label">Payer Name</div>
                <lib-text-common class="variable-text">{{ refundTransaction?.payer?.fullName | uppercase }}</lib-text-common>
            </div>

            <div>
                <div class="label">Refund Limit</div>
                <lib-text-common class="variable-text">{{ amountAvailable | vituCurrencyUsd }}</lib-text-common>
            </div>

            <div>
                <div *ngIf="serviceFeeAmountAvailable > 0">
                    <div class="label">Service Fee Refund Limit</div>
                    <lib-text-common class="variable-text">{{ serviceFeeAmountAvailable | vituCurrencyUsd }}</lib-text-common>
                </div>
            </div>

        </div>

        <div class="second-row">

            <div>
                <div class="label">Card</div>
                <lib-text-common class="variable-text">{{ refundTransaction?.card?.cardBrand | uppercase }}</lib-text-common>
            </div>

            <div>
                <div class="label">Card Number</div>
                <lib-text-common class="variable-text">{{ refundTransaction?.card?.first6 }}XXX{{ refundTransaction?.card?.last4 }}</lib-text-common>
            </div>

            <div>
                <div class="label">Card Expiration</div>
                <lib-text-common class="variable-text">{{ refundTransaction?.card?.expirationDate }}</lib-text-common>
            </div>

        </div>

        <form class="form" [formGroup]="refundForm" [libVituForm]="refundForm" (libVituFormSubmit)="onSubmit()">

            <div class="refund-inputs-container">

                <div class="form-fields-container form-fields-container-first-line">

                    <div class="refund-amount">
                        <lib-vitu-form-field-input
                            #refundAmountInputFieldComponent
                            [formGroup]="refundForm"
                            formFieldName="refundAmount"
                            label="Refund Amount"
                            type="twoDigitDecimalAllowZeroNotNegative"
                            prefix="$"
                            [errorOverrides]="[{'refundLimitExceeded': limitExceeded }, {'refundAtLeastOneInvalid': amountRequired}]"
                            (blurred)="markAsUntouched()">
                        </lib-vitu-form-field-input>
                    </div>

                    <div class="refund-toggle-group">
                        <mat-button-toggle-group [(value)]="refundToggleValue" class="main-amount">
                            <mat-button-toggle [value]="RefundToggleValue.None" (mousedown)="setRefundToggleValue(RefundToggleValue.None, $event)">None</mat-button-toggle>
                            <mat-button-toggle [value]="RefundToggleValue.Custom" (mousedown)="setRefundToggleValue(RefundToggleValue.Custom, $event)">Custom</mat-button-toggle>
                            <mat-button-toggle [value]="RefundToggleValue.All" (mousedown)="setRefundToggleValue(RefundToggleValue.All, $event)">All</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                </div>

                <div *ngIf="serviceFeeAmountAvailable > 0">

                    <div *ngIf="!isPayPal" class="form-fields-container">

                        <div class="refund-amount">
                            <lib-vitu-form-field-input
                                #serviceFeeRefundAmountInputFieldComponent
                                [formGroup]="refundForm"
                                formFieldName="serviceFeeRefundAmount"
                                label="Service Fee Refund Amount"
                                type="twoDigitDecimalAllowZeroNotNegative"
                                prefix="$"
                                [errorOverrides]="[{'refundLimitExceeded': serviceFeeLimitExceeded }, {'refundAtLeastOneInvalid': amountRequired}  ]"
                                (blurred)="markAsUntouched()">
                            </lib-vitu-form-field-input>
                        </div>

                        <div class="refund-toggle-group">
                            <mat-button-toggle-group [(value)]="serviceFeeRefundToggleValue">
                                <mat-button-toggle [value]="RefundToggleValue.None" (mousedown)="setServiceFeeRefundToggleValue(RefundToggleValue.None, $event)">None</mat-button-toggle>
                                <mat-button-toggle [value]="RefundToggleValue.Custom" (mousedown)="setServiceFeeRefundToggleValue(RefundToggleValue.Custom, $event)">Custom</mat-button-toggle>
                                <mat-button-toggle [value]="RefundToggleValue.All" (mousedown)="setServiceFeeRefundToggleValue(RefundToggleValue.All, $event)">All</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                    </div>

                    <div *ngIf="isPayPal" class="form-fields-container-paypal">
                        The service fee will be refunded with this transaction.  The amount of service fee refunded depends on the amount of the refund.
                    </div>

                </div>

                <div>
                    <lib-vitu-form-field-input
                        [formGroup]="refundForm"
                        formFieldName="refundDescription"
                        label="Refund Description"
                        [maxlength]="refundDescriptionMaxLength">
                    </lib-vitu-form-field-input>
                </div>

            </div>

            <div class="error-message-container">
                <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
                </lib-modal-error-message>
            </div>

            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Submit"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>

    </div>

</div>
