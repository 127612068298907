import { Action } from "@ngrx/store";
import { CreateWebhookDto } from "@merchant_api/models/create-webhook-dto";
import { UpdateWebhookDto } from "@merchant_api/models/update-webhook-dto";
import { WebhookDto } from "@merchant_api/models/webhook-dto";
import { WebhookEventTypesDto } from "@merchant_api/models/webhook-event-types-dto";

export enum ActionTypes {
    GetWebhook = "[Webhook] GetWebhook",
    GetNewWebhook = "[Webhook] GetNewWebhook",
    GetWebhookSucceed = "[Webhook] GetWebhookSucceed",
    GetWebhookFail = "[Webhook] GetWebhookFail",
    UpdateWebhook = "[Webhook] UpdateWebhook",
    DeleteWebhook = "[Webhook] DeleteWebhook",
    CreateWebhook = "[Webhook] CreateWebhook",
    ToggleWebhook = "[Webhook] ToggleWebhook",
    ToggleWebhookSucceed = "[Webhook] ToggleWebhookSucceed",
    ToggleWebhookFail = "[Webhook] ToggleWebhookFail",
    GetWebhookLookups = "[Webhook] GetWebhookLookups",
    GetWebhookLookupsSucceed = "[Webhook] GetWebhookLookupsSucceed"
}

export class GetWebhookAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetWebhook;
}

export class GetNewWebhookAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewWebhook;
}

export class GetWebhookSucceedAction implements Action {

    constructor(
        public webhook: WebhookDto,
        public lookups: WebhookEventTypesDto
    ) {}

    readonly type = ActionTypes.GetWebhookSucceed;
}

export class GetWebhookFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetWebhookFail;
}

export class UpdateWebhookAction implements Action {

    constructor(public id: number, public webhook: UpdateWebhookDto) {}

    readonly type = ActionTypes.UpdateWebhook;
}

export class DeleteWebhookAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteWebhook;
}

export class CreateWebhookAction implements Action {

    constructor(public webhook: CreateWebhookDto) {}

    readonly type = ActionTypes.CreateWebhook;
}

export class ToggleWebhookAction implements Action {

    constructor(
        public id: number,
        public webhook: UpdateWebhookDto,
        public undo: () => void
    ) {}

    readonly type = ActionTypes.ToggleWebhook;
}

export class ToggleWebhookSucceedAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.ToggleWebhookSucceed;
}

export class ToggleWebhookFailAction implements Action {

    constructor(
        public id: number,
        public error: any,
        public undo: () => void
    ) {}

    readonly type = ActionTypes.ToggleWebhookFail;
}

export class GetWebhookLookupsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetWebhookLookups;
}

export class GetWebhookLookupsSucceedAction implements Action {

    constructor(public lookups: WebhookEventTypesDto) {}

    readonly type = ActionTypes.GetWebhookLookupsSucceed;
}
