import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
    selector: "[libColumnFlexDirective]"
})
export class ColumnFlexDirective implements OnInit {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("libColumnFlexDirective") flex: string;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.style.flex = this.flex;
    }

}
