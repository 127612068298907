import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { Observable, Subject } from "rxjs";
import { SnackBarContentComponent } from "./snackbar-content.component/snackbar-content.component";
import { VituToastTone } from "./vitu-toast-tone";

@Injectable({ providedIn: "root" })
export class VituToastService {

    private closeEmitter = new Subject<void>();

    open(message: string|Array<string>, tone = VituToastTone.Neutral,
        horizPosition?: MatSnackBarHorizontalPosition, vertPosition?: MatSnackBarVerticalPosition,
        buttonText?: string): Observable<void> {

        const horizontalPosition = horizPosition ?? "center";
        const verticalPosition = vertPosition ?? "top";

        // Calculate the duration to display the toast for based
        // on the number of chars, between 4 secs & 7 secs.
        let totalChars = 0;
        if (Array.isArray(message)) {
            message.forEach((messagePart) => {
                if (typeof messagePart === "string") {
                    totalChars += messagePart.length;
                }
            });
        }
        else if (typeof message === "string") {
            totalChars = message.length;
        }

        const increment = 50;   // approx chars in a line of text
        const base = 3 * increment;
        const excess = Math.max(totalChars - base, 0);
        const excessIncrements = Math.floor(excess / increment);
        const duration = Math.min(4000 + (excessIncrements * 500), 7000);

        const snackbarConfig: MatSnackBarConfig = {
            panelClass: "vitu-material-snackbar-override",
            duration: buttonText ? undefined : duration,
            horizontalPosition,     // 'start' | 'center' | 'end' | 'left' | 'right'
            verticalPosition        // 'top' | 'bottom'
        };

        this.snackBar.openFromComponent(SnackBarContentComponent, {
            data: {
                text: message,
                tone,
                buttonText,
                closeFn: () => this.close()
            },
            ...snackbarConfig
        });

        return this.closeEmitter.asObservable();
    }

    close() {
        this.snackBar.dismiss();
        this.closeEmitter.next();
    }

    constructor(private snackBar: MatSnackBar) {}

}
