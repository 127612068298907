import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TitleBarComponent } from "./title-bar.component";
import { RouterModule } from "@angular/router";
import { BreadcrumbsModule } from "../breadcrumbs";

@NgModule({
    declarations: [
        TitleBarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BreadcrumbsModule
    ],
    exports: [
        TitleBarComponent
    ]
})
export class TitleBarModule { }
