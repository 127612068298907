import { Action } from "@ngrx/store";
import { CreateUserDto } from "@merchant_api/models/create-user-dto";
import { RoleDto } from "@merchant_api/models/role-dto";
import { UserDetailsDto } from "@merchant_api/models/user-details-dto";


export enum ActionTypes {
    GetUser = "[User] GetUser",
    GetNewUser = "[User] GetNewUser",
    GetUserSucceed = "[User] GetUserSucceed",
    GetUserFail = "[User] GetUserFail",
    UpdateUser = "[User] UpdateUser",
    DeleteUser = "[User] DeleteUser",
    CreateUser = "[User] CreateUser",
    ResetUserPassword = "[User] ResetUserPassword",
}

export class GetUserAction implements Action {

    constructor(
        public id: string
    ) {}

    readonly type = ActionTypes.GetUser;
}

export class GetNewUserAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewUser;
}

export class GetUserSucceedAction implements Action {

    constructor(
        public user: UserDetailsDto,
        public roleList: RoleDto[]
    ) {}

    readonly type = ActionTypes.GetUserSucceed;
}

export class GetUserFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetUserFail;
}

export class UpdateUserAction implements Action {

    constructor(public user: UserDetailsDto) {}

    readonly type = ActionTypes.UpdateUser;
}

export class DeleteUserAction implements Action {

    constructor(public userId: string) {}

    readonly type = ActionTypes.DeleteUser;
}

export class CreateUserAction implements Action {

    constructor(public user: CreateUserDto) {}

    readonly type = ActionTypes.CreateUser;
}

export class ResetUserPasswordAction implements Action {

    constructor(public userId: string) {}

    readonly type = ActionTypes.ResetUserPassword;

}
