import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ActionTypes, GetRolesAction, GetRolesFailAction, GetRolesSucceedAction } from "./roles.actions";
import { of } from "rxjs";
import { RolesService } from "@merchant_api/services/roles.service";

@Injectable()
export class RolesEffects {

    constructor(
        private actions$: Actions,
        private rolesService: RolesService
    ) {}

    initRolesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith("/dashboard/roles")),
            map(() => new GetRolesAction())
        )
    );

    getRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetRolesAction>(ActionTypes.GetRoles),
            switchMap(() =>
                this.rolesService.rolesSearch().pipe(
                    switchMap((roles) => of(new GetRolesSucceedAction(roles))),
                    catchError((error) => of(new GetRolesFailAction(error)))
                )
            )
        ),
    );

}
