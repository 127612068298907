import { ActionTypes } from "./next-payment-actions.actions";
import { NextPaymentActionsState } from "./next-payment-actions.state";

export const NextPaymentActionsReducer = (
    state: NextPaymentActionsState = new NextPaymentActionsState(),
    action: any,
): NextPaymentActionsState => {

    switch (action.type) {

        case ActionTypes.GetNextPaymentActions: {

            return {
                ...state,
                loading: true,
                record: []
            };
        }

        case ActionTypes.GetNextPaymentActionsSucceed: {

            return {
                ...state,
                loading: false,
                record: action.record,
                error: null
            };
        }

        case ActionTypes.GetNextPaymentActionsFail: {

            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default:
            return state;
    }
};
