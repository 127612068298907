import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VituSelectComponent } from "./vitu-select.component";
import { FormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    declarations: [
        VituSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatOptionModule,
        MatSelectModule
    ],
    exports: [
        VituSelectComponent
    ]
})
export class VituSelectModule { }
