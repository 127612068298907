<app-client-details
    [isCreate]="isCreate"
    [client]="client$ | async"
    [rolesLookup]="rolesLookup$ | async"
    [currentUser]="currentUser$ | async"
    [loading]="loading$ | async"
    [secrets]="secrets$ | async"
    [secretsLoading]="secretsLoading$ | async"
    [secretsError]="secretsError$ | async"
    (deleteClient)="onDeleteClient($event)"
    (updateClient)="onUpdateClient($event)"
    (createClient)="onCreateClient($event)"
    (createClientSecret)="onCreateClientSecret()"
    (deleteClientSecret)="onDeleteClientSecret($event)"
    (back)="onBack()">
</app-client-details>
