import { Component, Input } from "@angular/core";

@Component({
    selector: "app-roles-table-cell-content",
    templateUrl: "./roles-table-cell-content.component.html",
    styleUrls: ["./roles-table-cell-content.component.less"],
})
export class RolesTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;
}
