import { TransactionSiblingsState } from "./transaction-siblings.state";
import { ActionTypes } from "./transaction-siblings.actions";

export const TransactionSiblingsReducer = (
    state: TransactionSiblingsState = new TransactionSiblingsState(),
    action: any,
  ): TransactionSiblingsState => {

    switch (action.type) {

      case ActionTypes.GetTransactionSiblingsDetail: {

        return {
          ...state,
          loading: true,
          record: []
        };
      }

      case ActionTypes.GetTransactionSiblingsDetailSucceed: {

        return {
          ...state,
          loading: false,
          record: action.record,
          error: null
        };
      }

      case ActionTypes.GetTransactionSiblingsDetailFail: {

        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      default:
        return state;
    }
  };
