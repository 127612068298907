import { Component, Input } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { ActionButtonKind } from "../action-button";

@Component({
    selector: "lib-copy-to-clipboard-button",
    templateUrl: "./copy-to-clipboard-button.component.html",
    styleUrls: ["./copy-to-clipboard-button.component.less"],
})
export class CopyToClipboardButtonComponent {

    @Input() value: string;

    ActionButtonKind = ActionButtonKind;

    constructor(private clipboard: Clipboard) {}

    onClick(): void {
        if (this.value) {
            this.clipboard.copy(this.value);
        }
    }

}
