import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";

import { MultiStageExpansionPanelModule } from "../multi-stage-expansion-panel/multi-stage-expansion-panel.module";
import { DashboardCommonComponent } from "./dashboard-common.component";
import { DashboardSidebarModule } from "./dashboard-sidebar/dashboard-sidebar.module";
import { DashboardSubmenuModule } from "./dashboard-submenu/dashboard-submenu.module";

@NgModule({
    declarations: [
        DashboardCommonComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        RouterModule,
        DashboardSidebarModule,
        DashboardSubmenuModule,
        MultiStageExpansionPanelModule
    ],
    exports: [
        DashboardCommonComponent
    ]
})
export class DashboardCommonModule { }
