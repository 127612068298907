import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "lib-vitu-form-field-switch",
    templateUrl: "./vitu-form-field-switch.component.html",
    styleUrls: ["./vitu-form-field-switch.component.less"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi:true,
            useExisting: VituFormFieldSwitchComponent
        }
    ]
})
export class VituFormFieldSwitchComponent implements ControlValueAccessor, OnInit {

    @Input() label = "";
    @Input() useSlider = true;

    @Output() changed = new EventEmitter<boolean>();

    onChange = (value: any) => {};

    onTouched = () => {};

    touched = false;
    checked = false;
    disabled = false;

    formControlName: any;

    labelBefore: boolean;

    ngOnInit(): void {
        this.labelBefore = !this.useSlider;
    }

    onToggled(checked: boolean) {
        this.checked = checked;
        this.markAsTouched();
        this.onChange(this.checked);
        this.changed.emit(this.checked);
    }

    // from ControlValueAccessor
    writeValue(value: any) {
        this.checked = value;
    }

    // from ControlValueAccessor
    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    // from ControlValueAccessor
    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    // from ControlValueAccessor
    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    private markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

}
