import { ModalAction, ModalActionType } from "@admin_app/shared/modal-action";

export interface CreateClientSecretParams {
    clientId?: number;
    description?: string;
    localExpirationDate?: null | string;
    hashType?: string;
    type?: string;
    value?: string;
}

export class CreateClientSecretModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: CreateClientSecretParams) {
        super(action, params);
    }
}
