import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "shared-lib";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService
    ) {}

    canActivate() {

        return this.authService.validateToken();
    }
}
