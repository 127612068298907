import { Component, Input, OnChanges } from "@angular/core";
import { LocalTimePoint } from "../local-time-point";
import { DateTimeLabelUtils } from "./date-time-label-utils";

@Component({
    selector: "lib-date-time-label",
    templateUrl: "./date-time-label.component.html",
    styleUrls: ["./date-time-label.component.less"],
})
export class DateTimeLabelComponent implements OnChanges {

    @Input() dateUtc: string;
    @Input() singleLine = false;
    @Input() rawDateWithNoTime = false;

    dateUtcWithTimeZone: string;

    LocalTimePoint = LocalTimePoint;

    ngOnChanges() {
        // TODO :
        // Time zone info should really be supplied by APIs always,
        // but some APIs don't currently do this (so we clean it here).
        // See ticket VPAY-2121. Can remove the following code when that
        // ticket is resolved.
        if ((typeof this.dateUtc === "string") && this.dateUtc.length) {
            this.dateUtcWithTimeZone = DateTimeLabelUtils.ensureTimeZone(this.dateUtc);
        }
    }

}
