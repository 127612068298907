import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { TableSorting, Pager, ActionButtonKind } from "shared-lib";

import { Sorting, Filter } from "@merchant_app/storage/transactions/transactions.state";
import { Transaction } from "@merchant_api/models/transaction";
import { Payment } from "@merchant_api/models/payment";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

type RowsData = Transaction[];

@Component({
    selector: "app-transaction-log",
    templateUrl: "./transaction-log.component.html",
    styleUrls: ["./transaction-log.component.less"],
})
export class TransactionLogComponent {

    @Input() currentUser: CurrentUserDto;
    @Input() loading = false;
    @Input() error: Error;
    @Input() exportLoading = false;
    @Input() rowsData: RowsData;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;
    @Input() filter: Filter;

    @Output() filterChanged = new EventEmitter<Filter>();
    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() exportToCsv = new EventEmitter<void>();

    ActionButtonKind = ActionButtonKind;

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {}

    onSortingChanged(sort: Sorting|TableSorting) {
        const sorting = sort as Sorting;
        this.sortingChanged.emit(sorting);
    }

    onRowSelected(rowData: string|Payment): void {
        if (!this.hasTransactionsGetAndPaymentsGetPermissions) {
            return;
        }
        const token = rowData as string;
        this.router.navigate([`/dashboard/transactions/${token}`]);
    }

    exportButtonDisabled() {
        return this.exportLoading
            || !this.filter?.localDateInterval?.from
            || !this.filter?.localDateInterval?.to;
    }

    get hasTransactionsGetAndPaymentsGetPermissions(): boolean {
        return this.currPerms.has(["core.payments.get", "core.transactions.get"]);
    }

}
