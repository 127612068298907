<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Configurations' }]">
    </lib-title-bar>

    <lib-table-base
        class="tids-table"
        [columnDefs]="tidColumnDefs"
        [rowsData]="tids"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Configurations"
        [rowsDataShowWhileLoading]="true"
        selectedRowProperty="id"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-configurations-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-configurations-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
