import { Pipe, PipeTransform } from "@angular/core";
import { VituNumberFormatCommon } from "./vitu-number-format-common";

@Pipe({
  name: "vituCurrencyUsd"
})
export class VituNumberFormatCurrencyUsdPipe implements PipeTransform {

    // arg1 : (optional) => pass "showZero" if you want to show zero values (i.e. "$ 0.00")
    transform(value: any, arg1?: string): string {

        let retVal;

        try {
            retVal = `$ ${VituNumberFormatCommon.transform(value, false, arg1)}`;
        }
        catch (error) {
            retVal = "ERROR!";
        }

        return retVal;
    }

}

