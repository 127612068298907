import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getMidsState = (store: IStore) => store.mids;

export const getMids = createSelector(
    getMidsState,
    merchantsState => merchantsState.record,
);

export const getMidsLoading = createSelector(
    getMidsState,
    merchantsState => merchantsState.loading,
);

export const getMidsError = createSelector(
    getMidsState,
    merchantsState => merchantsState.error,
);
