<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Developer Tools' }, { 'label': 'Clients' }]">
        <lib-action-button *ngIf="hasCreatePermission"
            label="New Client" fontImage="far fa-plus-circle fa-bg" (click)="onClickCreate()">
        </lib-action-button>
    </lib-title-bar>

    <lib-table-base
        class="clients-table"
        [columnDefs]="clientColumnDefs"
        [rowsData]="clients"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Clients"
        selectedRowProperty="id"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-clients-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-clients-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
