import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { ActionButtonKind, ValidatorsVituCustom } from "shared-lib";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { WebhookDto } from "@merchant_api/models/webhook-dto";
import { WebhookEventTypesDto } from "@merchant_api/models/webhook-event-types-dto";
import { LookupDto } from "@merchant_api/models/lookup-dto";
import { CreateWebhookDto } from "@merchant_api/models/create-webhook-dto";
import { UpdateWebhookDto } from "@merchant_api/models/update-webhook-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-webhook-details",
    templateUrl: "./webhook-details.component.html",
    styleUrls: ["./webhook-details.component.less"],
})
export class WebhookDetailsComponent implements OnInit, OnChanges {

    @Input() isCreate: boolean;
    @Input() webhook: WebhookDto;
    @Input() lookups: WebhookEventTypesDto;
    @Input() currentUser: CurrentUserDto;
    @Input() loading: boolean;
    @Input() generatedSecret: string;
    @Input() generatedSecretLoading: boolean;

    @Output() generateSecret = new EventEmitter<void>();
    @Output() deleteWebhook = new EventEmitter<number>();
    @Output() updateWebhook = new EventEmitter<{id: number; webhook: UpdateWebhookDto}>();
    @Output() createWebhook = new EventEmitter<CreateWebhookDto>();
    @Output() back = new EventEmitter<void>();

    submitButtonText: string;
    showSecret = false;

    ActionButtonKind = ActionButtonKind;

    constructor(
        private fb: UntypedFormBuilder,
        private currPerms: CurrentUserPermissions
    ) {}

    webhookForm = this.fb.group({
        url: [null, [Validators.required, ValidatorsVituCustom.validHttpsUrl]],
        eventTypes: [null as Array<{id: string; name: string; checked: boolean}>],
        isActive: [true],
        secret: [null, [Validators.required]]
    });

    get formControls(): FormControls<any> { return this.webhookForm.controls; }

    formControl(controlName: string) { return this.webhookForm.controls[controlName] as UntypedFormControl; }

    get viewOnly(): boolean {
        return !this.isCreate && !this.hasUpdateWebhookPermission;
    }

    onClickGenerateSecret() {
        this.generateSecret.emit();
    }

    onClickToggleShowSecret() {
        this.showSecret = !this.showSecret;
    }

    ngOnInit() {

        this.submitButtonText = (this.isCreate ? "Submit" : "Update");

        if (this.isCreate) {
            this.formControls?.secret.disable();
        }
    }

    get pageTitle(): string {
        let firstPart: string;
        if (this.isCreate) {
            firstPart = "New";
        }
        else if(this.hasUpdateWebhookPermission) {
            firstPart = "Edit";
        }
        else {
            firstPart = "View";
        }
        return firstPart + " Webhook";
    }

    ngOnChanges(changes: SimpleChanges) {

        if ("webhook" in changes && this.webhook) {

            this.webhookForm.patchValue(this.webhook);

            if (!this.isCreate) {
                this.webhookForm.markAllAsTouched();
            }
        }

        if ("lookups" in changes && this.lookups) {
            this.formControls?.eventTypes.patchValue(this.getFormArray());
        }

        if ("generatedSecret" in changes && this.generatedSecret) {

            this.webhookForm.markAsDirty();    // because we're dirtying it manually here (ie. programmatic)
            this.formControls?.secret.patchValue(this.generatedSecret);
        }
    }

    private getFormArray(): Array<{id: string; name: string; checked: boolean}> {

        return this.lookups?.eventTypes?.map(i => ({
            id: i.id,
            name: i.name,
            checked: this.isChecked(i, this.webhook)
        }));
    }

    private isChecked(eventType: LookupDto, webhook: WebhookDto) {

        return webhook?.eventTypes
            ? webhook.eventTypes?.indexOf(eventType.id) >= 0
            : false;
    }

    onClickDelete() {

        this.deleteWebhook.emit(this.webhook.id);
    }

    onClickBack() {

        this.back.emit();
    }

    onSubmit() {

        const webhookForm = this.webhookForm.value;

        const url = webhookForm.url;
        const eventTypes = webhookForm.eventTypes.filter(i => i.checked).map(i => i.id);

        if (this.isCreate) {
            const createWebhookDto: CreateWebhookDto = {
                url,
                eventTypes
            };
            this.createWebhook.emit(createWebhookDto);
        }
        else {
            const secret = webhookForm.secret;
            const isActive = webhookForm.isActive;
            const updateWebhookDto: UpdateWebhookDto = {
                url,
                eventTypes,
                secret,
                isActive
            };
            this.updateWebhook.emit({
                id: this.webhook.id,
                webhook: updateWebhookDto
            });
        }

    }

    get hasDeleteWebhookPermission(): boolean {
        return this.currPerms.has(["core.webhooks.delete"]);
    }

    get hasUpdateWebhookPermission(): boolean {
        return this.currPerms.has(["core.webhooks.update"]);
    }

    get hasGenerateSecretPermission(): boolean {
        return this.currPerms.has(["core.webhooks.generate-secret"]);
    }

}
