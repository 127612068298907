import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Filter } from "@merchant_app/storage/payments/payments.state";
import { CardBrandEnum } from "@merchant_api/models/card-brand-enum";
import { TransactionType } from "@merchant_api/models/transaction-type";
import { DisputeTypeEnum } from "@merchant_api/models/dispute-type-enum";
import { LocalTimePoint } from "shared-lib";
import { GatewayEnum } from "@merchant_api/models/gateway-enum";
import { PaymentStatus } from "@merchant_api/models/payment-status";
import { DigitalWalletEnum } from "@merchant_api/models/digital-wallet-enum";

@Component({
    selector: "app-group-transaction-log-filters",
    templateUrl: "./group-transaction-log-filters.component.html",
    styleUrls: ["./group-transaction-log-filters.component.less"]
})
export class GroupTransactionLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();

    @Input() filter: Filter;

    gatewayList: Array<GatewayEnum>;
    typeList: Array<TransactionType>;
    digitalWalletList: Array<DigitalWalletEnum>;

    readonly moreFields = ["cardBrands", "disputeTypes", "gateways", "tid", "localDateSettledInterval", "mid", "digitalWallets"];

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.gatewayList = Object.values(GatewayEnum);
        this.typeList = Object.values(TransactionType).sort();
        this.digitalWalletList = Object.values(DigitalWalletEnum);
    }

    amountMask = {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        radix: ".",
        mapToRadix: [","]
    };

    filterForm = this.fb.group({
        localDateInterval: [null],
        localDateSettledInterval: [null],
        amount: [null],
        first6: [null, [Validators.pattern(/^\d{6}$/)]],
        last4: [null, [Validators.pattern(/^\d{4}$/)]],
        payerName: [null, [Validators.pattern(/^[a-zA-Z0-9\s]+$/)]],
        cardBrands: [null],
        disputeTypes: [null],
        token: [null],
        statuses: [null],
        gateways: [null],
        tid: [null],
        mid: [null],
        digitalWallets: [null]
    });

    cardList = [
        CardBrandEnum.Amex,
        CardBrandEnum.Discover,
        CardBrandEnum.MasterCard,
        CardBrandEnum.Visa,
    ];

    disputeTypeList = Object.values(DisputeTypeEnum);

    statusList = Object.values(PaymentStatus);

    onFilterChanged(filter: any) {

        this.filterChanged.emit(filter);
    }

}
