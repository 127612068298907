<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.DateCreated">
        <lib-date-time-label [dateUtc]="element.dateCreated"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'type'">
        <lib-text-table-column>
            {{ displayTextForTransactionType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-transaction-status-chip
            [status]="element?.status">
        </lib-transaction-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.Amount">
        <lib-text-table-column>
            {{ element.amount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.PayerName">
        <lib-text-table-column>
            {{ getDisplayTextForPayerName() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.CardBrand">
        <span *ngIf="element.card?.cardBrand" class="card-image">
            <i [class]="'fab fa-cc-' + element.card.cardBrand.toLowerCase() + ' fa-2x'"></i>
        </span>
        <lib-text-table-column *ngIf="!element.card?.cardBrand">
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.CardNumber">
        <lib-text-table-column>
            {{ getDisplayTextForCardNumber() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingTransactionOrderBy.TransactionId">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feesOnly'">
        <lib-binary-status-icon
            [value]="element?.feesOnly"
            [showPositiveOnly]="true">
        </lib-binary-status-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'DigitalWallet'">
        <lib-digital-wallet-image-table-column
            [kind]="element.digitalWallet">
        </lib-digital-wallet-image-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
