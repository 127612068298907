<div class="outer-container" [formGroup]="formGroup">

    <mat-form-field class="form-field" [ngClass]="{'global-form-field-highlight-non-empty': _isFilter}">

        <mat-label>
            <span>
                {{ label }}
            </span>
            <lib-vitu-tooltip
                *ngIf="!!infoTip?.length"
                class="info-tip"
                [text]="infoTip">
                    <i class="far fa-info-circle"></i>            
            </lib-vitu-tooltip>
        </mat-label>

        <input
            [ngClass]="{'obscured': type === 'obscured'}"
            #inputElement
            [type]="inputType"
            matInput
            [formControlName]="formFieldName"
            [formControl]="formControl"
            [readonly]="_readonly"
            [required]="hasRequiredValidator"
            [disableControl]="_disabled"
            [autocomplete]="autocomplete"
            [maxlength]="maxlength"
            [imask]="mask"
            [unmask]="unmaskInputType"
            [libTwoDigitDecimalNumber]="(type === 'twoDigitDecimal') || (type === 'twoDigitDecimalAllowNegative') || (type === 'twoDigitDecimalAllowZeroNotNegative')"
            [libTwoDigitDecimalNumberAllowNegative]="type === 'twoDigitDecimalAllowNegative'"
            [libTwoDigitDecimalNumberAllowZeroNotNegative]="type === 'twoDigitDecimalAllowZeroNotNegative'"
            (focus)="updateOutlineGap()"
            (blur)="onBlur($event)"
            (change)="onChange($event)">

        <span *ngIf="prefix" matPrefix>{{ prefix + '&nbsp;' }}</span>

        <button *ngIf="showReset"
            mat-button matSuffix mat-icon-button
            libStopPropagation
            aria-label="Clear" (click)="handleReset.emit()">
            <mat-icon>close</mat-icon>
        </button>

        <button *ngIf="showButton"
            mat-button matSuffix mat-icon-button
            libStopPropagation
            (click)="handleButton.emit()">
            <mat-icon>{{ showButton }}</mat-icon>
        </button>

        <mat-error *ngFor="let item of errors | keyvalue">{{ resolveError(errors, item.key) }}</mat-error>

    </mat-form-field>

    <div *ngIf="(type === 'obscured') && inputElement.value" class="toggle-show-obscured" (click)="onClickToggleShowObscured()">
        <i [class]="'far ' + (showObscured ? 'fa-eye-slash' : 'fa-eye')"></i>
    </div>

</div>
