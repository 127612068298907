import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getWebhookState = (store: IStore) => store.webhook;

export const getWebhookInfo = createSelector(
    getWebhookState,
    webhookState => webhookState.webhook,
);

export const getWebhookLookups = createSelector(
    getWebhookState,
    webhookState => webhookState.lookups,
);

export const getWebhookLoading = createSelector(
    getWebhookState,
    webhookState => webhookState.loading,
);

export const getToggleWebhooksLoading = createSelector(
    getWebhookState,
    webhookState => webhookState.toggleWebhooksLoading,
);
