import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as ClientActions from "@merchant_app/storage/client/client.actions";
import * as ClientSelectors from "@merchant_app/storage/client/client.selectors";
import * as CurrentUserSelectors from "@merchant_app/storage/current-user/current-user.selectors";
import * as ClientSecretsActions from "@merchant_app/storage/client-secrets/client-secrets.actions";
import * as ClientSecretsSelectors from "@merchant_app/storage/client-secrets/client-secrets.selectors";
import { GoBackAction } from "@merchant_app/storage/router/router.actions";
import { UpdateClientDto } from "@merchant_api/models/update-client-dto";
import { CreateClientDto } from "@merchant_api/models/create-client-dto";

@Component({
    selector: "app-client",
    templateUrl: "./client.component.html",
    styleUrls: ["./client.component.less"]
})
export class ClientComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    client$ = this.store.select(ClientSelectors.getClientInfo);
    rolesLookup$ = this.store.select(ClientSelectors.getClientRoles);
    loading$ = this.store.select(ClientSelectors.getClientLoading);
    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);
    secrets$ = this.store.select(ClientSecretsSelectors.getClientSecrets);
    secretsLoading$ = this.store.select(ClientSecretsSelectors.getClientSecretsLoading);
    secretsError$ = this.store.select(ClientSecretsSelectors.getClientSecretsError);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;

        if (this.isCreate) {
            this.store.dispatch(new ClientActions.GetNewClientAction());
        }
        else {
            this.store.dispatch(new ClientSecretsActions.ResetClientSecretsAction());
            this.store.dispatch(new ClientSecretsActions.GetClientSecretsAction(this.route.snapshot.params.id));
            this.store.dispatch(new ClientActions.GetClientAction(this.route.snapshot.params.id));
        }
    }

    onUpdateClient(payload: {id: number; client: UpdateClientDto}) {

        this.store.dispatch(new ClientActions.UpdateClientAction(payload.id, payload.client));
    }

    onCreateClient(client: CreateClientDto) {

        this.store.dispatch(new ClientActions.CreateClientAction(client));
    }

    onDeleteClient(clientId: number) {

        this.store.dispatch(new ClientActions.DeleteClientAction(clientId));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

    onCreateClientSecret(): void {

        this.store.dispatch(new ClientActions.AddClientSecretAction());
    }

    onDeleteClientSecret(secretId: number): void {

        this.store.dispatch(new ClientActions.DeleteClientSecretAction(secretId));
    }

}
