import * as E from "linq";

export enum VituHttpStatus {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503
}

export class VituHttpError extends Error {

    status: number;
    baseError: any;

    constructor(message: string, baseError: any) {
        super(message);
        this.baseError = baseError;
    }

}

export class VituHttpErrorUnauthorized extends VituHttpError {

    static create = (err: any) => new VituHttpErrorUnauthorized(err);

    private constructor(err: any) {
        super("Unauthorized", err);
        this.status = VituHttpStatus.Unauthorized;
    }

}

export class VituHttpErrorInternalServerError extends VituHttpError {

    static create = (err: any) => new VituHttpErrorInternalServerError(err);

    private constructor(err: any) {

        super("Internal Server Error", err);
        this.status = VituHttpStatus.InternalServerError;
    }

}

export class VituHttpErrorForbidden extends VituHttpError {

    static create = (err: any) => new VituHttpErrorForbidden(err);

    private constructor(err: any) {
        super("Forbidden", err);
        this.status = VituHttpStatus.Forbidden;
    }

}

export class VituHttpErrorNotFound extends VituHttpError {

    static create = (err: any) => new VituHttpErrorNotFound(err);

    private constructor(err: any) {
        super("Not Found", err);
        this.status = VituHttpStatus.NotFound;
    }

}

export class VituHttpErrorServiceUnavailable extends VituHttpError {

    static create = (err: any) => new VituHttpErrorServiceUnavailable(err);

    private constructor(err: any) {
        super("Service Unavailable", err);
        this.status = VituHttpStatus.ServiceUnavailable;
    }

}

export class VituHttpErrorUnknown extends VituHttpError {

    static create = (err: any) => new VituHttpErrorUnknown(err);

    private constructor(err: any) {
        super("Unknown Error", err);
        this.status = err.status;
    }

}

export class VituHttpErrorUnableToConnect extends VituHttpError {

    static create = (err: any) => new VituHttpErrorUnableToConnect(err);

    private constructor(err: any) {
        super("Unable to connect", err);
        this.status = err.status;
    }

}

export class VituHttpErrorBadRequest extends VituHttpError {

    static create(err: any): VituHttpErrorBadRequest {

        let errorMessage: string;

        let errorResponse = err.error;

        try {

            errorResponse = JSON.parse(errorResponse);
        }
        catch {}

        if (errorResponse?.errors) {

            errorMessage = JSON.stringify(errorResponse.errors);
        }

        let errors = errorResponse?.errors;

        if (errors) {

            if (typeof errors == "object" && !Array.isArray(errors)) {

                errors = E.from(Object.keys(errors))
                    .selectMany(i => Array.isArray(errors[i]) ? errors[i] : [errors[i]])
                    .toArray();
            }

            if (Array.isArray(errors)) {

                errorMessage = E.from(errors)
                    .select(i => i.value || i)
                    .firstOrDefault();
            }
        }

        if (!errorMessage && errorResponse?.detail) {
            if ((typeof errorResponse.detail === "string") && errorResponse.detail.length) {
                errorMessage = errorResponse.detail;
            }
        }

        if (!errorMessage && errorResponse?.title) {
            if ((typeof errorResponse.title === "string") && errorResponse.title.length) {
                errorMessage = errorResponse.title;
            }
        }

        if (!errorMessage) {
            errorMessage = "Bad Request";
        }

        return new VituHttpErrorBadRequest(errorMessage, err);
    }

    private constructor(errorMessage: string, err: any) {
        super(errorMessage, err);
        this.status = VituHttpStatus.BadRequest;
    }

}
