import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { TransactionsFilter } from "@admin_app/storage/funding-item/funding-item.state";

@Component({
    selector: "app-funding-item-transaction-filters",
    templateUrl: "./funding-item-transaction-filters.component.html",
    styleUrls: ["./funding-item-transaction-filters.component.less"]
})
export class FundingItemTransactionFiltersComponent {

    @Output() transactionsFilterChanged = new EventEmitter<TransactionsFilter>();
    @Input() transactionsFilter: TransactionsFilter;

    feesOnlyList = [
        "No",
        "Yes"
    ];

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {}

    filterForm = this.fb.group({
//        localDateInterval: [null],
        localFeesOnly: [null]
    });

    onTransactionsFilterChanged(filter: TransactionsFilter) {
        this.transactionsFilterChanged.emit(filter);
    }

}
