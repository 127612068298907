import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";

import { TableBaseComponent } from "./table-base.component";
import { ColumnFlexDirective } from "./column-flex-directive";
import { BusyIndicatorModule } from "../busy-indicator/busy-indicator.module";
import { PagerModule } from "../pager/pager.module";
import { TableColumnSortComponent } from "./table-column-sort/table-column-sort.component";
import { VituTooltipModule } from "../tooltip";

@NgModule({
    declarations: [
        ColumnFlexDirective,
        TableColumnSortComponent,
        TableBaseComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        BusyIndicatorModule,
        PagerModule,
        VituTooltipModule
    ],
    exports: [
        TableBaseComponent,
        TableColumnSortComponent
    ]
})
export class TableBaseModule { }
