<app-funding-log
    [funding]="funding$ | async"
    [fundingLoading]="fundingLoading$ | async"
    [fundingError]="fundingError$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)">
</app-funding-log>
