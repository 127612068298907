import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, Pager } from "shared-lib";
import { TidResult } from "@merchant_api/models/tid-result";

@Component({
    selector: "app-configuration-log",
    templateUrl: "./configuration-log.component.html",
    styleUrls: [ "./configuration-log.component.less" ]
})
export class ConfigurationLogComponent {

    @Input() tids: Array<TidResult>;
    @Input() loading = false;
    @Input() error: Error;

    @Input() pager: Pager;

    @Output() pageChanged = new EventEmitter<number>();

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    tidColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "tid", title: "TID", flexWidthBasisInPixels: 130, flexWidthGrow: 1},
        { id: "mid", title: "MID", flexWidthBasisInPixels: 140, flexWidthGrow: 1},
        { id: "gateway", title: "Gateway", flexWidthBasisInPixels: 90, flexWidthGrow: 1},
        { id: "feePlan", title: "Fee Plan", flexWidthBasisInPixels: 80, flexWidthGrow: 1},
        { id: "surchargePercent", title: "Surcharge", flexWidthBasisInPixels: 80, flexWidthGrow: 1},
        { id: "deviceSerial", title: "Device Serial", flexWidthBasisInPixels: 110, flexWidthGrow: 1},
        { id: "deviceName", title: "Device Name", flexWidthBasisInPixels: 115, flexWidthGrow: 1},
        { id: "deviceManufacturer", title: "Device Manufacturer", flexWidthBasisInPixels: 100, flexWidthGrow: 1},
        { id: "deviceModel", title: "Device Model", flexWidthBasisInPixels: 80, flexWidthGrow: 1},
        { id: "deviceLocation", title: "Location", flexWidthBasisInPixels: 70, flexWidthGrow: 1},
        { id: "status", title: "Status", flexWidthBasisInPixels: 100, flexWidthGrow: 1},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

}
