<app-event-details
    [event]="event$ | async"
    [eventLoading]="eventLoading$ | async"
    [eventDeliveries]="eventDeliveries$ | async"
    [eventDeliveriesLoading]="eventDeliveriesLoading$ | async"
    [eventDeliveriesError]="eventDeliveriesError$ | async"
    (cancel)="onCancel()"
    (resend)="onResend($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)">
</app-event-details>
