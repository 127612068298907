import { Action } from "@ngrx/store";
import { PagedDataDtoOfTidResult } from "@merchant_api/models/paged-data-dto-of-tid-result";

export enum ActionTypes {
    ResetTids = "[TIDS] ResetTids",
    GetTids = "[TIDS] GetTids",
    GetTidsSucceed = "[TIDS] GetTidsSucceed",
    GetTidsFail = "[TIDS] GetTidsFail",
    PageChangedTids = "[TIDS] PageChangedTids"
}

export class ResetTidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetTids;
}

export class GetTidsAction implements Action {

    constructor(public page: number, public pageSize?: number) {}

    readonly type = ActionTypes.GetTids;
}

export class GetTidsSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfTidResult,
        public pageSize: number,
        public page: number) {}

    readonly type = ActionTypes.GetTidsSucceed;
}

export class GetTidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetTidsFail;
}

export class PageChangedTidsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedTids;
}
