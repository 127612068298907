<div class="outer-container">

    <div class="label-container" *ngIf="labelBefore">
        <lib-text-common class="label left">{{ label }}</lib-text-common>
    </div>

    <lib-vitu-slide-toggle
        *ngIf="useSlider"
        class="slider"
        [checked]="checked"
        (checkedChange)="onToggled($event)"
        [disabled]="disabled">
    </lib-vitu-slide-toggle>

    <mat-checkbox
        *ngIf="!useSlider"
        #checkbox
        class="checkbox"
        [checked]="checked"
        (change)="onToggled(checkbox.checked)"
        [disabled]="disabled">
    </mat-checkbox>

    <div class="label-container" *ngIf="!labelBefore">
        <lib-text-common class="label right">{{ label }}</lib-text-common>
    </div>

</div>
