import { FundingInstructionDetails } from "@merchant_api/models/funding-instruction-details";
import { FundingStatus } from "@merchant_api/models/funding-status";
import { FundingTransaction } from "@merchant_api/models/funding-transaction";
import { FundingTransactionOrderBy } from "@merchant_api/models/funding-transaction-order-by";
import { TransactionsFilter, TransactionsSorting } from "@merchant_app/storage/funding-item/funding-item.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionButtonKind, ColumnDef, LocalTimePoint, Pager, TableSorting } from "shared-lib";
import { FundingFee } from "@merchant_api/models/funding-fee";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";
import { AdjustmentDto } from "@merchant_api/models/adjustment-dto";

@Component({
    selector: "app-funding-item-details",
    templateUrl: "./funding-item-details.component.html",
    styleUrls: ["./funding-item-details.component.less"],
})
export class FundingItemDetailsComponent {

    @Input() currentUser: CurrentUserDto;

    @Input() fundingItem: FundingInstructionDetails;
    @Input() fundingItemLoading: boolean;

    @Input() fundingItemTransactions: Array<FundingTransaction>;
    @Input() fundingItemTransactionsLoading: boolean;
    @Input() fundingItemTransactionsError: Error;

    @Input() fundingItemMidFees: Array<FundingFee>;
    @Input() fundingItemMidFeesLoading: boolean;
    @Input() fundingItemMidFeesError: Error;

    @Input() fundingItemAdjustments: Array<AdjustmentDto>;
    @Input() fundingItemAdjustmentsLoading: boolean;
    @Input() fundingItemAdjustmentsError: Error;

    @Input() fundingItemExportLoading: boolean;

    @Input() transactionsPager: Pager;
    @Output() transactionsPageChanged = new EventEmitter<{ref: string; page: number}>();

    @Input() transactionsSorting: TransactionsSorting;
    @Output() transactionsSortingChanged = new EventEmitter<{ref: string; sorting: TransactionsSorting}>();

    @Input() transactionsFilter: TransactionsFilter;
    @Output() transactionsFilterChanged = new EventEmitter<{ref: string; filter: TransactionsFilter}>();

    @Output() cancel = new EventEmitter<void>();

    @Output() exportTransactionsToCsv = new EventEmitter<string>();

    merchantId: string;

    pageTitle = "View Funding Item";

    ActionButtonKind = ActionButtonKind;
    LocalTimePoint = LocalTimePoint;
    FundingStatus = FundingStatus;

    readonly transactionsColumnDefs: ColumnDef[];
    readonly midFeesColumnDefs: ColumnDef[];
    readonly adjustmentsColumnDefs: ColumnDef[];

    constructor(private currPerms: CurrentUserPermissions) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.transactionsColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: FundingTransactionOrderBy.DateCreated, title: `Date (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 3, canSort: true},
            {id: "type", title: "Type", flexWidthBasisInPixels: 65, flexWidthGrow: 4},
            {id: "status", title: "Status", flexWidthBasisInPixels: 115, flexWidthGrow: 4},
            {id: FundingTransactionOrderBy.Amount, title: "Amount", flexWidthBasisInPixels: 96, flexWidthGrow: 4, canSort: true},
            {id: FundingTransactionOrderBy.CardBrand, title: "Card", flexWidthBasisInPixels: 50, flexWidthGrow: 4, canSort: true},
            {id: FundingTransactionOrderBy.CardNumber, title: "Card Number", flexWidthBasisInPixels: 150, flexWidthGrow: 4, canSort: true},
            {id: "DigitalWallet", title: "Digital Wallet", flexWidthBasisInPixels: 75, flexWidthGrow: 4},
            {id: FundingTransactionOrderBy.PayerName,
                title: "Payer Name", flexWidthBasisInPixels: 120, flexWidthGrow: 8, canSort: true},
            {id: FundingTransactionOrderBy.TransactionId,
                title: "Transaction ID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true},
            {id: "feesOnly", title: "Fees Only", flexWidthBasisInPixels: 60, flexWidthGrow: 4},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
        ];

        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.midFeesColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: "name", title: "Name", flexWidthBasisInPixels: 300, flexWidthGrow: 0, canSort: true},
            {id: "category", title: "Category", flexWidthBasisInPixels: 300, flexWidthGrow: 0, canSort: true},
            {id: "amount", title: "Amount", flexWidthBasisInPixels: 200, flexWidthGrow: 0, canSort: true},
            {id: "isAbsorbed", title: "Absorbed By Vitu", flexWidthBasisInPixels: 200, flexWidthGrow: 0, canSort: true},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
        ];

        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.adjustmentsColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: "createdUtc", title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 1 },
            {id: "adjustmentDate", title: "Adjustment Date", flexWidthBasisInPixels: 130, flexWidthGrow: 1 },
            {id: "amount", title: "Amount", flexWidthBasisInPixels: 100, flexWidthGrow: 1 },
            {id: "reason", title: "Reason", flexWidthBasisInPixels: 130, flexWidthGrow: 1 },
            {id: "type", title: "Type", flexWidthBasisInPixels: 130, flexWidthGrow: 1},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
        ];
    }

    get vituPayDepositAmountInfoTip() {
        const preseveMultilineIndentation = `VituPay Deposit Amount =
    Transaction Fees
    + MID Fees
    + Chargeback Amount
    - Absorbed Fees`;
        return preseveMultilineIndentation;
    }

    get netTransactionAmountInfoTip() {
        const preseveMultilineIndentation = `Net Transaction Amount =
    Transaction Amount
    - Refund Amount
    + Rejected Amount`;
        return preseveMultilineIndentation;
    }

    get merchantDepositAmountInfoTip() {
        const preseveMultilineIndentation = `Merchant Deposit Amount =
    Net Transaction Amount
    - Fee Amount
    - Chargeback Amount`;
            return preseveMultilineIndentation;
    }

    onCancel(): void {

        this.cancel.emit();
    }

    onTransactionsPageChanged(page: number): void {
        this.transactionsPageChanged.emit({ref: this.fundingItem.ref, page});
    }

    onTransactionsSortingChanged(sorting: TableSorting): void {
        this.transactionsSortingChanged.emit({
            ref: this.fundingItem.ref,
            sorting: {
                orderBy: sorting.orderBy as FundingTransactionOrderBy,
                orderDirection: sorting.orderDirection
            }
        });
    }

    onTransactionsFilterChanged(filter: TransactionsFilter): void {
        this.transactionsFilterChanged.emit({
            ref: this.fundingItem.ref,
            filter
        });
    }

    onExportTransactionsToCsv(): void {
        this.exportTransactionsToCsv.emit(this.fundingItem.ref);
    }

    getTransactionsTabLabel(): string {
        return "Transactions";
    }

    getMidFeesTabLabel(): string {
        let count = 0;
        if (this.fundingItemMidFees?.length) {
            count = this.fundingItemMidFees.length;
        }
        return this.appendCountIfNecessary("MID Fees", count);
    }

/*
    getSettlementsTabLabel(): string {
        let count = 0;
        if (this.fundingItem?.settlementSummary?.length) {
            count = this.fundingItem.settlementSummary.length;
        }
        return this.appendCountIfNecessary("Settlements", count);
    }
*/

    getAdjustmentsTabLabel(): string {
        let count = 0;
        if (this.fundingItemAdjustments?.length) {
            count = this.fundingItemAdjustments.length;
        }
        return this.appendCountIfNecessary("Adjustments", count);
    }

    exportTransactionsButtonDisabled(): boolean {
        return this.fundingItemTransactionsLoading || this.fundingItemExportLoading;
    }

    get hasGetTransactionsReportPermission(): boolean {
        return this.currPerms.has(["core.funding.get-transactions-report"]);
    }

    private appendCountIfNecessary(title: string, count: number): string {
        return title + ((count > 0) ? ` (${count})` : "");
    }

}
