import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-image-field",
    templateUrl: "./image-field.component.html",
    styleUrls: ["./image-field.component.less"]
})
export class ImageFieldComponent {

    @Input() label = "";
    @Input() infoTip: string;
    @Input() fontImage: string|boolean;     // use boolean false to turn it off (& supply by ng-content)

    get hasFontImage() {
        return (typeof this.fontImage === "string") && (this.fontImage.length > 0);
    }

}
