<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'name'">
        <lib-text-table-column>
            {{ report.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'description'">
        <lib-text-table-column>
            {{ report.description }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'download'">
        <lib-vitu-tooltip text="Download">
            <i class="far fa-file-download global-table-cell-image-link" (click)="onClickDownload()"></i>
        </lib-vitu-tooltip>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
