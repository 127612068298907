import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-digital-wallet-image-field",
    templateUrl: "./digital-wallet-image-field.component.html",
    styleUrls: ["./digital-wallet-image-field.component.less"]
})
export class DigitalWalletImageFieldComponent {

    @Input() kind: string;

    get digitalWalletFontImage() {
        let retVal = null;
        switch (this.kind) {
            case "GooglePay": {
                retVal = "fab fa-google-pay";
                break;
            }
            case "ApplePay": {
                retVal = "fab fa-apple-pay";
                break;
            }
            case "PayPal": {
                retVal = "fab fa-paypal fa-xs";
                break;
            }
            case "Venmo": {
                retVal = false; // supplied instead by digitalWalletImageFile
                break;
            }
        }
        return retVal;
    }

    get digitalWalletImageFile() {
        let retVal = null;
        switch (this.kind) {
            case "Venmo": {
                retVal = "/assets/img/venmo-icon-dark.png";
                break;
            }
        }
        return retVal;
    }

}
