import { ModalAction, ModalActionType } from "../modal-action";

export interface DownloadReportParams {
    batchId?: string;
    merchantProcessorIds?: undefined;
    startDate?: undefined;
    endDate?: undefined;
    statementId?: string;
    merchantId?: number;
}

export class DownloadReportModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: DownloadReportParams) {
        super(action, params);
    }
}
