import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getEventDeliveriesState = (store: IStore) => store.eventDeliveries;

export const getEventDeliveries = createSelector(
    getEventDeliveriesState,
    eventDeliveriesState => eventDeliveriesState.rows,
);

export const getEventDeliveriesLoading = createSelector(
    getEventDeliveriesState,
    eventDeliveriesState => eventDeliveriesState.loading,
);

export const getEventDeliveriesError = createSelector(
    getEventDeliveriesState,
    eventDeliveriesState => eventDeliveriesState.error,
);

export const getEventDeliveriesPager = createSelector(
    getEventDeliveriesState,
    eventDeliveriesState => eventDeliveriesState.pager,
);

export const getEventDeliveriesSorting = createSelector(
    getEventDeliveriesState,
    eventDeliveriesState => eventDeliveriesState.sorting,
);
