import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getClientSecretsState = (store: IStore) => store.clientSecrets;

export const getClientSecrets = createSelector(
    getClientSecretsState,
    clientSecretsState => clientSecretsState.record,
);

export const getClientSecretsLoading = createSelector(
    getClientSecretsState,
    clientSecretsState => clientSecretsState.loading,
);

export const getClientSecretsError = createSelector(
    getClientSecretsState,
    clientSecretsState => clientSecretsState.error,
);
