/* tslint:disable */
/* eslint-disable */
export enum FundingTransactionOrderBy {
  TransactionId = 'TransactionId',
  Amount = 'Amount',
  DateCreated = 'DateCreated',
  CardBrand = 'CardBrand',
  CardNumber = 'CardNumber',
  PayerName = 'PayerName'
}
