import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextTableColumnModule } from "./text-table-column/text-table-column.module";
import { TextCommonModule } from "./text-common/text-common.module";
import { TextFieldModule } from "./text-field/text-field.module";
import { ImageFieldModule } from "./image-field/image-field.module";
import { DigitalWalletImageFieldModule } from "./digital-wallet-image-field";
import { DigitalWalletImageTableColumnModule } from "./digital-wallet-image-table-column";


@NgModule({
    imports: [
        CommonModule,
        TextCommonModule,
        TextTableColumnModule,
        TextFieldModule,
        ImageFieldModule,
        DigitalWalletImageFieldModule,
        DigitalWalletImageTableColumnModule
    ],
    exports: [
        TextCommonModule,
        TextTableColumnModule,
        TextFieldModule,
        ImageFieldModule,
        DigitalWalletImageFieldModule,
        DigitalWalletImageTableColumnModule
    ]
})
export class TextModule { }
