import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";

import * as DisputeSelectors from "@merchant_app/storage/dispute/dispute.selectors";
import { GoBackAction } from "@merchant_app/storage/router/router.actions";

@Component({
    selector: "app-dispute",
    templateUrl: "./dispute.component.html",
    styleUrls: ["./dispute.component.less"]
})
export class DisputeComponent {

    constructor(private store: Store<IStore>) {}

    dispute$ = this.store.select(DisputeSelectors.getDispute);
    disputeLoading$ = this.store.select(DisputeSelectors.getDisputeLoading);

    onCancel(): void {
        this.store.dispatch(GoBackAction());
    }

}
