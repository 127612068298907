import { DateRangeModalContentComponent } from "./date-range-modal-content.component";
import { Injectable } from "@angular/core";
import { DateRange } from "../date-range";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class DateRangeModal {

    constructor(
        private modal: MatDialog,
    ) {}

    create(clearDateRange: DateRange, initialDateRange: DateRange,
        options?: {
            restrictPast: boolean;
            restrictFuture: boolean;
            restrictToPastMonth: boolean;
            restrictToDateOfBirth: boolean;
            singleDateMode: boolean;
        }) {

        const modalInstance = this.modal.open(DateRangeModalContentComponent, {
            width: options?.singleDateMode ? "540px" : "800px",
        });

        modalInstance.componentInstance.clearDate = clearDateRange;
        modalInstance.componentInstance.initializeDate = initialDateRange;
        modalInstance.componentInstance.restrictPast = options.restrictPast;
        modalInstance.componentInstance.restrictFuture = options.restrictFuture;
        modalInstance.componentInstance.restrictToPastMonth = options.restrictToPastMonth;
        modalInstance.componentInstance.restrictToDateOfBirth = options.restrictToDateOfBirth;
        modalInstance.componentInstance.singleDateMode = options.singleDateMode;
        modalInstance.componentInstance.init();

        return modalInstance.afterClosed();
    }
}
