import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Pager } from "shared-lib";

import {
    FilterTransactionsAction,
    SortTransactionsAction,
    PageChangedTransactionsAction,
    ExportTransactionsToCsvAction
} from "@merchant_app/storage/transactions/transactions.actions";
import { Sorting, Filter } from "@merchant_app/storage/transactions/transactions.state";

import { getTransactionsState } from "@merchant_app/storage/transactions/transactions.selectors";
import { Transaction } from "@merchant_api/models/transaction";
import * as CurrentUserSelectors from "@merchant_app/storage/current-user/current-user.selectors";

type RowsData = Transaction[];

@Component({
    selector: "app-transactions",
    templateUrl: "./transactions.component.html",
    styleUrls: ["./transactions.component.less"],
})
export class TransactionsComponent {

    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);

    get rowsData$(): Observable<RowsData> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.rows));
    }

    get sorting$(): Observable<Sorting> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.sorting));
    }

    get pager$(): Observable<Pager> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.pager));
    }

    get loading$(): Observable<boolean> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.loading));
    }

    get error$(): Observable<Error> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.error));
    }

    get filter$(): Observable<Filter> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.filter));
    }

    get exportLoading$(): Observable<boolean> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.exportLoading));
    }

    constructor(private store: Store<IStore>) {}

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterTransactionsAction(filter));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortTransactionsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedTransactionsAction(page));
    }

    exportToCsv() {
        this.store.dispatch(new ExportTransactionsToCsvAction());
    }

}
