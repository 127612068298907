import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-digital-wallet-image-table-column",
    templateUrl: "./digital-wallet-image-table-column.component.html",
    styleUrls: ["./digital-wallet-image-table-column.component.less"],
})
export class DigitalWalletImageTableColumnComponent {

    @Input() kind: string;

}
