import { Action } from "@ngrx/store";
import { PagedDataDtoOfDisputeDto } from "@merchant_api/models/paged-data-dto-of-dispute-dto";
import { Filter, Sorting } from "./disputes.state";

export enum ActionTypes {
    GetDisputes = "[DISPUTES] GetDisputes",
    GetDisputesSucceed = "[DISPUTES] GetDisputesSucceed",
    GetDisputesFail = "[DISPUTES] GetDisputesFail",
    SortDisputes = "[DISPUTES] SortDisputes",
    PageChangedDisputes = "[DISPUTES] PageChangedDisputes",
    FilterDisputes = "[DISPUTES] FilterDisputes"
}

export class GetDisputesAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetDisputes;
}

export class GetDisputesSucceedAction implements Action {

    constructor(public response: PagedDataDtoOfDisputeDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetDisputesSucceed;
}

export class GetDisputesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetDisputesFail;
}

export class SortDisputesAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortDisputes;
}

export class PageChangedDisputesAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedDisputes;
}

export class FilterDisputesAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterDisputes;
}
