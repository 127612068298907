import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { of, Subject } from "rxjs";
import {
    GetPaymentDetailAction,
    ActionTypes,
    GetPaymentDetailSucceedAction,
    CapturePaymentTransactionAction,
    VoidPaymentTransactionAction,
    RefundPaymentTransactionAction,
    GetPaymentFeesAction,
    GetPaymentFeesSucceedAction,
    GetPaymentDetailFailAction,
    GetPaymentFeesFailAction
} from "./payment.actions";
import { PaymentsService } from "@merchant_api/services/payments.service";
import { NavigateAction } from "../router/router.actions";
import { GetTransactionSiblingsDetailAction } from "../transaction-siblings/transaction-siblings.actions";
import { MatDialog } from "@angular/material/dialog";
import { RefundPaymentModalComponent } from "@merchant_app/shared/refund-payment-modal/refund-payment-modal.component";
import { GetNextPaymentActionsAction } from "../next-payment-actions/next-payment-actions.actions";
import { GenericNoOpAction } from "../generic.actions";
import { ModalAction, ModalActionType } from "@merchant_app/shared/modal-action";
import { GetTransactionDetailAction } from "../transaction/transaction.actions";
import { TransactionResult } from "@merchant_api/models/transaction-result";
import { GlobalSpinnerService, VituToastService, VituToastTone } from "shared-lib";

@Injectable()
export class PaymentEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService,
        private toast: VituToastService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService
    ) { }

    getPayment = createEffect(() => this.actions.pipe(
            ofType<GetPaymentDetailAction>(ActionTypes.GetPaymentDetail),
            switchMap(action => this.paymentsService.paymentsGet({ token: action.token })
                    .pipe(
                        mergeMap(result => of(new GetPaymentDetailSucceedAction(result, action.getRelations))),
                        catchError((error) => of(new GetPaymentDetailFailAction(error)))
                    ))
        ));

    getPaymentSucceed = createEffect(() => this.actions.pipe(
            ofType<GetPaymentDetailSucceedAction>(ActionTypes.GetPaymentDetailSucceed),
            switchMap(action => [
                (action.getRelations)
                    ? new GetTransactionSiblingsDetailAction(action.record.transactions.map(transaction => transaction.token))
                    : new GenericNoOpAction(),
                new GetNextPaymentActionsAction(action.record.token),
                new GetPaymentFeesAction(action.record.token)
            ])
        ));

    refundPaymentTransaction = createEffect((): any => this.actions.pipe(
            ofType<RefundPaymentTransactionAction>(ActionTypes.RefundPaymentTransaction),
            switchMap(refundAction => {
                const errorMessageSubject = new Subject<string>();
                const modalActionSubject = new Subject<ModalAction>();

                const dialogRef = this.dialog
                    .open(RefundPaymentModalComponent, {
                        width: "700px",
                        data: {
                            refundData: refundAction.refund,
                            modalActionSubject,
                            errorMessageSubject
                        },
                        disableClose: true
                    });
                return modalActionSubject.pipe(
                    switchMap(({action, params}: ModalAction) => {
                        switch (action) {
                            case ModalActionType.CANCEL:
                            {
                                dialogRef.close();
                                return of();
                            }
                            case ModalActionType.CONFIRM:
                            {
                                return this.globalSpinner.apply(
                                    this.paymentsService.paymentsRefund(
                                        {
                                            token: params.token,
                                            body: params.refund
                                        })
                                        .pipe(
                                            switchMap((record: TransactionResult) => {
                                                dialogRef.close();
                                                return of(GetTransactionDetailAction(
                                                    { token: record.token, getRelations: true }));
                                            }),
                                            catchError(error => {
                                                errorMessageSubject.next(error instanceof Error ? error.message : error);
                                                return of();
                                            }),
                                        )
                                );
                            }
                        }
                    })
                );
            })
        ), { dispatch: true });

    // capturePaymentTransaction = createEffect(() => this.actions.pipe(
    //         ofType<CapturePaymentTransactionAction>(ActionTypes.CapturePaymentTransaction),
    //         switchMap(action => this.paymentsService.authorizationsCapture({ id: action.token, body: action.paymentTransaction })
    //                 .pipe(
    //                     mergeMap(response => {

    //                         this.toast.open("Payment updated successfully.", VituToastTone.Positive);
    //                         return of(NavigateAction({ payload: { path: [`/dashboard/transactions/${response.token}`] } }));
    //                     })
    //                 ))
    //     ));

    getPaymentFees = createEffect(() => this.actions.pipe(
            ofType<GetPaymentFeesAction>(ActionTypes.GetPaymentFees),
            switchMap(action => this.paymentsService.paymentsFees({ token: action.token })
                    .pipe(
                        mergeMap(result => of(new GetPaymentFeesSucceedAction(result))),
                        catchError((error) => of(new GetPaymentFeesFailAction(error)))
                    ))
        ));

}
