import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextTableColumnModule } from "../text-table-column";
import { DigitalWalletImageTableColumnComponent } from "./digital-wallet-image-table-column.component";

@NgModule({
    declarations: [
        DigitalWalletImageTableColumnComponent
    ],
    imports: [
        CommonModule,
        TextTableColumnModule
    ],
    exports: [
        DigitalWalletImageTableColumnComponent
    ]
})
export class DigitalWalletImageTableColumnModule { }
