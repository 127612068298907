import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardSubmenuComponent } from "./dashboard-submenu.component";
import { DashboardSidebarMenuItemModule } from "../dashboard-sidebar-menu-item/dashboard-sidebar-menu-item.module";

@NgModule({
    declarations: [
        DashboardSubmenuComponent
    ],
    imports: [
        CommonModule,
        DashboardSidebarMenuItemModule
    ],
    exports: [
        DashboardSubmenuComponent
    ]
})
export class DashboardSubmenuModule { }
