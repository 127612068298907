import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextFieldComponent } from "./text-field.component";
import { TextCommonModule } from "../text-common/text-common.module";

@NgModule({
    declarations: [
        TextFieldComponent
    ],
    imports: [
        CommonModule,
        TextCommonModule
    ],
    exports: [
        TextFieldComponent
    ]
})
export class TextFieldModule { }
