import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getStatementsState = (store: IStore) => store.statements;

export const getStatements = createSelector(
    getStatementsState,
    statementsState => statementsState.record,
);

export const getStatementsLoading = createSelector(
    getStatementsState,
    statementsState => statementsState.loading,
);
