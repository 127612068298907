<div 
    class="outer-container"
    [matTooltip]="text"
    [matTooltipDisabled]="isDisabled"
    [matTooltipClass]="tooltipClass"
    [matTooltipShowDelay]="showDelayInMillis"
    [matTooltipPosition]="calculatePosition">

    <ng-content></ng-content>

</div>
