<div class="outer-container" [ngClass]="{'outer-container-open': sidebarOpen }">
    <div class="menu">
        <ng-container *ngFor="let item of sidebarItems">

            <!-- <div *ngIf="item.hasSeparatorBefore" class="separator" [ngClass]="{'separator-open': sidebarOpen }"></div> -->

            <div #itemDiv *ngIf="hasPermissions(currentUserPermissions, item)"
                (mouseover)="onMouseOverItem(item, itemDiv)" (mouseout)="onMouseOutItem()">

                <lib-dashboard-sidebar-menu-item
                    [item]="item"
                    [sidebarOpen]="sidebarOpen"
                    [isItemActionable]="isItemActionable(item)"
                    [isItemActive]="isItemActive(item)"
                    [subMenuActive]="isItemSubMenuActive(item)"
                    (navigateTo)="onNavigateTo($event)">
                </lib-dashboard-sidebar-menu-item>
            </div>

        </ng-container>

    </div>

    <div>

        <!-- <div *ngIf="helpItem.hasSeparatorBefore" class="separator" [ngClass]="{'separator-open': sidebarOpen }"></div> -->

        <lib-dashboard-sidebar-menu-item
            [item]="helpItem"
            [sidebarOpen]="sidebarOpen"
            [isItemActionable]="isItemActionable(helpItem)"
            [isItemActive]="isItemActive(helpItem)"
            [subMenuActive]="isItemSubMenuActive(helpItem)"
            (navigateTo)="onNavigateTo($event)">
        </lib-dashboard-sidebar-menu-item>

        <div class="copyright-container">
            <div class="hide-contents" [ngClass]="{'show-contents': sidebarOpen }">
                <div class="copyright-note">
                    <lib-text-common>
                        {{ copyrightInfo }}
                    </lib-text-common>
                </div>
            </div>
        </div>
    </div>

</div>
