import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "./material.module";

import { TransactionsTableComponent } from "./transactions-table/transactions-table.component";
import { TransactionsTableCellContentComponent } from "./transactions-table/transactions-table-cell-content/transactions-table-cell-content.component"; // eslint-disable-line max-len
import { SafePipe } from "./safe.pipe";
import { RefundPaymentModalComponent } from "./refund-payment-modal/refund-payment-modal.component";

import { environment } from "@merchant_environments/environment";

import {
    ActionButtonModule,
    DashboardCommonModule,
    MultiStageExpansionPanelModule,
    TableBaseModule,
    StopPropagationModule,
    BusyIndicatorModule,
    GlobalSpinnerModule,
    ConfirmationModalModule,
    VituFormModule,
    VituFormFieldModule,
    ModalErrorMessageModule,
    ErrorHandlerModule,
    DateTimeModule,
    StatusChipModule,
    PagerModule,
    VituToastModule,
    TextModule,
    TitleBarModule,
    FiltersBaseModule,
    VituTooltipModule,
    UserStatusIconModule,
    FundingItemStatusChipModule,
    TransactionStatusChipModule,
    BinaryStatusIconModule,
    Environment,
    VituInputModule,
    SpaceBetweenCapsModule,
    VituNumberFormatModule,
    CopyToClipboardButtonModule,
    SwitchArrayModule,
    PermissionsSwitchArrayModule,
    GroupedSwitchArrayModule,
    VituSlideToggleModule,
    VituSelectModule,
    VituDateModule,
    VituTimeModule
} from "shared-lib";

const exportableModules = [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardCommonModule,
    BusyIndicatorModule,
    GlobalSpinnerModule,
    MultiStageExpansionPanelModule,
    TableBaseModule,
    ActionButtonModule,
    StopPropagationModule,
    ConfirmationModalModule,
    VituFormModule,
    VituFormFieldModule,
    ModalErrorMessageModule,
    ErrorHandlerModule,
    DateTimeModule,
    StatusChipModule,
    PagerModule,
    VituToastModule,
    TextModule,
    TitleBarModule,
    FiltersBaseModule,
    VituTooltipModule,
    UserStatusIconModule,
    FundingItemStatusChipModule,
    TransactionStatusChipModule,
    BinaryStatusIconModule,
    VituInputModule,
    SpaceBetweenCapsModule,
    VituNumberFormatModule,
    CopyToClipboardButtonModule,
    SwitchArrayModule,
    PermissionsSwitchArrayModule,
    GroupedSwitchArrayModule,
    VituSlideToggleModule,
    VituSelectModule,
    VituDateModule,
    VituTimeModule
];

const exportableDomEntities = [
    TransactionsTableComponent,
    SafePipe,
    RefundPaymentModalComponent,
    TransactionsTableCellContentComponent
];

@NgModule({
    imports: [
        MaterialModule.forRoot(),
        ...exportableModules,
    ],
    declarations: [
        ...exportableDomEntities,
    ],
    exports: [
        MaterialModule,
        ...exportableModules,
        ...exportableDomEntities,
    ],
    providers: [
        { provide: Environment, useValue: environment }
    ]
})
export class SharedModule {}
