import { MerchantEventOrderByEnum } from "@admin_api/models/merchant-event-order-by-enum";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-event-history-table-cell-content",
    templateUrl: "./event-history-table-cell-content.component.html",
    styleUrls: ["./event-history-table-cell-content.component.less"],
})
export class EventHistoryTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    MerchantEventOrderByEnum = MerchantEventOrderByEnum;

}
