import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "lib-text-field",
    templateUrl: "./text-field.component.html",
    styleUrls: ["./text-field.component.less"]
})
export class TextFieldComponent {

    @Input() label = "";
    @Input() infoTip: string;

}
