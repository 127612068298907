import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, tap, map, filter } from "rxjs/operators";
import { Observable, of, Subject, throwError } from "rxjs";
import {
    ActionTypes
} from "./report.actions";
import { DownloadReportAction } from "./report.actions";
import { DownloadReportModalComponent } from "@merchant_app/shared/download-report-modal/download-report-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ModalActionType } from "@merchant_app/shared/modal-action";
import { GlobalSpinnerService, LocalTimePoint } from "shared-lib";
import { StrictHttpResponse } from "@merchant_api/strict-http-response";
import { DownloadReportModalAction } from "@merchant_app/shared/download-report-modal/download-report-modal-action";
import { ReportType } from "@merchant_app/shared/download-report-modal/report-type";
import { ReportsService } from "@merchant_api/services/reports.service";

@Injectable()
export class ReportEffects {

    constructor(
        private actions: Actions,
        private dialog: MatDialog,
        private reportsService: ReportsService,
        private globalSpinner: GlobalSpinnerService
    ) { }

    downloadReport = createEffect(() => this.actions.pipe(
            ofType<DownloadReportAction>(ActionTypes.DownloadReport),
            switchMap(downloadReportAction => {
                const errorMessageSubject = new Subject<string>();
                const modalActionSubject = new Subject<DownloadReportModalAction>();

                const dialogRef = this.dialog
                    .open(DownloadReportModalComponent, {
                        width: "400px",
                        data: {
                            reportType: downloadReportAction.reportType,
                            modalActionSubject,
                            errorMessageSubject
                        },
                        disableClose: true,
                        autoFocus: false
                    });

                return modalActionSubject.pipe(
                    switchMap(({action, params}: DownloadReportModalAction) => {
                        switch (action) {
                            case ModalActionType.CANCEL:
                            {
                                return of(true);
                            }
                            case ModalActionType.CONFIRM:
                            {
                                let reportRequest: Observable<any> = throwError(new Error("Bad Report Type."));

                                switch (downloadReportAction.reportType) {
                                    case ReportType.TRANSACTIONS:
                                        {
                                            reportRequest = this.reportsService.reportTransactionReport$Response(
                                                {
                                                    MerchantProcessorIds: params.merchantProcessorIds,
                                                    StartDate: LocalTimePoint.convertLocalValueToUtcValue(params.startDate),
                                                    EndDate: LocalTimePoint.convertLocalValueToUtcValue(params.endDate)
                                                });
                                            break;
                                        }
                                    case ReportType.STATEMENT:
                                        {
                                            reportRequest = this.reportsService.reportStatementReports$Response(
                                                {
                                                    id: params.statementId
                                                });
                                            break;
                                        }
                                    case ReportType.FUNDING:
                                        {
                                            reportRequest = this.reportsService.reportFundingReport$Response(
                                                {
                                                    MerchantProcessorIds: params.merchantProcessorIds,
                                                    StartDate: LocalTimePoint.convertLocalValueToUtcValue(params.startDate),
                                                    EndDate: LocalTimePoint.convertLocalValueToUtcValue(params.endDate)
                                                });
                                                break;
                                            }
                                        }
                                return this.globalSpinner.apply(reportRequest.pipe(
                                    map((response: any) => {
                                        const fileName = this.getDownloadFileName(response);
                                        this.openFile(fileName, response);
                                        return true;
                                    }),
                                    catchError(error => {
                                        // This will catch errors from both request failure & file opening failure
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of(false);
                                    })
                                ));

                            }
                        }
                    }),
                    filter((doClose: boolean) => doClose),
                    tap(() => dialogRef.close())
                );
            })
        ), { dispatch: false });

    private getDownloadFileName(response: StrictHttpResponse<Blob>): string {
        let fileName = null;

        try {
            const contentDisposition = response.headers.get("content-disposition");
            const regEx = /filename=[\"]?([^\"]+?)[\"]?;/;
            const fileNameMatches = contentDisposition.match(regEx);
            if (Array.isArray(fileNameMatches) && (fileNameMatches.length === 2)) {
                fileName = fileNameMatches[1];
            }
            if (!fileName) {
                throw new Error();
            }
        }
        catch (error) {
            throw new Error("Unable to download file.");
        }

        return fileName;
    }

    private openFile(fileName: string, response: StrictHttpResponse<Blob>): void {

        try {
            const blob = new Blob([response.body], { type: "application/octet-stream" });
            const fileUrl = URL.createObjectURL(blob);
            const tempAnchorElement: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
            tempAnchorElement.href = fileUrl;
            tempAnchorElement.download = fileName;
            document.body.appendChild(tempAnchorElement);
            tempAnchorElement.click();
            document.body.removeChild(tempAnchorElement);
            URL.revokeObjectURL(fileUrl);
        }
        catch (error) {
            throw new Error("Unable to open file.");
        }

    }

}
