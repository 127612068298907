<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    [moreFields]="moreFields"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localRaiseDateInterval"
                        label="Date Created"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="caseNumber"
                        label="Case"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('caseNumber')"
                        (handleReset)="filtersBase.resetField('caseNumber')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="disputeType"
                        isFilter
                        label="Type"
                        [options]="disputeTypeList"
                        [showReset]="filtersBase.resetFieldVisible('disputeType')"
                        (handleReset)="wipeField('disputeType')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="processor"
                        isFilter
                        label="Processor"
                        [options]="processorList"
                        [showReset]="filtersBase.resetFieldVisible('processor')"
                        (handleReset)="wipeField('processor')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="disputeAmount"
                        isFilter
                        label="Amount"
                        autocomplete="off"
                        [mask]="amountMask"
                        [showReset]="filtersBase.resetFieldVisible('disputeAmount')"
                        (handleReset)="filtersBase.resetField('disputeAmount')">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

        <ng-template #moreFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="paymentToken"
                        label="Payment Token"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('paymentToken')"
                        (handleReset)="filtersBase.resetField('paymentToken')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="disputeOutcome"
                        label="Outcome"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('disputeOutcome')"
                        (handleReset)="filtersBase.resetField('disputeOutcome')">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
