import { Filter, Sorting } from "@merchant_app/storage/funding/funding.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, LocalTimePoint, Pager, TableSorting } from "shared-lib";
import { FundingInstruction } from "@merchant_api/models/funding-instruction";
import { FundingInstructionsOrderBy } from "@merchant_api/models/funding-instructions-order-by";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

@Component({
    selector: "app-funding-log",
    templateUrl: "./funding-log.component.html",
    styleUrls: ["./funding-log.component.less"],
})
export class FundingLogComponent {

    @Input() funding: Array<FundingInstruction>;
    @Input() fundingLoading = false;
    @Input() fundingError: Error;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    readonly columnDefs: ColumnDef[];

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: FundingInstructionsOrderBy.DateCreated, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true},
            {id: FundingInstructionsOrderBy.MID, title: "MID", flexWidthBasisInPixels: 170, flexWidthGrow: 1, canSort: true},
            {id: "vituPayDepositAmount", title: "VituPay Deposit Amount", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: false},
            {id: "merchantDepositAmount", title: "Merchant Deposit Amount", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: false},
            {id: FundingInstructionsOrderBy.FundingStatus, title: "Funding Status",
                flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
            {id: FundingInstructionsOrderBy.DateFunded, title: `Date Funded (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(id: string) {
        if (this.currPerms.has(["core.funding.get"])) {
            this.router.navigate([`/dashboard/funding/${id}`]);
        }
    }

}
