import { EventDeliveryState } from "./event-delivery.state";
import {
    ActionTypes,
} from "./event-delivery.actions";

export const EventDeliveryReducer = (state: EventDeliveryState = new EventDeliveryState(), action: any): EventDeliveryState => {

    switch (action.type) {

        case ActionTypes.GetEventDelivery: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetEventDeliverySucceed: {
            return {
                ...state,
                record: action.response,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetEventDeliveryFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
