import { ErrorHandler, Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { from, Observable, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionTypes, AddDetailsToCurrentUserAction, GetCurrentUserAction, GetCurrentUserSucceedAction } from "./current-user.actions";
import { PermissionsService } from "@merchant_api/services/permissions.service";
import { CurrentUserDto } from "./current-user.state";
import { Router } from "@angular/router";
import { AuthService, VituHttpErrorForbidden, GlobalErrorHandler, PermissionUtils, ErrorUiService } from "shared-lib";
import { UsersService } from "@merchant_api/services/users.service";

@Injectable()
export class CurrentUserEffects {

    constructor(
        private actions: Actions,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private usersService: UsersService,
        private router: Router,
        private errorHandler: ErrorHandler,
        private errorUi: ErrorUiService
    ) {}

        getCurrentUser = createEffect(() => this.actions.pipe(
            ofType<GetCurrentUserAction>(ActionTypes.GetCurrentUser),
            switchMap(() => from(this.authService.loadUserProfile())
                    .pipe(
                        switchMap((userProfile: any): Observable<any> =>
                            this.permissionsService.permissionsSearchForCurrentUser().pipe(
                                switchMap(currentUserPermissions => {

                                    const currentUserDto: CurrentUserDto = {
                                        id: userProfile?.info?.sub,
                                        permissions: currentUserPermissions && currentUserPermissions.map(
                                            currentUserPermission => currentUserPermission.name),
                                        name: "",
                                        email: "",
                                        defaultRoute: ""
                                    };
                                    currentUserDto.defaultRoute = this.getDefaultRoute(currentUserDto.permissions);
                                    (this.errorHandler as GlobalErrorHandler).currentUser = currentUserDto;

                                    return of(new GetCurrentUserSucceedAction(currentUserDto));
                                }),
                                catchError(error => {

                                    if (error instanceof VituHttpErrorForbidden) {
                                        this.router.navigateByUrl("/forbidden");
                                    }

                                    return of();
                                }),
                            )
                        )
                    )
                )
        ));

        getCurrentUserSucceed$ = createEffect(() =>

            this.actions.pipe(
                ofType<GetCurrentUserSucceedAction>(ActionTypes.GetCurrentUserSucceed),
                filter(action => PermissionUtils.hasPermissions(action.record.permissions, ["core.users.get"])),
                switchMap(action =>
                    this.usersService.usersGet({ id: action.record.id }).pipe(
                        switchMap((userDetailsDto) => of(new AddDetailsToCurrentUserAction(userDetailsDto)))
                    )
                )
            ),
        );

        private getDefaultRoute(currentPermissions: string[]): string {

            let defaultRoute = "/dashboard/help";

            if(this.currentUserHasPermissions(currentPermissions, ["core.payments.search"])) {
                defaultRoute = "/dashboard/payments";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.transactions.search"])) {
                defaultRoute = "/dashboard/transactions";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.users.search"])) {
                defaultRoute = "/dashboard/users";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.roles.search"])) {
                defaultRoute = "/dashboard/roles";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.reports.billing"])) {
                defaultRoute = "/dashboard/reports";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.merchants.get-tids"])) {
                defaultRoute = "/dashboard/configurations";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.funding.search"])) {
                defaultRoute = "/dashboard/funding";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.clients.search"])) {
                defaultRoute = "/dashboard/developer/clients";
            }
            else if(this.currentUserHasPermissions(currentPermissions, ["core.webhooks.search"])) {
                defaultRoute = "/dashboard/developer/webhooks/configure";
            }

            if (this.errorUi) {
                this.errorUi.defaultRoute = defaultRoute;
            }

            return defaultRoute;
        }

        private currentUserHasPermissions(currentPermissions: string[], requiredPermissions: string[]): boolean {

            return PermissionUtils.hasPermissions(currentPermissions, requiredPermissions);
        }

}
