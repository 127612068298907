import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-text-table-column",
    templateUrl: "./text-table-column.component.html",
    styleUrls: ["./text-table-column.component.less"],
})
export class TextTableColumnComponent {

    @Input() link: string;
    @Input() tooltipDisabled = false;
    @Input() horizAlign = "left";

}
