import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-modal-error-message",
    templateUrl: "./modal-error-message.component.html",
    styleUrls: ["./modal-error-message.component.less"],
})
export class ModalErrorMessageComponent {

    @Input() message: string;

}
