import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface GlobalSpinnerData {
    show: boolean;
}

@Injectable({ providedIn: "root" })
export class GlobalSpinnerService {

    data$: Observable<GlobalSpinnerData>;

    private showCount = 0;
    private get data() {
        return { show: this.showCount > 0 };
    }
    private dataEmitter = new BehaviorSubject<GlobalSpinnerData>(this.data);

    apply(obs: Observable<any>): Observable<any> {
        this.start();
        return obs.pipe(
            // Ensure it calls stop on the complete and error channels (not required on 'next')
            // (Note: 'finalize' won't work as it gets called on unsubscribe which we don't want)
            tap(
                () => {},
                () => {
                    this.stop();
                },
                () => {
                    this.stop();
                }
            )
        );
    };

    constructor() {
        this.data$ = this.dataEmitter.asObservable();
    }

    start() {
        ++this.showCount;
        this.emitData();
    }

    private stop() {
        --this.showCount;
        this.emitData();
    }

    private emitData() {
        this.dataEmitter.next(this.data);
    }

}
