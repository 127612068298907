import { ClientDetailsDto } from "@merchant_api/models/client-details-dto";
import { ClientSecretLookupsDto } from "@merchant_api/models/client-secret-lookups-dto";
import { RoleDto } from "@merchant_api/models/role-dto";

export class ClientState {
    loading = false;
    error = null;
    client: ClientDetailsDto = null;
    lookups: ClientSecretLookupsDto = null;
    roles: Array<RoleDto> = [];
}
