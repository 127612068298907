import { Component, Input } from "@angular/core";
import { TooltipPosition } from "@angular/material/tooltip";

@Component({
    selector: "lib-vitu-tooltip",
    templateUrl: "./vitu-tooltip.component.html",
    styleUrls: ["./vitu-tooltip.component.less"],
})
export class VituTooltipComponent {

    @Input() text = "";
    @Input() isDisabled = false;
    @Input() showDelayInMillis = 500;
    @Input() position: TooltipPosition | "right-arrow" = "below";

    get tooltipClass(): string {
        if (this.position === "right-arrow") {
            return "vitu-tooltip-with-right-arrow";
        }
        return "vitu-tooltip";
    }

    get calculatePosition(): TooltipPosition {
        if (this.position === "right-arrow") {
            return "right";
        }
        return this.position;
    }

}
