import { ModalActionType } from "@admin_app/shared/modal-action";
import { OnDestroy } from "@angular/core";
import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { Subject, Subscription } from "rxjs";
import { ActionButtonKind, LocalTimePoint } from "shared-lib";
import { CreateClientSecretModalAction, CreateClientSecretParams } from "./create-client-secret-modal-action";
import { ClientSecretLookupsDto } from "@merchant_api/models/client-secret-lookups-dto";
import { ClientDetailsDto } from "@merchant_api/models/client-details-dto";

@Component({
    selector: "app-create-client-secret-modal",
    templateUrl: "./create-client-secret-modal.component.html",
    styleUrls: ["./create-client-secret-modal.component.less"]
})
export class CreateClientSecretModalComponent implements OnDestroy {

    LocalTimePoint = LocalTimePoint;

    get formControls() {
        return this.createClientSecretForm && this.createClientSecretForm.controls;
    }

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<CreateClientSecretModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            lookups: ClientSecretLookupsDto;
            client: ClientDetailsDto;
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<CreateClientSecretModalAction>;
        }
    ) {
    }

    private subscriptions = new Subscription();

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ActionButtonKind = ActionButtonKind;

    createClientSecretForm = this.fb.group({
        clientId: [this.data.client.id],
        type: [null, [Validators.required]],
        value: [null, [Validators.required]],
        hashType: [null, [Validators.required]],
        localExpirationDate: [null, []],
        description: [null, [Validators.required]]
    });

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new CreateClientSecretModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        const params = this.createClientSecretForm.value as CreateClientSecretParams;
        this.data.modalActionSubject.next(new CreateClientSecretModalAction(ModalActionType.CONFIRM, params));
    }

    generateSecret() {

        const secretValue = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {

            /* eslint-disable no-bitwise */
            const r = Math.random() * 16 | 0;
            const v = (c == "x") ? r : (r & 0x3 | 0x8);
            /* eslint-enable no-bitwise */
            return v.toString(16);
        });

        this.createClientSecretForm.controls.value.setValue(secretValue);
    }

}
