import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import {
    ActionTypes,
    GetClientsAction,
    GetClientsFailAction,
    GetClientsSucceedAction
} from "./clients.actions";
import { ClientsService } from "@merchant_api/services/clients.service";

@Injectable()
export class ClientsEffects {

    constructor(
        private actions$: Actions,
        private clientsService: ClientsService
    ) {}

    initClientsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => /\/dashboard\/developer\/clients/g.test(action.payload.routerState.url) &&
                !(/\/dashboard\/clients\//g.test(action.payload.routerState.url))),
            map(() => new GetClientsAction())
        )
    );

    getClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetClientsAction>(ActionTypes.GetClients),
            switchMap(() =>
                this.clientsService.clientsSearch().pipe(
                    switchMap((clients) => of(new GetClientsSucceedAction(clients))),
                    catchError((error) => of(new GetClientsFailAction(error)))
                )
            )
        ),
    );

}
