import { PagedDataDtoOfFundingInstruction } from "@merchant_api/models/paged-data-dto-of-funding-instruction";
import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./funding.state";

export enum ActionTypes {
    GetFunding = "[FUNDING] GetFunding",
    GetFundingSucceed = "[FUNDING] GetFundingSucceed",
    GetFundingFail = "[FUNDING] GetFundingFail",
    SortFunding = "[FUNDING] SortFunding",
    PageChangedFunding = "[FUNDING] PageChangedFunding",
    FilterFunding = "[FUNDING] FilterFunding",
}

export class GetFundingAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetFunding;
}

export class GetFundingSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfFundingInstruction,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetFundingSucceed;
}

export class GetFundingFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetFundingFail;
}

export class SortFundingAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortFunding;
}

export class PageChangedFundingAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedFunding;
}

export class FilterFundingAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterFunding;
}
