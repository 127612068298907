import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActionButtonKind, ColumnDef, LocalTimePoint, ValidatorsVituCustom } from "shared-lib";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { UpdateClientDto } from "@merchant_api/models/update-client-dto";
import { CreateClientDto } from "@merchant_api/models/create-client-dto";
import { RoleDto } from "@merchant_api/models/role-dto";
import { ClientDetailsDto } from "@merchant_api/models/client-details-dto";
import { ClientSecretDto } from "@merchant_api/models/client-secret-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-client-details",
    templateUrl: "./client-details.component.html",
    styleUrls: ["./client-details.component.less"],
})
export class ClientDetailsComponent implements OnInit, OnChanges {

    @Input() isCreate: boolean;
    @Input() client: ClientDetailsDto;
    @Input() rolesLookup: Array<RoleDto>;
    @Input() currentUser: CurrentUserDto;
    @Input() loading: boolean;
    @Input() secrets: Array<ClientSecretDto>;
    @Input() secretsLoading: boolean;
    @Input() secretsError: Error;

    @Output() deleteClient = new EventEmitter<number>();
    @Output() updateClient = new EventEmitter<{id: number; client: UpdateClientDto}>();
    @Output() createClient = new EventEmitter<CreateClientDto>();
    @Output() back = new EventEmitter<void>();
    @Output() createClientSecret = new EventEmitter<void>();
    @Output() deleteClientSecret = new EventEmitter<number>();

    submitButtonText: string;

    ActionButtonKind = ActionButtonKind;
    LocalTimePoint = LocalTimePoint;

    get secretsColumnDefs(): ColumnDef[] {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        const retVal: ColumnDef[] = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: "type", title: "Type", flexWidthBasisInPixels: 160, flexWidthGrow: 1, canSort: true},
            {id: "value", title: "Value", flexWidthBasisInPixels: 470, flexWidthGrow: 2},
            {id: "description", title: "Description", flexWidthBasisInPixels: 230, flexWidthGrow: 1, canSort: true},
            {id: "expirationDate", title: `Expiration Date (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 180, flexWidthGrow: 0, canSort: true},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
        if (this.hasDeleteSecretPermission) {
            const deleteSecretColumn: ColumnDef = {id: "delete", title: "Delete", flexWidthBasisInPixels: 40, flexWidthGrow: 0};
            retVal.splice(5, 0, deleteSecretColumn);
        }
        return retVal;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private currPerms: CurrentUserPermissions
    ) {}

    clientForm = this.fb.group({
        name: [null, [Validators.required]],
        roleIds: [null, [ValidatorsVituCustom.selectMultiIntegrity(() => this.rolesLookup?.map((role) => role.id))]]
    });

    get formControls(): FormControls<any> { return this.clientForm.controls; }

    ngOnInit() {
        this.submitButtonText = (this.isCreate ? "Submit" : "Update");
    }

    get pageTitle(): string {
        let firstPart: string;
        if (this.isCreate) {
            firstPart = "New";
        }
        else if(this.hasUpdateClientPermission) {
            firstPart = "Edit";
        }
        else {
            firstPart = "View";
        }
        return firstPart + " Client";
    }

    ngOnChanges(changes: SimpleChanges) {

        if ("client" in changes && this.client) {

            this.clientForm.patchValue(this.client);

            if (!this.isCreate) {
                this.clientForm.markAllAsTouched();
            }
        }

    }

    onClickDeleteClient() {

        this.deleteClient.emit(this.client.id);
    }

    onClickCreateSecret() {

        this.createClientSecret.emit();
    }

    onDeleteClientSecret(secretId: number) {

        this.deleteClientSecret.emit(secretId);
    }

    onClickBack() {

        this.back.emit();
    }

    getSecretsTabLabel(): string {
        let count = 0;
        if (this.secrets?.length) {
            count = this.secrets?.length;
        }
        return this.appendCountIfNecessary("Secrets", count);
    }

    onSecretRowSelected(secretId: number) {
    }

    onSubmit() {

        const clientForm = this.clientForm.value;

        const name = clientForm.name;
        const roleIds = clientForm.roleIds;

        if (this.isCreate) {
            const createClientDto: CreateClientDto = {
                name,
                roleIds
            };
            this.createClient.emit(createClientDto);
        }
        else {
            const updateClientDto: UpdateClientDto = {
                name,
                roleIds
            };
            this.updateClient.emit({
                id: this.client.id,
                client: updateClientDto
            });
        }

    }

    get globalRoleList() {
        let retVal = [];
        if (this.rolesLookup?.length) {
            retVal = retVal.concat(this.rolesLookup.filter(role => !role.isCustom));
        }
        return retVal.sort(ClientDetailsComponent.compareRole);
    }

    get customRoleList() {
        let retVal = [];
        if (this.rolesLookup?.length) {
            retVal = retVal.concat(this.rolesLookup.filter(role => role.isCustom));
        }
        return retVal.sort(ClientDetailsComponent.compareRole);
    }

    get groupedRoleList() {
        return this.globalRoleList.concat(this.customRoleList);
    }

    get hasUpdateClientPermission(): boolean {
        return this.currPerms.has(["core.clients.update"]);
    }

    get hasDeleteClientPermission(): boolean {
        return this.currPerms.has(["core.clients.delete"]);
    }

    get hasCreateSecretPermission(): boolean {
        return this.currPerms.has(["core.clients.create-secret"]);
    }

    get hasDeleteSecretPermission(): boolean {
        return this.currPerms.has(["core.clients.delete-secret"]);
    }

    get roleGroups() {

        const groups = [];
        let currentMinIdx = 0;

        if (this.globalRoleList?.length) {
            groups.push({name: "", minIdx: 0, maxIdx: this.globalRoleList.length - 1});
            currentMinIdx = this.globalRoleList.length;
        }
        if (this.customRoleList?.length) {
            groups.push({name: "CUSTOM", minIdx: currentMinIdx, maxIdx: currentMinIdx + this.customRoleList.length - 1});
        }

        return JSON.stringify(groups);
    }

    private static compareRole(a: RoleDto, b: RoleDto): number {
        const aVal = a.name?.toLowerCase();
        const bVal = b.name?.toLowerCase();
        if (aVal > bVal) {
            return 1;
        }
        else if (aVal < bVal) {
            return -1;
        }
        return 0;
    }

    private appendCountIfNecessary(title: string, count: number): string {
        return title + ((count > 0) ? ` (${count})` : "");
    }

}
