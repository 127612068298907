<div class="outer-container" [ngClass]="{'disabled': disabled}">

    <button mat-stroked-button
        class="switch-to-page-button"
        (click)="onClickPrevPage()"
        [ngClass]="{'disabled': disabled || (prevPage === null)}">
        <span><i class="far fa-arrow-left"></i></span>
    </button>

    <button mat-stroked-button
        *ngIf="firstPageButtonVisible()"
        class="switch-to-page-button"
        (click)="onClickChangeToPage(1)"
        [ngClass]="{'disabled': disabled}">
        <span>1</span>
    </button>

    <span *ngIf="firstPageButtonSeparatorVisible()">...</span>

    <ng-container *ngFor="let pg of pages">
        <button mat-stroked-button
            class="current-page-button"
            [disabled]="true"
            *ngIf="pg === page; else showClickableBtn"
            color="primary">
            <span>{{ pg }}</span>
        </button>
        <ng-template #showClickableBtn>
            <button mat-stroked-button
                class="switch-to-page-button"
                (click)="onClickChangeToPage(pg)"
                [ngClass]="{'disabled': disabled}">
                <span>{{ pg }}</span>
            </button>
        </ng-template>
    </ng-container>

    <button mat-stroked-button
        class="switch-to-page-button"
        (click)="onClickNextPage()"
        [ngClass]="{'disabled': disabled || (nextPage === null)}">
        <span><i class="far fa-arrow-right"></i></span>
    </button>

</div>
