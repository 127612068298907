<app-payment-log
    [rowsData]="rowsData$ | async"
    [sorting]="sorting$ | async"
    [pager]="pager$ | async"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)"
    (sortingChanged)="onSortingChanged($event)"
    [exportLoading]="exportLoading$ | async"
    [loading]="loading$ | async"
    [error]="error$ | async"
    (pageChanged)="onPageChanged($event)"
    (exportToCsv)="exportToCsv()"
    [currentUser]="currentUser$ | async">
</app-payment-log>
