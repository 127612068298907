import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TransactionOrderBy } from "@merchant_api/models/transaction-order-by";
import { Sorting } from "@merchant_app/storage/transactions/transactions.state";
import { ColumnDef, TableSorting, LocalTimePoint, Pager } from "shared-lib";

@Component({
    selector: "app-transactions-table",
    templateUrl: "./transactions-table.component.html",
    styleUrls: ["./transactions-table.component.less"],
})
export class TransactionsTableComponent implements OnInit {

    @Input() rowsData: any[];
    @Input() rowsDataLoading = false;
    @Input() rowsDataError: Error;
    @Input() rowsDataErrorMessage: string;
    @Input() rowsDataEmptyMessage: string;
    @Input() rowsDataShowWhileLoading = false;

    @Input() selectedRowProperty: string = null;
    @Input() selectedRowValue = null;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() pageChanged = new EventEmitter<number>();
    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() rowSelected = new EventEmitter<string>();

    columnDefs: ColumnDef[];

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as (null | TransactionOrderBy),
            orderDirection: sorting.orderDirection
        });
    }

    onPageChanged(page: number) {
        this.pageChanged.emit(page);
    }

    onRowSelected(row: string) {
        this.rowSelected.emit(row);
    }

    ngOnInit() {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: TransactionOrderBy.DateAndTime, title: `Date (${LocalTimePoint.formatZ()})`, flexWidthBasisInPixels: 100,
                flexWidthGrow: 2, canSort: true},
            { id: TransactionOrderBy.TransactionType, title: "Type", flexWidthBasisInPixels: 80, flexWidthGrow: 0},
            { id: "TransactionToken", title: "Token", flexWidthBasisInPixels: 180, flexWidthGrow: 0},
            { id: "TransactionStatus", title: "Status", flexWidthBasisInPixels: 80, flexWidthGrow: 2},
            { id: TransactionOrderBy.Amount, title: "Amount", flexWidthBasisInPixels: 96, flexWidthGrow: 4, canSort: true},
            { id: "CardBrand", title: "Card", flexWidthBasisInPixels: 50, flexWidthGrow: 4},
            { id: TransactionOrderBy.CardNumber, title: "Card Number", flexWidthBasisInPixels: 150, flexWidthGrow: 4, canSort: true},
            { id: "DigitalWallet", title: "Digital Wallet", flexWidthBasisInPixels: 75, flexWidthGrow: 4},
            { id: TransactionOrderBy.PayerName, title: "Payer Name", flexWidthBasisInPixels: 100, flexWidthGrow: 8, canSort: true},
            { id: "Fees", title: "Fees", flexWidthBasisInPixels: 85, flexWidthGrow: 4},
            { id: "AuthCode", title: "Auth Code", flexWidthBasisInPixels: 60, flexWidthGrow: 4},
            { id: TransactionOrderBy.TransactionId, title: "Transaction ID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

}
