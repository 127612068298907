import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextTableColumnComponent } from "./text-table-column.component";
import { TextCommonModule } from "../text-common/text-common.module";

@NgModule({
    declarations: [
        TextTableColumnComponent
    ],
    imports: [
        CommonModule,
        TextCommonModule
    ],
    exports: [
        TextTableColumnComponent
    ]
})
export class TextTableColumnModule { }
