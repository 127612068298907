import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import {
    ActionTypes,
    GetClientSecretsAction,
    GetClientSecretsFailAction,
    GetClientSecretsSucceedAction
} from "./client-secrets.actions";
import { ClientSecretsService } from "@merchant_api/services/client-secrets.service";

@Injectable()
export class ClientSecretsEffects {

    constructor(
        private actions$: Actions,
        private clientSecretService: ClientSecretsService
    ) {}

    getClientSecrets$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetClientSecretsAction>(ActionTypes.GetClientSecrets),
            switchMap((action) =>
                this.clientSecretService.clientSecretsSearch({ ClientId: action.clientId }).pipe(
                    switchMap((clientSecrets) => of(new GetClientSecretsSucceedAction(clientSecrets))),
                    catchError((error) => of(new GetClientSecretsFailAction(error)))
                )
            )
        ),
    );

}
