<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Payments' }]">
    </lib-title-bar>

    <app-group-transaction-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)">
    </app-group-transaction-log-filters>

    <div class="table-actions-bar">
        <lib-action-button label="Export" (click)="this.exportToCsv.emit()" [disabled]="exportButtonDisabled()"
            fontImage="far fa-file-export fa-lg" [kind]="ActionButtonKind.SECONDARY">
        </lib-action-button>
    </div>

    <div class="table-container">
        <ng-container *ngIf="true then table"></ng-container>
        <ng-container *ngIf="exportLoading then spinner"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #table>

    <lib-table-base
        [columnDefs]="paymentsColumnDefs"
        [rowsData]="rowsData"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Payments"
        rowsDataEmptyMessage="No Payments Found"
        [rowsDataShowWhileLoading]="true"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-group-transactions-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-group-transactions-table-cell-content>
        </ng-template>

    </lib-table-base>

</ng-template>
