<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Users' }]">
        <lib-action-button *ngIf="hasCreatePermission"
            label="New User" fontImage="far fa-plus-circle fa-bg" (click)="onClickCreateUser()">
        </lib-action-button>
    </lib-title-bar>
    
    <lib-table-base
        class="users-table"
        [columnDefs]="userColumnDefs"
        [rowsData]="users"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Users"
        selectedRowProperty="id"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-users-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-users-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
