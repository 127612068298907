import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getClientsState = (store: IStore) => store.clients;

export const getClients = createSelector(
    getClientsState,
    clientState => clientState.record,
);

export const getClientsLoading = createSelector(
    getClientsState,
    clientState => clientState.loading,
);

export const getClientsError = createSelector(
    getClientsState,
    clientState => clientState.error,
);
