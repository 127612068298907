import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { VituTooltipComponent } from "./vitu-tooltip.component";

@NgModule({
    declarations: [
        VituTooltipComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule
    ],
    exports: [
        VituTooltipComponent
    ]
})
export class VituTooltipModule { }
