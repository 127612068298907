import { PaymentDetails } from "@merchant_api/models/payment-details";
import { TransactionFeesResult } from "@merchant_api/models/transaction-fees-result";

export class PaymentState {
  loading = false;
  error = null;
  record: PaymentDetails = {};
  feesLoading = false;
  feesError = null;
  fees: TransactionFeesResult = {};
}
