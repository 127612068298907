import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spaceBetweenCaps"
})
export class SpaceBetweenCapsPipe implements PipeTransform {

  transform(value: any, capitalizeFirstLetter = false): string {

    if (typeof value === "string") {
        let outString = "";
        for (let ii = 0; ii < value.length; ii++) {
            let charToTest = value[ii];
            if ((ii > 0) && (charToTest === charToTest.toUpperCase())) {
                outString += " ";
            }
            if ((ii === 0) && capitalizeFirstLetter) {
                charToTest = charToTest.toUpperCase();
            }
            outString += charToTest;
        }
        return outString;
    }

    return value;
  }

}

