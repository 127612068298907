import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MultiStageExpansionPanelComponent } from "./multi-stage-expansion-panel.component";

@NgModule({
    declarations: [
        MultiStageExpansionPanelComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MultiStageExpansionPanelComponent
    ]
})
export class MultiStageExpansionPanelModule { }
