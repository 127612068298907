import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export enum ActionButtonKind {
    PRIMARY,
    SECONDARY,
    STROKED,
    ALERT,
    FILTER
}

export enum ActionButtonAlertTone {
    Neutral = 1,
    Positive = 2,
    Negative = 3
}

@Component({
    selector: "lib-action-button",
    templateUrl: "./action-button.component.html",
    styleUrls: ["./action-button.component.less"],
})
export class ActionButtonComponent implements OnInit {

    @Input() label: string;
    @Input() kind: ActionButtonKind = ActionButtonKind.PRIMARY;
    @Input() disabled: boolean;
    @Input() badge: number;
    @Input() image: string;
    @Input() fontImage: string;
    @Input() fontImageExtra: string;
    @Input() iconAfterText: boolean;
    @Input() minWidthInPixels = 0;
    @Input() alertTone = ActionButtonAlertTone.Negative;

    @Input() set largeButton(value: any) {
        this._largeButton = !(value === false);
    };
    get largeButton() { return this._largeButton; }
    private _largeButton = false;

    type = "button";

    ActionButtonKind = ActionButtonKind;
    ActionButtonAlertTone = ActionButtonAlertTone;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<void>();

    ngOnInit() {
        if (this.iconAfterText === undefined) {
            this.iconAfterText = (this.kind === ActionButtonKind.FILTER);
        }

        if (!this.minWidthInPixels && this.largeButton) {
            this.minWidthInPixels = 150;
        }
    }

    onClick(): void {
        if (!this.disabled) {
            this.click.emit();
        }
    }

}
