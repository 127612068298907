import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-user-status-icon",
    templateUrl: "./user-status-icon.component.html",
    styleUrls: ["./user-status-icon.component.less"],
})
export class UserStatusIconComponent {

    @Input() isLocked: boolean;

}
