import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "lib-status-chip",
    templateUrl: "./status-chip.component.html",
    styleUrls: ["./status-chip.component.less"],
})
export class StatusChipComponent {

    /*
     * Notes on use:
     * Need to set the 'class' on the host (lib-status-chip) element to get the desired background color for chip in various statuses.
     * The status will be folded to lowercase in order to do this, and spaces will be replaced with dash chars.
     *   eg. A status of 'No Funding' will require a class of 'no-funding' to be applied.
     * If it can't match the background to the status the chip background color will fallback to black.
    */

    @HostBinding("class") hostClass;

    @Input() set status(value: string) {
        this._status = value;
        if (typeof this.status === "string") {
            this.hostClass = this.status?.toLowerCase().split(" ").join("-");
        }
    };
    get status(): string {
        return this._status;
    }

    _status: string;

}
