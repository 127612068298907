import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DashboardSidebarComponent } from "./dashboard-sidebar.component";
import { DashboardSidebarMenuItemModule } from "../dashboard-sidebar-menu-item/dashboard-sidebar-menu-item.module";
import { TextModule } from "../../text";

@NgModule({
    declarations: [
        DashboardSidebarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TextModule,
        DashboardSidebarMenuItemModule
    ],
    exports: [
        DashboardSidebarComponent
    ]
})
export class DashboardSidebarModule { }
