import { RootStoreConfig } from "@ngrx/store";
import { IStore } from "./store";

import { RouterReducer } from "./router/router.reducers";

import { MerchantState } from "./merchant/merchant.state";
import { MerchantReducer } from "./merchant/merchant.reducers";
import { TransactionsState } from "./transactions/transactions.state";
import { TransactionsReducer } from "./transactions/transactions.reducers";

import { TransactionState } from "./transaction/transaction.state";
import { TransactionReducer } from "./transaction/transaction.reducers";

import { TransactionSiblingsState } from "./transaction-siblings/transaction-siblings.state";
import { TransactionSiblingsReducer } from "./transaction-siblings/transaction-siblings.reducers";

import { PaymentsState } from "./payments/payments.state";
import { PaymentsReducer } from "./payments/payments.reducers";

import { PaymentState } from "./payment/payment.state";
import { PaymentReducer } from "./payment/payment.reducers";

import { UserState } from "./user/user.state";
import { UserReducer } from "./user/user.reducer";
import { UsersReducer } from "./users/users.reducers";

import { NextPaymentActionsReducer } from "./next-payment-actions/next-payment-actions.reducers";
import { NextPaymentActionsState } from "./next-payment-actions/next-payment-actions.state";
import { CurrentUserReducer } from "./current-user/current-user.reducer";
import { CurrentUserState } from "./current-user/current-user.state";
import { UsersState } from "./users/users.state";
import { RoleReducer } from "./role/role.reducer";
import { RolesReducer } from "./roles/roles.reducers";
import { RoleState } from "./role/role.state";
import { RolesState } from "./roles/roles.state";
import { WebhooksState } from "./webhooks/webhooks.state";
import { WebhooksReducer } from "./webhooks/webhooks.reducers";
import { WebhookReducer } from "./webhook/webhook.reducer";
import { WebhookState } from "./webhook/webhook.state";
import { ReportReducer } from "./report/report.reducers";
import { ReportState } from "./report/report.state";
import { StatementsState } from "./statements/statements.state";
import { StatementsReducer } from "./statements/statements.reducers";
import { ClientsReducer } from "./clients/clients.reducers";
import { ClientsState } from "./clients/clients.state";
import { ClientState } from "./client/client.state";
import { ClientReducer } from "./client/client.reducer";
import { ClientSecretsState } from "./client-secrets/client-secrets.state";
import { ClientSecretsReducer } from "./client-secrets/client-secrets.reducers";
import { TidsReducer } from "./tids/tids.reducers";
import { TidsState } from "./tids/tids.state";
import { MidsReducer } from "./mids/mids.reducers";
import { MidsState } from "./mids/mids.state";
import { FundingReducer } from "./funding/funding.reducers";
import { FundingState } from "./funding/funding.state";
import { FundingItemState } from "./funding-item/funding-item.state";
import { FundingItemReducer } from "./funding-item/funding-item.reducers";
import { EventReducer } from "./event/event.reducers";
import { EventsReducer } from "./events/events.reducers";
import { EventDeliveryReducer } from "./event-delivery/event-delivery.reducers";
import { EventDeliveriesReducer } from "./event-deliveries/event-deliveries.reducers";
import { EventState } from "./event/event.state";
import { EventsState } from "./events/events.state";
import { EventDeliveryState } from "./event-delivery/event-delivery.state";
import { EventDeliveriesState } from "./event-deliveries/event-deliveries.state";
import { DisputesState } from "./disputes/disputes.state";
import { DisputesReducer } from "./disputes/disputes.reducers";
import { DisputeState } from "./dispute/dispute.state";
import { DisputeReducer } from "./dispute/dispute.reducers";

export const ReducerMap: { [key in keyof IStore]: (state: any, action: any) => any } = {
    router: RouterReducer,
    merchant: MerchantReducer,
    transactions: TransactionsReducer,
    transaction: TransactionReducer,
    transactionSiblings: TransactionSiblingsReducer,
    webhook: WebhookReducer,
    webhooks: WebhooksReducer,
    payments: PaymentsReducer,
    payment: PaymentReducer,
    nextPaymentActions: NextPaymentActionsReducer,
    user: UserReducer,
    users: UsersReducer,
    role: RoleReducer,
    roles: RolesReducer,
    clients: ClientsReducer,
    client: ClientReducer,
    currentUser: CurrentUserReducer,
    report: ReportReducer,
    statements: StatementsReducer,
    clientSecrets: ClientSecretsReducer,
    tids: TidsReducer,
    mids: MidsReducer,
    funding: FundingReducer,
    fundingItem: FundingItemReducer,
    event: EventReducer,
    events: EventsReducer,
    eventDelivery: EventDeliveryReducer,
    eventDeliveries: EventDeliveriesReducer,
    disputes: DisputesReducer,
    dispute: DisputeReducer
};

export const ReducerConfiguration: RootStoreConfig<IStore> = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateSerializability: false,
    }
};

// for unit tests
export const GetInitialState = (): IStore => (
    {
        router: {
            state: {
                url: "",
                queryParams: {},
                params: {},
                routerPathSegments: [],
                routerPath: "",
            },
            navigationId: 0,
        },
        merchant: new MerchantState(),
        transactions: new TransactionsState(),
        transaction: new TransactionState(),
        transactionSiblings: new TransactionSiblingsState(),
        webhook: new WebhookState(),
        webhooks: new WebhooksState(),
        payments: new PaymentsState(),
        payment: new PaymentState(),
        nextPaymentActions: new NextPaymentActionsState(),
        user: new UserState(),
        users: new UsersState(),
        role: new RoleState(),
        roles: new RolesState(),
        clients: new ClientsState(),
        client: new ClientState(),
        currentUser: new CurrentUserState(),
        report: new ReportState(),
        statements: new StatementsState(),
        clientSecrets: new ClientSecretsState(),
        tids: new TidsState(),
        mids: new MidsState(),
        funding: new FundingState(),
        fundingItem: new FundingItemState(),
        event: new EventState(),
        events: new EventsState(),
        eventDelivery: new EventDeliveryState(),
        eventDeliveries: new EventDeliveriesState(),
        disputes: new DisputesState(),
        dispute: new DisputeState()
    }
);
