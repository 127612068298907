<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'tid'">
        <lib-text-table-column>
            {{ element.tid }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'gateway'">
        <lib-text-table-column>
            {{ element.gateway }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feePlan'">
        <lib-text-table-column>
            {{ element.feePlan }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'surchargePercent'">
        <lib-text-table-column>
            {{ element.surcharge?.percent | vituPercentage }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceSerial'">
        <lib-text-table-column>
            {{ cloverPosConfig?.deviceSerial }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceName'">
        <lib-text-table-column>
            {{ cloverPosConfig?.deviceName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceManufacturer'">
        <lib-text-table-column>
            {{ cloverPosConfig?.deviceManufacturer }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceModel'">
        <lib-text-table-column>
            {{ cloverPosConfig?.deviceModel }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceLocation'">
        <lib-text-table-column>
            {{ cloverPosConfig?.deviceLocation }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            *ngIf="cloverPosConfig?.status; else noPosTerminalStatus"
            class="pos-terminal-statuses"
            [status]="cloverPosConfig?.status">
        </lib-status-chip>
        <ng-template #noPosTerminalStatus>
            <lib-text-table-column>
            </lib-text-table-column>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





