import { UserState } from "./user.state";
import { ActionTypes } from "./user.actions";

export const UserReducer = (
    state: UserState = new UserState(),
    action: any,
  ): UserState => {

    switch (action.type) {

        case ActionTypes.GetUser:
        case ActionTypes.GetNewUser: {
            return {
                ...state,
                user: null,
                roleList: null,
                loading: true
            };
        }

        case ActionTypes.GetUserSucceed: {
            return {
                ...state,
                user: action.user,
                roleList: action.roleList,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetUserFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        default: {
            return state;
        }
    }

};
