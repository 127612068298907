import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-funding-item-status-chip",
    templateUrl: "./funding-item-status-chip.component.html",
    styleUrls: ["./funding-item-status-chip.component.less"],
})
export class FundingItemStatusChipComponent {

    @Input() status: string;

}
