import { AfterViewChecked, ChangeDetectorRef,
    Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "lib-text-common",
    templateUrl: "./text-common.component.html",
    styleUrls: ["./text-common.component.less"],
})
export class TextCommonComponent  implements AfterViewChecked {

    @Input() link: string;
    @Input() tooltipDisabled = false;
    @Input() tooltipMaxLengthInChars = 1000;
    @Input() horizAlign = "left";
    @Input() infoTip: string;

    @HostBinding("style.width") @Input() width = "100%";

    @ViewChild("outerContainer") outerContainer: ElementRef;
    @ViewChild("innerContainer") innerContainer: ElementRef;
    @ViewChild("contentInnerContainer") contentInnerContainer: ElementRef;

    get isTooltipDisabled(): boolean {
        return this.tooltipDisabled || !this.isTooltipRequired;
    }

    isTooltipRequired = false;
    text = "";

    private mouseDownX: number;
    private mouseDownY: number;

    ngAfterViewChecked(): void {
        const innerElement = this.innerContainer?.nativeElement;
        const isTooltipRequired = (innerElement && (innerElement.scrollWidth > innerElement.offsetWidth));

        let changed = false;

        if (isTooltipRequired !== this.isTooltipRequired) {
            this.isTooltipRequired = isTooltipRequired;
            changed = true;
        }

        const contentElement = this.contentInnerContainer?.nativeElement;
        const text = contentElement.textContent?.trim()?.substring(0, this.tooltipMaxLengthInChars);
        if (text !== this.text) {
            this.text = text;
            changed = true;
        }

        if (changed) {  // guard against any possible loop
            this.cd.detectChanges();
        }
    }

    constructor(private cd: ChangeDetectorRef, private router: Router) {}

    onLinkMouseDown(event: MouseEvent) {
        this.mouseDownX = event.x;
        this.mouseDownY = event.y;
    }

    onLinkMouseUp(event: MouseEvent, link: string) {
        if (link) {
            const mousePositionTolerance = 3;
            if ((event.button === 0) &&
                (Math.abs(event.x - this.mouseDownX) < mousePositionTolerance) &&
                (Math.abs(event.y - this.mouseDownY) < mousePositionTolerance)) {
                this.router.navigate([link]);
            }
        }
    }

}
