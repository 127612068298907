<div class="outer-container" [ngClass]="{'readonly': readonly}">

    <div class="inner-container">

        <input
            [type]="inputType"
            class="control"
            [ngModel]="value"
            (ngModelChange)="onChange($event)"
            [readonly]="readonly"
            placeholder=" "
            [min]="min"
            [max]="max" />

        <div class="input-label" [ngClass]="{'animate-label': animateLabel}">
            <div class="white-border-line"></div>
            <div class="input-label-text">{{ label }}</div>
        </div>

    </div>

</div>
