import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EventDeliveryStatusEnum } from "@merchant_api/models/event-delivery-status-enum";

@Component({
    selector: "app-event-deliveries-table-cell-content",
    templateUrl: "./event-deliveries-table-cell-content.component.html",
    styleUrls: ["./event-deliveries-table-cell-content.component.less"],
})
export class EventDeliveriesTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    @Output() resend = new EventEmitter<{eventDeliveryId: number; isDelivered: boolean}>();

    constructor() {}

    onClickResend() {
        this.resend.emit({eventDeliveryId: this.element.id, isDelivered: this.element.status === EventDeliveryStatusEnum.Delivered});
    }

}
