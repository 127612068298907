import { Component, Input } from "@angular/core";
import { TransactionOrderBy } from "@merchant_api/models/transaction-order-by";
import { DateTimeFormatUtils } from "shared-lib";

@Component({
    selector: "app-transactions-table-cell-content",
    templateUrl: "./transactions-table-cell-content.component.html",
    styleUrls: ["./transactions-table-cell-content.component.less"],
})
export class TransactionsTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    TransactionOrderBy = TransactionOrderBy;
    DateTimeFormatUtils = DateTimeFormatUtils;

    getDisplayTextForPayerName() {
        let displayText;
        if (this.element.payer?.fullName) {
            displayText = `${this.element.payer.fullName}`.toUpperCase();
        }
        return displayText;
    }

    getDisplayTextForCardNumber() {
        let displayText;
        if ((this.element.card?.first6) && (this.element.card?.last4)) {
            displayText = `${this.element.card.first6}XXX${this.element.card?.last4}`;
        }
        return displayText;
    }

    get displayTextForTransactionType() {
        let suffix = "";
        if (this.element.isServiceFee) {
            suffix = " (SF)";
        }
        return `${this.element.type}${suffix}`;
    }

}
