<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onTransactionsFilterChanged($event)"
    [filter]="transactionsFilter"
    suppressInitialRequest
    shortLayout>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-short-layout">
<!--
                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date"
                        [clearValue]="transactionsFilter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>
-->
                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="localFeesOnly"
                        isFilter
                        label="Fees Only"
                        [options]="feesOnlyList"
                        [showReset]="filtersBase.resetFieldVisible('localFeesOnly')"
                        (handleReset)="filtersBase.resetField('localFeesOnly')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                </div>

                <div class="form-field-container">
                </div>

                <div class="form-field-container">
                </div>

                <div class="form-field-container">
                </div>

                <div class="form-field-container">
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
