import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

import { DateRangePickerDirective } from "./date-range-picker/date-range-picker.directive";
import { DateRangeModal } from "./date-range-picker/date-range-modal/date-range-modal.service";
import { DateRangeModalContentComponent } from "./date-range-picker/date-range-modal/date-range-modal-content.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ActionButtonModule } from "../../action-button/action-button.module";

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        NgbDatepickerModule,
        ActionButtonModule
    ],
    declarations: [
        DateRangePickerDirective,
        DateRangeModalContentComponent
    ],
    providers: [
        DateRangeModal,
    ],
    exports: [
        DateRangePickerDirective,
    ]
})
export class DateInputsModule {}
