<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="FundingInstructionsOrderBy.MID">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingInstructionsOrderBy.DateCreated">
        <lib-date-time-label [dateUtc]="element.dateCreated"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'vituPayDepositAmount'">
        <lib-text-table-column>
            {{ element.vituPayDepositAmount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'merchantDepositAmount'">
        <lib-text-table-column>
            {{ element.merchantDepositAmount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingInstructionsOrderBy.FundingStatus">
        <lib-funding-item-status-chip
            [status]="element.fundingStatus">
        </lib-funding-item-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="FundingInstructionsOrderBy.DateFunded">
        <lib-date-time-label [dateUtc]="element.dateFunded"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
