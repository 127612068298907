import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DateRange } from "./date-inputs/date-range-picker/date-range";
import { DateLabel } from "./date-inputs/date-range-picker/date-range-modal/date-label";
import { DateTime } from "luxon";

/**
 * Represents a single (immutable) point in time at the point of construction.
 *
 * NOTE: Contains a point in time which is shifted for 'timeZone'.
 */
export class LocalTimePoint {

    private static timeZone = "America/Los_Angeles";
    private static timeZoneLocale = "en-US";

//    private static timeZone = "Europe/London";
//    private static timeZoneLocale = "en-GB";

//    private static timeZone = "UTC";
//    private static timeZoneLocale;

    private static timeZoneOffsetFromUtc = DateTime.now().setZone(LocalTimePoint.timeZone).offset;

    // To get good timezone abbreviated names need to use the locale as well as timezone
    // (otherwise luxon will use the browser's system locale, and user may see GMT-8 instead of PST)
    private static timeZoneOffsetShortName = LocalTimePoint.timeZone === "UTC"
        ? DateTime.now().setZone(LocalTimePoint.timeZone).offsetNameShort
        : DateTime.now().setZone(LocalTimePoint.timeZone).setLocale(LocalTimePoint.timeZoneLocale).offsetNameShort;

    private value: DateTime;

    static formatZ(): string {
        return this.timeZoneOffsetShortName;
    }

    static convertUtcValueToLocalValue(utcValue: string): string {
        if (!utcValue) {
            return utcValue;
        }
        return DateTime.fromISO(utcValue).toUTC().plus({ minutes: LocalTimePoint.timeZoneOffsetFromUtc }).toISO();
    }

    static convertLocalValueToUtcValue(localValue: string): string {
        if (!localValue) {
            return localValue;
        }
        return DateTime.fromISO(localValue).toUTC().minus({ minutes: LocalTimePoint.timeZoneOffsetFromUtc }).toISO();
    }

    get startOfDay(): string {
        return this.value.startOf("day").toISO();
    }

    get endOfDay(): string {
        return this.value.endOf("day").toISO();
    }

    get startOfPreviousDay(): string {
        return this.value.minus({ days: 1 }).startOf("day").toISO();
    }

    get endOfPreviousDay(): string {
        return this.value.minus({ days: 1 }).endOf("day").toISO();
    }

    get startOfWeek(): string {
        // uses 'MONDAY' as start day of week (as per ISO 8601)
        return this.value.startOf("week").toISO();
    }

    get startOfMonth(): string {
        return this.value.startOf("month").toISO();
    }

    get startOfPreviousMonth(): string {
        return this.value.minus({ months: 1 }).startOf("month").toISO();
    }

    get endOfPreviousMonth(): string {
        return this.value.minus({ months: 1 }).endOf("month").toISO();
    }

    get asNgbDate(): NgbDate {
        return new NgbDate(this.value.year, this.value.month, this.value.day );
    }

    startOfDayMinusDays(numberOfDays: number): string {
        return this.value.minus({ days: numberOfDays }).startOf("day").toISO();
    }

    endOfDayPlusDays(numberOfDays: number): string {
        return this.value.plus({ days: numberOfDays }).endOf("day").toISO();
    }

    endOfNextMonth(): string {
        return this.value.plus({ months: 1 }).endOf("month").toISO();
    }

    constructor() {
        this.value = DateTime.utc().plus({ minutes: LocalTimePoint.timeZoneOffsetFromUtc });
    }

    // ONLY TEST CODE SHOULD USE THIS!
    setValueForTestingOnly(isoValue: string) {
        this.value = DateTime.fromISO(isoValue).toUTC();
    }

}

// Module loader should ensure this is a singleton (i.e. only loaded once if many other TS modules depend on it)
const localTimePoint = new LocalTimePoint();

const initialFilterDateRangeLastTwoWeeks: DateRange = {
    from: localTimePoint.startOfDayMinusDays(13),
    to: localTimePoint.endOfDay,
    label: DateLabel.CUSTOM     // Need to set this as reducers use 'mergeRecursive' which will cause issues if label not set
};

export const getInitialLocalFilterDateRangeLastTwoWeeks = (): DateRange => ({
    from: initialFilterDateRangeLastTwoWeeks.from,
    to: initialFilterDateRangeLastTwoWeeks.to,
    label: initialFilterDateRangeLastTwoWeeks.label
});

const initialFilterDateRangeToday: DateRange = {
    from: localTimePoint.startOfDay,
    to: localTimePoint.endOfDay,
    label: DateLabel.TODAY
};

export const getInitialLocalFilterDateRangeToday = (): DateRange => ({
    from: initialFilterDateRangeToday.from,
    to: initialFilterDateRangeToday.to,
    label: initialFilterDateRangeToday.label
});

const initialFilterDateRangeToEndOfNextMonth: DateRange = {
    from: localTimePoint.startOfDay,
    to: localTimePoint.endOfNextMonth(),
    label: DateLabel.CUSTOM     // Need to set this as reducers use 'mergeRecursive' which will cause issues if label not set
};

export const getInitialLocalFilterDateRangeToEndOfNextMonth = (): DateRange => ({
    from: initialFilterDateRangeToEndOfNextMonth.from,
    to: initialFilterDateRangeToEndOfNextMonth.to,
    label: initialFilterDateRangeToEndOfNextMonth.label
});
