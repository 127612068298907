<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Funding' }]">
    </lib-title-bar>

    <app-funding-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)">
    </app-funding-log-filters>

    <lib-table-base
        class="funding-table"
        [columnDefs]="columnDefs"
        [rowsData]="funding"
        [rowsDataLoading]="fundingLoading"
        [rowsDataError]="fundingError"
        rowsDataErrorMessage="Error Loading Funding"
        [rowsDataShowWhileLoading]="true"
        selectedRowProperty="ref"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-funding-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-funding-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
