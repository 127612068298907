import { OrderDirectionEnum } from "@merchant_api/models/order-direction-enum";
import { TransactionOrderBy } from "@merchant_api/models/transaction-order-by";
import { CardBrandEnum } from "@merchant_api/models/card-brand-enum";
import { TransactionType } from "@merchant_api/models/transaction-type";
import { TransactionStatus } from "@merchant_api/models/transaction-status";
import { getInitialLocalFilterDateRangeLastTwoWeeks, DateRange, Pager, FilterBase, createFilter } from "shared-lib";
import { GatewayEnum } from "@merchant_api/models/gateway-enum";
import { Transaction } from "@merchant_api/models/transaction";
import { DigitalWalletEnum } from "@merchant_api/models/digital-wallet-enum";

export class Sorting {
    orderBy: null | TransactionOrderBy = TransactionOrderBy.DateAndTime;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    localDateSettledInterval: DateRange = undefined;
    transactionId: string = undefined;
    amount: number = undefined;
    authCode: string = undefined;
    first6: string = undefined;
    last4: string = undefined;
    payerName: string = undefined;
    cardBrands: Array<CardBrandEnum> = undefined;
    types: Array<TransactionType> = undefined;
    statuses: Array<TransactionStatus> = undefined;
    gateways: Array<GatewayEnum> = undefined;
    tid: string = undefined;
    mid: string = undefined;
    isServiceFee: boolean = undefined;
    digitalWallets: Array<DigitalWalletEnum> = undefined;
    token: string = undefined;
}

export class TransactionsState {
    loading = false;
    error = null;
    exportLoading = false;
    exportError = null;
    rows: Transaction[];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
