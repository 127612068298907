/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateWebhookDto } from '../models/create-webhook-dto';
import { EntityDto } from '../models/entity-dto';
import { ItemsDtoOfWebhookDto } from '../models/items-dto-of-webhook-dto';
import { UpdateWebhookDto } from '../models/update-webhook-dto';
import { WebhookDto } from '../models/webhook-dto';
import { WebhookEventTypesDto } from '../models/webhook-event-types-dto';

@Injectable({
  providedIn: 'root',
})
export class WebhooksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation webhooksSearch
   */
  static readonly WebhooksSearchPath = '/v1/webhooks';

  /**
   * Permissions: core.webhooks.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksSearch$Response(params?: {
  }): Observable<StrictHttpResponse<ItemsDtoOfWebhookDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksSearchPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemsDtoOfWebhookDto>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksSearch(params?: {
  }): Observable<ItemsDtoOfWebhookDto> {

    return this.webhooksSearch$Response(params).pipe(
      map((r: StrictHttpResponse<ItemsDtoOfWebhookDto>) => r.body as ItemsDtoOfWebhookDto)
    );
  }

  /**
   * Path part for operation webhooksPost
   */
  static readonly WebhooksPostPath = '/v1/webhooks';

  /**
   * Permissions: core.webhooks.create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPost$Response(params: {
    body: CreateWebhookDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPost(params: {
    body: CreateWebhookDto
  }): Observable<EntityDto> {

    return this.webhooksPost$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation webhooksGet
   */
  static readonly WebhooksGetPath = '/v1/webhooks/{id}';

  /**
   * Permissions: core.webhooks.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<WebhookDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebhookDto>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGet(params: {
    id: number;
  }): Observable<WebhookDto> {

    return this.webhooksGet$Response(params).pipe(
      map((r: StrictHttpResponse<WebhookDto>) => r.body as WebhookDto)
    );
  }

  /**
   * Path part for operation webhooksPut
   */
  static readonly WebhooksPutPath = '/v1/webhooks/{id}';

  /**
   * Permissions: core.webhooks.update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPut$Response(params: {
    id: number;
    body: UpdateWebhookDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.update
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPut(params: {
    id: number;
    body: UpdateWebhookDto
  }): Observable<void> {

    return this.webhooksPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation webhooksDelete
   */
  static readonly WebhooksDeletePath = '/v1/webhooks/{id}';

  /**
   * Permissions: core.webhooks.delete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.delete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksDelete(params: {
    id: number;
  }): Observable<void> {

    return this.webhooksDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation webhooksGenerateSecret
   */
  static readonly WebhooksGenerateSecretPath = '/v1/webhooks/generate-secret';

  /**
   * Permissions: core.webhooks.generate-secret
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksGenerateSecret()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGenerateSecret$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksGenerateSecretPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.generate-secret
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksGenerateSecret$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGenerateSecret(params?: {
  }): Observable<string> {

    return this.webhooksGenerateSecret$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation webhooksEventTypes
   */
  static readonly WebhooksEventTypesPath = '/v1/webhooks/event-types';

  /**
   * Permissions: core.webhooks.lookups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksEventTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksEventTypes$Response(params?: {
  }): Observable<StrictHttpResponse<WebhookEventTypesDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksEventTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebhookEventTypesDto>;
      })
    );
  }

  /**
   * Permissions: core.webhooks.lookups
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksEventTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksEventTypes(params?: {
  }): Observable<WebhookEventTypesDto> {

    return this.webhooksEventTypes$Response(params).pipe(
      map((r: StrictHttpResponse<WebhookEventTypesDto>) => r.body as WebhookEventTypesDto)
    );
  }

}
