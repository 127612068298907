import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";

import * as EventSelectors from "@merchant_app/storage/event/event.selectors";

import * as EventDeliveriesSelectors from "@merchant_app/storage/event-deliveries/event-deliveries.selectors";

import { GoBackAction } from "@merchant_app/storage/router/router.actions";
import { ResendEventDeliveryAction } from "@merchant_app/storage/event-delivery/event-delivery.actions";
import {
    PageChangedEventDeliveriesAction,
    SortEventDeliveriesAction
} from "@merchant_app/storage/event-deliveries/event-deliveries.actions";
import { Sorting } from "@merchant_app/storage/event-deliveries/event-deliveries.state";

@Component({
    selector: "app-event",
    templateUrl: "./event.component.html",
    styleUrls: ["./event.component.less"]
})
export class EventComponent {

    constructor(
        private store: Store<IStore>
    ) {}

    event$ = this.store.select(EventSelectors.getEvent);
    eventLoading$ = this.store.select(EventSelectors.getEventLoading);

    eventDeliveries$ = this.store.select(EventDeliveriesSelectors.getEventDeliveries);
    eventDeliveriesLoading$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesLoading);
    eventDeliveriesError$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesError);

    pager$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesPager);
    sorting$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesSorting);

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onResend(payload: {token: string; eventDeliveryId: number; isDelivered: boolean}): void {

        this.store.dispatch(new ResendEventDeliveryAction(payload.token, payload.eventDeliveryId, payload.isDelivered));
    }

    onPageChanged(payload: {token: string; page: number}) {
        this.store.dispatch(new PageChangedEventDeliveriesAction(payload.token, payload.page));
    }

    onSortingChanged(payload: {token: string; sorting: Sorting}) {
        this.store.dispatch(new SortEventDeliveriesAction(payload.token, payload.sorting));
    }

}
