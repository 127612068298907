import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SpaceBetweenCapsPipe } from "./space-between-caps.pipe";

@NgModule({
    declarations: [
        SpaceBetweenCapsPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SpaceBetweenCapsPipe
    ]
})
export class SpaceBetweenCapsModule { }
