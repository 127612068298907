import { Action } from "@ngrx/store";
import { NextPaymentAction } from "@merchant_api/models/next-payment-action";

export enum ActionTypes {
    GetNextPaymentActions = "[NextPaymentActions] GetNextPaymentActions",
    GetNextPaymentActionsSucceed = "[NextPaymentActions] GetNextPaymentActionsSucceed",
    GetNextPaymentActionsFail = "[NextPaymentActions] GetNextPaymentActionsFail"
}

export class GetNextPaymentActionsAction implements Action {

    constructor(
      public token: string
    ) { }

    readonly type = ActionTypes.GetNextPaymentActions;
}

export class GetNextPaymentActionsSucceedAction implements Action {

    constructor(
      public record: Array<NextPaymentAction>
    ) {}

    readonly type = ActionTypes.GetNextPaymentActionsSucceed;
}

export class GetNextPaymentActionsFailAction implements Action {

    constructor(
      public error: any
    ) {}

    readonly type = ActionTypes.GetNextPaymentActionsFail;
}
