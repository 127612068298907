import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetMidsAction, GetMidsFailAction, GetMidsSucceedAction } from "./mids.actions";
import { of } from "rxjs";
import { MerchantService } from "@merchant_api/services/merchant.service";

@Injectable()
export class MidsEffects {

    constructor(
        private actions$: Actions,
        private merchantService: MerchantService
    ) {}

    getMids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidsAction>(ActionTypes.GetMids),
            switchMap((action) =>
                this.merchantService.merchantGetMids({ "Pager.PageSize": 100 }).pipe(
                    switchMap((mids) => of(new GetMidsSucceedAction(mids))),
                    catchError((error) => of(new GetMidsFailAction(error)))
                ))
        )
    );

}
