<div class="outer-container">
    
    <lib-title-bar [crumbs]="[{ 'label': 'Help and Support' }]">
    </lib-title-bar>

    <div class="links-container">
        <div class="link-container" id="vituPaySupport">
            <div class="link-container-inner">
                <div class="link-icon">
                    <div class="link-icon-inner">
                        <i class="far fa-question-circle"></i>
                    </div>
                </div>
                <div class="link-details">
                    <div class="link-main">
                        <a class="link-anchor" href="https://support.vitupay.com" target="_blank">VituPay Support</a>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="link-container" id="customerSupport">
            <div class="link-container-inner">
                <div class="link-icon">
                    <div class="link-icon-inner">
                        <i class="far fa-user-headset"></i>
                    </div>
                </div>
                <div class="link-details">
                    <div class="link-main">
                        Customer Support
                    </div>
                    <div class="link-sub">
                        Phone: 833-207-0522
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
