import { RolesState } from "./roles.state";
import { ActionTypes } from "./roles.actions";

export const RolesReducer = (
    state: RolesState = new RolesState(),
    action: any,
  ): RolesState => {

    switch (action.type) {

        case ActionTypes.GetRoles: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetRolesSucceed: {
            return {
                ...state,
                record: action.roles,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetRolesFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
