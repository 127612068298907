import { OrderDirectionEnum } from "@merchant_api/models/order-direction-enum";
import { EventDeliveryDto } from "@merchant_api/models/event-delivery-dto";
import { getInitialLocalFilterDateRangeLastTwoWeeks, DateRange, Pager, FilterBase, createFilter } from "shared-lib";
import { EventDeliveryOrderByEnum } from "@merchant_api/models/event-delivery-order-by-enum";

export class Sorting {
    orderBy: null | EventDeliveryOrderByEnum = EventDeliveryOrderByEnum.CreatedUtc;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval?: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    startDate?: string = undefined;
    endDate?: string = undefined;

    eventTypes: Array<string> = undefined;
}

export class EventDeliveriesState {
    loading = false;
    error: Error = null;
    rows: Array<EventDeliveryDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
