import { Action } from "@ngrx/store";
import { ClientSecretDto } from "@merchant_api/models/client-secret-dto";

export enum ActionTypes {
    ResetClientSecrets = "[ClientSecrets] ResetClientSecrets",
    GetClientSecrets = "[ClientSecrets] GetClientSecrets",
    GetClientSecretsSucceed = "[ClientSecrets] GetClientSecretsSucceed",
    GetClientSecretsFail = "[ClientSecrets] GetClientSecretsFail",
}

export class ResetClientSecretsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetClientSecrets;
}

export class GetClientSecretsAction implements Action {

    constructor(public clientId: number) {}

    readonly type = ActionTypes.GetClientSecrets;
}

export class GetClientSecretsSucceedAction implements Action {

    constructor(public clientSecrets: Array<ClientSecretDto>) {}

    readonly type = ActionTypes.GetClientSecretsSucceed;
}

export class GetClientSecretsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetClientSecretsFail;
}
