import { EventDetailsDto } from "@merchant_api/models/event-details-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetEvent = "[Event] GetEvent",
    GetEventSucceed = "[Event] GetEventSucceed",
    GetEventFail = "[Event] GetEventFail"
}

export class GetEventAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.GetEvent;
}

export class GetEventSucceedAction implements Action {

    constructor(public response: EventDetailsDto) {}

    readonly type = ActionTypes.GetEventSucceed;
}

export class GetEventFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetEventFail;
}
