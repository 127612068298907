import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ConfirmationModalComponent, ConfirmationModalData } from "../confirmation-modal/confirmation-modal.component";
import { GlobalSpinnerService } from "../global-spinner/global-spinner.service";
import { VituToastTone } from "../toast/vitu-toast-tone";
import { VituToastService } from "../toast/vitu-toast.service";

export enum ErrorUi {
    None,
    Toast,
    Modal
}

export interface ErrorUiConfig {
    clientErrorUi?: ErrorUi;
    serverErrorUi?: ErrorUi;
}

export interface ErrorUiAppReloadConfig {
    origin: string;
    help: string;
}

@Injectable({ providedIn: "root" })
export class ErrorUiService {

    reloadConfig: ErrorUiAppReloadConfig;
    defaultRoute: string;

    readonly defaultConfig: ErrorUiConfig = {
        clientErrorUi: ErrorUi.Toast,
        serverErrorUi: ErrorUi.Modal
    };

    private dialogRef: MatDialogRef<ConfirmationModalComponent>;
    private config: ErrorUiConfig;

    handleNotification(message: string, isServerError = false): void {

        if (!message) {
            return;
        }

        const title = "Error";

        if (isServerError) {
            switch (this.config.serverErrorUi)
            {
                case ErrorUi.Modal:
                    {
                        this.displayMessageOnReloadPageModal(title, message);
                        break;
                    }
            }
        }
        else {
            switch (this.config.clientErrorUi)
            {
                case ErrorUi.Toast:
                    {
                        this.displayMessageOnToast(message);
                        break;
                    }
                case ErrorUi.Modal:
                    {
                        this.displayMessageOnModal(title, message);
                        break;
                    }
            }
        }

    }

    dismissAll(): void {
        this.toast.close();
        this.dialog.closeAll();
    }

    // IMPORTANT: Doesn't support concurrency.
    // i.e. If another request's response were to come back before the error UI was re-enabled,
    // then that request would not have an error UI shown.
    // => Can only be used in a 'single request' situation, where there is a single
    // thread of behavior. (eg. from a Modal)
    disable(obs: Observable<any>): Observable<any> {
        const config = {
            clientErrorUi: ErrorUi.None,
            serverErrorUi: ErrorUi.None
        };
        this.overrideStart(config);
        return obs.pipe(
            // Ensure it calls stop on all 3 tap channels
            // (Note: 'finalize' won't work as it gets called on unsubscribe which we don't want)
            tap(
                () => {
                    this.overrideEnd();
                },
                () => {
                    this.overrideEnd();
                },
                () => {
                    this.overrideEnd();
                }
            )
        );
    };

    constructor(
        private toast: VituToastService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private router: Router
    ) {
        this.resetConfig();
    }

    private resetConfig() {
        this.config = this.defaultConfig;
    }

    private overrideStart(config: ErrorUiConfig) {
        this.config = config;
    }

    private overrideEnd() {
        this.resetConfig();
    }

    private displayMessageOnToast(message: string): void {
        this.toast.open(message, VituToastTone.Negative);
    }

    private displayMessageOnModal(
        title: string, message: string, confirmButtonText?: string, secondButtonText?: string, thirdButtonText?: string): Observable<any> {

        this.dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: "600px",
            data: {
                title,
                subtitle: message,
                confirmButtonText,
                secondButtonText,
                thirdButtonText,
                noCancel: true
            } as ConfirmationModalData
        });

        return this.dialogRef.afterClosed();
    }

    private displayMessageOnReloadPageModal(title: string, message: string): void {

        if (!this.reloadConfig) {
            return;
        }

        const firstButtonText = "Reload";
        let secondButtonText = "Home";
        let thirdButtonText = "Help";

        const onHomePage = (this.router.url === "/" || (this.defaultRoute && (this.router.url === this.defaultRoute)));
        if (onHomePage) {
            secondButtonText = undefined;
        }
        if (this.router.url === "/") {
            thirdButtonText = undefined;
        }

        this.displayMessageOnModal(title, message, firstButtonText, secondButtonText, thirdButtonText).subscribe((result: any) => {

            switch (result) {
                case 1:
                    {
                        // RELOAD
                        this.globalSpinner.start();
                        window.location.reload();
                        break;
                    }
                case 2:
                    {
                        // HOME
                        this.router.navigate([`/`]);
                        break;
                    }
                case 3:
                    {
                        // HELP
                        this.router.navigate([`${this.reloadConfig.help}`]);
                        break;
                    }
            }

        }); // NOTE: no need to unsubscribe from afterClosed (=> it completes itself);
    }

}
