import { EventsDto } from "@merchant_api/models/events-dto";
import { Action } from "@ngrx/store";
import { Sorting, Filter } from "./events.state";

export enum ActionTypes {
    FilterEvents = "[Events] FilterEvents",
    SortEvents = "[Events] SortEvents",
    PageChangedEvents = "[Events] PageChangedEvents",
    GetEvents = "[Events] GetEvents",
    GetEventsSucceed = "[Events] GetEventsSucceed",
    GetEventsFail = "[Events] GetEventsFail"
}

export class FilterEventsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterEvents;
}

export class SortEventsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortEvents;
}

export class PageChangedEventsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedEvents;
}

export class GetEventsAction implements Action {

    constructor(public page: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetEvents;
}

export class GetEventsSucceedAction implements Action {

    constructor(
        public response: EventsDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetEventsSucceed;
}

export class GetEventsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetEventsFail;
}
