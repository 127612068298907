import { ActionTypes } from "./payment.actions";
import { PaymentState } from "./payment.state";

export const PaymentReducer = (
    state: PaymentState = new PaymentState(),
    action: any,
): PaymentState => {

    switch (action.type) {

        case ActionTypes.GetPaymentDetail: {

            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetPaymentDetailSucceed: {

            return {
                ...state,
                loading: false,
                record: action.record,
                error: null
            };
        }

        case ActionTypes.GetPaymentDetailFail: {

            return {
                ...state,
                loading: false,
                error: action.error,
                record: {}
            };
        }

        case ActionTypes.GetPaymentFees: {

            return {
                ...state,
                feesLoading: true
            };
        }

        case ActionTypes.GetPaymentFeesSucceed: {

            return {
                ...state,
                feesLoading: false,
                fees: action.fees,
                feesError: null
            };
        }

        case ActionTypes.GetPaymentFeesFail: {

            return {
                ...state,
                feesLoading: false,
                feesError: action.error,
                fees: {}
            };
        }

        default:
            return state;
    }
};
