import { MidsState } from "./mids.state";
import { ActionTypes } from "./mids.actions";

export const MidsReducer = (
    state: MidsState = new MidsState(),
    action: any,
  ): MidsState => {

    switch (action.type) {

        case ActionTypes.ResetMids: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetMids: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetMidsSucceed: {
            return {
                ...state,
                record: action.mids,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetMidsFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }
    }

};
