import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-transaction-status-chip",
    templateUrl: "./transaction-status-chip.component.html",
    styleUrls: ["./transaction-status-chip.component.less"],
})
export class TransactionStatusChipComponent {

    @Input() status: string;

}
