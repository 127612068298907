import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActionButtonKind } from "shared-lib";
import { RoleDetailsDto } from "@merchant_api/models/role-details-dto";
import { PermissionDto } from "@merchant_api/models/permission-dto";
import { CreateRoleDto } from "@merchant_api/models/create-role-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-role-details",
    templateUrl: "./role-details.component.html",
    styleUrls: ["./role-details.component.less"],
})
export class RoleDetailsComponent implements OnInit, OnChanges {

    @Input() isCreate: boolean;
    @Input() role: RoleDetailsDto;
    @Input() permissionList: PermissionDto[];
    @Input() loading: boolean;

    @Output() deleteRole = new EventEmitter<RoleDetailsDto>();
    @Output() updateRole = new EventEmitter<RoleDetailsDto>();
    @Output() createRole = new EventEmitter<CreateRoleDto>();
    @Output() back = new EventEmitter<void>();

    submitButtonText: string;

    ActionButtonKind = ActionButtonKind;

    constructor(private fb: UntypedFormBuilder, private currPerms: CurrentUserPermissions) {}

    roleForm = this.fb.group({
        name: [null, [Validators.required, Validators.pattern(/^[\w][\w- &()]*$/)]],
        permissionIds: [null as Array<{id: number; name: string; checked: boolean}>]
    });

    get formControls(): FormControls<RoleDetailsDto> { return this.roleForm.controls; }

    ngOnInit() {
        this.submitButtonText = (this.isCreate ? "Submit" : "Update");
    }

    get pageTitle(): string {
        if (this.isCreate) {
            return "New Role";
        }
        if (!this.role) {
            return "---";
        }
        if (this.role.isCustom && this.hasUpdateRolePermission) {
            return "Edit Role";
        }
        return "View Role";
    }

    ngOnChanges(changes: SimpleChanges) {

        if ("role" in changes && this.role) {
            this.roleForm.controls.name.patchValue(this.role.name);
        }

        if ("permissionList" in changes && this.permissionList) {
            this.formControls?.permissionIds.patchValue(this.getFormArray());
        }

        if (!this.isCreate) {
            this.roleForm.markAllAsTouched();
        }
    }

    private getFormArray(): Array<any> {
        return this.permissionList?.map(i => ({
            ...i,
            checked: this.isChecked(i, this.role)
        }));
    }

    private isChecked(permission, role: RoleDetailsDto) {

        return role?.permissionIds
            ? role.permissionIds?.indexOf(permission.id) >= 0
            : false;
    }

    onClickDelete() {

        this.deleteRole.emit(this.role);
    }

    onClickBack() {

        this.back.emit();
    }

    onSubmit() {

        const roleForm = this.roleForm.value;

        const name = roleForm.name;
        const permissionIds = roleForm.permissionIds.filter(i => i.checked).map(i => i.id);

        if (this.isCreate) {
            const createRoleDto: CreateRoleDto = {
                name,
                permissionIds
            };
            this.createRole.emit(createRoleDto);
        }
        else {
            const roleDetailsDto: RoleDetailsDto = {
                id: this.role.id,
                concurrencyToken: this.role?.concurrencyToken,
                name,
                permissionIds
            };
            this.updateRole.emit(roleDetailsDto);
        }

    }

    get hasDeleteRolePermission(): boolean {
        return this.currPerms.has(["core.roles.delete"]);
    }

    get hasUpdateRolePermission(): boolean {
        return this.currPerms.has(["core.roles.update"]);
    }

}
