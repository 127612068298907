import { Action } from "@ngrx/store";
import { Refund } from "@merchant_api/models/refund";
import { Capture } from "@merchant_api/models/capture";
import { Void } from "@merchant_api/models/void";
import { TransactionFeesResult } from "@merchant_api/models/transaction-fees-result";
import { PaymentDetails } from "@merchant_api/models/payment-details";

export enum ActionTypes {
    GetPaymentDetail = "[PAYMENT] GetPaymentDetail",
    GetPaymentDetailSucceed = "[PAYMENT] GetPaymentDetailSucceed",
    GetPaymentDetailFail = "[PAYMENT] GetPaymentDetailFail",
    RefundPaymentTransaction = "[PAYMENT] RefundPaymentTransaction",
    CapturePaymentTransaction = "[PAYMENT] CapturePaymentTransaction",
    VoidPaymentTransaction = "[PAYMENT] VoidPaymentTransaction",
    GetPaymentFees = "[PAYMENT] GetPaymentFees",
    GetPaymentFeesSucceed = "[PAYMENT] GetPaymentFeesSucceed",
    GetPaymentFeesFail = "[PAYMENT] GetPaymentFeesFail"
}

export class GetPaymentDetailAction implements Action {

  constructor(
    public token: string,
    public getRelations = false
  ) {}

  readonly type = ActionTypes.GetPaymentDetail;
}

export class GetPaymentDetailSucceedAction implements Action {

  constructor(
    public record: PaymentDetails,
    public getRelations = false
  ) {}

  readonly type = ActionTypes.GetPaymentDetailSucceed;
}

export class GetPaymentDetailFailAction implements Action {

    constructor(
      public error: any
    ) {}

    readonly type = ActionTypes.GetPaymentDetailFail;
}

export class RefundPaymentTransactionAction implements Action {

    constructor(
        public refund: {payment: PaymentDetails; amount: number; serviceFeeAmount: number|null}
    ) {}

    readonly type = ActionTypes.RefundPaymentTransaction;
}

export class CapturePaymentTransactionAction implements Action {

  constructor(
    public paymentTransaction: Capture,
    public token: string,
  ) { }

  readonly type = ActionTypes.CapturePaymentTransaction;
}

export class VoidPaymentTransactionAction implements Action {

  constructor(
    public paymentTransaction: Void,
    public token: string,
  ) { }

  readonly type = ActionTypes.VoidPaymentTransaction;
}

export class GetPaymentFeesAction implements Action {

    constructor(
      public token: string
    ) {}

    readonly type = ActionTypes.GetPaymentFees;
}

export class GetPaymentFeesSucceedAction implements Action {

    constructor(
        public fees: TransactionFeesResult
    ) {}

    readonly type = ActionTypes.GetPaymentFeesSucceed;
}

export class GetPaymentFeesFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetPaymentFeesFail;
}
