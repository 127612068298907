import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SidebarItem } from "../sidebar-item";

@Component({
    selector: "lib-dashboard-sidebar-menu-item",
    templateUrl: "./dashboard-sidebar-menu-item.component.html",
    styleUrls: ["./dashboard-sidebar-menu-item.component.less"],
})
export class DashboardSidebarMenuItemComponent {

    @Input() item: SidebarItem;
    @Input() sidebarOpen: boolean;
    @Input() isItemActionable: boolean;
    @Input() isItemActive: boolean;
    @Input() subMenuActive = false;
    @Input() isSubmenuItem = false;

    @Output() navigateTo = new EventEmitter<string>();

    onClickItem() {
        if (this.isItemActionable) {
            this.navigateTo.emit(this.item.primaryRouteToActivate);
        }
    }

    get noPrimaryRoute() {
        return !this.item || !this.item.primaryRouteToActivate;
    }

}
