import { AfterContentChecked, Component, ContentChild, ContentChildren,
    EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from "@angular/core";
import { FormControlName, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, filter, distinctUntilChanged, skip } from "rxjs/operators";
import { ActionButtonKind } from "../action-button";
import { FiltersMode } from "./filters-mode";
import { isEqualWith } from "lodash";

export class FilterBase<Type> {
    _initialFilter: Type;
}

export const createFilter = <Type>(c: new () => Type): Type => {
    const retVal = new c();
    (retVal as any)._initialFilter = new c();
    return retVal;
};

@Component({
    selector: "lib-filters-base",
    templateUrl: "./filters-base.component.html",
    styleUrls: ["./filters-base.component.less"],
})
export class FiltersBaseComponent  implements OnInit, AfterContentChecked, OnDestroy {

    @Input() filterForm: UntypedFormGroup;
    @Input() basicFiltersModeHeightInPixels = 118;

    @Input() set shortLayout(value: any) {
        this._shortLayout = !(value === false);
    };

    get shortLayout() {
        return this._shortLayout;
    }
    _shortLayout = false;

    set filterMode(value: FiltersMode) {
        this.setFilterMode(value);
    }
    get filterMode() {
        return this._filterMode;
    }
    _filterMode: FiltersMode;

    @Output() filterChanged = new EventEmitter<any>();

    @Input() filter: any;

    @Input() moreFields = [];

    // Typically use this attribute when the filters are on a page which sends out
    // a request for the data BEFORE the filters are set up (eg. within the page init
    // effect, for performance reasons) and we don't desire the same request to be
    // duplicated when the filters are subsequently initialized.
    @Input() set suppressInitialRequest(value: any) {
        this._suppressInitialRequest = !(value === false);
    };
    _suppressInitialRequest = false;

    @ContentChild("basicFilters") basicFilters;
    @ContentChild("moreFilters") moreFilters;
    @ContentChildren(FormControlName, {descendants: true}) formControlNames!: QueryList<FormControlName>;

    midStageSizesInPixels: number[];

    FiltersMode = FiltersMode;
    ActionButtonKind = ActionButtonKind;

    subscription: Subscription = new Subscription();

    resetFieldVisible(controlName: string) {

        const controlValue = this.filterForm.get(controlName).value;
        if (Array.isArray(controlValue) && !controlValue.length) {
            return false;
        }
        return controlValue;
    }

    resetField(controlName: string) {

        this.filterForm.get(controlName).reset();
        if (this.filter) {
            this.filterForm.get(controlName).patchValue(this.filter._initialFilter[controlName]);
        }
    }

    resetMoreFields() {

        if (this.filtersDirty(true)) {
            this.moreFields.forEach(moreField => {
                this.resetField(moreField);
            });
        }
    }

    resetAllFields() {

        if (this.filtersDirty()) {
            this.filterForm.reset();
            if (this.filter) {
                this.filterForm.patchValue(this.filter._initialFilter);
            }
        }
    }

    ngOnInit() {

        this.midStageSizesInPixels = [ this.basicFiltersModeHeightInPixels ];

        this.filterForm.patchValue(this.filter);

        const _initialFilter = this.filter?._initialFilter || {};

        this.subscription.add(this.filterForm.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(() => this.filterForm.valid),
                skip(this._suppressInitialRequest ? 1 : 0)
            )
            .subscribe((i) => {
                this.filterChanged.emit({ ...i, _initialFilter });
            }));

        this.setFilterMode(this.getRequiredFilterMode());
    }

    ngAfterContentChecked(): void {
/*
        // AC_todo :
        // Be good to figure out how to filter the 'moreFields' automatically
        // instead of having to pass them as a parameter, but can't currently
        // figure out how to get ContentChildren UNDER a div (instead we only
        // have the entire set of basicFields + moreFields).

        this.formControlNames.forEach(formControlName => {
            console.log(`${formControlName.name}`);
        });
*/
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getRequiredFilterMode(): FiltersMode {
        if (this.filtersDirty(true)) {
            return FiltersMode.ALL;
        }
        else {
            return FiltersMode.BASIC;
        }
    }

    setFilterMode(filterMode: FiltersMode) {

        if (filterMode === this.filterMode) {
            return;
        }

        switch (filterMode) {
            case FiltersMode.NONE: {
                this.resetAllFields();
                break;
            }
            case FiltersMode.BASIC: {
                if (this.filterMode === FiltersMode.ALL) {
                    this.resetMoreFields();
                }
                break;
            }
            case FiltersMode.ALL: {
                break;
            }
        }

        this._filterMode = filterMode;
    }

    filtersDirty(onlyMoreFields = false): boolean {
        let count = 0;
        if (this.filter?._initialFilter) {
            Object.keys(this.filter._initialFilter).forEach(key => {
                if (!onlyMoreFields || (this.moreFields.indexOf(key) !== -1)) {
                    if (!this.isEqual(this.filter[key], this.filter._initialFilter[key])) {
                        ++count;
                    }
                }
            });
        }
        return (count > 0);
    }

    private isEqual(value: any, other: any) {
        // Adjust standard 'isEqual' with extra condition(s) useful to filters:

        // For Multi-selection lists
        //  1. Equate [] and null.
        //  2. Equate [] and undefined.
        return isEqualWith(value, other, (a, b) => {
            if ((Array.isArray(a) && (a.length === 0)) && ((b === undefined) || (b === null))) {
                return ((b === undefined) || (b === null));
            }
            if ((Array.isArray(b) && (b.length === 0)) && ((a === undefined) || (a === null))) {
                return ((a === undefined) || (a === null));
            }
        });
    }

}
