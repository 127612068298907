import { Action } from "@ngrx/store";
import { Month } from "@merchant_api/models/month";
import { StatementReportItem } from "@merchant_api/models/statement-report-item";

export enum ActionTypes {
    ResetStatements = "[Statements] ResetStatements",
    GetStatements = "[Statements] GetStatements",
    GetStatementsSucceed = "[Statements] GetStatementsSucceed",
    GetStatementsFail = "[Statements] GetStatementsFail"
}

export class ResetStatementsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetStatements;
}

export class GetStatementsAction implements Action {

    constructor(public month: Month, public year: number, public merchantId: number) {}

    readonly type = ActionTypes.GetStatements;
}

export class GetStatementsSucceedAction implements Action {

    constructor(public statements: Array<StatementReportItem>) {}

    readonly type = ActionTypes.GetStatementsSucceed;
}

export class GetStatementsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetStatementsFail;
}
