import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { DateRange, LocalTimePoint } from "../../../date-time";

@Component({
    selector: "lib-vitu-form-field-date",
    templateUrl: "./vitu-form-field-date.component.html",
    styleUrls: ["./vitu-form-field-date.component.less"]
})
export class VituFormFieldDateComponent implements OnChanges {

    @Input() formGroup: UntypedFormGroup;
    @Input() formFieldName: string;

    @Input() clearValue: DateRange;

    @Input() set isFilter(value: any) {
        this._isFilter = !(value === false);
    };

    @Input() set disabled(value: any) {
        this._disabled = !(value === false);
    };

    @Input() set singleDateMode(value: any) {
        this._singleDateMode = !(value === false);
    };

    @Input() set readonly(value: any) {
        this._readonly = !(value === false);
    };

    @Input() set restrictPast(value: any) {
        this._restrictPast = !(value === false);
    };

    @Input() set restrictFuture(value: any) {
        this._restrictFuture = !(value === false);
    };

    @Input() set restrictToPastMonth(value: any) {
        this._restrictToPastMonth = !(value === false);
    };

    @Input() set restrictToDateOfBirth(value: any) {
        this._restrictToDateOfBirth = !(value === false);
    };

    @Input() label: string;
    @Input() infoTip: string;

    @Input() showReset = false;
    @Output() handleReset = new EventEmitter<void>();

    @Input() isUtc = false;

    formControl: UntypedFormControl;

    _isFilter = false;
    _disabled = false;
    _singleDateMode = false;
    _readonly = false;
    _restrictPast = false;
    _restrictFuture = false;
    _restrictToPastMonth = false;
    _restrictToDateOfBirth = false;

    @ViewChild(MatFormField) formField: MatFormField;

    LocalTimePoint = LocalTimePoint;

    get displayLabel() {
        let retVal = this.label;
        if (!this.isUtc) {
            retVal += ` (${LocalTimePoint.formatZ()})`;
        }
        return retVal;
    }

    get errors() {
        return (this.formGroup?.controls[this.formFieldName] as UntypedFormControl)?.errors;
    }

    get hasRequiredValidator() {
        const abstractControl = this.formGroup?.controls[this.formFieldName];
        if (abstractControl?.validator) {
          const validator = abstractControl.validator({} as AbstractControl);
          if (validator && validator.required) {
            return true;
          }
        }
        return false;
    }

    ngOnChanges() {
        if (this.formGroup && this.formFieldName) {
            this.formControl = this.formGroup.controls[this.formFieldName] as UntypedFormControl;
        }
    }

    updateOutlineGap() {
        // Workaround: Angular Bug
        // Label on outline form fields doesn't align properly
        // Fix adapted from : https://github.com/angular/components/issues/15027
        this.formField.updateOutlineGap();
    }

    onClickIcon(datePicker: any) {
        if (!this._disabled) {
            datePicker?.openModal();
        }
    }

}
