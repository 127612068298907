<div class="outer-container">

    <ng-container *ngIf="dateUtcWithTimeZone; else emptyValue">

        <ng-container *ngIf="rawDateWithNoTime; else localizedDateTime">

            <div>{{ dateUtcWithTimeZone | date: 'MM/dd/yyyy' : "UTC" }}</div>

        </ng-container>
        <ng-template #localizedDateTime>

            <ng-container *ngIf="singleLine; else multiLine">
                <div class="single-line">
                    <span>{{ dateUtcWithTimeZone | date: 'MM/dd/yyyy&nbsp;' : LocalTimePoint.formatZ() }}</span>
                    <span>{{ dateUtcWithTimeZone | date: 'h:mm:ss&nbsp;a' : LocalTimePoint.formatZ() }}</span>
                </div>
            </ng-container>
            <ng-template #multiLine>
                <div>
                    <div>{{ dateUtcWithTimeZone | date: 'MM/dd/yyyy&nbsp;' : LocalTimePoint.formatZ() }}</div>
                    <div class="dim-time">{{ dateUtcWithTimeZone | date: 'h:mm:ss&nbsp;a' : LocalTimePoint.formatZ() }}</div>
                </div>
            </ng-template>

        </ng-template>

    </ng-container>

    <ng-template #emptyValue>
        {{ "—" }}
    </ng-template>

</div>
