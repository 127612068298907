<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="PaymentOrderBy.Amount">
        <lib-text-table-column>
            {{ element.amount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'Token'">
        <lib-text-table-column>
            {{ element.token }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'TransactionStatus'">
        <lib-status-chip
            class="group-transaction-statuses"
            [status]="element?.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentOrderBy.PayerName">
        <lib-text-table-column>
            {{ getDisplayTextForPayerName() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'DigitalWallet'">
        <lib-digital-wallet-image-table-column
            [kind]="element.digitalWallet">
        </lib-digital-wallet-image-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'CardBrand'">
        <span *ngIf="element.card?.cardBrand" class="card-image">
            <i [class]="'fab fa-cc-' + element.card.cardBrand.toLowerCase() + ' fa-2x'"></i>
        </span>
        <lib-text-table-column *ngIf="!element.card?.cardBrand">
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentOrderBy.CardNumber">
        <lib-text-table-column>
            {{ getDisplayTextForCardNumber() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'DisputeType'">
        <ng-container *ngIf="element.disputeTypes?.length; else noDisputes">
            <ng-container *ngFor="let type of element.disputeTypes">
                <lib-vitu-tooltip [text]="type" position="above" [showDelayInMillis]="0">
                    <div class="icon" (mousedown)="onMouseDownDisputeType($event, type)">

                        <ng-container [ngSwitch]="type">

                            <ng-container *ngSwitchCase="DisputeTypeEnum.Chargeback">
                                <span class="fa-stack">
                                    <i class="fas fa-history fa-stack-2x"></i>
                                    <div class="dispute-type-chargeback-icon-mask"></div>
                                    <i class="far fa-dollar-sign fa-stack-1x"></i>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="DisputeTypeEnum.Retrieval">
                                <i class="fas fa-hand-holding-usd fa-2x"></i>
                            </ng-container>

                        </ng-container>

                    </div>
                </lib-vitu-tooltip>
            </ng-container>
        </ng-container>
        <ng-template #noDisputes>
            <lib-text-table-column>
            </lib-text-table-column>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentOrderBy.DateAndTime">
        <lib-date-time-label [dateUtc]="element.dateUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'Fees'">
        <lib-text-table-column>
            {{ element.fees | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





