import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, map, filter as filterOperator } from "rxjs/operators";
import { of } from "rxjs";
import { EventsService } from "@merchant_api/services/events.service";
import {
    ActionTypes,
    ResendEventDeliveryAction
} from "./event-delivery.actions";
import { GenericNoOpAction } from "../generic.actions";
import { MatDialog } from "@angular/material/dialog";
import {
    VituToastService,
    VituToastTone,
    GlobalSpinnerService,
    ErrorUiService,
    ConfirmationModalComponent,
    ConfirmationModalData,
    ErrorUi
} from "shared-lib";
import { GetEventDeliveriesAction } from "../event-deliveries/event-deliveries.actions";

@Injectable()
export class EventDeliveryEffects {

    constructor(
        private actions: Actions,
        private eventsService: EventsService,
        private dialog: MatDialog,
        private toast: VituToastService,
        private globalSpinner: GlobalSpinnerService,
        private errorUi: ErrorUiService
    ) { }

    resend = createEffect(() =>
        this.actions.pipe(
            ofType<ResendEventDeliveryAction>(ActionTypes.ResendEventDelivery),
            switchMap(({ token, eventDeliveryId, isDelivered }) => this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Resend Event",
                        subtitle: (isDelivered
                            ? "This event has already been delivered.  Are you sure you want to resend it?"
                            : "Are you sure you want to resend this event?"),
                        confirmButtonText: "Resend"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((confirmed: boolean) => ({ token, eventDeliveryId, confirmed })),
                )),
            filterOperator(({ confirmed }) => confirmed),
            switchMap(({ token, eventDeliveryId }) =>
                this.errorUi.disable(
                    this.globalSpinner.apply(
                        this.eventsService.eventDeliveriesSend({ id: eventDeliveryId, token }).pipe(
                            switchMap(() => {
                                this.toast.open(`Event redelivery triggered.`, VituToastTone.Positive);
                                return of(new GetEventDeliveriesAction(token, 1));
                            }),
                            catchError(() => {
                                this.toast.open(`Event redelivery failed.`, VituToastTone.Negative);
                                return of(new GenericNoOpAction());
                            })
                        )
                    )
                )
            )
        ),
    );

}
