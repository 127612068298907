import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SwitchArrayComponent } from "./switch-array.component";
import { ActionButtonModule } from "../action-button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        SwitchArrayComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        ActionButtonModule
    ],
    exports: [
        SwitchArrayComponent
    ]
})
export class SwitchArrayModule { }
