import { WebhookDto } from "@merchant_api/models/webhook-dto";

export class WebhooksState {
    loading = false;
    error = null;
    record: Array<WebhookDto> = [];
    secret = null;
    secretLoading = false;
    secretError = null;
}
