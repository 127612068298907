import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActionButtonModule } from "../action-button";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CopyToClipboardButtonComponent } from "./copy-to-clipboard-button.component";

@NgModule({
    declarations: [
        CopyToClipboardButtonComponent
    ],
    imports: [
        CommonModule,
        ActionButtonModule,
        ClipboardModule
    ],
    exports: [
        CopyToClipboardButtonComponent
    ]
})
export class CopyToClipboardButtonModule { }
