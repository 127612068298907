import { Component } from "@angular/core";

@Component({
    selector: "lib-vitu-form-buttons",
    templateUrl: "./vitu-form-buttons.component.html",
    styleUrls: ["./vitu-form-buttons.component.less"]
})
export class VituFormButtonsComponent{

}
