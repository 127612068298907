import { EventState } from "./event.state";
import {
    ActionTypes,
} from "./event.actions";

export const EventReducer = (state: EventState = new EventState(), action: any): EventState => {

    switch (action.type) {

        case ActionTypes.GetEvent: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetEventSucceed: {
            return {
                ...state,
                record: action.response,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetEventFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
