import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, withLatestFrom, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import {
    LocalTimePoint
} from "shared-lib";
import { Filter, Sorting } from "./event-deliveries.state";
import { getEventDeliveriesState } from "./event-deliveries.selectors";
import {
    ActionTypes,
    PageChangedEventDeliveriesAction,
    FilterEventDeliveriesAction,
    GetEventDeliveriesAction,
    GetEventDeliveriesSucceedAction,
    SortEventDeliveriesAction,
    GetEventDeliveriesFailAction

} from "./event-deliveries.actions";
import { EventsService } from "@merchant_api/services/events.service";

@Injectable()
export class EventDeliveriesEffects {

    constructor(
        private actions: Actions,
        private eventsService: EventsService,
        private store: Store<IStore>
    ) { }
/*
    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterEventDeliveriesAction>(ActionTypes.FilterEventDeliveries),
            switchMap(action => of(new GetEventDeliveriesAction(action.token, 1, action.filter)))
        ),
    );
*/
    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortEventDeliveriesAction>(ActionTypes.SortEventDeliveries),
            switchMap(action => of(new GetEventDeliveriesAction(action.token, 1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedEventDeliveriesAction>(ActionTypes.PageChangedEventDeliveries),
            switchMap(action => of(new GetEventDeliveriesAction(action.token, action.page)))
        ),
    );

    getEventDeliveries = createEffect(() =>
        this.actions.pipe(
            ofType<GetEventDeliveriesAction>(ActionTypes.GetEventDeliveries),
            withLatestFrom(this.store.select(getEventDeliveriesState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(action.token, sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.eventsService.eventDeliveriesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetEventDeliveriesSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) =>
                        of(new GetEventDeliveriesFailAction(error)))
                );
            })
        ),
    );

    private getParams(token: string, sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            token,
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
//            ...{ filter params },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
