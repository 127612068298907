import * as E from "linq";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from "@ngrx/effects";
import { map, filter, mergeMap, debounceTime, switchMap } from "rxjs/operators";
import { fromEvent, merge, of } from "rxjs";
import { AuthService } from "shared-lib";

@Injectable()
export class AppEffects {
    constructor(
        private actions: Actions,
        private authService: AuthService
    ) { }

    private inactivityInterval = 15 * 60 * 1000;

    inactivity$ = createEffect(() =>
        this.actions.pipe(
            ofType(ROOT_EFFECTS_INIT),
            mergeMap(() => {

                const events = [
                    "mousemove",
                    "keydown",
                    "DOMMouseScroll",
                    "mousewheel",
                    "mousedown",
                    "touchstart",
                ];

                const events$ = E.from(events)
                    .select(e => fromEvent(document, e))
                    .toArray();

                const kickstartInactivityWatcher = of(null);

                const allEvents$ = merge(
                    kickstartInactivityWatcher,
                    ...events$
                );

                return allEvents$.pipe(
                    debounceTime(this.inactivityInterval),
                    switchMap(() => this.authService.validateToken()),
                    filter(i => i),
                    map(() => this.authService.logout()),
                );
            }),
        )
    , { dispatch: false });

    // Handles incoming signals from another open browser tab (which this user
    // was also logged in to), to also log the user out from this tab.
    logoutFromOtherTabs$ = createEffect(() =>
        this.actions.pipe(
            ofType(ROOT_EFFECTS_INIT),
            mergeMap(() =>
                fromEvent<StorageEvent>(window, "storage").pipe(
                    filter(event => event.key === "DO_LOGOUT"),
                    map(() => this.authService.logout())
                )
            )
        )
    , { dispatch: false });

}
