<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    [moreFields]="moreFields"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="types"
                        isFilter
                        multiple
                        label="Type"
                        [options]="typeList"
                        [showReset]="filtersBase.resetFieldVisible('types')"
                        (handleReset)="filtersBase.resetField('types')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="statuses"
                        isFilter
                        multiple
                        label="Status"
                        [options]="statusList"
                        [showReset]="filtersBase.resetFieldVisible('statuses')"
                        (handleReset)="filtersBase.resetField('statuses')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <div class="global-form-line-combo-field">
                        <lib-vitu-form-field-input
                            [formGroup]="filterForm"
                            formFieldName="first6"
                            isFilter
                            label="First6"
                            autocomplete="off"
                            [mask]="{ mask: '000000' }"
                            [showReset]="filtersBase.resetFieldVisible('first6')"
                            (handleReset)="filtersBase.resetField('first6')">
                        </lib-vitu-form-field-input>
                        <lib-vitu-form-field-input
                            [formGroup]="filterForm"
                            formFieldName="last4"
                            isFilter
                            label="Last4"
                            autocomplete="off"
                            [mask]="{ mask: '0000' }"
                            [showReset]="filtersBase.resetFieldVisible('last4')"
                            (handleReset)="filtersBase.resetField('last4')">
                        </lib-vitu-form-field-input>
                    </div>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="amount"
                        isFilter
                        label="Amount"
                        autocomplete="off"
                        [mask]="amountMask"
                        [showReset]="filtersBase.resetFieldVisible('amount')"
                        (handleReset)="filtersBase.resetField('amount')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="payerName"
                        isFilter
                        label="Payer Name"
                        [maxlength]="71"
                        [showReset]="filtersBase.resetFieldVisible('payerName')"
                        (handleReset)="filtersBase.resetField('payerName')"
                        [errorOverrides]="[{'pattern': 'Only alphanumeric and space chars'}]">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

        <ng-template #moreFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="cardBrands"
                        isFilter
                        multiple
                        label="Card Brand"
                        [options]="cardList"
                        [showReset]="filtersBase.resetFieldVisible('cardBrands')"
                        (handleReset)="filtersBase.resetField('cardBrands')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="transactionId"
                        isFilter
                        label="Transaction ID"
                        [maxlength]="20"
                        [showReset]="filtersBase.resetFieldVisible('transactionId')"
                        (handleReset)="filtersBase.resetField('transactionId')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="authCode"
                        isFilter
                        label="Auth Code"
                        [maxlength]="20"
                        [showReset]="filtersBase.resetFieldVisible('authCode')"
                        (handleReset)="filtersBase.resetField('authCode')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="gateways"
                        isFilter
                        multiple
                        label="Gateway"
                        [options]="gatewayList"
                        [showReset]="filtersBase.resetFieldVisible('gateways')"
                        (handleReset)="filtersBase.resetField('gateways')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="tid"
                        isFilter
                        label="TID"
                        autocomplete="off"
                        [maxlength]="14"
                        [showReset]="filtersBase.resetFieldVisible('tid')"
                        (handleReset)="filtersBase.resetField('tid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateSettledInterval"
                        label="Date Settled"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

            </div>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="digitalWallets"
                        isFilter
                        multiple
                        label="Digital Wallet"
                        [options]="digitalWalletList"
                        [showReset]="filtersBase.resetFieldVisible('digitalWallets')"
                        (handleReset)="filtersBase.resetField('digitalWallets')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="isServiceFee"
                        isFilter
                        label="Service Fee"
                        [options]="serviceFeeList"
                        [showReset]="filtersBase.resetFieldVisible('isServiceFee')"
                        (handleReset)="filtersBase.resetField('isServiceFee')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="token"
                        label="Token"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('token')"
                        (handleReset)="filtersBase.resetField('token')">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
