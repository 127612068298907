import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getWebhooksState = (store: IStore) => store.webhooks;

export const getWebhooks = createSelector(
    getWebhooksState,
    webhookState => webhookState.record,
);

export const getWebhooksLoading = createSelector(
    getWebhooksState,
    webhookState => webhookState.loading,
);

export const getWebhooksError = createSelector(
    getWebhooksState,
    webhookState => webhookState.error,
);

export const getWebhooksSecret = createSelector(
    getWebhooksState,
    webhookState => webhookState.secret,
);

export const getWebhooksSecretLoading = createSelector(
    getWebhooksState,
    webhookState => webhookState.secretLoading,
);
