<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="MerchantEventOrderByEnum.Id">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'token'">
        <lib-text-table-column>
            {{ element.token }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="MerchantEventOrderByEnum.EventType">
        <lib-text-table-column>
            {{ element.eventType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="MerchantEventOrderByEnum.CreatedUtc">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





