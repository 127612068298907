import { IStore } from "@merchant_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Filter, Sorting } from "@merchant_app/storage/funding/funding.state";

import * as FundingActions from "@merchant_app/storage/funding/funding.actions";
import * as FundingSelectors from "@merchant_app/storage/funding/funding.selectors";

@Component({
    selector: "app-funding",
    templateUrl: "./funding.component.html",
    styleUrls: ["./funding.component.less"],
})
export class FundingComponent {

    funding$ = this.store.select(FundingSelectors.getFunding);
    fundingLoading$ = this.store.select(FundingSelectors.getFundingLoading);
    fundingError$ = this.store.select(FundingSelectors.getFundingError);

    pager$ = this.store.select(FundingSelectors.getFundingPager);
    sorting$ = this.store.select(FundingSelectors.getFundingSorting);
    filter$ = this.store.select(FundingSelectors.getFundingFilter);

    constructor(private store: Store<IStore>) {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new FundingActions.SortFundingAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new FundingActions.PageChangedFundingAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FundingActions.FilterFundingAction(filter));
    }

}
