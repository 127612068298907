/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdjustmentDto } from '../models/adjustment-dto';
import { DisputeDto } from '../models/dispute-dto';
import { FundingFee } from '../models/funding-fee';
import { FundingInstructionDetails } from '../models/funding-instruction-details';
import { FundingInstructionsOrderBy } from '../models/funding-instructions-order-by';
import { FundingStatus } from '../models/funding-status';
import { FundingTransactionOrderBy } from '../models/funding-transaction-order-by';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfFundingInstruction } from '../models/paged-data-dto-of-funding-instruction';
import { PagedDataDtoOfFundingTransaction } from '../models/paged-data-dto-of-funding-transaction';
import { ProcessorEnum } from '../models/processor-enum';


/**
 * Billing API.
 */
@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fundingInstructionsSearchInstructions
   */
  static readonly FundingInstructionsSearchInstructionsPath = '/v1/billing/funding-instructions';

  /**
   * Permissions: core.funding.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsSearchInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsSearchInstructions$Response(params?: {
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    OrderBy?: FundingInstructionsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingInstruction>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsSearchInstructionsPath, 'get');
    if (params) {
      rb.query('MID', params.MID, {});
      rb.query('DBA', params.DBA, {});
      rb.query('FundingStatuses', params.FundingStatuses, {"style":"form","explode":true});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('FundedStartDate', params.FundedStartDate, {});
      rb.query('FundedEndDate', params.FundedEndDate, {});
      rb.query('Processors', params.Processors, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingInstruction>;
      })
    );
  }

  /**
   * Permissions: core.funding.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsSearchInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsSearchInstructions(params?: {
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    OrderBy?: FundingInstructionsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingInstruction> {

    return this.fundingInstructionsSearchInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingInstruction>) => r.body as PagedDataDtoOfFundingInstruction)
    );
  }

  /**
   * Path part for operation fundingInstructionsGetInstructionDetails
   */
  static readonly FundingInstructionsGetInstructionDetailsPath = '/v1/billing/funding-instructions/{ref}';

  /**
   * Permissions: core.funding.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsGetInstructionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetInstructionDetails$Response(params: {
    ref: string;
  }): Observable<StrictHttpResponse<FundingInstructionDetails>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsGetInstructionDetailsPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FundingInstructionDetails>;
      })
    );
  }

  /**
   * Permissions: core.funding.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsGetInstructionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetInstructionDetails(params: {
    ref: string;
  }): Observable<FundingInstructionDetails> {

    return this.fundingInstructionsGetInstructionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<FundingInstructionDetails>) => r.body as FundingInstructionDetails)
    );
  }

  /**
   * Path part for operation fundingInstructionsSearchFundingTransactions
   */
  static readonly FundingInstructionsSearchFundingTransactionsPath = '/v1/billing/funding-instructions/{ref}/transactions';

  /**
   * Permissions: core.funding.get-transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsSearchFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsSearchFundingTransactions$Response(params: {
    ref: string;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingTransaction>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsSearchFundingTransactionsPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
      rb.query('FeesOnly', params.FeesOnly, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingTransaction>;
      })
    );
  }

  /**
   * Permissions: core.funding.get-transactions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsSearchFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsSearchFundingTransactions(params: {
    ref: string;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingTransaction> {

    return this.fundingInstructionsSearchFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingTransaction>) => r.body as PagedDataDtoOfFundingTransaction)
    );
  }

  /**
   * Path part for operation fundingInstructionsExportFundingTransactions
   */
  static readonly FundingInstructionsExportFundingTransactionsPath = '/v1/billing/funding-instructions/{ref}/transactions/export';

  /**
   * Permissions: core.funding.get-transactions-report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsExportFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsExportFundingTransactions$Response(params: {
    ref: string;
    feesOnly?: boolean;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsExportFundingTransactionsPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
      rb.query('feesOnly', params.feesOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Permissions: core.funding.get-transactions-report
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsExportFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsExportFundingTransactions(params: {
    ref: string;
    feesOnly?: boolean;
  }): Observable<Blob> {

    return this.fundingInstructionsExportFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation fundingInstructionsGetMidFees
   */
  static readonly FundingInstructionsGetMidFeesPath = '/v1/billing/funding-instructions/{ref}/mid-fees';

  /**
   * Permissions: core.funding.get-fees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsGetMidFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetMidFees$Response(params: {
    ref: string;
  }): Observable<StrictHttpResponse<Array<FundingFee>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsGetMidFeesPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FundingFee>>;
      })
    );
  }

  /**
   * Permissions: core.funding.get-fees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsGetMidFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetMidFees(params: {
    ref: string;
  }): Observable<Array<FundingFee>> {

    return this.fundingInstructionsGetMidFees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FundingFee>>) => r.body as Array<FundingFee>)
    );
  }

  /**
   * Path part for operation fundingInstructionsGetAdjustments
   */
  static readonly FundingInstructionsGetAdjustmentsPath = '/v1/billing/funding-instructions/{ref}/adjustments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsGetAdjustments()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetAdjustments$Response(params: {
    ref: string;
  }): Observable<StrictHttpResponse<Array<AdjustmentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsGetAdjustmentsPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdjustmentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsGetAdjustments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetAdjustments(params: {
    ref: string;
  }): Observable<Array<AdjustmentDto>> {

    return this.fundingInstructionsGetAdjustments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentDto>>) => r.body as Array<AdjustmentDto>)
    );
  }

  /**
   * Path part for operation fundingInstructionsGetTransactionFees
   */
  static readonly FundingInstructionsGetTransactionFeesPath = '/v1/billing/funding-instructions/{ref}/transaction-fees';

  /**
   * Permissions: core.funding.get-fees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsGetTransactionFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetTransactionFees$Response(params: {
    ref: string;
  }): Observable<StrictHttpResponse<Array<FundingFee>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsGetTransactionFeesPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FundingFee>>;
      })
    );
  }

  /**
   * Permissions: core.funding.get-fees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsGetTransactionFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetTransactionFees(params: {
    ref: string;
  }): Observable<Array<FundingFee>> {

    return this.fundingInstructionsGetTransactionFees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FundingFee>>) => r.body as Array<FundingFee>)
    );
  }

  /**
   * Path part for operation fundingInstructionsGetDisputes
   */
  static readonly FundingInstructionsGetDisputesPath = '/v1/billing/funding-instructions/{ref}/disputes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingInstructionsGetDisputes()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetDisputes$Response(params: {
    ref: string;
  }): Observable<StrictHttpResponse<Array<DisputeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingInstructionsGetDisputesPath, 'get');
    if (params) {
      rb.path('ref', params.ref, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DisputeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingInstructionsGetDisputes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingInstructionsGetDisputes(params: {
    ref: string;
  }): Observable<Array<DisputeDto>> {

    return this.fundingInstructionsGetDisputes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DisputeDto>>) => r.body as Array<DisputeDto>)
    );
  }

}
