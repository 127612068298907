<div class="outer-container" [ngClass]="{'field-empty': label.length === 0}">
    <div class="label">
        <lib-text-common [infoTip]="infoTip">
            {{ label }}
        </lib-text-common>
    </div>
    <div class="value">
        <lib-text-common>
            <ng-content></ng-content>
        </lib-text-common>
    </div>
</div>
