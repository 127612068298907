import { Action } from "@ngrx/store";
import { UpdateClientDto } from "@merchant_api/models/update-client-dto";
import { CreateClientDto } from "@merchant_api/models/create-client-dto";
import { RoleDto } from "@merchant_api/models/role-dto";
import { ClientDetailsDto } from "@merchant_api/models/client-details-dto";
import { ClientSecretLookupsDto } from "@merchant_api/models/client-secret-lookups-dto";

export enum ActionTypes {
    GetClient = "[Client] GetClient",
    GetNewClient = "[Client] GetNewClient",
    GetClientSucceed = "[Client] GetClientSucceed",
    GetClientFail = "[Client] GetClientFail",
    UpdateClient = "[Client] UpdateClient",
    DeleteClient = "[Client] DeleteClient",
    CreateClient = "[Client] CreateClient",
    AddClientSecret = "[Client] AddClientSecret",
    DeleteClientSecret = "[Client] DeleteClientSecret"
}

export class GetClientAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetClient;
}

export class GetNewClientAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewClient;
}

export class GetClientSucceedAction implements Action {

    constructor(
        public client: ClientDetailsDto,
        public roles: Array<RoleDto>,
        public lookups: ClientSecretLookupsDto
    ) {}

    readonly type = ActionTypes.GetClientSucceed;
}

export class GetClientFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetClientFail;
}

export class UpdateClientAction implements Action {

    constructor(public id: number, public client: UpdateClientDto) {}

    readonly type = ActionTypes.UpdateClient;
}

export class DeleteClientAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteClient;
}

export class CreateClientAction implements Action {

    constructor(public client: CreateClientDto) {}

    readonly type = ActionTypes.CreateClient;
}

export class AddClientSecretAction implements Action {

    constructor() {}

    readonly type = ActionTypes.AddClientSecret;
}

export class DeleteClientSecretAction implements Action {

    constructor(public secretId: number) {}

    readonly type = ActionTypes.DeleteClientSecret;
}
