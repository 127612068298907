import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { UsersService } from "@merchant_api/services/users.service";
import { MatDialog } from "@angular/material/dialog";
import { RolesService } from "@merchant_api/services/roles.service";
import { ActionTypes, GetUsersAction, GetUsersFailAction, GetUsersSucceedAction } from "./users.actions";
import { throwError, of } from "rxjs";
import { PagerLargeSinglePageSize } from "../common";

@Injectable()
export class UsersEffects {

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
        private dialog: MatDialog,
        private rolesService: RolesService,
    ) {}

    initUsersPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith("/dashboard/users")),
            map(() => new GetUsersAction())
        )
    );

    getUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUsersAction>(ActionTypes.GetUsers),
            switchMap(() =>
                this.usersService.usersSearch({ "Pager.PageSize": PagerLargeSinglePageSize }).pipe(
                    switchMap((users) => of(new GetUsersSucceedAction(users))),
                    catchError((error) => of(new GetUsersFailAction(error)))
                )
            )
        ),
    );

}
