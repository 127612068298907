import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef } from "shared-lib";
import { RoleDto } from "@merchant_api/models/role-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

@Component({
    selector: "app-role-log",
    templateUrl: "./role-log.component.html",
    styleUrls: [ "./role-log.component.less" ]
})
export class RoleLogComponent {

    @Input() roles: RoleDto[];
    @Input() loading = false;
    @Input() error: Error;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    roleColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "id", title: "ID", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true},
        { id: "name", title: "Name", flexWidthBasisInPixels: 400, flexWidthGrow: 0, canSort: true},
        { id: "custom", title: "Custom", flexWidthBasisInPixels: 60, flexWidthGrow: 0, canSort: true},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    onClickCreateRole() {
        if (this.hasCreateRolePermission) {
            this.router.navigate([`/dashboard/roles/create`]);
        }
    }

    onRowSelected(roleDto: RoleDto) {
        if (this.hasGetRolePermission) {
            this.router.navigate([`/dashboard/roles/${roleDto.id}`]);
        }
    }

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {}

    get hasCreateRolePermission(): boolean {
        return this.currPerms.has(["core.roles.create"]);
    }

    get hasGetRolePermission(): boolean {
        return this.currPerms.has(["core.roles.get"]);
    }

}
