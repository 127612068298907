import { ReportType } from "@merchant_app/shared/download-report-modal/report-type";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    DownloadReport = "[REPORT] DownloadReport",
    DownloadReportSucceed = "[REPORT] DownloadReportSucceed"
}

export class DownloadReportAction implements Action {

    constructor(public reportType: ReportType) {}

    readonly type = ActionTypes.DownloadReport;
}

export class DownloadReportSucceedAction implements Action {

    constructor() {}

    readonly type = ActionTypes.DownloadReportSucceed;
}
