import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import {
    ActionTypes,
    FilterTransactionsAction,
    SortTransactionsAction,
    PageChangedTransactionsAction,
    ExportTransactionsToCsvAction,
    ExportTransactionsToCsvSucceedAction,
    ExportTransactionsToCsvFailAction,
    GetTransactionsAction,
    GetTransactionsSucceedAction,
    GetTransactionsFailAction,
} from "./transactions.actions";
import { filter as filterOperator, switchMap, withLatestFrom, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { PaymentsService } from "@merchant_api/services/payments.service";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import { LocalTimePoint } from "shared-lib";
import { Filter, Sorting } from "./transactions.state";
import { getTransactionsState } from "./transactions.selectors";

@Injectable()
export class TransactionsEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService,
        private store: Store<IStore>,
    ) { }

    initTransactionsPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) => new RegExp(/dashboard\/transactions$/).test(action.payload.routerState.url)),
            switchMap(() =>
                [ new GetTransactionsAction(1) ]
            )
        )
    );

    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterTransactionsAction>(ActionTypes.FilterTransactions),
            switchMap(action => of(new GetTransactionsAction(1, action.filter)))
        ),
    );

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortTransactionsAction>(ActionTypes.SortTransactions),
            switchMap(action => of(new GetTransactionsAction(1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedTransactionsAction>(ActionTypes.PageChangedTransactions),
            switchMap(action => of(new GetTransactionsAction(action.page)))
        ),
    );

    getTransactions = createEffect(() =>
        this.actions.pipe(
            ofType<GetTransactionsAction>(ActionTypes.GetTransactions),
            withLatestFrom(this.store.select(getTransactionsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.paymentsService.transactionsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetTransactionsSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) => of(new GetTransactionsFailAction(error)))
                );
            })
        ),
    );

    exportToCsv = createEffect(() =>
        this.actions.pipe(
            ofType<ExportTransactionsToCsvAction>(ActionTypes.ExportTransactionsToCsv),
            withLatestFrom(this.store.select(getTransactionsState)),
            switchMap(([_, state]) => {

                const params = this.getParams(state.sorting, state.filter, state.pager.pageSize, state.pager.page);

                return this.paymentsService.transactionsExport(params).pipe(
                    tap(response => window.location.href = response.url),
                    switchMap((response) =>
                        of(new ExportTransactionsToCsvSucceedAction(response))),
                    catchError((error) =>
                        of(new ExportTransactionsToCsvFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        const retVal: any = {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                TransactionId: filter.transactionId,
                Amount: filter.amount,
                AuthCode: filter.authCode,
                First6: filter.first6,
                Last4: filter.last4,
                PayerName: filter.payerName,
                CardBrands: filter.cardBrands,
                Statuses: filter.statuses,
                Gateways: filter.gateways,
                TID: filter.tid,
                MID: filter.mid,
                DigitalWallets: filter.digitalWallets,
                Types: filter.types,
                Token: filter.token
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            SettlementStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.from),
            SettlementEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };

        if (typeof filter.isServiceFee === "string") {
            retVal.IsServiceFee = filter.isServiceFee === "Yes";
        }

        return retVal;
    }

}
