import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store } from "@ngrx/store";
import { getUsers, getUsersLoading, getUsersError } from "@merchant_app/storage/users/users.selectors";
import { getCurrentUserPermissions } from "@merchant_app/storage/current-user/current-user.selectors";

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: [ "./users.component.less" ]
})
export class UsersComponent {

    users$ = this.store.select(getUsers);

    loading$ = this.store.select(getUsersLoading);

    error$ = this.store.select(getUsersError);

    constructor(private store: Store<IStore>) {}

}
