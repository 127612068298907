<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'feeCategory'">
        <lib-text-table-column>
            {{ element.feeCategory }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feeType'">
        <lib-text-table-column>
            {{ element.feeType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feeValue'">
        <lib-text-table-column>
            {{ element.feeValue | vituCurrencyUsd }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.feeValue ?? 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'perc'">
        <lib-text-table-column>
            {{ feePerc | vituPercentage }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="feePerc"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





