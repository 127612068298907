import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@merchant_app/storage/store";

import * as CurrentUserSelectors from "@merchant_app/storage/current-user/current-user.selectors";

import * as FundingItemActions from "@merchant_app/storage/funding-item/funding-item.actions";
import * as FundingItemSelectors from "@merchant_app/storage/funding-item/funding-item.selectors";

import { GoBackAction } from "@merchant_app/storage/router/router.actions";
import { FundingInstructionDetails } from "@merchant_api/models/funding-instruction-details";
import {
    ExportTransactionsToCsvAction,
    FilterFundingItemTransactionsAction,
    PageChangedFundingItemTransactionsAction,
    SortFundingItemTransactionsAction
} from "@merchant_app/storage/funding-item/funding-item.actions";
import { TransactionsFilter, TransactionsSorting } from "@merchant_app/storage/funding-item/funding-item.state";

@Component({
    selector: "app-funding-item",
    templateUrl: "./funding-item.component.html",
    styleUrls: ["./funding-item.component.less"]
})
export class FundingItemComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);
    fundingItem$ = this.store.select(FundingItemSelectors.getFundingItem);
    fundingItemLoading$ = this.store.select(FundingItemSelectors.getFundingItemLoading);
    fundingItemTransactions$ = this.store.select(FundingItemSelectors.getFundingItemTransactions);
    fundingItemTransactionsLoading$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsLoading);
    fundingItemTransactionsError$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsError);
    fundingItemTransactionsPager$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsPager);
    fundingItemTransactionsSorting$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsSorting);
    fundingItemTransactionsFilter$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsFilter);
    fundingItemExportLoading$ = this.store.select(FundingItemSelectors.getFundingItemExportLoading);
    fundingItemMidFees$ = this.store.select(FundingItemSelectors.getFundingItemMidFees);
    fundingItemMidFeesLoading$ = this.store.select(FundingItemSelectors.getFundingItemMidFeesLoading);
    fundingItemMidFeesError$ = this.store.select(FundingItemSelectors.getFundingItemMidFeesError);
    fundingItemAdjustments$ = this.store.select(FundingItemSelectors.getFundingItemAdjustments);
    fundingItemAdjustmentsLoading$ = this.store.select(FundingItemSelectors.getFundingItemAdjustmentsLoading);
    fundingItemAdjustmentsError$ = this.store.select(FundingItemSelectors.getFundingItemAdjustmentsError);

    ngOnInit() {

        this.store.dispatch(new FundingItemActions.ResetFundingItemAction());
        this.store.dispatch(new FundingItemActions.GetFundingItemAction(this.route.snapshot.params.id));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onFundingItemTransactionsPageChanged(payload: {ref: string; page: number}) {

        this.store.dispatch(new PageChangedFundingItemTransactionsAction(payload.ref, payload.page));
    }

    onFundingItemTransactionsSortingChanged(payload: {ref: string; sorting: TransactionsSorting}) {

        this.store.dispatch(new SortFundingItemTransactionsAction(payload.ref, payload.sorting));
    }

    onFundingItemTransactionsFilterChanged(payload: {ref: string; filter: TransactionsFilter}) {

        this.store.dispatch(new FilterFundingItemTransactionsAction(payload.ref, payload.filter));
    }

    onExportTransactionsToCsv(ref: string) {

        this.store.dispatch(new ExportTransactionsToCsvAction(ref));
    }

}
