/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CloverCancel } from '../models/clover-cancel';
import { CloverPayment } from '../models/clover-payment';
import { SurchargeResult } from '../models/surcharge-result';
import { TransactionDeliveryResponse } from '../models/transaction-delivery-response';


/**
 * Clover Devices integration API
 */
@Injectable({
  providedIn: 'root',
})
export class CloverPosService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation cloverSalesBeginSale
   */
  static readonly CloverSalesBeginSalePath = '/v1/payments/clover-sales';

  /**
   * Start the payment process on a clover device.
   *
   * Use this endpoint to start the payment process on your Clover device.\
   * \
   * Permissions: core.clover.sale
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloverSalesBeginSale()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloverSalesBeginSale$Response(params: {
    body: CloverPayment
  }): Observable<StrictHttpResponse<TransactionDeliveryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloverPosService.CloverSalesBeginSalePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionDeliveryResponse>;
      })
    );
  }

  /**
   * Start the payment process on a clover device.
   *
   * Use this endpoint to start the payment process on your Clover device.\
   * \
   * Permissions: core.clover.sale
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloverSalesBeginSale$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloverSalesBeginSale(params: {
    body: CloverPayment
  }): Observable<TransactionDeliveryResponse> {

    return this.cloverSalesBeginSale$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionDeliveryResponse>) => r.body as TransactionDeliveryResponse)
    );
  }

  /**
   * Path part for operation cloverSalesCancel
   */
  static readonly CloverSalesCancelPath = '/v1/payments/clover-sales/cancel';

  /**
   * Starts the cancellation process on a clover device.
   *
   * Use this endpoint to cancel the payment process on your Clover device.\
   * \
   * Permissions: core.clover.cancel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloverSalesCancel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloverSalesCancel$Response(params: {
    body: CloverCancel
  }): Observable<StrictHttpResponse<TransactionDeliveryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloverPosService.CloverSalesCancelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionDeliveryResponse>;
      })
    );
  }

  /**
   * Starts the cancellation process on a clover device.
   *
   * Use this endpoint to cancel the payment process on your Clover device.\
   * \
   * Permissions: core.clover.cancel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloverSalesCancel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloverSalesCancel(params: {
    body: CloverCancel
  }): Observable<TransactionDeliveryResponse> {

    return this.cloverSalesCancel$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionDeliveryResponse>) => r.body as TransactionDeliveryResponse)
    );
  }

  /**
   * Path part for operation surchargeFee
   */
  static readonly SurchargeFeePath = '/v1/payments/surcharge/fee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `surchargeFee()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  surchargeFee$Response(params?: {
    First6?: string;
    Amount?: number;
    TID?: string;
  }): Observable<StrictHttpResponse<SurchargeResult>> {

    const rb = new RequestBuilder(this.rootUrl, CloverPosService.SurchargeFeePath, 'get');
    if (params) {
      rb.query('First6', params.First6, {});
      rb.query('Amount', params.Amount, {});
      rb.query('TID', params.TID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurchargeResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `surchargeFee$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  surchargeFee(params?: {
    First6?: string;
    Amount?: number;
    TID?: string;
  }): Observable<SurchargeResult> {

    return this.surchargeFee$Response(params).pipe(
      map((r: StrictHttpResponse<SurchargeResult>) => r.body as SurchargeResult)
    );
  }

  /**
   * Path part for operation surchargeVerifyTidSurcharge
   */
  static readonly SurchargeVerifyTidSurchargePath = '/v1/payments/surcharge/verify-tid-surcharge';

  /**
   * Allows to retrieve expected surcharge data for a given TID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `surchargeVerifyTidSurcharge()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  surchargeVerifyTidSurcharge$Response(params?: {
    Amount?: number;
    TID?: string;
  }): Observable<StrictHttpResponse<SurchargeResult>> {

    const rb = new RequestBuilder(this.rootUrl, CloverPosService.SurchargeVerifyTidSurchargePath, 'get');
    if (params) {
      rb.query('Amount', params.Amount, {});
      rb.query('TID', params.TID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurchargeResult>;
      })
    );
  }

  /**
   * Allows to retrieve expected surcharge data for a given TID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `surchargeVerifyTidSurcharge$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  surchargeVerifyTidSurcharge(params?: {
    Amount?: number;
    TID?: string;
  }): Observable<SurchargeResult> {

    return this.surchargeVerifyTidSurcharge$Response(params).pipe(
      map((r: StrictHttpResponse<SurchargeResult>) => r.body as SurchargeResult)
    );
  }

}
