/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Month } from '../models/month';
import { StatementReportItem } from '../models/statement-report-item';


/**
 * Reports API.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reportTransactionReport
   */
  static readonly ReportTransactionReportPath = '/v1/billing/report/transaction-report';

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportTransactionReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTransactionReport$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportTransactionReportPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('MerchantProcessorIds', params.MerchantProcessorIds, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportTransactionReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTransactionReport(params?: {
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<Blob> {

    return this.reportTransactionReport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportFundingReport
   */
  static readonly ReportFundingReportPath = '/v1/billing/report/funding-report';

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportFundingReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReport$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportFundingReportPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('MerchantProcessorIds', params.MerchantProcessorIds, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportFundingReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReport(params?: {
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<Blob> {

    return this.reportFundingReport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportStatementReports
   */
  static readonly ReportStatementReportsPath = '/v1/billing/report/statement-reports/{id}';

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportStatementReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReports$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportStatementReportsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportStatementReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReports(params: {
    id: string;
  }): Observable<Blob> {

    return this.reportStatementReports$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportStatementReportsAll
   */
  static readonly ReportStatementReportsAllPath = '/v1/billing/report/statement-reports/{year}/{month}';

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportStatementReportsAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReportsAll$Response(params: {
    year: number;
    month: Month;
  }): Observable<StrictHttpResponse<Array<StatementReportItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportStatementReportsAllPath, 'get');
    if (params) {
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatementReportItem>>;
      })
    );
  }

  /**
   * Permissions: core.reports.billing
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportStatementReportsAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReportsAll(params: {
    year: number;
    month: Month;
  }): Observable<Array<StatementReportItem>> {

    return this.reportStatementReportsAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatementReportItem>>) => r.body as Array<StatementReportItem>)
    );
  }

}
