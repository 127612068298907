import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FiltersBaseComponent } from "./filters-base.component";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActionButtonModule } from "../action-button";
import { MultiStageExpansionPanelModule } from "../multi-stage-expansion-panel";

@NgModule({
    declarations: [
        FiltersBaseComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        ActionButtonModule,
        MultiStageExpansionPanelModule
    ],
    exports: [
        FiltersBaseComponent
    ]
})
export class FiltersBaseModule { }
