import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { UserDto } from "@merchant_api/models/user-dto";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";
import { ColumnDef } from "shared-lib";

@Component({
    selector: "app-user-log",
    templateUrl: "./user-log.component.html",
    styleUrls: [ "./user-log.component.less" ]
})
export class UserLogComponent {

    @Input() users: UserDto[];
    @Input() loading = false;
    @Input() error: Error;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    userColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "firstName", title: "First Name", flexWidthBasisInPixels: 200, flexWidthGrow: 0, canSort: true},
        { id: "lastName", title: "Last Name", flexWidthBasisInPixels: 200, flexWidthGrow: 0, canSort: true},
        { id: "email", title: "Email", flexWidthBasisInPixels: 430, flexWidthGrow: 0, canSort: true},
        { id: "lockedStatus", title: "Locked Status", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    onClickCreateUser() {
        if (this.hasCreatePermission) {
            this.router.navigate([`/dashboard/users/create`]);
        }
    }

    onRowSelected(id: string) {
        if (this.hasGetPermission) {
            this.router.navigate([`/dashboard/users/${id}`]);
        }
    }

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {}

    get hasCreatePermission(): boolean {
        return this.currPerms.has(["core.users.create"]);
    }

    get hasGetPermission(): boolean {
        return this.currPerms.has(["core.users.get", "core.roles.search"]);
    }

}
