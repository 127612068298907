import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { getCurrentUserPermissions, getCurrentUserState } from "@merchant_app/storage/current-user/current-user.selectors";
import { IStore } from "@merchant_app/storage/store";
import { AuthService, SidebarItem } from "shared-lib";
import { getMerchantState } from "@merchant_app/storage/merchant/merchant.selectors";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.less"],
})
export class DashboardComponent {

    sidebarOpen = (window.innerWidth > 1440);

    sidebarItems: SidebarItem[] = [
        {
            name: "Payments",
            primaryRouteToActivate: "/dashboard/payments",
            permissions: ["core.payments.search"],
            faIcon: "far fa-money-check-edit-alt"
        },
        {
            name: "Transactions",
            primaryRouteToActivate: "/dashboard/transactions",
            permissions: ["core.transactions.search"],
            faIcon: "far fa-file-invoice-dollar"
        },
        {
            name: "Disputes",
            primaryRouteToActivate: "/dashboard/disputes",
            faIcon: "far fa-boxing-glove",
            permissions: ["core.disputes.get"]
        },
        {
            name: "Users",
            primaryRouteToActivate: "/dashboard/users",
            permissions: ["core.users.search"],
            faIcon: "far fa-user-friends"
        },
        {
            name: "Roles",
            primaryRouteToActivate: "/dashboard/roles",
            permissions: ["core.roles.search"],
            faIcon: "far fa-user-tag"
        },
        {
            name: "Reports",
            primaryRouteToActivate: "/dashboard/reports",
            permissions: ["core.reports.billing"],
            faIcon: "far fa-file-chart-pie"
        },
        {
            name: "Configurations",
            primaryRouteToActivate: "/dashboard/configurations",
            permissions: ["core.merchants.get-tids"],
            faIcon: "far fa-cogs"
        },
        {
            name: "Funding",
            primaryRouteToActivate: "/dashboard/funding",
            permissions: ["core.funding.search"],
            faIcon: "far fa-coins"
        },
        {
            name: "Developer Tools",
            faIcon: "far fa-tools",
            secondaryRouteToCapture: "/dashboard/developer",
            subItems: [
                {
                    name: "Clients",
                    primaryRouteToActivate: "/dashboard/developer/clients",
                    permissions: ["core.clients.search"],
                    faIcon: "far fa-server"
                },
                {
                    name: "Webhooks",
                    faIcon: "far fa-arrow-from-left",
                    secondaryRouteToCapture: "/dashboard/developer/webhooks",
                    subItems: [
                        {
                            name: "Configure",
                            primaryRouteToActivate: "/dashboard/developer/webhooks/configure",
                            permissions: ["core.webhooks.search"],
                            faIcon: "far fa-cog"
                        },
                        {
                            name: "Event History",
                            primaryRouteToActivate: "/dashboard/developer/webhooks/event-history",
                            permissions: ["core.webhooks.lookups"],
                            faIcon: "fas fa-history"
                        }
                    ]
                }
            ]
        }
    ];

    merchantName$ = this.store.pipe(
        select(getMerchantState),
        map(i => i.merchant?.name)
    );

    currentUserName$ = this.store.pipe(
        select(getCurrentUserState),
        map(i => i.record?.name)
    );

    currentEmail$ = this.store.pipe(
        select(getCurrentUserState),
        map(i => i.record?.email)
    );

    currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    constructor(private store: Store<IStore>, private authService: AuthService, public router: Router) {}

    onLogout() {
        this.authService.logout();
    }

}
