export class Pager {
    pageSize = 20;
    page = 1;
    pageCount: number = null;
    morePagesMode = true;

    constructor(pageSize?: number) {
        if (pageSize) {
            this.pageSize = pageSize;
        }
    }
}
