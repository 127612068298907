import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./shared/router-guards/auth.guard";
import { PermissionsGuard } from "./shared/router-guards/permissions.guard";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { TransactionsComponent } from "./pages/transactions/transactions.component";
import { TransactionComponent } from "./pages/transaction/transaction.component";
import { UserComponent } from "./pages/user/user.component";
import { UsersComponent } from "./pages/users/users.component";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { PageUnavailableComponent } from "./pages/page-unavailable/page-unavailable.component";
import { RoleComponent } from "./pages/role/role.component";
import { RolesComponent } from "./pages/roles/roles.component";
import { WebhooksComponent } from "./pages/webhooks/webhooks.component";
import { WebhookComponent } from "./pages/webhook/webhook.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { ClientsComponent } from "./pages/clients/clients.component";
import { ClientComponent } from "./pages/client/client.component";
import { ConfigurationsComponent } from "./pages/configurations/configurations.component";
import { FundingComponent } from "./pages/funding/funding.component";
import { FundingItemComponent } from "./pages/funding-item/funding-item.component";
import { HelpComponent } from "./pages/help/help.component";
import { ErrorUiService } from "shared-lib";
import { DynamicDefaultRouteGuard } from "./shared/router-guards/default-dynamic-route.guard";
import { EventComponent } from "./pages/event/event.component";
import { EventHistoryComponent } from "./pages/event-history/event-history.component";
import { DisputesComponent } from "./pages/disputes/disputes.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { PaymentsComponent } from "./pages/payments/payments.component";

class DummyComponent {}

const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermissionsGuard],
    children: [
      { path: "payments", component: PaymentsComponent,
            data: { requiredPermissions: ["core.payments.search"] } },
      { path: "transactions", component: TransactionsComponent,
            data: { requiredPermissions: ["core.transactions.search"] } },
      { path: "transactions/:id/history", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "transactions/:id/chargebacks", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "transactions/:id/retrievals", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "transactions/:id/fees", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "transactions/:id/additional-information", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "transactions/:id", component: TransactionComponent,
            data: { requiredPermissions: ["core.payments.get", "core.transactions.get"] } },
      { path: "developer/webhooks/event-history/:id", component: EventComponent,
            data: { requiredPermissions: [] }  },
      { path: "developer/webhooks/event-history", component: EventHistoryComponent,
            data: { requiredPermissions: ["core.webhooks.lookups"] }  },
      { path: "developer/webhooks/configure/create", component: WebhookComponent,
            data: { requiredPermissions: ["core.webhooks.create"] }  },
      { path: "developer/webhooks/configure/:id", component: WebhookComponent,
            data: { requiredPermissions: ["core.webhooks.get"] }  },
      { path: "developer/webhooks/configure", component: WebhooksComponent,
            data: { requiredPermissions: ["core.webhooks.search"] } },
      { path: "users/create", component: UserComponent, data: { requiredPermissions: ["core.users.create"] }  },
      { path: "users/:id", component: UserComponent, data: { requiredPermissions: ["core.users.get"] }  },
      { path: "users", component: UsersComponent, data: { requiredPermissions: ["core.users.search"] } },
      { path: "roles/create", component: RoleComponent, data: { requiredPermissions: ["core.roles.create"] }  },
      { path: "roles/:id", component: RoleComponent, data: { requiredPermissions: ["core.roles.get"] }  },
      { path: "roles", component: RolesComponent, data: { requiredPermissions: ["core.roles.search"] } },
      { path: "reports", component: ReportsComponent, data: { requiredPermissions: ["core.reports.billing"] } },
      { path: "developer/clients/create", component: ClientComponent, data: { requiredPermissions: ["core.clients.create"] } },
      { path: "developer/clients/:id", component: ClientComponent, data: { requiredPermissions: ["core.clients.get"] } },
      { path: "developer/clients", component: ClientsComponent, data: { requiredPermissions: ["core.clients.search"] } },
      { path: "configurations", component: ConfigurationsComponent, data: { requiredPermissions: ["core.merchants.get-tids"] } },
      { path: "funding", component: FundingComponent, data: { requiredPermissions: ["core.funding.search"] } },
      { path: "funding/:id", component: FundingItemComponent, data: { requiredPermissions: ["core.funding.get"] }  },
      { path: "help", component: HelpComponent },
      { path: "disputes/:id", component: DisputeComponent, data: { requiredPermissions: ["core.disputes.get"] }  },
      { path: "disputes", component: DisputesComponent, data: { requiredPermissions: ["core.disputes.get"] } },
      { path: "**", component: DummyComponent, canActivate: [DynamicDefaultRouteGuard], pathMatch: "full" }
    ]
  },
  { path: "forbidden", component: ForbiddenComponent },
  { path: "page-unavailable", component: PageUnavailableComponent },
  { path: "**", redirectTo: "dashboard", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionsGuard]
})
export class AppRoutingModule {

    constructor(errorUi: ErrorUiService) {
        errorUi.reloadConfig = {
            origin: window.location.origin,
            help: "/dashboard/help"
        };
    }

}
