import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BinaryStatusIconComponent } from "./binary-status-icon.component";

@NgModule({
    declarations: [
        BinaryStatusIconComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BinaryStatusIconComponent
    ]
})
export class BinaryStatusIconModule { }
