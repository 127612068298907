<div class="outer-container" [ngClass]="{'padding-transition': !isSubmenuItem, 'outer-container-open': sidebarOpen}">
    <a class="menu-link" [ngClass]="{'active': isItemActive, 'sub-menu-active': subMenuActive, 'sub-menu-link': !item.faIcon, 'no-primary-route': noPrimaryRoute}"
        matRipple [matRippleDisabled]="sidebarOpen" (click)="onClickItem()">
        <div class="menu-link-inner">
            <div class="menu-item-icon" [ngClass]="{'sidebar-open': sidebarOpen }">

                <lib-vitu-tooltip
                    [text]="item.name"
                    [isDisabled]="sidebarOpen || (item.subItems?.length > 0)"
                    position="right-arrow">

                    <ng-container *ngIf="item.faIcon; else noProvidedIcon">
                        <span class="fa-stack fa-xs">
                            <i [class]="item.faIcon + ' fa-stack-2x main-icon'" [style]="item.faIconStyleCustom"></i>
                        </span>                     
                    </ng-container>
                    <ng-template #noProvidedIcon>
                        <div class="hide-contents" [ngClass]="{'opacity-transition': !isSubmenuItem, 'show-contents': !sidebarOpen }">

                            <div class="sub-item-icon-container">
                                <i class="fal fa-circle sub-item-icon-outer"></i>
                                <span class="sub-item-icon-inner">{{ item.name.substring(0, 1) }}</span>
                            </div>

                        </div>
                    </ng-template>

                </lib-vitu-tooltip>

            </div>

            <div class="menu-item-text">
                <lib-text-common [tooltipDisabled]="!sidebarOpen">
                    {{ item.name }}
                </lib-text-common>
            </div>

            <div *ngIf="sidebarOpen && item?.subItems" class="menu-item-sub-icon">
                <i class="far fa-chevron-right"></i>
            </div>

        </div>
    </a>
</div>
