import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom, tap } from "rxjs/operators";
import { of } from "rxjs";

import { BillingService } from "@merchant_api/services/billing.service";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import {
    ActionTypes,
    ExportTransactionsToCsvAction,
    ExportTransactionsToCsvFailAction,
    ExportTransactionsToCsvSucceedAction,
    FilterFundingItemTransactionsAction,
    GetFundingItemAction,
    GetFundingItemFailAction,
    GetFundingItemMidFeesAction,
    GetFundingItemMidFeesFailAction,
    GetFundingItemMidFeesSucceedAction,
    GetFundingItemAdjustmentsAction,
    GetFundingItemAdjustmentsFailAction,
    GetFundingItemAdjustmentsSucceedAction,
    GetFundingItemSucceedAction,
    GetFundingItemTransactionsAction,
    GetFundingItemTransactionsFailAction,
    GetFundingItemTransactionsSucceedAction,
    PageChangedFundingItemTransactionsAction,
    SortFundingItemTransactionsAction
} from "./funding-item.actions";
import { VituToastService, VituToastTone } from "shared-lib";
import { getFundingItemState } from "./funding-item.selectors";
import { PagedDataDtoOfFundingTransaction } from "@merchant_api/models/paged-data-dto-of-funding-transaction";
import { TransactionsFilter, TransactionsSorting } from "./funding-item.state";
import { StrictHttpResponse } from "@merchant_api/strict-http-response";
import { PageLoadFailAction } from "../router/router.actions";

@Injectable()
export class FundingItemEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private store: Store<IStore>,
        private toast: VituToastService
    ) {}

    getFundingItem$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFundingItemAction>(ActionTypes.GetFundingItem),
            switchMap(action =>
                this.billingService.fundingInstructionsGetInstructionDetails({ ref: action.ref }).pipe(
                    switchMap(fundingItem => of(new GetFundingItemSucceedAction(fundingItem))),
                    catchError((error) => of(new GetFundingItemFailAction(error)))
                )
            )
        )
    );

    getFundingItemSucceed$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFundingItemSucceedAction>(ActionTypes.GetFundingItemSucceed),
            switchMap(action =>
                [
                    new GetFundingItemTransactionsAction(action.fundingItem.ref, 1),
                    new GetFundingItemMidFeesAction(action.fundingItem.ref),
                    new GetFundingItemAdjustmentsAction(action.fundingItem.ref)
                ]
            )
        ),
    );

    getFundingItemFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemFailAction>(ActionTypes.GetFundingItemFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    getFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemTransactionsAction>(ActionTypes.GetFundingItemTransactions),
            withLatestFrom(this.store.select(getFundingItemState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.transactionsFilter;
                const sorting = action.sorting ? action.sorting : state.transactionsSorting;
                const pageSize = action.pageSize ? action.pageSize : state.transactionsPager.pageSize;
                const params = this.getParams(action.ref, sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.billingService.fundingInstructionsSearchFundingTransactions(params).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemTransactionsSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) =>
                        of(new GetFundingItemTransactionsFailAction(error)))
                );
            })
        )
    );

    pageChangedFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedFundingItemTransactionsAction>(ActionTypes.PageChangedFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.ref, action.page)))
        ),
    );

    sortFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<SortFundingItemTransactionsAction>(ActionTypes.SortFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.ref, 1, undefined, action.sorting)))
        ),
    );

    filterFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterFundingItemTransactionsAction>(ActionTypes.FilterFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.ref, 1, undefined, undefined, action.filter)))
        ),
    );

    exportTransactionsToCsv = createEffect(() =>
        this.actions$.pipe(
            ofType<ExportTransactionsToCsvAction>(ActionTypes.ExportTransactionsToCsv),
            withLatestFrom(this.store.select(getFundingItemState)),
            switchMap(([action, state]) => {

                const params = this.getExportParams(
                    action.ref,
                    state.transactionsSorting,
                    state.transactionsFilter,
                    state.transactionsPager.pageSize,
                    state.transactionsPager.page);

                return this.billingService.fundingInstructionsExportFundingTransactions$Response(params).pipe(
                    switchMap((response) =>
                        of(new ExportTransactionsToCsvSucceedAction(response))),
                    catchError((error) =>
                        of(new ExportTransactionsToCsvFailAction(error)))
                );}
            )
        )
    );

    exportTransactionsToCsvSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<ExportTransactionsToCsvSucceedAction>(ActionTypes.ExportTransactionsToCsvSucceed),
            tap((action) => {
                try {
                    const fileName = this.getDownloadFileName(action.response);
                    this.openFile(fileName, action.response);
                }
                catch (error) {
                    this.toast.open("Unable to export file.", VituToastTone.Negative);
                }
            })
        ),{ dispatch: false }
    );

    getFundingItemMidFees = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemMidFeesAction>(ActionTypes.GetFundingItemMidFees),
            switchMap((action) =>
                this.billingService.fundingInstructionsGetMidFees({ref: action.ref}).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemMidFeesSucceedAction(response))),
                    catchError((error) =>
                        of(new GetFundingItemMidFeesFailAction(error)))
                )
            )
        )
    );

    getFundingItemAdjustments = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemAdjustmentsAction>(ActionTypes.GetFundingItemAdjustments),
            switchMap((action) =>
                this.billingService.fundingInstructionsGetAdjustments({ref: action.ref}).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemAdjustmentsSucceedAction(response))),
                    catchError((error) =>
                        of(new GetFundingItemAdjustmentsFailAction(error)))
                ))
        )
    );

    private getExportParams(ref: string, sorting: TransactionsSorting, filter: TransactionsFilter, pageSize: number, page: number): any {

        const retVal: any = {
            ref
        };

        if (typeof filter.localFeesOnly === "string") {
            retVal.feesOnly = filter.localFeesOnly === "Yes";
        }

        return retVal;
    }

    private getParams(ref: string, sorting: TransactionsSorting, filter: TransactionsFilter, pageSize: number, page: number): any {

        const retVal: any = {
            ref,
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
//            ...{ filter params },
            // StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            // EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };

        if (typeof filter.localFeesOnly === "string") {
            retVal.FeesOnly = filter.localFeesOnly === "Yes";
        }

        return retVal;
    }

    private getDownloadFileName(response: StrictHttpResponse<Blob>): string {
        let fileName = null;

        try {
            const contentDisposition = response.headers.get("content-disposition");
            const regEx = /filename=[\"]?([^\"]+?)[\"]?;/;
            const fileNameMatches = contentDisposition.match(regEx);
            if (Array.isArray(fileNameMatches) && (fileNameMatches.length === 2)) {
                fileName = fileNameMatches[1];
            }
            if (!fileName) {
                throw new Error();
            }
        }
        catch (error) {
            throw new Error("Unable to download file.");
        }

        return fileName;
    }

    private openFile(fileName: string, response: StrictHttpResponse<Blob>): void {

        try {
            const blob = new Blob([response.body], { type: "application/octet-stream" });
            const fileUrl = URL.createObjectURL(blob);
            const tempAnchorElement: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
            tempAnchorElement.href = fileUrl;
            tempAnchorElement.download = fileName;
            document.body.appendChild(tempAnchorElement);
            tempAnchorElement.click();
            document.body.removeChild(tempAnchorElement);
            URL.revokeObjectURL(fileUrl);
        }
        catch (error) {
            throw new Error("Unable to open file.");
        }

    }

}
