import { DisputeState } from "./dispute.state";
import {
    ActionTypes,
} from "./dispute.actions";

export const DisputeReducer = (state: DisputeState = new DisputeState(), action: any): DisputeState => {

    switch (action.type) {

        case ActionTypes.GetDispute: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetDisputeSucceed: {
            return {
                ...state,
                record: action.response,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetDisputeFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
