<lib-multi-stage-expansion-panel [midStageSizesInPixels]="midStageSizesInPixels" [stage]="filterMode">

    <div class="expansion-panel-contents">

        <div class="filters-title-line" [ngClass]="{'filters-title-line-short': shortLayout}">

            <div class="filters-title">
                <div class="filters-title-image-container"><i class="fas fa-sliders-h"></i></div>
                <div>FILTERS</div>
            </div>

            <div [ngClass]="{'filters-short-container': shortLayout}">
                <ng-container *ngIf="shortLayout then filtersBlock">
                </ng-container>
            </div>

            <div class="filter-panel-buttons">    
                <span *ngIf="moreFields?.length">
                    <lib-action-button *ngIf="filterMode !== FiltersMode.ALL; else showFewerFilters"
                        [kind]="ActionButtonKind.FILTER"
                        label="Show More Filters"
                        fontImage="far fa-chevron-down"
                        (click)="setFilterMode(FiltersMode.ALL)">
                    </lib-action-button>
                    <ng-template #showFewerFilters>
                        <lib-action-button
                            [kind]="ActionButtonKind.FILTER"
                            label="Show Fewer Filters"
                            fontImage="far fa-chevron-up"
                            (click)="setFilterMode(FiltersMode.BASIC)">
                        </lib-action-button>
                    </ng-template>
                </span>

                <lib-action-button
                    [kind]="ActionButtonKind.FILTER"
                    [disabled]="!filtersDirty()"
                    label="Clear Filters"
                    fontImage="far fa-redo"
                    (click)="resetAllFields()">
                </lib-action-button>
            </div>

        </div>

        <ng-container *ngIf="!shortLayout then filtersBlock">
        </ng-container>

        <ng-template #filtersBlock>
            <form [formGroup]="filterForm" class="form-container" (keydown.enter)="$event.preventDefault()">

                <div class="form-lines">

                    <ng-container *ngTemplateOutlet="basicFilters"></ng-container>

                    <div class="more-filters" [ngClass]="{ 'more-filters-show': filterMode === FiltersMode.ALL }">
                        <ng-container *ngTemplateOutlet="moreFilters"></ng-container>
                    </div>

                </div>

            </form>
        </ng-template>

    </div>

</lib-multi-stage-expansion-panel>

<!--
    WORKAROUND:

    Material Icons have an issue where they display the text (eg. 'close')
    until the icon has been loaded the first time in the brower.
    Simple workaround for this graphical glitch is to load the required
    icon into an offscreen mat-icon.
-->
<div style="position: fixed; top: -100px; left: -100px;">
    <mat-icon>close</mat-icon>
</div>
