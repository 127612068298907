import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
    getCurrentUserPermissions
} from "@merchant_app/storage/current-user/current-user.selectors";
import { IStore } from "@merchant_app/storage/store";
import { PermissionUtils } from "shared-lib";

@Injectable({ providedIn: "root" })
export class CurrentUserPermissions {

    private currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    private currentUserPermissions = [];

    constructor(private store: Store<IStore>) {
        this.currentUserPermissions$.subscribe(currentUserPermissions => {
            this.currentUserPermissions = currentUserPermissions;
        });
    }

    has(requiredPermissions: string[]): boolean {
        return PermissionUtils.hasPermissions(this.currentUserPermissions, requiredPermissions);
    }
}
