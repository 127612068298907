import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DigitalWalletImageFieldComponent } from "./digital-wallet-image-field.component";
import { ImageFieldModule } from "../image-field";

@NgModule({
    declarations: [
        DigitalWalletImageFieldComponent
    ],
    imports: [
        CommonModule,
        ImageFieldModule
    ],
    exports: [
        DigitalWalletImageFieldComponent
    ]
})
export class DigitalWalletImageFieldModule { }
