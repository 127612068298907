<div #outerContainer class="outer-container" [ngClass]="{'right-aligned': (horizAlign === 'right')}">

    <div #innerContainer class="inner-container">

        <lib-vitu-tooltip
            [text]="text"
            [isDisabled]="isTooltipDisabled">

            <span [ngClass]="{'link' : link}" (mousedown)="onLinkMouseDown($event)" (mouseup)="onLinkMouseUp($event, link ? link : null)">
                <span #contentInnerContainer>
                    <ng-content></ng-content>
                </span>
                <ng-container *ngIf="!text">{{ "—" }}</ng-container>
            </span>
            <span *ngIf="infoTip">

                <lib-vitu-tooltip
                    [text]="infoTip">
                        <i class="far fa-info-circle"></i>
                </lib-vitu-tooltip>

            </span>

        </lib-vitu-tooltip>

    </div>

</div>
