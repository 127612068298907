import { DisputeDto } from "@merchant_api/models/dispute-dto";
import { DisputeOrderBy } from "@merchant_api/models/dispute-order-by";
import { DisputeTypeEnum } from "@merchant_api/models/dispute-type-enum";
import { OrderDirectionEnum } from "@merchant_api/models/order-direction-enum";
import { ProcessorEnum } from "@merchant_api/models/processor-enum";
import { createFilter, DateRange, FilterBase, Pager } from "shared-lib";

export class Sorting {
    orderBy: null | DisputeOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    caseNumber: string = undefined;
    mid: string = undefined;
    processor: ProcessorEnum = undefined;
    disputeType: DisputeTypeEnum = undefined;
    disputeAmount: number = undefined;
    disputeOutcome: string = undefined;
    localRaiseDateInterval: DateRange = undefined;
    localStatusDateInterval: DateRange = undefined;
    localDueDateInterval: DateRange = undefined;
    paymentToken: string = undefined;
    publicTransactionId: string = undefined;
}

export class DisputesState {
    loading = false;
    error = null;
    record: Array<DisputeDto> = [];
    pager = new Pager();
    sorting = new Sorting();
    filter = createFilter(Filter);
}
