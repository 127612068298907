import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getEventState = (store: IStore) => store.event;

export const getEvent = createSelector(
    getEventState,
    eventState => eventState.record,
);

export const getEventLoading = createSelector(
    getEventState,
    eventState => eventState.loading,
);
