export class VituNumberFormatCommon {

    // arg1 : (optional) => pass "showZero" if you want to show zero values (i.e. "0.00")
    static transform(value: any, clipTrailingZeros = false, arg1?: string): string {
        let retVal = "—";
        if ((value === undefined) || (value === null)) {
            value = 0;
        }
        if (typeof value === "number") {
            let truncated = `${this.bankersRounding(value)}`;

            // Use toLocalString to add commas between thousands, etc.
            // (but only use it after this.bankersRounding(...), as we want rounding to be done by this.bankersRounding(...))
            truncated = parseFloat(truncated).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

            if ((arg1 === "showZero") || (truncated !== "0.00")) {
                retVal = truncated;
                if (clipTrailingZeros && (retVal.length > 3)) {
                    if (retVal.charAt(retVal.length - 1) === "0") {
                        retVal = retVal.slice(0, retVal.length - 1);    // get rid of trailing "0"
                    }
                    if (retVal.charAt(retVal.length - 1) === "0") {
                        retVal = retVal.slice(0, retVal.length - 2);    // get rid of trailing ".0"
                    }
                }
            }
        }
        else {
            throw new Error();
        }
        return retVal;
    }

    // Use accepted solution from http://stackoverflow.com/a/3109234
    // which is exact same implementation as within the 'bankers-rounding'
    // NPM module (see https://www.npmjs.com/package/bankers-rounding)
    private static bankersRounding(num, decimalPlaces = 2) {
        const d = decimalPlaces || 0;
        const m = Math.pow(10, d);
        const n = +(d ? num * m : num).toFixed(8);
        const i = Math.floor(n);
        const f = n - i;
        const e = 1e-8;
        const r = (f > 0.5 - e && f < 0.5 + e)
            ? ((i % 2 == 0) ? i : i + 1)
            : Math.round(n);
        return d ? r / m : r;
    }

}

