import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-page-unavailable",
    templateUrl: "./page-unavailable.component.html",
    styleUrls: ["./page-unavailable.component.less"]
})
export class PageUnavailableComponent {

    ActionButtonKind = ActionButtonKind;

    constructor(
        private router: Router
    ) {}

    onClick() {

        this.router.navigate([`/`]);
    }
}
