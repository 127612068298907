import { Action } from "@ngrx/store";
import { ClientDto } from "@merchant_api/models/client-dto";

export enum ActionTypes {
    GetClients = "[Clients] GetClients",
    GetClientsSucceed = "[Clients] GetClientsSucceed",
    GetClientsFail = "[Clients] GetClientsFail",
}

export class GetClientsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetClients;
}

export class GetClientsSucceedAction implements Action {

    constructor(public clients: Array<ClientDto>) {}

    readonly type = ActionTypes.GetClientsSucceed;
}

export class GetClientsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetClientsFail;
}
