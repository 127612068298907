import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store } from "@ngrx/store";
import { getRoles, getRolesError, getRolesLoading } from "@merchant_app/storage/roles/roles.selectors";

@Component({
    selector: "app-roles",
    templateUrl: "./roles.component.html",
    styleUrls: [ "./roles.component.less" ]
})
export class RolesComponent {

    roles$ = this.store.select(getRoles);

    loading$ = this.store.select(getRolesLoading);

    error$ = this.store.select(getRolesError);

    constructor(private store: Store<IStore>) {}

}
