<div class="container">
    <div class="access-denied">
        <div>
            <h1>Access Denied!</h1>
            <lib-action-button
                label="Back to Login"
                fontImage="far fa-long-arrow-left fa-bg"
                [kind]="ActionButtonKind.PRIMARY"
                (click)="logOut()">
            </lib-action-button>
        </div>
    </div>
</div>
