import { ReportState } from "./report.state";

export const ReportReducer = (
    state: ReportState = new ReportState(),
    action: any,
): ReportState => {

    switch (action.type) {
        default:
            return state;
    }
};
