import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getClientState = (store: IStore) => store.client;

export const getClientInfo = createSelector(
    getClientState,
    clientState => clientState.client,
);

export const getClientRoles = createSelector(
    getClientState,
    clientState => clientState.roles,
);

export const getClientLoading = createSelector(
    getClientState,
    clientState => clientState.loading,
);
