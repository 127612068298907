<app-webhook-details
    [isCreate]="isCreate"
    [webhook]="webhook$ | async"
    [lookups]="lookups$ | async"
    [currentUser]="currentUser$ | async"
    [loading]="loading$ | async"
    [generatedSecret]="generatedSecret$ | async"
    [generatedSecretLoading]="generatedSecretLoading$ | async"
    (generateSecret)="generateSecret()"
    (deleteWebhook)="deleteWebhook($event)"
    (updateWebhook)="updateWebhook($event)"
    (createWebhook)="createWebhook($event)"
    (back)="onBack()">
</app-webhook-details>
