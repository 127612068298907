import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTimeFormatUtils } from "./date-time-format-utils";

@Injectable({
    providedIn: "root"
})
export class NgbCustomDateAdapter extends NgbDateAdapter<string> {

    fromModel(date: string): NgbDateStruct {

        if (!date) {

            return {
                year: null,
                month: null,
                day: null,
            };
        }

        return DateTimeFormatUtils.getDateObjectForDateRangeDate(date) as NgbDateStruct;
    }

    toModel(date: NgbDateStruct): string {

        if (!date) return null;

        return DateTimeFormatUtils.getSameTimeOnDate(date.day, date.month, date.year);
    }
}
