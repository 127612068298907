import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { throwError, of } from "rxjs";
import { PaymentsService } from "@merchant_api/services/payments.service";
import { ActionTypes, GetNextPaymentActionsAction, GetNextPaymentActionsFailAction,
    GetNextPaymentActionsSucceedAction } from "./next-payment-actions.actions";

@Injectable()
export class NextPaymentActionsEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService
    ) { }

    getNextPaymentActions = createEffect(() => this.actions.pipe(
            ofType<GetNextPaymentActionsAction>(ActionTypes.GetNextPaymentActions),
            switchMap(action => this.paymentsService.paymentsGetNextPaymentActions({ token: action.token })
                    .pipe(
                        mergeMap(result => of(new GetNextPaymentActionsSucceedAction(result))),
                        catchError((error) => of(new GetNextPaymentActionsFailAction(error)))
                    ))
        ));

}
