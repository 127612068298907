/* tslint:disable */
/* eslint-disable */
export enum TransactionOrderBy {
  Amount = 'Amount',
  DateAndTime = 'DateAndTime',
  PayerName = 'PayerName',
  CardNumber = 'CardNumber',
  TransactionId = 'TransactionId',
  TransactionType = 'TransactionType'
}
