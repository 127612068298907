import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatDialogModule } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "./confirmation-modal.component";
import { ActionButtonModule } from "../action-button/action-button.module";
import { CopyToClipboardButtonModule } from "../copy-to-clipboard-button/copy-to-clipboard-button.module";

@NgModule({
    declarations: [
        ConfirmationModalComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        ActionButtonModule,
        CopyToClipboardButtonModule
    ],
    exports: [
        ConfirmationModalComponent
    ]
})
export class ConfirmationModalModule { }
