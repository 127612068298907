import { OrganizationDto } from "@merchant_api/models/organization-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetMerchant = "[Merchant] GetMerchant",
    GetMerchantSucceed = "[Merchant] GetMerchantSucceed",
    GetMerchantFail = "[Merchant] GetMerchantFail"
}

export class GetMerchantAction implements Action {
    constructor() {}

    readonly type = ActionTypes.GetMerchant;
}

export class GetMerchantSucceedAction implements Action {
    constructor(public merchant: OrganizationDto) {}

    readonly type = ActionTypes.GetMerchantSucceed;
}

export class GetMerchantFailAction implements Action {
    constructor(public error: any) {}

    readonly type = ActionTypes.GetMerchantFail;
}
