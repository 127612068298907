import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { VituSlideToggleComponent } from "./vitu-slide-toggle.component";

@NgModule({
    declarations: [
        VituSlideToggleComponent
    ],
    imports: [
        CommonModule,
        MatSlideToggleModule
    ],
    exports: [
        VituSlideToggleComponent
    ]
})
export class VituSlideToggleModule { }
