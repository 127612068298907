import { Component, Input } from "@angular/core";

@Component({
    selector: "app-clients-table-cell-content",
    templateUrl: "./clients-table-cell-content.component.html",
    styleUrls: ["./clients-table-cell-content.component.less"],
})
export class ClientsTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;
}
