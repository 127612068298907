import { StatementsState } from "./statements.state";
import { ActionTypes } from "./statements.actions";

export const StatementsReducer = (
    state: StatementsState = new StatementsState(),
    action: any,
  ): StatementsState => {

    switch (action.type) {

        case ActionTypes.ResetStatements: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetStatements: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetStatementsSucceed: {
            return {
                ...state,
                record: action.statements,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetStatementsFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }
    }

};
