import { Component, OnInit } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store } from "@ngrx/store";

import * as TidsActions from "@merchant_app/storage/tids/tids.actions";
import * as TidsSelectors from "@merchant_app/storage/tids/tids.selectors";

@Component({
    selector: "app-configurations",
    templateUrl: "./configurations.component.html",
    styleUrls: [ "./configurations.component.less" ]
})
export class ConfigurationsComponent implements OnInit {

    tids$ = this.store.select(TidsSelectors.getTids);

    loading$ = this.store.select(TidsSelectors.getTidsLoading);

    error$ = this.store.select(TidsSelectors.getTidsError);

    pager$ = this.store.select(TidsSelectors.getTidsPager);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new TidsActions.GetTidsAction(1));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new TidsActions.PageChangedTidsAction(page));
    }

}
