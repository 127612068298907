export enum DateLabel {
    TODAY = "Today",
    YESTERDAY = "Yesterday",
    THIS_WEEK = "This week",
    LAST_7_DAYS = "Last 7 days",
    LAST_30_DAYS = "Last 30 days",
    THIS_MONTH = "This Month",
    LAST_MONTH = "Last Month",
    CUSTOM = "Custom Date",
}
