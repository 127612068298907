<div #outerContainer class="outer-container" [ngClass]="{ 'always-in-front': alwaysInFront }">
    <div *ngIf="dimBehind" [ngClass]="{ 'dim-behind-layer': dimBehind }">
    </div>

    <div class="circles-layer">

        <div class="circles-container" [ngStyle]="{ 'width': (3 * maxCircleDiameterInPixels + (2 * horizontalGapBetweenCirclesInPixels)) + 'px', 'height': maxCircleDiameterInPixels + 'px' }">

            <div class="circle-size-max" [ngStyle]="{ 'width': maxCircleDiameterInPixels + 'px', 'height': maxCircleDiameterInPixels + 'px' }">
                <div class="circle-expand left"></div>
            </div>
            <div class="circle-spacer" [ngStyle]="{ 'margin-left': horizontalGapBetweenCirclesInPixels + 'px' }"></div>
            <div class="circle-size-max" [ngStyle]="{ 'width': maxCircleDiameterInPixels + 'px', 'height': maxCircleDiameterInPixels + 'px' }">
                <div class="circle-expand center"></div>
            </div>
            <div class="circle-spacer" [ngStyle]="{ 'margin-left': horizontalGapBetweenCirclesInPixels + 'px' }"></div>
            <div class="circle-size-max" [ngStyle]="{ 'width': maxCircleDiameterInPixels + 'px', 'height': maxCircleDiameterInPixels + 'px' }">
                <div class="circle-expand right"></div>
            </div>

        </div>

    </div>

</div>
