import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "lib-vitu-slide-toggle",
    templateUrl: "./vitu-slide-toggle.component.html",
    styleUrls: ["./vitu-slide-toggle.component.less"],
})
export class VituSlideToggleComponent {

    @Input() checked: boolean;
    @Output() checkedChange = new EventEmitter<boolean>();

    @Input() disabled: boolean;

    onCheckedChange(slider) {
        this.checked = slider.checked;
        this.checkedChange.emit(this.checked);
    }

}
