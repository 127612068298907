export class Environment {
    isDevelopment: boolean;
    descriptor: string;
    portalApiRootUrl: string;
    coreApiRootUrl: string;
    authRootUrl: string;
    stackDriverErrorReporting: {
        projectId: string;
        key: string;
        appName: string;
    };
};
