import { PaymentsState } from "./payments.state";
import {
    ActionTypes,
} from "./payments.actions";

export const PaymentsReducer = (state: PaymentsState = new PaymentsState(), action: any): PaymentsState => {

    switch (action.type) {

        case ActionTypes.FilterPayments:
        case ActionTypes.SortPayments:
        case ActionTypes.PageChangedPayments:
        case ActionTypes.GetPayments: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetPaymentsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                rows: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetPaymentsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                rows: []
            };
        }

        case ActionTypes.ExportPaymentsToCsv: {
            return {
                ...state,
                exportLoading: true
            };
        }

        case ActionTypes.ExportPaymentsToCsvSucceed: {
            return {
                ...state,
                ...action.resource,
                exportLoading: false,
                exportError: null
            };
        }

        case ActionTypes.ExportPaymentsToCsvFail: {
            return {
                ...state,
                exportError: action.error,
                exportLoading: false
            };
        }

        default: {
            return state;
        }

    }

};
