<div class="outer-container">

    <div *ngIf="firstCrumb as crumb" class="first-crumb-container">

        <ng-container *ngIf="crumb.link; else activeCrumb">
            <span class="breadcrumb-link" [routerLink]="crumb.link">{{ crumb.label }}</span>
        </ng-container>
        <ng-template #activeCrumb>
            <span class="breadcrumb-regular">{{ crumb.label }}</span>
        </ng-template>
        <span *ngIf="crumbs?.length > 1" class="breadcrumb-regular">&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span>

    </div>

    <div *ngIf="crumbs?.length > 1" class="other-crumbs-container">

        <div class="other-crumbs-inner-container">

            <ng-container *ngFor="let otherCrumb of otherCrumbs; let idx = index; let len = count">

                <ng-container *ngIf="otherCrumb.link; else activeCrumbOther">
                    <span class="breadcrumb-link" [routerLink]="otherCrumb.link">{{ otherCrumb.label }}</span>
                </ng-container>
                <ng-template #activeCrumbOther>
                    <span class="breadcrumb-regular">{{ otherCrumb.label }}</span>
                </ng-template>
                <span *ngIf="idx < (len - 1)" class="breadcrumb-regular">&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            </ng-container>

        </div>

    </div>

</div>
