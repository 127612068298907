<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'type'">
        <lib-text-table-column>
            {{ element.type }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'value'">
        <div class="value-container">
            <div class="value-eye" (click)="onClickToggleShowSecret()">
                <i [class]="'far ' + (showSecret ? 'fa-eye-slash' : 'fa-eye')"></i>
            </div>
            <input
                class="value-input"
                [type]="showSecret ? 'text' : 'password'"
                [value]="element.value"
                [readonly]="true">
            <lib-copy-to-clipboard-button *ngIf="showSecret" [value]="element.value">
            </lib-copy-to-clipboard-button>
        </div>
    </ng-container>
    
    <ng-container *ngSwitchCase="'description'">
        <lib-text-table-column>
            {{ element.description }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'expirationDate'">
        <lib-date-time-label [dateUtc]="element.expirationUtc">
        </lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForExpirationDate"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'delete'">
        <lib-vitu-tooltip text="Delete">
            <i class="far fa-trash global-table-cell-image-link" (click)="onClickDeleteSecret()"></i>
        </lib-vitu-tooltip>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
