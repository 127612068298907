import { Component } from "@angular/core";

@Component({
    selector: "app-help-details",
    templateUrl: "./help-details.component.html",
    styleUrls: ["./help-details.component.less"],
})
export class HelpDetailsComponent {

}
