<lib-dashboard-common
    brandIconPath="assets/img/title-icon.svg"
    [merchantName]="merchantName$ | async"
    [userName]="currentUserName$ | async"
    [email]="currentEmail$ | async"
    [currentUserPermissions]="currentUserPermissions$ | async"
    [sidebarOpen]="sidebarOpen"
    [sidebarItems]="sidebarItems"
    (logout)="onLogout()">
        <router-outlet></router-outlet>
</lib-dashboard-common>
