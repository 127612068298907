import { WebhookDto } from "@merchant_api/models/webhook-dto";
import { WebhookEventTypesDto } from "@merchant_api/models/webhook-event-types-dto";

export class WebhookState {
    loading = false;
    error = null;
    webhook: WebhookDto = null;
    lookups: WebhookEventTypesDto = null;
    toggleWebhooksLoading: Array<number> = [];
    toggleWebhookError = null;
}
