import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
    ActionTypes,
    GetTransactionSiblingsDetailAction,
    GetTransactionSiblingsDetailFailAction,
    GetTransactionSiblingsDetailSucceedAction
} from "./transaction-siblings.actions";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { throwError, of, Observable, forkJoin } from "rxjs";
import { PaymentsService } from "@merchant_api/services/payments.service";
import { TransactionDetails } from "@merchant_api/models/transaction-details";

@Injectable()
export class TransactionSiblingsEffects {

  constructor(
    private actions: Actions,
    private paymentsService: PaymentsService
  ) {}

  getTransactionSiblings = createEffect(() => this.actions.pipe(
        ofType<GetTransactionSiblingsDetailAction>(ActionTypes.GetTransactionSiblingsDetail),
        switchMap((action: GetTransactionSiblingsDetailAction) => {

            const transactionQueries: Observable<TransactionDetails>[] = [];
            action.tokens.forEach((transactionSibling) => {
                transactionQueries.push(this.paymentsService.transactionsGet({ token: transactionSibling }));
            });

            return forkJoin(transactionQueries).pipe(
                mergeMap(result => of(new GetTransactionSiblingsDetailSucceedAction(result))),
                catchError((error) => of(new GetTransactionSiblingsDetailFailAction(error)))
            );
        })
      ));

}
