import { Component, Injector, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { ActionButtonKind } from "../action-button";

@Component({
    selector: "lib-permissions-switch-array",
    templateUrl: "./permissions-switch-array.component.html",
    styleUrls: ["./permissions-switch-array.component.less"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi:true,
            useExisting: PermissionsSwitchArrayComponent
        },
        {
            provide: NG_VALIDATORS,
            multi:true,
            useExisting: PermissionsSwitchArrayComponent
        }
    ]
})
export class PermissionsSwitchArrayComponent implements ControlValueAccessor, Validator, OnInit {

    @Input() heading = "";
    @Input() atLeastOneRequired = true;
    @Input() errorMessageAtLeastOneRequired = "At least one is required";

    // Using 'readonly' attribute as disabled is confusing for form control
    // (& we can bind easier to readonly & get behaviour we need from it)
    @Input() set readonly(value: any) {
        this._readonly = !(value === false);
    };
    _readonly = false;

    switchGroups: Array<{name: string; switches: Array<any>; open: boolean}>;
    get switches(): any[] {
        let retVal = [];
        this.switchGroups?.forEach(sg => {
            retVal = retVal.concat(sg.switches);
        });
        return retVal;
    };

    onChange = (value: any) => {};

    onTouched = () => {};

    disabled = false;

    ActionButtonKind = ActionButtonKind;

    formControl: any;

    formControlName: any;

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        this.formControlName = this.injector.get(NgControl);
    }

    get areAllSelected(): boolean {
        const selected = this.switches?.filter(i => i.checked);
        return (selected?.length === this.switches?.length);
    }

    get areNoneSelected(): boolean {
        const selected = this.switches?.filter(i => i.checked);
        return (selected?.length === 0);
    }

    get requireAtLeastOneChecked(): boolean {
        if (this.atLeastOneRequired && this.switches?.length) {
            const selected = this.switches?.filter(i => i.checked);
            if (selected?.length === 0) {
                return true;
            }
        }
        return false;
    }

    get touched(): boolean {
        return this.formControlName?.touched;
    }

    onSwitchGroupMouseUp(element: any, container: any): void {
        setTimeout(() => {
            const rowElementRect = element.getBoundingClientRect();
            const containerElementRect = container.getBoundingClientRect();
            if (rowElementRect.top < containerElementRect.top) {
                element.scrollIntoView(true);
            } else if (rowElementRect.bottom > containerElementRect.bottom) {
                element.scrollIntoView(false);
            }
        }, 350);
    }

    onClickSelectAll(): void {
        this.switches.forEach(sw => sw.checked = true);
        this.onChange(this.switches);
    }

    onClickSelectNone(): void {
        this.switches.forEach(sw => sw.checked = false);
        this.onChange(this.switches);
    }

    onReadCheckboxChanged(switchGroup: any, checked: boolean): void {
        switchGroup.switches?.forEach(sw => {
            if (!sw.writable) {
                sw.checked = checked;
            }
        });
        this.markAsTouched();
        this.onChange(this.switches);
    }

    onWriteCheckboxChanged(switchGroup: any, checked: boolean): void {
        switchGroup.switches?.forEach(sw => {
            if (sw.writable) {
                sw.checked = checked;
            }
        });
        this.markAsTouched();
        this.onChange(this.switches);
    }

    onCheckboxChanged(sw: any, checked: boolean): void {
        sw.checked = checked;
        this.markAsTouched();
        this.onChange(this.switches);
    }

    orderedSwitchesForGroup(switchGroup): Array<any> {
        let retVal = [];
        retVal = retVal.concat(switchGroup.switches.filter(sw => !sw.writable));
        retVal = retVal.concat(switchGroup.switches.filter(sw => sw.writable));
        return retVal;
    }

    switchGroupHasPermissionsWhichRead(switchGroup): boolean {
        const readables = switchGroup.switches?.filter(sw => !sw.writable);
        return (readables?.length > 0);
    }

    switchGroupHasPermissionsWhichWrite(switchGroup): boolean {
        const writables = switchGroup.switches?.filter(sw => sw.writable);
        return (writables?.length > 0);
    }

    switchGroupReadCheckboxChecked(switchGroup: any): boolean {
        const readOn = switchGroup.switches?.filter(sw => (!sw.writable && sw.checked));
        return (readOn?.length > 0);
    }

    switchGroupWriteCheckboxChecked(switchGroup: any): boolean {
        const writeOn = switchGroup.switches?.filter(sw => (sw.writable && sw.checked));
        return (writeOn?.length > 0);
    }

    switchGroupReadCheckboxIndeterminate(switchGroup: any): boolean {
        let numOff = 0;
        let numOn = 0;
        switchGroup.switches?.forEach(sw => {
            if (!sw.writable) {
                if (sw.checked) {
                    ++numOn;
                }
                else {
                    ++numOff;
                }
            }
        });
        return (numOff > 0) && (numOn > 0);
    }

    switchGroupWriteCheckboxIndeterminate(switchGroup: any): boolean {
        let numOff = 0;
        let numOn = 0;
        switchGroup.switches?.forEach(sw => {
            if (sw.writable) {
                if (sw.checked) {
                    ++numOn;
                }
                else {
                    ++numOff;
                }
            }
        });
        return (numOff > 0) && (numOn > 0);
    }

    // from ControlValueAccessor
    writeValue(value: any) {
        const switches = value;

        const groups = {};
        switches?.forEach(sw => {
            groups[sw.group] = 1;
        });

        this.switchGroups = [];
        Object.keys(groups).sort().forEach(group => {
            const filtered = switches.filter(sw => sw.group === group);
            this.switchGroups.push({ name: group, switches: filtered, open: false });
        });
    }

    // from ControlValueAccessor
    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    // from ControlValueAccessor
    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    // from ControlValueAccessor
    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    // from Validator
    validate(control: AbstractControl): ValidationErrors | null {
        if (this.requireAtLeastOneChecked) {
            return {
                atLeastOneChecked: { valid: false }
            };
        }
        return null;
    }

    setSwitchGroupOpen(switchGroup: any, isOpen: boolean) {
        switchGroup.open = isOpen;
    }

    private markAsTouched() {
        if (!this.touched) {
            this.onTouched();
        }
    }

}
