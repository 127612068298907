import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { GlobalSpinnerComponent } from "./global-spinner.component";
import { BusyIndicatorModule } from "../busy-indicator/busy-indicator.module";

@NgModule({
    declarations: [
        GlobalSpinnerComponent,
    ],
    imports: [
        CommonModule,
        BusyIndicatorModule
    ],
    exports: [
        GlobalSpinnerComponent
    ]
})
export class GlobalSpinnerModule { }
