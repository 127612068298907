import { IStore } from "@merchant_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import * as DisputesActions from "@merchant_app/storage/disputes/disputes.actions";
import * as DisputesSelectors from "@merchant_app/storage/disputes/disputes.selectors";
import { Filter, Sorting } from "@merchant_app/storage/disputes/disputes.state";

@Component({
    selector: "app-disputes",
    templateUrl: "./disputes.component.html",
    styleUrls: ["./disputes.component.less"],
})
export class DisputesComponent implements OnInit {

    disputes$ = this.store.select(DisputesSelectors.getDisputes);
    disputesLoading$ = this.store.select(DisputesSelectors.getDisputesLoading);
    disputesError$ = this.store.select(DisputesSelectors.getDisputesError);

    pager$ = this.store.select(DisputesSelectors.getDisputesPager);
    sorting$ = this.store.select(DisputesSelectors.getDisputesSorting);
    filter$ = this.store.select(DisputesSelectors.getDisputesFilter);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new DisputesActions.SortDisputesAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new DisputesActions.PageChangedDisputesAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new DisputesActions.FilterDisputesAction(filter));
    }

}
