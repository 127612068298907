import { ReportType } from "@merchant_app/shared/download-report-modal/report-type";
import { DownloadReportAction } from "@merchant_app/storage/report/report.actions";
import { IStore } from "@merchant_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Report } from "../../shared/download-report-modal/report";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.less"],
})
export class ReportsComponent {

    reports: Array<Report>;

    constructor(private store: Store<IStore>) {
        this.reports = Object.values(ReportType).map(reportType => {

            const reportName = (reportType === ReportType.STATEMENT ? reportType : reportType + " Report");

            const report: Report = {
                type: reportType,
                name: reportName
            };

            switch (reportType) {
                case ReportType.TRANSACTIONS: {
                    report.description = "All data held for each transaction processed within a date range";
                    break;
                }
                case ReportType.STATEMENT: {
                    report.description = "Merchant statements";
                    break;
                }
                case ReportType.FUNDING: {
                    report.description = "All funding instructions issued within a date range";
                    break;
                }
            }

            return report;
        });

    }

    onDownloadReport(reportType: ReportType) {
        this.store.dispatch(new DownloadReportAction(reportType));
    }

}
