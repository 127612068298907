import { TransactionsState } from "./transactions.state";
import {
    ActionTypes,
} from "./transactions.actions";

export const TransactionsReducer = (state: TransactionsState = new TransactionsState(), action: any): TransactionsState => {

    switch (action.type) {

        case ActionTypes.FilterTransactions:
        case ActionTypes.SortTransactions:
        case ActionTypes.PageChangedTransactions:
        case ActionTypes.GetTransactions: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetTransactionsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                rows: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetTransactionsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                rows: []
            };
        }

        case ActionTypes.ExportTransactionsToCsv: {
            return {
                ...state,
                exportLoading: true
            };
        }

        case ActionTypes.ExportTransactionsToCsvSucceed: {
            return {
                ...state,
                ...action.resource,
                exportLoading: false,
                exportError: null
            };
        }

        case ActionTypes.ExportTransactionsToCsvFail: {
            return {
                ...state,
                exportLoading: false,
                exportError: action.error
            };
        }

        default: {
            return state;
        }

    }

};
