/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EntityDto } from '../models/entity-dto';
import { EventDeliveriesDto } from '../models/event-deliveries-dto';
import { EventDeliveryOrderByEnum } from '../models/event-delivery-order-by-enum';
import { EventDeliveryStatusEnum } from '../models/event-delivery-status-enum';
import { EventDetailsDto } from '../models/event-details-dto';
import { EventOrderByEnum } from '../models/event-order-by-enum';
import { EventsDto } from '../models/events-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';


/**
 * API to retrieve event history.
 */
@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation eventDeliveriesSearch
   */
  static readonly EventDeliveriesSearchPath = '/v1/events/{token}/deliveries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventDeliveriesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventDeliveriesSearch$Response(params: {
    token: string;
    OrderBy?: Array<EventDeliveryOrderByEnum>;
    Status?: EventDeliveryStatusEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<EventDeliveriesDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.EventDeliveriesSearchPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('Status', params.Status, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventDeliveriesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `eventDeliveriesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventDeliveriesSearch(params: {
    token: string;
    OrderBy?: Array<EventDeliveryOrderByEnum>;
    Status?: EventDeliveryStatusEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<EventDeliveriesDto> {

    return this.eventDeliveriesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<EventDeliveriesDto>) => r.body as EventDeliveriesDto)
    );
  }

  /**
   * Path part for operation eventDeliveriesSend
   */
  static readonly EventDeliveriesSendPath = '/v1/events/{token}/deliveries/{id}/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventDeliveriesSend()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventDeliveriesSend$Response(params: {
    token: string;
    id: number;
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.EventDeliveriesSendPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `eventDeliveriesSend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventDeliveriesSend(params: {
    token: string;
    id: number;
  }): Observable<EntityDto> {

    return this.eventDeliveriesSend$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation eventsSearch
   */
  static readonly EventsSearchPath = '/v1/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsSearch$Response(params?: {
    OrderBy?: Array<EventOrderByEnum>;
    EventTypes?: Array<string>;
    Mid?: string;
    Tid?: string;
    FundingInstructionRef?: string;
    TransactionId?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<EventsDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('EventTypes', params.EventTypes, {"style":"form","explode":true});
      rb.query('Mid', params.Mid, {});
      rb.query('Tid', params.Tid, {});
      rb.query('FundingInstructionRef', params.FundingInstructionRef, {});
      rb.query('TransactionId', params.TransactionId, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `eventsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsSearch(params?: {
    OrderBy?: Array<EventOrderByEnum>;
    EventTypes?: Array<string>;
    Mid?: string;
    Tid?: string;
    FundingInstructionRef?: string;
    TransactionId?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<EventsDto> {

    return this.eventsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<EventsDto>) => r.body as EventsDto)
    );
  }

  /**
   * Path part for operation eventsGet
   */
  static readonly EventsGetPath = '/v1/events/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsGet$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<EventDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `eventsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsGet(params: {
    token: string;
  }): Observable<EventDetailsDto> {

    return this.eventsGet$Response(params).pipe(
      map((r: StrictHttpResponse<EventDetailsDto>) => r.body as EventDetailsDto)
    );
  }

}
