import { UntypedFormControl } from "@angular/forms";

export class ValidatorsVituCustom {

    /*
        Verifies the integrity of the value set in a mat-select SINGLE SELECT field,
        by comparing against the allowedValues.
        Note: not applied for unset field value (=> use Validators.required for that)
        Note: not applied for empty allowedValues (as there would be no values for the user to change to)
    */
    static selectSingleIntegrity = (allowedValues: () => Array<any>) => {

        const selectSingleInner = (c: UntypedFormControl) => {
            const allowedVals = allowedValues();
            let valid = true;
            const fieldValue = c.value;
            if ((allowedVals?.length) && (fieldValue != null)) {
                valid = (allowedVals.indexOf(fieldValue) !== -1);
            }
            return valid ? null : {
                vituSelectSingleIntegrity: {
                    valid: false
                }
            };
        };

        return selectSingleInner;
    };

    static selectMultiIntegrity = (allowedValues: () => Array<any>) => {

        /*
            Verifies the integrity of the value set in a mat-select MULTI SELECT field,
            by comparing against the allowedValues.
            Note: not applied for unset/empty field value (=> use Validators.required for that)
            Note: not applied for empty allowedValues (as there would be no values for the user to change to)
        */
        const selectMultiInner = (c: UntypedFormControl) => {
            const allowedVals = allowedValues();
            let valid = true;
            const fieldValues = c.value;
            if ((allowedVals?.length) && (fieldValues?.length)) {
                fieldValues.forEach((fieldItem) => {
                    if (allowedVals?.indexOf(fieldItem) === -1) {
                        valid = false;
                    }
                });
            }
            return valid ? null : {
                vituSelectMultiIntegrity: {
                    valid: false
                }
            };
        };

        return selectMultiInner;
    };

    static validHttpHttps = (control: UntypedFormControl) => {

        let valid = true;

        let url;

        const controlValue = control.value;
        if (controlValue?.length > 0) {
            try {
                url = new URL(controlValue);
                valid = (url.protocol === "http:") || ((url.protocol === "https:"));
            }
            catch (_) {
                valid = false;
            }
        }

        return valid ? null : {
            vituValidHttpHttpsUrl: {
                valid: false
            }
        };
    };

    static validHttpsUrl = (control: UntypedFormControl) => {

        let valid = true;

        let url;

        const controlValue = control.value;
        if (controlValue?.length > 0) {
            try {
                url = new URL(controlValue);
                valid = (url.protocol === "https:");
            }
            catch (_) {
                valid = false;
            }
        }

        return valid ? null : {
            vituValidHttpsUrl: {
                valid: false
            }
        };
    };

}
