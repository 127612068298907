<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date"
                        [clearValue]="filter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="eventTypes"
                        isFilter
                        multiple
                        label="Event Type"
                        [options]="webhookLookups?.eventTypes"
                        optionLabelKey="name"
                        optionValueKey="id"
                        [showReset]="filtersBase.resetFieldVisible('eventTypes')"
                        (handleReset)="filtersBase.resetField('eventTypes')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="tid"
                        isFilter
                        label="TID"
                        autocomplete="off"
                        [maxlength]="14"
                        [showReset]="filtersBase.resetFieldVisible('tid')"
                        (handleReset)="filtersBase.resetField('tid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="fundingInstructionRef"
                        isFilter
                        label="Funding Ref"
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('fundingInstructionRef')"
                        (handleReset)="filtersBase.resetField('fundingInstructionRef')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="transactionId"
                        isFilter
                        label="Transaction ID"
                        [maxlength]="20"
                        [showReset]="filtersBase.resetFieldVisible('transactionId')"
                        (handleReset)="filtersBase.resetField('transactionId')">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
