/* tslint:disable */
/* eslint-disable */
export enum FundingStatus {
  Processing = 'Processing',
  Success = 'Success',
  Error = 'Error',
  OnHold = 'OnHold',
  NoFunding = 'NoFunding',
  Funded = 'Funded',
  InsufficientFunds = 'InsufficientFunds',
  Rejected = 'Rejected',
  Reprocessed = 'Reprocessed',
  MissingBalance = 'MissingBalance'
}
