<div class="outer-container">

    <div class="heading-container">
        {{ heading }}
    </div>

    <div class="switches-container" #scrollContainer
        [ngClass]="{'invalid': touched && requireAtLeastOneChecked}">

        <div #sg class="switch-group-container" *ngFor="let switchGroup of switchGroups" (mousedown)="onSwitchGroupMouseUp(sg, scrollContainer)">

            <lib-multi-stage-expansion-panel [midStageSizesInPixels]="[50]" [stage]="switchGroup.open ? 2 : 1">
                <div class="switch-group-container-inner">

                    <div class="switch-group-line-one">
                        <div class="switch-group-title">
                            {{ switchGroup.name }}
                        </div>
                        <div class="checkbox-container">
                            <mat-checkbox #groupCheckbox
                                class="custom-checkbox"
                                [checked]="switchGroupCheckboxChecked(switchGroup)"
                                [indeterminate]="switchGroupCheckboxIndeterminate(switchGroup)"
                                (change)="onGroupCheckboxChanged(switchGroup, groupCheckbox.checked)"
                                [disabled]="_readonly">
                            </mat-checkbox>
                        </div>
                        <div>
                            <lib-action-button *ngIf="!switchGroup.open; else showFewer"
                                [kind]="ActionButtonKind.FILTER"
                                fontImage="far fa-chevron-down"
                                (click)="setSwitchGroupOpen(switchGroup, true)">
                            </lib-action-button>
                            <ng-template #showFewer>
                                <lib-action-button
                                    [kind]="ActionButtonKind.FILTER"
                                    fontImage="far fa-chevron-up"
                                    (click)="setSwitchGroupOpen(switchGroup, false)">
                                </lib-action-button>
                            </ng-template>
                        </div>
                    </div>

                    <div *ngFor="let switch of orderedSwitchesForGroup(switchGroup)" class="switch-group-line-two">

                            <div class="switch-title">
                                {{ switch.name }}
                            </div>
                            <div class="checkbox-container">
                                <mat-checkbox #cb
                                    class="custom-checkbox"
                                    [checked]="switch.checked"
                                    (change)="onCheckboxChanged(switch, cb.checked)"
                                    [disabled]="_readonly">
                                </mat-checkbox>
                            </div>
                            <div></div>

                    </div>

                </div>
            </lib-multi-stage-expansion-panel>

        </div>

    </div>

    <div class="switches-error">
        <mat-error *ngIf="touched && requireAtLeastOneChecked">
            {{ errorMessageAtLeastOneRequired }}
        </mat-error>
    </div>

</div>
