<div #outerContainer class="outer-container">

    <div class="inner-container">
        <ng-container *ngIf="!rowsDataLoading && emptyRowsData && !rowsDataError then emptyRows"></ng-container>
        <ng-container *ngIf="!rowsDataLoading && rowsDataError then errorRows"></ng-container>
        <ng-container *ngIf="(rowsDataShowWhileLoading || !rowsDataLoading) && !rowsDataError && rowsData?.length then table"></ng-container>
        <ng-container *ngIf="rowsDataLoading then spinner"></ng-container>
    </div>

    <div #pagerContainer *ngIf="pager && rowsData?.length && moreThanOnePage && !rowsDataError" class="pager-container">
        <lib-pager
            [disabled]="rowsDataLoading"
            [page]="pager.page"
            [pageCount]="pager.pageCount"
            [morePagesMode]="pager.morePagesMode"
            (pageChanged)="onPageChanged($event)">
        </lib-pager>
    </div>

</div>

<ng-template #table>
    <div class="table-container">

        <div #tableVerticalScrollContainer class="table-inner-container" [ngClass]="{ 'apply-vert-scrollbar-to-table': usesVertScrollbar }">
            <mat-table #materialTable [dataSource]="rowsDataForDisplay" class="table"
                matSort [matSortActive]="sortOrderBy" [matSortDirection]="sortDirection" (matSortChange)="onSortChanged($event)">

                <ng-container *ngFor="let columnId of columnIds; let idx = index" [matColumnDef]="columnId">
                    <mat-cell class="vitu-table-cell" #tableCell *matCellDef="let element; let pageRowIndex = index" [libColumnFlexDirective]="getFlexForColumn(columnId)">
                        <div class="cell-container">
                            <!-- DYNAMIC PROJECTION OF CELL CONTENT TEMPLATE -->
                            <ng-template *ngTemplateOutlet="templateRef; context : {columnId : columnId, element : element, pageRowIndex: pageRowIndex}">
                            </ng-template>
                        </div>
                    </mat-cell>

                    <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!canSortColumn(columnId, idx)" [libColumnFlexDirective]="getFlexForColumn(columnId)">
                        <div>
                            <span>
                                {{ getTitleForColumn(columnId) }}
                            </span>
                            <lib-vitu-tooltip
                                *ngIf="!!getInfoTipForColumn(columnId)"
                                [text]="getInfoTipForColumn(columnId)">
                                    <i class="far fa-info-circle"></i>            
                            </lib-vitu-tooltip>
                        </div>
                    </mat-header-cell>
                </ng-container>

                <mat-row class="vitu-table-row" #tableRow *matRowDef="let row; columns: columnIds; let pageRowIndex = index"
                    (mousedown)="onRowMouseDown($event)" (mouseup)="onRowMouseUp($event, row, pageRowIndex)"
                    [ngClass]="{'highlight-row': isRowSelected(row)}"
                    [ngStyle]="{'min-width': minimumTotalColumnWidthInPixels + 'px' }">
                </mat-row>

                <mat-header-row #headerRow *matHeaderRowDef="columnIds; sticky: true"
                    [ngStyle]="{'min-width': minimumTotalColumnWidthInPixels + 'px' }">
                </mat-header-row>

            </mat-table>
        </div>
    </div>
</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #emptyRows>
    <div class="centered-message">
        {{rowsDataEmptyMessage}}
    </div>
</ng-template>

<ng-template #errorRows>
    <div class="centered-message">
        <div class="error-mesage-container">
            <div>{{rowsDataErrorMessage}}</div>
            <!-- <div class="error-message-details">{{ "( " + rowsDataError?.message + " )"}}</div> -->
        </div>
    </div>
</ng-template>
