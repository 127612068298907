import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbDatepickerConfig, NgbDateParserFormatter, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "./ngb-date-custom-parser-formatter";
import { NgbCustomDateAdapter } from "./ngb-custom-date-adapter";
import { NgbCustomDatePickerConfig } from "./ngb-custom-datepicker-config";
import { DateTimeLabelComponent } from "./date-time-label/date-time-label.component";
import { DateInputsModule } from "./date-inputs/date-inputs.module";

@NgModule({
    imports: [
        CommonModule,
        DateInputsModule
    ],
    declarations: [
        DateTimeLabelComponent
    ],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        {
            provide: NgbDateAdapter,
            useClass: NgbCustomDateAdapter,
        },
        {
            provide: NgbDatepickerConfig,
            useClass: NgbCustomDatePickerConfig,
        }
    ],
    exports: [
        DateTimeLabelComponent,
        DateInputsModule
    ]
})
export class DateTimeModule {}
