<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Funding' , 'link': '/dashboard/funding' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="!fundingItem then spinner"></ng-container>
        <ng-container *ngIf="fundingItem then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <div class="details-common-container">

        <lib-text-field label="Funding Ref">
            {{ fundingItem.ref }}
        </lib-text-field>

        <lib-text-field label="MID">
            {{ fundingItem.mid }}
        </lib-text-field>

        <lib-text-field [label]="'Date Created (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="fundingItem.dateCreated" [singleLine]="true" [dateUtc]="fundingItem.dateCreated"></lib-date-time-label>
        </lib-text-field>

        <lib-text-field id="fundingStatusTextField" label="Funding Status">
            <lib-funding-item-status-chip
                [status]="fundingItem.fundingStatus">
            </lib-funding-item-status-chip>
        </lib-text-field>

        <lib-text-field [label]="'Date Funded (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="fundingItem.dateFunded" [singleLine]="true" [dateUtc]="fundingItem.dateFunded"></lib-date-time-label>
        </lib-text-field>

        <lib-text-field label="Transaction Fees">
            {{ fundingItem.transactionFees | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="MID Fees">
            {{ fundingItem.midFees | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Adjustments Total">
            {{ fundingItem.adjustmentsTotal | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Absorbed Fees">
            {{ fundingItem.absorbedFees | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Chargeback Amount">
            {{ fundingItem.chargebackAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Chargeback Reversal Amount">
            {{ fundingItem.chargebackReversalAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="VituPay Deposit Amount" [infoTip]="vituPayDepositAmountInfoTip">
            {{ fundingItem.vituPayDepositAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Transaction Amount">
            {{ fundingItem.transactionAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Refund Amount">
            {{ fundingItem.refundAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Rejected Amount">
            {{ fundingItem.rejectedAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Net Transaction Amount" [infoTip]="netTransactionAmountInfoTip">
            {{ fundingItem.netTransactionAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field id="merchantDepositAmountTextField" label="Merchant Deposit Amount" [infoTip]="merchantDepositAmountInfoTip">
            {{ fundingItem.merchantDepositAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Instruction Date">
            <lib-date-time-label *ngIf="fundingItem.instructionDate" [rawDateWithNoTime]="true" [dateUtc]="fundingItem.instructionDate"></lib-date-time-label>
        </lib-text-field>

    </div>

    <div class="tabs-container">

        <mat-tab-group class="tab-group" [selectedIndex]="0">

            <mat-tab [label]="getTransactionsTabLabel()">

                <div class="table-filters">
                    <app-funding-item-transaction-filters
                        [transactionsFilter]="transactionsFilter"
                        (transactionsFilterChanged)="onTransactionsFilterChanged($event)">
                    </app-funding-item-transaction-filters>
                </div>

                <div *ngIf="hasGetTransactionsReportPermission && fundingItemTransactions?.length > 0" class="table-header">
                    <lib-action-button label="Export" (click)="onExportTransactionsToCsv()" [disabled]="exportTransactionsButtonDisabled()"
                        fontImage="far fa-file-export fa-lg" [kind]="ActionButtonKind.SECONDARY">
                    </lib-action-button>
                </div>

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="transactionsColumnDefs"
                    [rowsData]="fundingItemTransactions ? fundingItemTransactions : []"
                    [rowsDataLoading]="fundingItemTransactionsLoading"
                    [rowsDataError]="fundingItemTransactionsError"
                    rowsDataErrorMessage="Error Loading Transactions"
                    [rowsDataShowWhileLoading]="true"
                    rowsDataEmptyMessage="No Transactions"
                    [pager]="transactionsPager"
                    (pageChanged)="onTransactionsPageChanged($event)"
                    [sorting]="transactionsSorting"
                    (sortingChanged)="onTransactionsSortingChanged($event)">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-funding-item-transactions-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-funding-item-transactions-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getMidFeesTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="midFeesColumnDefs"
                    [rowsData]="fundingItemMidFees"
                    [rowsDataLoading]="fundingItemMidFeesLoading"
                    [rowsDataError]="fundingItemMidFeesError"
                    rowsDataErrorMessage="Error Loading MID Fees"
                    [rowsDataShowWhileLoading]="true"
                    rowsDataEmptyMessage="No MID Fees">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-funding-item-mid-fees-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-funding-item-mid-fees-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getAdjustmentsTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="adjustmentsColumnDefs"
                    [rowsData]="fundingItemAdjustments"
                    [rowsDataLoading]="fundingItemAdjustmentsLoading"
                    [rowsDataError]="fundingItemAdjustmentsError"
                    rowsDataErrorMessage="Error Loading Adjustments"
                    [rowsDataShowWhileLoading]="true"
                    rowsDataEmptyMessage="No Adjustments">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-funding-item-adjustments-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-funding-item-adjustments-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

        </mat-tab-group>

    </div>

    <div class="form-buttons-line">
        <lib-action-button
            [kind]="ActionButtonKind.STROKED"
            largeButton
            label="Back"
            (click)="onCancel()">
        </lib-action-button>
    </div>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
