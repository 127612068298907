import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { getWebhookLookups } from "@merchant_app/storage/webhook/webhook.selectors";
import { getEvents, getEventsError, getEventsLoading, getEventsState } from "@merchant_app/storage/events/events.selectors";
import { FilterEventsAction, PageChangedEventsAction, SortEventsAction } from "@merchant_app/storage/events/events.actions";
import { Filter, Sorting } from "@merchant_app/storage/events/events.state";

@Component({
    selector: "app-event-history",
    templateUrl: "./event-history.component.html",
    styleUrls: [ "./event-history.component.less" ]
})
export class EventHistoryComponent {

    events$ = this.store.select(getEvents);
    loading$ = this.store.select(getEventsLoading);
    error$ = this.store.select(getEventsError);
    webhookLookups$ = this.store.select(getWebhookLookups);

    pager$ = this.store.pipe(select(getEventsState), map((i) => i.pager));
    sorting$ = this.store.pipe(select(getEventsState), map((i) => i.sorting));
    filter$ = this.store.pipe(select(getEventsState), map((i) => i.filter));

    constructor(private store: Store<IStore>) {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortEventsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedEventsAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterEventsAction(filter));
    }

}
