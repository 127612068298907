import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
    ActionTypes,
    FilterPaymentsAction,
    SortPaymentsAction,
    PageChangedPaymentsAction,
    ExportPaymentsToCsvAction,
    ExportPaymentsToCsvSucceedAction,
    GetPaymentsAction,
    GetPaymentsSucceedAction,
    ExportPaymentsToCsvFailAction,
    GetPaymentsFailAction,
} from "./payments.actions";
import { switchMap, withLatestFrom, tap, catchError, filter as filterOperator } from "rxjs/operators";
import { of } from "rxjs";
import { PaymentsService } from "@merchant_api/services/payments.service";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import { LocalTimePoint } from "shared-lib";
import { Filter, Sorting } from "./payments.state";
import { getPaymentsState } from "./payments.selectors";
import { RouterNavigationAction, ROUTER_NAVIGATED } from "@ngrx/router-store";

@Injectable()
export class PaymentsEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService,
        private store: Store<IStore>,
    ) { }

    initPaymentsPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) => new RegExp(/dashboard\/payments$/).test(action.payload.routerState.url)),
            switchMap(() =>
                [ new GetPaymentsAction(1) ]
            )
        )
    );

    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterPaymentsAction>(ActionTypes.FilterPayments),
            switchMap(action => of(new GetPaymentsAction(1, action.filter)))
        ),
    );

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortPaymentsAction>(ActionTypes.SortPayments),
            switchMap(action => of(new GetPaymentsAction(1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedPaymentsAction>(ActionTypes.PageChangedPayments),
            switchMap(action => of(new GetPaymentsAction(action.page)))
        ),
    );

    getPayments = createEffect(() =>
        this.actions.pipe(
            ofType<GetPaymentsAction>(ActionTypes.GetPayments),
            withLatestFrom(this.store.select(getPaymentsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.paymentsService.paymentsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetPaymentsSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) => of(new GetPaymentsFailAction(error)))
                );
            })
        ),
    );

    exportToCsv = createEffect(() =>
        this.actions.pipe(
            ofType<ExportPaymentsToCsvAction>(ActionTypes.ExportPaymentsToCsv),
            withLatestFrom(this.store.select(getPaymentsState)),
            switchMap(([_, state]) => {

                const params = this.getParams(state.sorting, state.filter, state.pager.pageSize, state.pager.page);

                return this.paymentsService.paymentsExport(params).pipe(
                    tap(response => window.location.href = response.url),
                    switchMap((response) =>
                        of(new ExportPaymentsToCsvSucceedAction(response))),
                    catchError((error) =>
                        of(new ExportPaymentsToCsvFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                Amount: filter.amount,
                First6: filter.first6,
                Last4: filter.last4,
                PayerName: filter.payerName,
                CardBrands: filter.cardBrands,
                DisputeTypes: filter.disputeTypes,
                Token: filter.token,
                Statuses: filter.statuses,
                Gateways: filter.gateways,
                TID: filter.tid,
                MID: filter.mid,
                DigitalWallets: filter.digitalWallets
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            SettlementStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.from),
            SettlementEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
