import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard.component";
import { DashboardCommonModule } from "shared-lib";
import { RouterModule } from "@angular/router";

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        DashboardCommonModule,
        RouterModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class DashboardModule { }
