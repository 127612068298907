import { DisputeDto } from "@merchant_api/models/dispute-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetDispute = "[DISPUTE] GetDispute",
    GetDisputeSucceed = "[DISPUTE] GetDisputeSucceed",
    GetDisputeFail = "[DISPUTE] GetDisputeFail"
}

export class GetDisputeAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.GetDispute;
}

export class GetDisputeSucceedAction implements Action {

    constructor(public response: DisputeDto) {}

    readonly type = ActionTypes.GetDisputeSucceed;
}

export class GetDisputeFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetDisputeFail;
}
