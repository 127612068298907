import {
    AfterContentInit, ChangeDetectorRef, ContentChildren, Directive,
    EventEmitter, Input, OnDestroy, Output, QueryList, ViewContainerRef
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { isEqualWith, cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { VituFormSubmitButtonComponent } from "./vitu-form-submit-button/vitu-form-submit-button.component";

@Directive({
    selector: "[libVituForm]",
    exportAs: "vituForm"
})
export class VituFormDirective implements AfterContentInit, OnDestroy {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("libVituForm") formGroup: UntypedFormGroup;
    @Output() libVituFormSubmit = new EventEmitter<void>();
    @Output() libVituFormSubmitInvalidFieldNames = new EventEmitter<Array<string>>();

    @ContentChildren(VituFormSubmitButtonComponent, {descendants: true}) submitters: QueryList<VituFormSubmitButtonComponent>;

    get dirty() {
        return this.formGroup?.dirty;
    }

    private subscriptions = new Subscription();
    private pristineValue: any;

    extraChangeDetectionRun = false;

    ngAfterContentInit() {
        this.init();
        this.listenForFormEdits();
        this.listenForFormSubmit();

        if (!this.extraChangeDetectionRun) {
            // Workaround => Allows us to bypass an 'ExpressionChangedAfterInit' type error
            // which otherwise sometimes occurs inside forms (eg. when using disabled controls).
            // by allowing a single extra change detection run on init
            // (should have no adverse side effects)
            this.cd.detectChanges();
            this.extraChangeDetectionRun = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    constructor(private cd: ChangeDetectorRef, private viewContainerRef: ViewContainerRef) {}

    private init() {
        this.setPristineValue(this.formGroup.value);
        this.updateDisabledOnSubmitters();
    }

    private listenForFormEdits() {
        this.subscriptions.add(
            this.formGroup.valueChanges
                .pipe(debounceTime(200))
                .subscribe(value => {
                    if (this.formGroup.pristine) {
                        this.setPristineValue(value);
                    }
                    if (this.areValuesEqual(this.pristineValue, value)) {
                        this.formGroup.markAsPristine();
                    }
                    this.updateDisabledOnSubmitters();
                })
        );
    }

    private listenForFormSubmit() {
        this.submitters?.forEach(submitter => {
            this.subscriptions.add(submitter.clicked.subscribe(() => {
                if(this.formGroup.invalid) {
                    this.emitInvalidFieldNames();
                    this.highlightInvalid();
                }
                else {
                    this.submitForm();
                }
            }));
        });
    }

    private updateDisabledOnSubmitters() {
        this.submitters?.forEach(submitter => {
            submitter.disabled = this.formGroup.pristine;
        });
    }

    private highlightInvalid() {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.viewContainerRef?.element?.nativeElement) {
            const firstErrorElement = this.viewContainerRef.element.nativeElement.querySelector("mat-form-field.ng-invalid");
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    private emitInvalidFieldNames() {
        const invalidFieldNames = [];
        Object.keys(this.formGroup.controls).forEach((key: string) => {
            if (this.formGroup.controls[key].invalid) {
                invalidFieldNames.push(key);
            }
        });
        this.libVituFormSubmitInvalidFieldNames.emit(invalidFieldNames);
    }

    private submitForm() {
        this.libVituFormSubmit.emit();
    }

    private setPristineValue(value: any) {
        this.pristineValue = cloneDeep(value);
    }

    private areValuesEqual(value1: any, value2: any): boolean {
        // Adjust standard 'isEqual' with extra condition(s):
        //  1. Equate "" and null.
        //  2. Equate "" and undefined.
        return isEqualWith(value1, value2, (a, b) => {
            if (((a === null) || (a === "")) && ((b === null) || (b === ""))) {
                return true;
            }
            if (((a === undefined) || (a === "")) && ((b === undefined) || (b === ""))) {
                return true;
            }
        });
    }

}
