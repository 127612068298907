import { MerchantState } from "./merchant.state";
import { ActionTypes } from "./merchant.actions";

export const MerchantReducer = (
  state: MerchantState = new MerchantState(),
  action: any
): MerchantState => {

  switch (action.type) {

    case ActionTypes.GetMerchantSucceed: {

      return {
        ...state,
        loading: false,
        merchant: action.merchant,
      };
    }

    case ActionTypes.GetMerchantFail: {

        return {
            ...state,
            loading: false,
            error: action.error
        };
    }

    case ActionTypes.GetMerchant: {

      return {
        ...state,
        loading: true,
      };
    }

    default:
      return state;
  }
};
