import { TransactionState } from "./transaction.state";
import * as TransactionActions from "./transaction.actions";
import { createReducer, on } from "@ngrx/store";

export const TransactionReducer = createReducer(
    new TransactionState(),
    on(TransactionActions.GetTransactionDetailAction, (state) => ({
        ...state,
        loading: true,
    })),
    on(TransactionActions.GetTransactionDetailSucceedAction, (state, { record }) => ({
        ...state,
        loading: false,
        record,
        error: null
      })),
    on(TransactionActions.GetTransactionDetailFailAction, (state, { error }) => ({
        ...state,
        loading: false,
        error,
      })),
    on(TransactionActions.UpdateTransactionDetailAction, (state, { record }) => ({
        ...state,
        loading: false,
        record,
    }))
);
