import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetStatementsAction, GetStatementsSucceedAction } from "./statements.actions";
import { of } from "rxjs";
import { ReportsService } from "@merchant_api/services/reports.service";

@Injectable()
export class StatementsEffects {

    constructor(
        private actions$: Actions,
        private reportsService: ReportsService,
    ) {}

    getStatements$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetStatementsAction>(ActionTypes.GetStatements),
            switchMap((action: GetStatementsAction) => this.reportsService.reportStatementReportsAll(
                    {
                        year: action.year,
                        month: action.month,
                    }
                ).pipe(
                    catchError(() => of([])),
                    switchMap((statements) => of(new GetStatementsSucceedAction(statements)))
                ))
        )
    );

}
