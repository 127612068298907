import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-table-column-sort",
    templateUrl: "./table-column-sort.component.html",
    styleUrls: ["./table-column-sort.component.less"],
})
export class TableColumnSortComponent {

    @Input() key: number;

    get keyValid(): boolean {
        return (this.key != null) && (typeof this.key === "number");
    }

}
