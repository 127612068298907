import { ClientState } from "./client.state";
import { ActionTypes } from "./client.actions";

export const ClientReducer = (
    state: ClientState = new ClientState(),
    action: any
    ): ClientState => {

    switch (action.type) {

        case ActionTypes.GetClient:
        case ActionTypes.GetNewClient: {
            return {
                ...state,
                client: null,
                lookups: null,
                roles: null,
                loading: true
            };
        }

        case ActionTypes.GetClientSucceed: {
            return {
                ...state,
                client: action.client,
                roles: action.roles,
                lookups: action.lookups,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetClientFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        default: {
            return state;
        }
    }

};
