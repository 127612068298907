import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-breadcrumbs",
    templateUrl: "./breadcrumbs.component.html",
    styleUrls: ["./breadcrumbs.component.less"]
})
export class BreadcrumbsComponent {

    // Workaround for Angular bug:
    // Need to pass crumbs as a string (not a plain object)
    // as otherwise the click events out of routerLink don't
    // fire inside ngFor when it has to access an object.
    // eg. see https://github.com/angular/angular/issues/44720.

    @Input() set crumbsStr(value: string) {
        this.crumbs = JSON.parse(value);
    }

    crumbs = [];

    get firstCrumb() {
        return (this.crumbs?.length > 0) ? this.crumbs[0] : null;
    }

    get otherCrumbs() {
        return (this.crumbs?.length > 1) ? this.crumbs.slice(1) : [];
    }

}
