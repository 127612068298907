import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { UsersService } from "@merchant_api/services/users.service";
import { RolesService } from "@merchant_api/services/roles.service";
import { NavigateAction, PageLoadFailAction } from "../router/router.actions";
import { GetCurrentUserAction, GetCurrentUserSucceedAction } from "../current-user/current-user.actions";
import { ConfirmationModalComponent, ConfirmationModalData, VituToastService, VituToastTone } from "shared-lib";
import { ActionTypes, CreateUserAction, DeleteUserAction, GetNewUserAction, GetUserAction, GetUserFailAction, GetUserSucceedAction,
    ResetUserPasswordAction, UpdateUserAction } from "./user.actions";
import { ActionTypes as CurrentUserActionTypes } from "../current-user/current-user.actions";
import { UserDetailsDto } from "@merchant_api/models/user-details-dto";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { GlobalSpinnerService } from "shared-lib";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
        private rolesService: RolesService,
        private dialog: MatDialog,
        private toast: VituToastService,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUserAction>(ActionTypes.GetUser),
            switchMap(action =>
                forkJoin({
                    user: this.usersService.usersGet({ id: action.id }),
                    roleList: this.rolesService.rolesSearch(),
                }).pipe(
                    mergeMap(({ user, roleList }) => of(new GetUserSucceedAction(user, roleList))),
                    catchError((error) => of(new GetUserFailAction(error)))
                )
            )
        ),
    );

    getNewUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewUserAction>(ActionTypes.GetNewUser),
            switchMap(() =>
                this.rolesService.rolesSearch().pipe(
                    switchMap((roleList) => {
                        const newUser: UserDetailsDto = {
                            email: "",
                            firstName: "",
                            id: "",
                            isLockedOut: false,
                            lastName: "",
                            roleIds: []
                        };
                        return of(new GetUserSucceedAction(newUser, roleList));
                    }),
                    catchError((error) => of(new GetUserFailAction(error)))
                )
            )
        )
    );

    getUserFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUserFailAction>(ActionTypes.GetUserFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateUser$ = createEffect(() => this.actions$.pipe(
            ofType<UpdateUserAction>(ActionTypes.UpdateUser),
            switchMap(({ user }) =>
                this.globalSpinner.apply(this.usersService.usersPut({ id: user.id, body: user }).pipe(
                    this.waitOnGetCurrentUser.bind(this),
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/users"] } })))
                ))
            )
        ));

    deleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteUserAction>(ActionTypes.DeleteUser),
            switchMap(({ userId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete User",
                        subtitle: "Are you sure you want to permanently delete this user record?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ userId, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ userId }) =>
                this.globalSpinner.apply(
                    this.usersService.usersDelete({ id: userId }).pipe(
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/users"] } })))
                    )
                )
            )
        )
    );

    resetUserPassword$ = createEffect(() => this.actions$.pipe(
            ofType<ResetUserPasswordAction>(ActionTypes.ResetUserPassword),
            switchMap(({ userId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Reset Password",
                        subtitle: "Are you sure you want to reset this user password?",
                        confirmButtonText: "Reset"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((resetConfirmed: boolean) => ({ userId, resetConfirmed })),
                )),
            filter(({ resetConfirmed }) => resetConfirmed),
            switchMap(({ userId }) =>
                this.globalSpinner.apply(
                    this.usersService.usersResetPassword({ id: userId, body: { returnUrl: window.location.origin } }).pipe(
                        map(() => {
                            this.toast.open("We have sent an email with password reset instructions to user email", VituToastTone.Positive);
                        })
                    )
                )
            )
        ), { dispatch: false });

    createUser$ = createEffect(() => this.actions$.pipe(
            ofType<CreateUserAction>(ActionTypes.CreateUser),
            switchMap(({ user }) =>
                this.globalSpinner.apply(
                    this.usersService.usersPost({ body: user }).pipe(
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/users"] } })))
                    )
                )
            )
        ));

    private waitOnGetCurrentUser<T>(source: Observable<T>) {
        // AC_todo : Need to improve this:
        // Inefficient => Only needs called if role being altered is for current user
        return source.pipe(
            map(() => {
                this.store.dispatch(new GetCurrentUserAction());
                return 1;
            }),
            switchMap(() => this.actions$.pipe(
                ofType<GetCurrentUserSucceedAction>(CurrentUserActionTypes.GetCurrentUserSucceed),
                take(1))
            )
        );
    }

}
