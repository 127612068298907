/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeletedItemsInclusionEnum } from '../models/deleted-items-inclusion-enum';
import { MerchantProcessorDto } from '../models/merchant-processor-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { OrganizationDto } from '../models/organization-dto';
import { PagedDataDtoOfTidResult } from '../models/paged-data-dto-of-tid-result';
import { ProcessorEnum } from '../models/processor-enum';
import { TidResult } from '../models/tid-result';
import { TidsOrderBy } from '../models/tids-order-by';

@Injectable({
  providedIn: 'root',
})
export class MerchantService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantGet
   */
  static readonly MerchantGetPath = '/v1/merchant';

  /**
   * Permissions: core.merchants.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGet$Response(params?: {
  }): Observable<StrictHttpResponse<OrganizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantService.MerchantGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * Permissions: core.merchants.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGet(params?: {
  }): Observable<OrganizationDto> {

    return this.merchantGet$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationDto>) => r.body as OrganizationDto)
    );
  }

  /**
   * Path part for operation merchantGetTids
   */
  static readonly MerchantGetTidsPath = '/v1/merchant/tids';

  /**
   * Permissions: core.merchants.get-tids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantGetTids()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetTids$Response(params?: {
    OrderBy?: TidsOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfTidResult>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantService.MerchantGetTidsPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfTidResult>;
      })
    );
  }

  /**
   * Permissions: core.merchants.get-tids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantGetTids$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetTids(params?: {
    OrderBy?: TidsOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfTidResult> {

    return this.merchantGetTids$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfTidResult>) => r.body as PagedDataDtoOfTidResult)
    );
  }

  /**
   * Path part for operation merchantGetTid
   */
  static readonly MerchantGetTidPath = '/v1/merchant/tid/{tid}';

  /**
   * Permissions: core.merchants.get-tids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantGetTid()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetTid$Response(params: {
    tid: string;
  }): Observable<StrictHttpResponse<TidResult>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantService.MerchantGetTidPath, 'get');
    if (params) {
      rb.path('tid', params.tid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TidResult>;
      })
    );
  }

  /**
   * Permissions: core.merchants.get-tids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantGetTid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetTid(params: {
    tid: string;
  }): Observable<TidResult> {

    return this.merchantGetTid$Response(params).pipe(
      map((r: StrictHttpResponse<TidResult>) => r.body as TidResult)
    );
  }

  /**
   * Path part for operation merchantGetMids
   */
  static readonly MerchantGetMidsPath = '/v1/merchant/mids';

  /**
   * Permissions: core.merchants.get-mids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantGetMids()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetMids$Response(params?: {
    Processor?: ProcessorEnum;
    DeletedItems?: DeletedItemsInclusionEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<MerchantProcessorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantService.MerchantGetMidsPath, 'get');
    if (params) {
      rb.query('Processor', params.Processor, {});
      rb.query('DeletedItems', params.DeletedItems, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MerchantProcessorDto>>;
      })
    );
  }

  /**
   * Permissions: core.merchants.get-mids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantGetMids$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantGetMids(params?: {
    Processor?: ProcessorEnum;
    DeletedItems?: DeletedItemsInclusionEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<MerchantProcessorDto>> {

    return this.merchantGetMids$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MerchantProcessorDto>>) => r.body as Array<MerchantProcessorDto>)
    );
  }

}
