import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { isEqual } from "lodash";

@Component({
    selector: "lib-vitu-form-field-select",
    templateUrl: "./vitu-form-field-select.component.html",
    styleUrls: ["./vitu-form-field-select.component.less"]
})
export class VituFormFieldSelectComponent implements OnChanges {

    @Input() formGroup: UntypedFormGroup;
    @Input() formFieldName: string;

    @Input() errorOverrides = [];

    @Input() set isFilter(value: any) {
        this._isFilter = !(value === false);
    };

    @Input() set multiple(value: any) {
        this._multiple = !(value === false);
    };

    @Input() set disabled(value: any) {
        this._disabled = !(value === false);
    };

    @Input() label: string;

    @Input() labelEmpty: string;
    @Input() labelEmptyCondition = false;

    @Input() limitedVerticalSpace = false;

    get labelOverlay() {
        let retVal = this.label;
        if (this.hasRequiredValidator) {
            retVal += " *";
        }
        return retVal;
    }

    get value() {
        return this.select?.value;
    }

    @Input() set options(value: Array<any>) {
        // Workaround : Change detection seems to get stuck in a loop if options is set from a 'new' value each time
        // even if the actual value is the same (this only updates the _options if its actually changed)
        // (see: https://github.com/angular/components/issues/10039#issuecomment-367018196)
        if (!isEqual(this._options, value)) {
            this._options = value;
        }
    };
    get options() {
        return this._options;
    }
    _options: Array<any>;

    @Input() optionLabelKey: string;
    @Input() optionLabelFn: (option: any) => string;

    @Input() optionValueKey: string;
    @Input() optionValueFn: (option: any) => any;

    @Input() showReset = false;
    @Output() handleReset = new EventEmitter<void>();

    @Output() selectionChanged = new EventEmitter<void>();

    @Input() spaceBetweenCapsOnLabels = false;

    // Angular workaround :
    // Hangs if complex object is applied as property on custom component?
    // (so we serialize into a string instead)
    @Input() set groups(value: any) {
        this._groups = JSON.parse(value);
    }
    _groups = [];

    @Input() infoTip: string;

    @ViewChild(MatFormField) formField: MatFormField;
    @ViewChild(MatSelect) select: MatSelect;

    formControl: UntypedFormControl;

    optionLabels: Array<string>;
    optionValues: Array<any>;

    _isFilter = false;
    _multiple = false;
    _disabled = false;

    get errors() {
        return (this.formGroup?.controls[this.formFieldName] as UntypedFormControl)?.errors;
    }

    constructor(private fb: UntypedFormBuilder) {
        this.formFieldName = "DUMMY_ONE";
        const formConfig = {};
        formConfig[this.formFieldName] = [null];
        this.formGroup = fb.group(formConfig);
    }

    ngOnChanges() {
        if (this.formGroup && this.formFieldName) {
            this.formControl = this.formGroup.controls[this.formFieldName] as UntypedFormControl;
        }

        if (this.optionLabelKey) {
            this.optionLabels = this.options?.map(option => option[this.optionLabelKey]);
        }

        if (this.optionValueKey) {
            this.optionValues = this.options?.map(option => option[this.optionValueKey]);
        }
    }

    onSelectionChanged(event: any) {
        this.selectionChanged.emit();
    }

    sortComparator(a: any, b: any) {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        return 0;
    }

    updateOutlineGap() {
        // Workaround: Angular Bug
        // Label on outline form fields doesn't align properly
        // Fix adapted from : https://github.com/angular/components/issues/15027
        this.formField.updateOutlineGap();
    }

    optionLabel(option, idx, groupIdx = null) {
        if (this.optionLabelFn !== undefined) {
            return this.optionLabelFn(option);
        }
        else if (this.optionLabelKey !== undefined) {

            let overallIndex;
            if (groupIdx !== null) {
                overallIndex = this.startIndexForGroupIndex(groupIdx) + idx;
            }
            else {
                overallIndex = idx;
            }

            return this.optionLabels[overallIndex];
        }
        else {
            return option;
        }
    }

    optionValue(option, idx, groupIdx = null) {
        if (this.optionValueFn !== undefined) {
            return this.optionValueFn(option);
        }
        else if (this.optionValueKey !== undefined) {

            let overallIndex;
            if (groupIdx !== null) {
                overallIndex = this.startIndexForGroupIndex(groupIdx) + idx;
            }
            else {
                overallIndex = idx;
            }

            return this.optionValues[overallIndex];
        }
        else {
            return option;
        }
    }

    get hasRequiredValidator() {
        const abstractControl = this.formGroup?.controls[this.formFieldName];
        if (abstractControl?.validator) {
          const validator = abstractControl.validator({} as AbstractControl);
          if (validator && validator.required) {
            return true;
          }
        }
        return false;
    }

    get panelClass() {
        let retVal = this._multiple ? "select-panel-base-multi-select" : "select-panel-base-single-select";
        if (this.limitedVerticalSpace) {
            retVal += " limited-vertical-space";
        }
        return retVal;
    }

    optionsForGroup(group) {
        return this.options.slice(group.minIdx, group.maxIdx + 1);
    }

    resolveError(errors: any, key: string) {

        // (1) First apply error override (if exists)

        if (this.errorOverrides?.length) {
            const foundOverrides = this.errorOverrides.filter(errorOverride => !!errorOverride[key]);
            if (foundOverrides?.length) {
                return foundOverrides[0][key];
            }
        }

        // (2) If no error override then apply fallback message

        if (key === "required") {
            return "Field is required";
        }

        if (key === "vituSelectSingleIntegrity") {
            return `Please reapply value`;
        }

        if (key === "vituSelectMultiIntegrity") {
            return `Please reapply value(s)`;
        }

        return null;
    }

    private startIndexForGroupIndex(groupIndex: number) {
        return this._groups[groupIndex].minIdx;
    }

}
