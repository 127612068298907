import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Filter } from "@merchant_app/storage/disputes/disputes.state";
import { LocalTimePoint } from "shared-lib";
import { ProcessorEnum } from "@merchant_api/models/processor-enum";
import { DisputeTypeEnum } from "@merchant_api/models/dispute-type-enum";

@Component({
    selector: "app-disputes-log-filters",
    templateUrl: "./disputes-log-filters.component.html",
    styleUrls: ["./disputes-log-filters.component.less"]
})
export class DisputesLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    LocalTimePoint = LocalTimePoint;

    processorList: Array<ProcessorEnum>;
    disputeTypeList: Array<DisputeTypeEnum>;

    readonly moreFields = ["paymentToken", "disputeOutcome"];

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.processorList = Object.values(ProcessorEnum);
        this.disputeTypeList = Object.values(DisputeTypeEnum);
    }

    amountMask = {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        radix: ".",
        mapToRadix: [","]
    };

    filterForm = this.fb.group({
        caseNumber: [null],
        mid: [null],
        processor: [null],
        disputeType: [null],
        disputeAmount: [null],
        disputeOutcome: [null],
        localRaiseDateInterval: [null],
        localStatusDateInterval: [null],
        localDueDateInterval: [null],
        paymentToken: [null],
        publicTransactionId: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

    wipeField(fieldName: string) {
        this.filterForm.get(fieldName).setValue(undefined);
        this.filterForm.updateValueAndValidity();
    }

}
