import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
  })
export class DashboardService {

    title$: Observable<string>;
    private titleEmitter = new BehaviorSubject<string>("");

    constructor() {
        this.title$ = this.titleEmitter.asObservable();
    }

    setTitle(title: string) {
        this.titleEmitter.next(title);
    }

}
