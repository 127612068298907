<lib-table-base
    [columnDefs]="columnDefs"
    [rowsData]="rowsData"
    [rowsDataLoading]="rowsDataLoading"
    [rowsDataError]="rowsDataError"
    [rowsDataErrorMessage]="rowsDataErrorMessage"
    [rowsDataEmptyMessage]="rowsDataEmptyMessage"
    [rowsDataShowWhileLoading]="rowsDataShowWhileLoading"
    [selectedRowProperty]="selectedRowProperty"
    [selectedRowValue]="selectedRowValue"
    [sorting]="sorting"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager"
    (pageChanged)="onPageChanged($event)"
    (rowSelected)="onRowSelected($event)">

    <ng-template let-element="element" let-columnId="columnId">
        <app-transactions-table-cell-content
            [columnId]="columnId"
            [element]="element">
        </app-transactions-table-cell-content>
    </ng-template>

</lib-table-base>
