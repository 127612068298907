import { FundingState } from "./funding.state";
import { ActionTypes } from "./funding.actions";

export const FundingReducer = (
    state: FundingState = new FundingState(),
    action: any,
  ): FundingState => {

    switch (action.type) {

        case ActionTypes.GetFunding: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetFundingSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            const retVal = {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
            return retVal;
        }

        case ActionTypes.GetFundingFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        case ActionTypes.SortFunding: {
            return {
                ...state,
                loading: true,
                sorting: action.sorting
            };
        }

        case ActionTypes.FilterFunding: {
            return {
                ...state,
                loading: true,
                filter: action.filter
            };
        }

        default: {
            return state;
        }
    }

};
