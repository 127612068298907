import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getRoleState = (store: IStore) => store.role;

export const getRoleInfo = createSelector(
    getRoleState,
    roleState => roleState.role,
);

export const getRolePermissionList = createSelector(
    getRoleState,
    roleState => roleState.permissionList,
);

export const getRoleLoading = createSelector(
    getRoleState,
    roleState => roleState.loading,
);
