import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "[libStopPropagation]"
})
export class StopPropagationDirective {
    constructor() {}

    @HostListener("click", ["$event"])
    onClick(event: Event) {

        event.preventDefault();
        event.stopPropagation();
    }
}
