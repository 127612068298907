import { Component, Input, OnInit } from "@angular/core";
import { NgbDate, NgbCalendar, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateLabel } from "./date-label";
import { DateRange } from "../date-range";
import { MatDialogRef } from "@angular/material/dialog";
import { LocalTimePoint } from "../../../local-time-point";
import { DateTimeFormatUtils } from "../../../date-time-format-utils";
import { ActionButtonKind } from "../../../../../lib/action-button/action-button.component";

@Component({
    templateUrl: "./date-range-modal-content.component.html",
    styles: [`
        .custom-day {
            text-align: center;
            padding: 0.185rem 0.25rem;
            display: inline-block;
            height: 2rem;
            width: 2rem;
        }
        .custom-day.focused {
            background-color: #e6e6e6;
        }
        .custom-day.range, .custom-day:hover {
            background-color: rgb(2, 117, 216);
            color: white;
        }
        .custom-day.faded {
            background-color: rgba(2, 117, 216, 0.5);
        }
        .datepicker .row {
            position: relative;
        }
    `],
})
export class DateRangeModalContentComponent implements OnInit {

    clearDate: DateRange;
    initializeDate: DateRange;

    constructor(
        public activeModalWindow: MatDialogRef<DateRangeModalContentComponent>,
        private calendar: NgbCalendar
    ) {}

    DateLabel = DateLabel;
    ActionButtonKind = ActionButtonKind;

    restrictPast: boolean;
    restrictFuture: boolean;
    restrictToPastMonth: boolean;
    restrictToDateOfBirth: boolean;
    singleDateMode: boolean;
    hoveredDate: NgbDate;
    today: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    labelToDateMapList: DateRange[];

    get maxDate() { return this.restrictFuture ? this.today : null; }

    get minDate() {
        if (this.restrictPast) {
            return this.today;
        }
        if (this.restrictToDateOfBirth) {
            return this.calendar.getPrev(this.today, "y", 120);
        }
        if (this.restrictToPastMonth) {
            return this.calendar.getPrev(this.today, "m", 1);
        }
        return null;
    }

    // NOTE: This Date Range Modal works off 'local time' meaning its modal
    // of time (i.e. base localTimePoint) is 'now' in the applied time zone.
    // (see LocalTimePoint.timeZone).
    // As such, its input (eg. clearDate) and output dates are relative to this
    // applied time zone.
    private readonly localTimePoint = new LocalTimePoint();

    init() {
        this.today = this.localTimePoint.asNgbDate;
        this.fromDate = this.localTimePoint.asNgbDate;
        this.toDate = this.localTimePoint.asNgbDate;
        this.labelToDateMapList = this.initLabelToDateMapList();
    }

    ngOnInit() {
        this.setInitialDateRange(this.initializeDate);
    }

    setInitialDateRange(dateRange: DateRange) {

        if (dateRange) {

            this.fromDate = NgbDate.from(this.convertDateRangeDateToNgbDate(dateRange.from));
            this.toDate = NgbDate.from(this.convertDateRangeDateToNgbDate(dateRange.to));
        }
    }

    private initLabelToDateMapList() {

        return [
            {
                label: DateLabel.TODAY,
                from: this.localTimePoint.startOfDay,
                to: this.localTimePoint.endOfDay,
            },
            {
                label: DateLabel.YESTERDAY,
                from: this.localTimePoint.startOfPreviousDay,
                to: this.localTimePoint.endOfPreviousDay,
            },
            {
                label: DateLabel.THIS_WEEK,
                from: this.localTimePoint.startOfWeek,
                to: this.localTimePoint.endOfDay,
            },
            {
                label: DateLabel.LAST_7_DAYS,
                from: this.localTimePoint.startOfDayMinusDays(6),
                to: this.localTimePoint.endOfDay,
            },
            {
                label: DateLabel.LAST_30_DAYS,
                from: this.localTimePoint.startOfDayMinusDays(29),
                to: this.localTimePoint.endOfDay,
            },
            {
                label: DateLabel.THIS_MONTH,
                from: this.localTimePoint.startOfMonth,
                to: this.localTimePoint.endOfDay,
            },
            {
                label: DateLabel.LAST_MONTH,
                from: this.localTimePoint.startOfPreviousMonth,
                to: this.localTimePoint.endOfPreviousMonth,
            },
        ];
    }

    onDateSelection(date: NgbDate) {

        if (this.singleDateMode) {
            this.fromDate = date;
            this.toDate = date;
        }
        else {
            if (!this.fromDate && !this.toDate) {

                this.fromDate = date;
            } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {

                this.toDate = date;
            } else {

                this.toDate = null;
                this.fromDate = date;
            }
        }
    }

    isHovered(date: NgbDate) {

        return this.fromDate
            && !this.toDate
            && this.hoveredDate
            && date.after(this.fromDate)
            && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {

        return date.after(this.fromDate)
            && date.before(this.toDate);
    }

    isToday(date: NgbDate) {

        return date.equals(this.today);
    }

    isRange(date: NgbDate) {

        return date.equals(this.fromDate)
            || date.equals(this.toDate)
            || this.isInside(date)
            || this.isHovered(date);
    }

    selectDateByLabel(label: DateLabel) {

        const labelToDateItem = this.labelToDateMapList
            .find(i => i.label == label);

        this.activeModalWindow.close(labelToDateItem);
    }

    chooseSelectedDateAndClose() {

        const from = DateTimeFormatUtils.startOfDayOn(this.fromDate.day, this.fromDate.month, this.fromDate.year);
        const toDate = this.toDate ?? this.fromDate;
        const to = DateTimeFormatUtils.endOfDayOn(toDate.day, toDate.month, toDate.year);

        const matchWithLabel = this.labelToDateMapList
            .find(i => i.from == from && i.to == to);

        const result = matchWithLabel ?? { from, to, label: DateLabel.CUSTOM };

        this.activeModalWindow.close(result);
    }

    clearSelectedDateAndClose() {

        this.activeModalWindow.close(this.clearDate);
    }

    cancel() {

        this.activeModalWindow.close(null);
    }

    private convertDateRangeDateToNgbDate(date: string): NgbDateStruct {

        return DateTimeFormatUtils.getDateObjectForDateRangeDate(date) as NgbDateStruct;
    }
}
