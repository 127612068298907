import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getUsersState = (store: IStore) => store.users;

export const getUsers = createSelector(
    getUsersState,
    usersState => usersState.record,
);

export const getUsersLoading = createSelector(
    getUsersState,
    usersState => usersState.loading,
);

export const getUsersError = createSelector(
    getUsersState,
    usersState => usersState.error,
);
