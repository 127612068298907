import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as RoleActions from "@merchant_app/storage/role/role.actions";
import * as RoleSelectors from "@merchant_app/storage/role/role.selectors";
import { GoBackAction } from "@merchant_app/storage/router/router.actions";
import { RoleDetailsDto } from "@merchant_api/models/role-details-dto";
import { CreateRoleDto } from "@merchant_api/models/create-role-dto";

@Component({
    selector: "app-role",
    templateUrl: "./role.component.html",
    styleUrls: ["./role.component.less"]
})
export class RoleComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute
    ) {}

    role$ = this.store.select(RoleSelectors.getRoleInfo);
    permissionList$ = this.store.select(RoleSelectors.getRolePermissionList);
    loading$ = this.store.select(RoleSelectors.getRoleLoading);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;

        if (this.isCreate) {
            this.store.dispatch(new RoleActions.GetNewRoleAction());
        }
        else {
            this.store.dispatch(new RoleActions.GetRoleAction(this.route.snapshot.params.id));
        }
    }

    updateRole(role: RoleDetailsDto) {

        this.store.dispatch(new RoleActions.UpdateRoleAction(role));
    }

    createRole(role: CreateRoleDto) {

        this.store.dispatch(new RoleActions.CreateRoleAction(role));
    }

    deleteRole(role: RoleDetailsDto) {

        this.store.dispatch(new RoleActions.DeleteRoleAction(role));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
