import { EventDeliveriesState } from "./event-deliveries.state";
import {
    ActionTypes,
} from "./event-deliveries.actions";

export const EventDeliveriesReducer = (state: EventDeliveriesState = new EventDeliveriesState(), action: any): EventDeliveriesState => {

    switch (action.type) {

        case ActionTypes.ResetEventDeliveries: {
            return {
                ...state,
                loading: false,
                rows: []
            };
        }

        case ActionTypes.FilterEventDeliveries:
        case ActionTypes.SortEventDeliveries:
        case ActionTypes.PageChangedEventDeliveries:
        case ActionTypes.GetEventDeliveries: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetEventDeliveriesSucceed: {
            return {
                ...state,
                rows: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: action.response.pages,
                    morePagesMode: true
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetEventDeliveriesFail: {
            return {
                ...state,
                rows: [],
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
