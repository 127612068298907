import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VituFormDirective } from "./vitu-form.directive";
import { ReactiveFormsModule } from "@angular/forms";
import { ActionButtonModule } from "../../action-button/action-button.module";
import { VituFormSubmitButtonComponent } from "./vitu-form-submit-button/vitu-form-submit-button.component";
import { VituFormAbortButtonComponent } from "./vitu-form-abort-button/vitu-form-abort-button.component";
import { VituFormButtonsComponent } from "./vitu-form-buttons/vitu-form-buttons.component";

@NgModule({
    declarations: [
        VituFormDirective,
        VituFormSubmitButtonComponent,
        VituFormAbortButtonComponent,
        VituFormButtonsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ActionButtonModule
    ],
    exports: [
        VituFormDirective,
        VituFormSubmitButtonComponent,
        VituFormAbortButtonComponent,
        VituFormButtonsComponent
    ]
})
export class VituFormModule { }
