import { DateTime } from "luxon";

/**
 * Various utility methods for converting the DateTime format, typically for display.
 *
 * NOTE: There is no time zone conversion in these methods
 *       (various methods enforce UTC mode simply to guard against conversion which might not be needed).
 */
export class DateTimeFormatUtils {

    static isSameDay(date1: string, date2: string): boolean {
        const d1 = DateTime.fromISO(date1).toUTC();
        const d2 = DateTime.fromISO(date2).toUTC();
        return d1.hasSame(d2, "day");
    }

    static startOfDayOn(day: number, month: number, year: number): string {
        return DateTime.utc(year, month, day).startOf("day").toISO();
    }

    static endOfDayOn(day: number, month: number, year: number): string {
        return DateTime.utc(year, month, day).endOf("day").toISO();
    }

    static getDateObjectForDateRangeDate(date: string) {
        const parsedDate = DateTime.fromISO(date).toUTC();
        return {
            year: parsedDate.year,
            month: parsedDate.month,
            day: parsedDate.day
        };
    }

    static getSameTimeOnDate(day: number, month: number, year: number): string {
        const currentTime = DateTime.utc();
        const date = DateTime.utc(year, month, day, currentTime.hour,
            currentTime.minute, currentTime.second, currentTime.millisecond);
        return date.toISO();
    }

    static formatDateShort(date: string): string {
        const dateUtc = DateTime.fromISO(date).toUTC();
        return `${dateUtc.month}/${dateUtc.day}/${dateUtc.year % 100}`;
    }

    static formatDateShort2(day: number, month: number, year: number): string {
        const dateUtc = DateTime.utc(year, month, day);
        return `${dateUtc.month}/${dateUtc.day}/${dateUtc.year % 100}`;
    }

    static compareDates(date1: string, date2: string): number {
        const dateVal1 = (new Date(date1)).getTime();
        const dateVal2 = (new Date(date2)).getTime();
        return dateVal1 - dateVal2;
    }

}
