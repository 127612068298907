<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Create Client Secret</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <form class="form" [formGroup]="createClientSecretForm" [libVituForm]="createClientSecretForm" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <lib-vitu-form-field-select
                    id="type"
                    [formGroup]="createClientSecretForm"
                    formFieldName="type"
                    label="Secret Type"
                    [options]="data.lookups.secretTypes"
                    optionLabelKey="name"
                    optionValueKey="id">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    id="value"
                    [formGroup]="createClientSecretForm"
                    formFieldName="value"
                    label="Secret Value"
                    showButton="swap_horiz"
                    (handleButton)="generateSecret()">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    id="hashType"
                    [formGroup]="createClientSecretForm"
                    formFieldName="hashType"
                    label="Hash Type"
                    [options]="data.lookups.hashTypes"
                    optionLabelKey="name"
                    optionValueKey="id">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-date
                    id="localExpirationDate"
                    [formGroup]="createClientSecretForm"
                    formFieldName="localExpirationDate"
                    label="Expiration Date"
                    singleDateMode
                    readonly>
                </lib-vitu-form-field-date>

                <lib-vitu-form-field-input
                    id="description"
                    [formGroup]="createClientSecretForm"
                    formFieldName="description"
                    label="Description">
                </lib-vitu-form-field-input>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>
        
            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Create"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
