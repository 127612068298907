<div mat-dialog-title>
    <div>{{ data?.title }}</div>
    <i *ngIf="!noCancel" class="fal fa-times global-dialog-close-button" (click)="onCancel()"></i>
</div>

<div mat-dialog-content>
    <div class="sub-title-container">
        <div class="subtitle">
            <div>
                {{ data?.subtitle }}
            </div>
            <div *ngIf="copySubtitle" class="subtitle-copy">
                <lib-copy-to-clipboard-button [value]="data?.subtitle">
                </lib-copy-to-clipboard-button>
            </div>
        </div>
        <div *ngIf="data?.subtitle2" class="subtitle2">
            {{ data?.subtitle2 }}
        </div>
    </div>
</div>

<div mat-dialog-actions>

    <lib-action-button *ngIf="!noCancel" largeButton [kind]="ActionButtonKind.STROKED" label="Cancel" (click)="onCancel()">
    </lib-action-button>

    <lib-action-button *ngIf="thirdButtonText" largeButton [kind]="ActionButtonKind.SECONDARY" [label]="thirdButtonText" (click)="onThirdButton()">
    </lib-action-button>

    <lib-action-button *ngIf="secondButtonText" largeButton [kind]="ActionButtonKind.SECONDARY" [label]="secondButtonText" (click)="onSecondButton()">
    </lib-action-button>

    <lib-action-button largeButton [label]="data.confirmButtonText" (click)="onSubmit()" [kind]="kind">
    </lib-action-button>

</div>
