import { ActionTypes } from "./client-secrets.actions";
import { ClientSecretsState } from "./client-secrets.state";

export const ClientSecretsReducer = (
    state: ClientSecretsState = new ClientSecretsState(),
    action: any,
  ): ClientSecretsState => {

    switch (action.type) {

        case ActionTypes.ResetClientSecrets: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetClientSecrets: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetClientSecretsSucceed: {
            return {
                ...state,
                record: action.clientSecrets,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetClientSecretsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
