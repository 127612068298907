import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TwoDigitDecimalNumberDirective } from "./two-digit-decimal-number-directive";
import { VituFormFieldDateComponent } from "./vitu-form-field-date/vitu-form-field-date.component";
import { VituFormFieldInputComponent } from "./vitu-form-field-input/vitu-form-field-input.component";
import { VituFormFieldSelectComponent } from "./vitu-form-field-select/vitu-form-field-select.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SpaceBetweenCapsModule } from "../../space-between-caps";
import { MatIconModule } from "@angular/material/icon";
import { DateInputsModule } from "../../date-time";
import { DisableControlDirective } from "./disable-control.directive";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { IMaskModule } from "angular-imask";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { VituInputModule } from "../../vitu-input";
import { VituFormFieldSwitchModule } from "./vitu-form-field-switch";
import { VituTooltipModule } from "../../tooltip";
import { DecimalNumberDirective } from "./decimal-number-directive";

@NgModule({
    declarations: [
        DecimalNumberDirective,
        TwoDigitDecimalNumberDirective,
        DisableControlDirective,
        VituFormFieldDateComponent,
        VituFormFieldInputComponent,
        VituFormFieldSelectComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        SpaceBetweenCapsModule,
        MatIconModule,
        DateInputsModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
        IMaskModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatRippleModule,
        VituInputModule,
        VituFormFieldSwitchModule,
        VituTooltipModule
    ],
    exports: [
        DecimalNumberDirective,
        TwoDigitDecimalNumberDirective,
        DisableControlDirective,
        VituFormFieldDateComponent,
        VituFormFieldInputComponent,
        VituFormFieldSelectComponent,
        VituFormFieldSwitchModule
    ]
})
export class VituFormFieldModule { }
