/* tslint:disable */
/* eslint-disable */
export enum PaymentStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Rejected = 'Rejected',
  Funded = 'Funded',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  Voided = 'Voided'
}
