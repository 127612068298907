import * as E from "linq";
import { Component, Input, OnChanges, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "lib-pager",
    templateUrl: "./pager.component.html",
    styleUrls: ["./pager.component.less"],
})
export class PagerComponent implements OnChanges {

    @Input() page: number;
    @Input() pageCount: number;
    @Input() morePagesMode: boolean;
    @Input() disabled: boolean;

    @Output() pageChanged = new EventEmitter<number>();

    pages: number[];

    ngOnChanges() {

        if (this.morePagesMode) {
            this.calculatePagesInMoreMode();
        }
        else {
            this.calculatePagesInTotalMode();
        }
    }

    firstPageButtonVisible() {

        return this.pages.length > 0
            && this.pages[0] > 1;
    }

    firstPageButtonSeparatorVisible() {

        return this.pages.length > 0
            && this.pages[0] > 2;
    }

    onClickChangeToPage(page: number) {
        if (!this.disabled) {
            this.pageChanged.emit(page);
        }
    }

    onClickPrevPage() {
        if (!this.disabled) {
            const prev = this.prevPage;
            if (prev !== null) {
                this.pageChanged.emit(prev);
            }
        }
    }

    onClickNextPage() {
        if (!this.disabled && this.pages.length) {
            const next = this.nextPage;
            if (next !== null) {
                this.pageChanged.emit(next);
            }
        }
    }

    get prevPage(): number | null {
        let retVal = null;
        const prevPage = Math.max(1, this.page - 1);
        if (prevPage !== this.page) {
            retVal = prevPage;
        }
        return retVal;
    }

    get nextPage(): number | null {
        let retVal = null;
        if (this.pages.length) {
            const lastPageShown = this.pages[this.pages.length - 1];
            const nextPage = Math.min(this.page + 1, lastPageShown);
            if (nextPage !== this.page) {
                retVal = nextPage;
            }
        }
        return retVal;
    }

    private calculatePagesInMoreMode() {

        const currentPage = this.page;
        const numberOfPages = this.pageCount;

        const delta = 1;
        const maxDelta = delta * 2;

        const leftDelta = Math.min(
            numberOfPages <= delta ? maxDelta - numberOfPages + 1 : delta,
            currentPage - 1
        );

        const rightDelta = Math.min(
            currentPage <= delta ? maxDelta - currentPage + 1 : delta,
            numberOfPages - 1
        );

        this.pages = E.range(currentPage - leftDelta, rightDelta + leftDelta + 1, 1).toArray();
    }

    private calculatePagesInTotalMode(): void {

        const currentPage = this.page;
        const numberOfPages = this.pageCount;

        const delta = 1;
        const maxDelta = delta * 2;

        let pages = [];
        if (numberOfPages > 0) {
            const maxPage = Math.min(Math.max(currentPage + delta, maxDelta + 1), numberOfPages);
            const minPage = Math.max(1, maxPage - maxDelta);
            pages = E.range(minPage, maxPage - minPage + 1, 1).toArray();
        }

        this.pages = pages;
    }

}
