import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, filter } from "rxjs/operators";
import { of } from "rxjs";
import { DisputesService } from "@merchant_api/services/disputes.service";
import {
    ActionTypes,
    GetDisputeAction,
    GetDisputeFailAction,
    GetDisputeSucceedAction
} from "./dispute.actions";
import { RouterNavigationAction, ROUTER_NAVIGATED } from "@ngrx/router-store";
import { PageLoadFailAction } from "../router/router.actions";

@Injectable()
export class DisputeEffects {

    constructor(
        private actions: Actions,
        private disputesService: DisputesService
    ) { }

    initDisputePage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/disputes\/.+$/).test(action.payload.routerState.url)),
            switchMap((action: any) => {
                const rS = action.payload.routerState;
                const token = rS.root.firstChild.firstChild.params.id;
                return [new GetDisputeAction(token)];
            })
        )
    );

    getDispute = createEffect(() =>
        this.actions.pipe(
            ofType<GetDisputeAction>(ActionTypes.GetDispute),
            switchMap((action) =>
                this.disputesService.disputesGet({ token: action.token }).pipe(
                    switchMap((response) => of(new GetDisputeSucceedAction(response))),
                    catchError((error) => of(new GetDisputeFailAction(error)))
                )
            )
        ),
    );

    getDisputeFail = createEffect(() =>
        this.actions.pipe(
            ofType<GetDisputeFailAction>(ActionTypes.GetDisputeFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

}
