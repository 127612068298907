<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Users' , 'link': '/dashboard/users' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">
        <ng-container *ngIf="!user then spinner"></ng-container>
        <ng-container *ngIf="user then details"></ng-container>
    </div>

</div>


<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #details>

    <form [formGroup]="userForm" [libVituForm]="userForm" (libVituFormSubmit)="onSubmit()">

        <div class="form-inner-container">

            <lib-vitu-form-field-input
                class="field"
                [formGroup]="userForm"
                formFieldName="firstName"
                label="First Name"
                [disabled]="viewOnly">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                [formGroup]="userForm"
                formFieldName="lastName"
                label="Last Name"
                [disabled]="viewOnly">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="emailAddress"
                [formGroup]="userForm"
                formFieldName="email"
                type="email"
                label="Email Address"
                [disabled]="viewOnly">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                [formGroup]="userForm"
                formFieldName="roleIds"
                multiple
                label="Role(s)"
                [options]="groupedRoleList"
                optionLabelKey="name"
                optionValueKey="id"
                [groups]="roleGroups"
                [disabled]="viewOnly">
            </lib-vitu-form-field-select>

            <div class="actions-line" *ngIf="!isCreate">

                <lib-vitu-form-field-switch
                    formControlName="isLockedOut"
                    label="Locked"
                    [disableControl]="viewOnly">
                </lib-vitu-form-field-switch>

                <div class="action-buttons">

                    <lib-action-button
                        *ngIf="hasDeleteUserPermission"
                        id="deleteUserButton"
                        class="action-button"
                        label="Delete User"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="loading || !user || !currentUser || (user.id === currentUser.id)"
                        (click)="onClickDelete()">
                    </lib-action-button>
        
                    <lib-action-button
                        *ngIf="hasResetPasswordPermission"
                        id="resetPasswordButton"
                        class="action-button"
                        label="Reset Password"
                        [kind]="ActionButtonKind.SECONDARY"
                        [disabled]="loading"
                        (click)="onClickResetPassword()">
                    </lib-action-button>
        
                </div>

            </div>

        </div>

        <lib-vitu-form-buttons>

            <lib-vitu-form-abort-button
                (onAbort)="onClickBack()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                *ngIf="isCreate || hasUpdateUserPermission"
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>
