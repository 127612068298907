import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getTidsState = (store: IStore) => store.tids;

export const getTids = createSelector(
    getTidsState,
    tidsState => tidsState.record
);

export const getTidsLoading = createSelector(
    getTidsState,
    tidsState => tidsState.loading
);

export const getTidsError = createSelector(
    getTidsState,
    tidsState => tidsState.error
);

export const getTidsPager = createSelector(
    getTidsState,
    tidsState => tidsState.pager
);
