import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter as fitlerOp, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionTypes, FilterDisputesAction, GetDisputesAction, GetDisputesFailAction,
    GetDisputesSucceedAction, PageChangedDisputesAction, SortDisputesAction } from "./disputes.actions";
import { of } from "rxjs";
import { DisputesService } from "@merchant_api/services/disputes.service";
import { Filter, Sorting } from "./disputes.state";
import { LocalTimePoint } from "shared-lib";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getDisputesState } from "./disputes.selectors";

@Injectable()
export class DisputesEffects {

    constructor(
        private actions$: Actions,
        private disputesService: DisputesService,
        private store: Store<IStore>
    ) {}

    initDisputesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            fitlerOp((action: RouterNavigatedAction) => /\/dashboard\/disputes/g.test(action.payload.routerState.url) &&
                !(/\/dashboard\/disputes\//g.test(action.payload.routerState.url))),
            map(() => new GetDisputesAction(1))
        )
    );

    sort$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SortDisputesAction>(ActionTypes.SortDisputes),
            switchMap(action => of(new GetDisputesAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter$ = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterDisputesAction>(ActionTypes.FilterDisputes),
            switchMap(action => of(new GetDisputesAction(1, undefined, action.filter)))
        ),
    );

    pageChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedDisputesAction>(ActionTypes.PageChangedDisputes),
            switchMap(action => of(new GetDisputesAction(action.page)))
        ),
    );

    getDisputes$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetDisputesAction>(ActionTypes.GetDisputes),
            withLatestFrom(this.store.select(getDisputesState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.disputesService.disputesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetDisputesSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) =>
                        of(new GetDisputesFailAction(error))
                    )
                );
            })
        )
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                CaseNumber: filter.caseNumber,
                Mid: filter.mid,
                Processor: filter.processor,
                DisputeType: filter.disputeType,
                DisputeAmount: filter.disputeAmount,
                DisputeOutcome: filter.disputeOutcome,
                PaymentToken: filter.paymentToken,
                PublicTransactionId: filter.publicTransactionId
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDueDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDueDateInterval?.to),
            RaiseDateStartUtc: LocalTimePoint.convertLocalValueToUtcValue(filter.localRaiseDateInterval?.from),
            RaiseDateEndUtc: LocalTimePoint.convertLocalValueToUtcValue(filter.localRaiseDateInterval?.to),
            StatusDateStartUtc: LocalTimePoint.convertLocalValueToUtcValue(filter.localStatusDateInterval?.from),
            StatusDateEndUtc: LocalTimePoint.convertLocalValueToUtcValue(filter.localStatusDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
