import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-title-bar",
    templateUrl: "./title-bar.component.html",
    styleUrls: ["./title-bar.component.less"]
})
export class TitleBarComponent {

    @Input() crumbs = [];

    get crumbsStr() {
        return JSON.stringify(this.crumbs);
    }

}
