import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-binary-status-icon",
    templateUrl: "./binary-status-icon.component.html",
    styleUrls: ["./binary-status-icon.component.less"],
})
export class BinaryStatusIconComponent {

    @Input() value: boolean;
    @Input() reverseColors: boolean;
    @Input() showNegativeOnly: boolean;
    @Input() showPositiveOnly: boolean;

}
