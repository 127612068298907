import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackBarContentComponent } from "./snackbar-content.component/snackbar-content.component";
import { ActionButtonModule } from "../action-button";

@NgModule({
    declarations: [
        SnackBarContentComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        ActionButtonModule
    ]
})
export class VituToastModule { }
