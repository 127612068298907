import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SidebarItem } from "../sidebar-item";
import { isEqual } from "lodash";
import { PermissionUtils } from "../../misc/permission-utils";

@Component({
    selector: "lib-dashboard-sidebar",
    templateUrl: "./dashboard-sidebar.component.html",
    styleUrls: ["./dashboard-sidebar.component.less"],
})
export class DashboardSidebarComponent implements OnInit {

    @Input() userName: string;
    @Input() currentUserPermissions: any[] = [];
    @Input() sidebarOpen: boolean;
    @Input() sidebarItems: SidebarItem[];
    @Input() subMenuShownForItem: SidebarItem;
    @Output() logout = new EventEmitter<void>();
    @Output() mouseOverItem = new EventEmitter<{item: SidebarItem; vertPosInPixels: number}>();
    @Output() mouseOutItem = new EventEmitter<void>();
    @Output() navigateTo = new EventEmitter<string>();

    helpItem: SidebarItem;

    ngOnInit() {
        this.helpItem = {
            name: "Help",
            primaryRouteToActivate: "/dashboard/help",
            permissions: [],
            hasSeparatorBefore: true,
            faIcon: "far fa-question-circle"
        };
    }

    constructor(private router: Router) {}

    get copyrightInfo(): string {
        const copyrightYear = new Date().getFullYear(); // adequate just to use local time
        return `© ${copyrightYear} VITU\u00a0\u00a0|\u00a0\u00a0v1.0.0`;
    }

    isItemActive(item: SidebarItem) {

        return (
            this.isPrimaryRouteToActivate(item) ||
            this.isSecondaryRouteToCapture(item)
        );
    }

    isItemActionable(item: SidebarItem) {

        return !this.isPrimaryRouteToActivate(item, true);
    }

    isItemSubMenuActive(item: SidebarItem) {

        return isEqual(this.subMenuShownForItem, item);
    }

    hasPermissions(currentPermissions: string[], item: SidebarItem): boolean {
        let retVal: boolean;
        if (item.subItems?.length > 0) {
            retVal = false;
            for (const subItem of item.subItems) {
                if (PermissionUtils.hasPermissions(currentPermissions, subItem.permissions)) {
                    retVal = true;
                    break;
                }
            }
        }
        else {
            retVal = PermissionUtils.hasPermissions(currentPermissions, item.permissions);
        }
        return retVal;
    }

    onMouseOverItem(menuItem: SidebarItem, itemDiv: HTMLDivElement): void {
        this.mouseOverItem.emit({
            item: menuItem,
            vertPosInPixels: itemDiv.offsetTop
        });
    }

    onMouseOutItem(): void {
        this.mouseOutItem.emit();
    }

    onNavigateTo(location: string): void {
        this.navigateTo.emit(location);
    }

    private isPrimaryRouteToActivate(item: SidebarItem, mustBeAtEnd = false) {

        const matchIndex = this.router.url.indexOf(item.primaryRouteToActivate);

        if (matchIndex < 0) {
            return false;
        }
        return (
            !mustBeAtEnd ||
            this.router.url.substring(matchIndex) ===
                item.primaryRouteToActivate
        );
    }

    private isSecondaryRouteToCapture(item: SidebarItem) {

        return (
            item.secondaryRouteToCapture &&
            this.router.url.indexOf(item.secondaryRouteToCapture) > -1
        );
    }
}
