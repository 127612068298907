import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getCurrentUserState = createSelector(
    (store: IStore) => store.currentUser,
    store => store,
);

export const getCurrentUser = createSelector(
    getCurrentUserState,
    currentUserState => currentUserState.record,
);

export const getCurrentUserLoading = createSelector(
    getCurrentUserState,
    currentUserState => currentUserState.loading,
);

export const getCurrentUserPermissions = createSelector(
    getCurrentUser,
    currentUser => currentUser ? currentUser.permissions : []
);
