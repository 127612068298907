import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SidebarItem } from "./sidebar-item";
import { DashboardService } from "./dashboard.service";
import { Router } from "@angular/router";
import { PermissionUtils } from "../misc/permission-utils";

@Component({
    selector: "lib-dashboard-common",
    templateUrl: "./dashboard-common.component.html",
    styleUrls: ["./dashboard-common.component.less"]
})
export class DashboardCommonComponent {

    @Input() merchantName = "";
    @Input() email = "";
    @Input() userName = "";

    @Input() brandIconPath = "";
    @Input() brandIconOffsetInPixelsY = 0;
    @Input() currentUserPermissions: any[] = [];
    @Input() sidebarOpen = true;
    @Input() sidebarItems: SidebarItem[] = [];
    @Output() logout = new EventEmitter<void>();

    subMenuParentItem: SidebarItem = null;
    subMenuVertPosInPixels: number;
    subMenuShown = false;
    private hideSubMenuTimer: any;

    hideDashboardUserName = false;

    constructor(public dashboardService: DashboardService, private router: Router) { }

    onClickHamburger() {
        this.sidebarOpen = !this.sidebarOpen;
    }

    onLogout() {
        this.logout.emit();
    }

    onNavigateTo(location: string): void {
        this.hideSubMenu();
        if (location) {
            this.router.navigate([location]);
        }
    }

    onMouseOverItem(payload: {item: SidebarItem; vertPosInPixels: number}) {
        this.subMenuParentItem = payload.item;
        if (this.subMenuParentItem?.subItems) {
            this.subMenuVertPosInPixels = payload.vertPosInPixels;
            this.showSubMenu();
        }
    }

    onMouseOutItem() {
        this.triggerHideSubMenu();
    }

    onShowSubMenu() {
        this.showSubMenu();
    }

    onHideSubMenu() {
        this.triggerHideSubMenu();
    }

    showSubMenu() {
        clearTimeout(this.hideSubMenuTimer);
        this.subMenuShown = true;
    }

    triggerHideSubMenu() {
        clearTimeout(this.hideSubMenuTimer);
        this.hideSubMenuTimer = setTimeout(() => {
            this.hideSubMenu();
        }, 200);
    }

    onUserMenuShowChange(show: boolean) {
        this.hideDashboardUserName = show;
    }

    hasPermissions(currentPermissions: string[], requiredPermissions: string[]): boolean {
        return PermissionUtils.hasPermissions(currentPermissions, requiredPermissions);
    }

    private hideSubMenu() {
        this.subMenuShown = false;
    }

}
