<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Disputes' }]">
    </lib-title-bar>

    <app-disputes-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)">
    </app-disputes-log-filters>

    <lib-table-base
        class="disputes-table"
        [columnDefs]="columnDefs"
        [rowsData]="disputes"
        [rowsDataLoading]="disputesLoading"
        [rowsDataError]="disputesError"
        rowsDataErrorMessage="Error Loading Disputes"
        [rowsDataShowWhileLoading]="true"
        selectedRowProperty="token"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-disputes-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-disputes-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
