<router-outlet></router-outlet>
<lib-global-spinner></lib-global-spinner>

<!--
   mat-icon can show text in components if they aren't loaded.
   Dirty solution here to force a pre-load by loading a single one.
   (Another solution would be to self-host the Material Icons font
   instead of getting it from CDN link.)
-->
<div style="position: absolute; top: -10000px; left: -10000px;">
    <mat-icon>close</mat-icon>
</div>
