import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusChipModule } from "../status-chip";
import { TransactionStatusChipComponent } from "./transaction-status-chip.component";

@NgModule({
    declarations: [
        TransactionStatusChipComponent
    ],
    imports: [
        CommonModule,
        StatusChipModule
    ],
    exports: [
        TransactionStatusChipComponent
    ]
})
export class TransactionStatusChipModule { }
