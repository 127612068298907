import { Component } from "@angular/core";

@Component({
    selector: "app-help",
    templateUrl: "./help.component.html",
    styleUrls: ["./help.component.less"],
})
export class HelpComponent {

    constructor() {}

}
