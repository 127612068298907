import { UserDetailsDto } from "@merchant_api/models/user-details-dto";
import { Action } from "@ngrx/store";
import { CurrentUserDto } from "./current-user.state";

export enum ActionTypes {
    GetCurrentUser = "[CurrentUser] GetCurrentUser",
    GetCurrentUserSucceed = "[CurrentUser] GetCurrentUserSucceed",
    GetCurrentUserFail = "[CurrentUser] GetCurrentUserFail",
    AddDetailsToCurrentUser = "[CurrentUser] AddDetailsToCurrentUser"
}

export class GetCurrentUserAction implements Action {

  constructor() {}

  readonly type = ActionTypes.GetCurrentUser;
}

export class GetCurrentUserSucceedAction implements Action {

    constructor(public record: CurrentUserDto) {}

    readonly type = ActionTypes.GetCurrentUserSucceed;
}

export class GetCurrentUserFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetCurrentUserFail;
}

export class AddDetailsToCurrentUserAction implements Action {

    constructor(public userDetailsDto: UserDetailsDto) {}

    readonly type = ActionTypes.AddDetailsToCurrentUser;

}
