import { WebhookState } from "./webhook.state";
import { ActionTypes } from "./webhook.actions";

export const WebhookReducer = (
    state: WebhookState = new WebhookState(),
    action: any
    ): WebhookState => {

    switch (action.type) {

        case ActionTypes.GetWebhook:
        case ActionTypes.GetNewWebhook: {
            return {
                ...state,
                webhook: null,
                lookups: null,
                loading: true
            };
        }

        case ActionTypes.GetWebhookSucceed: {
            return {
                ...state,
                webhook: action.webhook,
                lookups: action.lookups,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetWebhookFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        case ActionTypes.ToggleWebhook: {
            state.toggleWebhooksLoading.push(action.id);
            return state;
        }

        case ActionTypes.ToggleWebhookSucceed: {
            state.toggleWebhooksLoading = state.toggleWebhooksLoading.filter((id) => (id !== action.id));
            return {
                ...state,
                toggleWebhookError: null
            };

        }

        case ActionTypes.ToggleWebhookFail: {
            state.toggleWebhooksLoading = state.toggleWebhooksLoading.filter((id) => (id !== action.id));
            return {
                ...state,
                toggleWebhookError: action.error
            };
        }

        case ActionTypes.GetWebhookLookupsSucceed: {
            return {
                ...state,
                lookups: action.lookups
            };
        }

        default: {
            return state;
        }
    }

};
