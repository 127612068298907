import { Injectable } from "@angular/core";
import { OAuthService, AuthConfig } from "angular-oauth2-oidc";
import { GetAppRootUrl, GetCurrentUrl } from "./urls";

class UserInfoModel {
    preferred_username: string;
    email: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {

    constructor(
        private oauthService: OAuthService,
        private authConfig: AuthConfig
    ) {}

    private discoveryDocumentRequest: Promise<any>;

    async configure() {

        this.oauthService.configure(this.authConfig);

        await this.loadDiscoveryDocument();
    }

    initTokenRefreshListener() {

        this.oauthService.setupAutomaticSilentRefresh();
    }

    private loadDiscoveryDocument() {

        this.discoveryDocumentRequest = this.discoveryDocumentRequest
            ?? this.oauthService.loadDiscoveryDocument()
                .catch(() => { delete this.discoveryDocumentRequest; });

        return this.discoveryDocumentRequest;
    }

    async validateToken() {

        let loginCodeFlowError = false;
        try {
            // Try to extract credentials from current URL (handle redirect from keycloak)
            await this.oauthService.tryLoginCodeFlow();
        }
        catch (error) {
            // eg. credentials in URL aren't valid
            loginCodeFlowError = true;
        }

        if (!loginCodeFlowError && this.oauthService.hasValidAccessToken()) {
            return true;
        }
        else {
            this.openLoginPageIfNecessary();
            return false;
        }
    }

    openLoginPageIfNecessary() {

        this.oauthService.redirectUri = GetCurrentUrl();
        // Initially goes to login page, but after can refresh expired token & bypass login form.
        this.oauthService.initCodeFlow();
    }

    logout() {

        // Signal to any other active tabs that they should logout too
        // (even though they will no longer have a valid token after this tab logs out.)
        const newValue = Math.floor((Math.random() * Number.MAX_SAFE_INTEGER) + 1);
        localStorage.setItem("DO_LOGOUT", newValue.toString());

        this.oauthService.redirectUri = GetAppRootUrl();
        this.oauthService.logOut();
    }

    getUserClaims() {

        return this.oauthService.getIdentityClaims() as UserInfoModel;
    }

    getAccessToken() {

        return this.oauthService.getAccessToken();
    }

    loadUserProfile() {

        return this.oauthService.loadUserProfile();
    }

}
