import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorUiService } from "./error-ui.service";
import {
    VituHttpError,
    VituHttpErrorUnableToConnect,
    VituHttpErrorBadRequest,
    VituHttpErrorForbidden,
    VituHttpErrorInternalServerError,
    VituHttpErrorNotFound,
    VituHttpErrorServiceUnavailable,
    VituHttpErrorUnauthorized,
    VituHttpErrorUnknown,
    VituHttpStatus
} from "../misc/vitu-http-errors";
import { GlobalErrorHandler } from "./global-error-handler.service";
import { AuthService } from "../misc/auth.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    private globalErrorHandler: GlobalErrorHandler;

    constructor(
        private authService: AuthService,
        private errorUi: ErrorUiService,
        errorHandler: ErrorHandler
    ) {
        this.globalErrorHandler = errorHandler as GlobalErrorHandler;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    const error: VituHttpError = this.createErrorIfNecessary(err);

                    if (error) {
                        if (error.status === VituHttpStatus.Unauthorized) {
                            this.authService.logout();
                        }

                        const isServerError = ((error.status === 0) || ((error.status >= 500) && (error.status < 600)));
                        this.errorUi.handleNotification(error.message, isServerError);

                        // Here we can log this out even if the error is caught in a parent handler
                        this.globalErrorHandler.logHttpErrorIfNecessary(error);

                        // We create a clone of the error object here because the globalErrorHandler
                        // may mutate the error object (eg. message) during logging, and its
                        // difficult to create good JavaScript Error object clones further downstream.
                        const errorClone: VituHttpError = this.createErrorIfNecessary(err);
                        return throwError(errorClone);
                    }
                })
            );
    }

    private createErrorIfNecessary(err: any): VituHttpError {

        let error;

        switch (err.status) {
            case 0:
                {
                    error = VituHttpErrorUnableToConnect.create(err);
                    break;
                }
            case 400:
                {
                    error = VituHttpErrorBadRequest.create(err);
                    break;
                }
            case 401:
                {
                    error = VituHttpErrorUnauthorized.create(err);
                    break;
                }
            case 403:
                {
                    error = VituHttpErrorForbidden.create(err);
                    break;
                }
            case 404:
                {
                    error = VituHttpErrorNotFound.create(err);
                    break;
                }
            case 500:
                {
                    error = VituHttpErrorInternalServerError.create(err);
                    break;
                }
            case 503:
                {
                    error = VituHttpErrorServiceUnavailable.create(err);
                    break;
                }
        }

        if (!error && (err.status >= 0) && !((err.status >= 200) && (err.status < 400))) {
            // Safe catch all for HTTP status codes that are errors & aren't decoded above
            error = VituHttpErrorUnknown.create(err);
        }

        return error;
    }

}
