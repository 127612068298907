import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalErrorMessageComponent } from "./modal-error-message.component";

@NgModule({
    declarations: [
        ModalErrorMessageComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ModalErrorMessageComponent
    ]
})
export class ModalErrorMessageModule { }
