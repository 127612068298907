import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { RolesService } from "@merchant_api/services/roles.service";
import { NavigateAction, PageLoadFailAction } from "../router/router.actions";
import { GetCurrentUserAction, GetCurrentUserSucceedAction } from "../current-user/current-user.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import { ActionTypes, CreateRoleAction, DeleteRoleAction, GetNewRoleAction, GetRoleAction,
    GetRoleFailAction, GetRoleSucceedAction, UpdateRoleAction } from "./role.actions";
import { ActionTypes as CurrentUserActionTypes } from "../current-user/current-user.actions";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { GlobalSpinnerService } from "shared-lib";
import { PermissionsService } from "@merchant_api/services/permissions.service";
import { RoleDetailsDto } from "@merchant_api/models/role-details-dto";

@Injectable()
export class RoleEffects {

    constructor(
        private actions$: Actions,
        private rolesService: RolesService,
        private permissionsService: PermissionsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetRoleAction>(ActionTypes.GetRole),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetRoleFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return forkJoin({
                    role: this.rolesService.rolesGet({ id: action.id }),
                    permissionList: this.permissionsService.permissionsSearch(),
                }).pipe(
                    mergeMap(({ role, permissionList }) => of(new GetRoleSucceedAction(role, permissionList))),
                    catchError((error) => of(new GetRoleFailAction(error)))
                );
            })
        ),
    );

    getNewRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewRoleAction>(ActionTypes.GetNewRole),
            switchMap(() =>
                this.permissionsService.permissionsSearch().pipe(
                    switchMap((permissionList) => {
                        const newRole: RoleDetailsDto = {
                            name: "",
                            permissionIds: []
                        };
                        return of(new GetRoleSucceedAction(newRole, permissionList));
                    }),
                    catchError((error) => of(new GetRoleFailAction(error)))
                )
            ),
        ),
    );

    getRoleFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetRoleFailAction>(ActionTypes.GetRoleFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateRole$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateRoleAction>(ActionTypes.UpdateRole),
        switchMap(({ role }) =>
            this.globalSpinner.apply(this.rolesService.rolesPut({ id: role.id, body: role }).pipe(
                this.waitOnGetCurrentUser.bind(this),
                switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/roles"] } })))
            ))
        )
    ));

    deleteRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteRoleAction>(ActionTypes.DeleteRole),
            switchMap(({ role }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Role",
                        subtitle: "Are you sure you want to permanently delete this role?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ role, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ role }) =>
                this.globalSpinner.apply(
                    this.rolesService.rolesDelete({
                        id: role.id,
                        concurrencyToken: role.concurrencyToken,
                    }).pipe(
                        this.waitOnGetCurrentUser.bind(this),
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/roles"] } })))
                    )
                )
            )
        )
    );

    createRole$ = createEffect(() => this.actions$.pipe(
        ofType<CreateRoleAction>(ActionTypes.CreateRole),
        switchMap(({ role }) =>
            this.globalSpinner.apply(
                this.rolesService.rolesPost({ body: role }).pipe(
                    this.waitOnGetCurrentUser.bind(this),
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/roles"] } })))
                )
            )
        )
    ));

    private waitOnGetCurrentUser<T>(source: Observable<T>) {
        // AC_todo : Need to improve this:
        // Inefficient => Only needs called if role being altered is for current user
        return source.pipe(
            map(() => {
                this.store.dispatch(new GetCurrentUserAction());
                return 1;
            }),
            switchMap(() => this.actions$.pipe(
                ofType<GetCurrentUserSucceedAction>(CurrentUserActionTypes.GetCurrentUserSucceed),
                take(1))
            )
        );
    }

}
