import { Component, Inject, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { PaymentDetails } from "@merchant_api/models/payment-details";
import { PaymentMethodType } from "@merchant_api/models/payment-method-type";
import { Refund } from "@merchant_api/models/refund";
import { Transaction } from "@merchant_api/models/transaction";
import { TransactionStatus } from "@merchant_api/models/transaction-status";
import { TransactionType } from "@merchant_api/models/transaction-type";
import { Subject } from "rxjs";
import { ActionButtonKind, VituFormFieldInputComponent, VituNumberFormatCurrencyPipe } from "shared-lib";
import { ModalAction, ModalActionType } from "../modal-action";

enum RefundToggleValue {
    None,
    Custom,
    All
}

@Component({
    selector: "app-refund-payment-modal",
    templateUrl: "./refund-payment-modal.component.html",
    styleUrls: ["./refund-payment-modal.component.less"],
    providers: [
        VituNumberFormatCurrencyPipe
    ]
})
export class RefundPaymentModalComponent {

    refundForm: UntypedFormGroup;

    RefundToggleValue = RefundToggleValue;

    @ViewChild("refundAmountInputFieldComponent") refundAmountInputFieldComponent: VituFormFieldInputComponent;
    @ViewChild("serviceFeeRefundAmountInputFieldComponent") serviceFeeRefundAmountInputFieldComponent: VituFormFieldInputComponent;

    setRefundToggleValue(value: RefundToggleValue, event: any) {
        this.refundToggleValue = value;
        event.preventDefault();
    }

    setServiceFeeRefundToggleValue(value: RefundToggleValue, event: any) {
        this.serviceFeeRefundToggleValue = value;
        event.preventDefault();
    }

    get refundToggleValue() {
        return this._refundToggleValue;
    }
    set refundToggleValue(value: RefundToggleValue) {
        this._refundToggleValue = value;
        let refundAmount: string = null;
        const control = this.refundForm.get("refundAmount");
        control.markAsUntouched();
        switch (value) {
            case RefundToggleValue.None:
                {
                    refundAmount = this.currencyPipe.transform(0, "showZero");
                    break;
                }
            case RefundToggleValue.Custom:
                {
                    refundAmount = "";
                    break;
                }
            case RefundToggleValue.All:
                {
                    refundAmount = this.currencyPipe.transform(this.amountAvailable, "showZero");
                    break;
                }
        }
        if (this.refundAmountInputFieldComponent) {
            this.refundAmountInputFieldComponent.focus();
        }
        control.setValue(refundAmount);
        control.updateValueAndValidity();
    }
    _refundToggleValue: RefundToggleValue;

    get serviceFeeRefundToggleValue() {
        return this._serviceFeeRefundToggleValue;
    }
    set serviceFeeRefundToggleValue(value: RefundToggleValue) {
        this._serviceFeeRefundToggleValue = value;
        let refundAmount: string = null;
        const control = this.refundForm.get("serviceFeeRefundAmount");
        control.markAsUntouched();
        switch (value) {
            case RefundToggleValue.None:
                {
                    refundAmount = this.currencyPipe.transform(0, "showZero");
                    break;
                }
            case RefundToggleValue.Custom:
                {
                    refundAmount = "";
                    break;
                }
            case RefundToggleValue.All:
                {
                    refundAmount = this.currencyPipe.transform(this.serviceFeeAmountAvailable, "showZero");
                    break;
                }
        }
        if (this.serviceFeeRefundAmountInputFieldComponent) {
            this.serviceFeeRefundAmountInputFieldComponent.focus();
        }
        control.setValue(refundAmount);
        this.refundForm.updateValueAndValidity();
    }
    _serviceFeeRefundToggleValue: RefundToggleValue;

    get formControls() {
        return this.refundForm && this.refundForm.controls;
    }

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<RefundPaymentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            refundData: { payment: PaymentDetails; amount: number; serviceFeeAmount: number|null };
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<any>;
        },
        private currencyPipe: VituNumberFormatCurrencyPipe
    ) {
        this.refundForm = this.fb.group({
            refundAmount: [null, [this.refundAtLeastOneAmountValidator.bind(this), this.refundAmountValidator.bind(this)]],
            refundDescription: [null, []],
            serviceFeeRefundAmount:
                [null, [this.refundAtLeastOneAmountValidator.bind(this), this.serviceFeeRefundAmountValidator.bind(this)]],
        });

        this.refundToggleValue = RefundToggleValue.Custom;
        this.serviceFeeRefundToggleValue = RefundToggleValue.Custom;

        this.data.refundData.payment.transactions.forEach((transaction: Transaction) => {
            if ((transaction.status === TransactionStatus.Success) || (transaction.status === TransactionStatus.Funded)) {
                if ((transaction.type === TransactionType.Sale) || (transaction.type === TransactionType.Capture)) {
                    this.refundTransaction = transaction;
                }
            }
        });
        this.amountAvailable = data.refundData.amount;
        this.serviceFeeAmountAvailable = data.refundData.serviceFeeAmount;

        if (!(this.serviceFeeAmountAvailable > 0)) {
            this.refundForm.get("serviceFeeRefundAmount").disable();
        }

        this.isPayPal = this.refundTransaction?.paymentMethod === PaymentMethodType.PayPal;
    }

    get limitExceeded() {
        return `Limit is $${this.currencyPipe.transform(this.amountAvailable, "showZero")}`;
    }

    get serviceFeeLimitExceeded() {
        return `Limit is $${this.currencyPipe.transform(this.serviceFeeAmountAvailable, "showZero")}`;
    }

    get amountRequired() {
        return (this.serviceFeeAmountAvailable > 0) ? "At least one amount required" : "Valid amount is required";
    }

    markAsUntouched() {
        if (this.refundForm) {
            // Shows validation issues only on form Submit
            // which looks better due to cross dependence of
            // the two amount fields.
            this.refundForm.get("refundAmount")?.markAsUntouched();
            this.refundForm.get("refundAmount")?.updateValueAndValidity();
            this.refundForm.get("serviceFeeRefundAmount")?.markAsUntouched();
            this.refundForm.get("serviceFeeRefundAmount")?.updateValueAndValidity();
        }
    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    refundTransaction: Transaction;
    amountAvailable: number = null;
    serviceFeeAmountAvailable: number = null;
    isPayPal = false;

    readonly refundDescriptionMaxLength = 100;

    ActionButtonKind = ActionButtonKind;
    PaymentMethodType = PaymentMethodType;

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new ModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        if (this.refundTransaction) {
            const token = this.refundTransaction.paymentToken;
            const refund = {
                amount: 0,
                description: this.refundForm.value.refundDescription,
                serviceFeeAmount: null
            } as Refund;
            const refundAmount = parseFloat(this.refundForm.value.refundAmount);
            if (refundAmount > 0) {
                refund.amount = refundAmount;
            }
            if (this.serviceFeeAmountAvailable > 0) {
                const serviceFeeRefundAmount = parseFloat(this.refundForm.value.serviceFeeRefundAmount);
                if (serviceFeeRefundAmount > 0) {
                    refund.serviceFeeAmount = serviceFeeRefundAmount;
                }
            }
            this.data.modalActionSubject.next(new ModalAction(ModalActionType.CONFIRM, { token, refund }));
        }
    }

    private refundAtLeastOneAmountValidator(control: AbstractControl) {

        let atLeastOne = false;

        const refundAmount = this.refundForm?.get("refundAmount");
        if (refundAmount) {
            const refundAmountValue = parseFloat(refundAmount.value);
            if (refundAmountValue > 0) {
                atLeastOne = true;
            }
        }

        const serviceFeeRefundAmount = this.refundForm?.get("serviceFeeRefundAmount");
        if (serviceFeeRefundAmount) {
            const serviceFeeRefundAmountValue = parseFloat(serviceFeeRefundAmount.value);
            if (serviceFeeRefundAmountValue > 0) {
                atLeastOne = true;
            }
        }

        if (!atLeastOne) {
            return { refundAtLeastOneInvalid: { valid: false } };
        }

        return null;
    }

    private refundAmountValidator(control: AbstractControl) {
        if (parseFloat(control.value) > this.amountAvailable) {
            return { refundLimitExceeded: { valid: false } };
        }
        return null;
    }

    private serviceFeeRefundAmountValidator(control: AbstractControl) {
        if (parseFloat(control.value) > this.serviceFeeAmountAvailable) {
            return { refundLimitExceeded: { valid: false } };
        }
        return null;
    }

}
