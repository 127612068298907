<span *ngIf="kind === 'GooglePay'" class="digital-wallet-image">
    <div style="margin-top: 5px;">
        <i [class]="'fab fa-google-pay fa-2x'"></i>
    </div>
</span>
<span *ngIf="kind === 'ApplePay'" class="digital-wallet-image">
    <div style="margin-top: 5px;">
        <i [class]="'fab fa-apple-pay fa-2x'"></i>
    </div>
</span>
<span *ngIf="kind === 'PayPal'" class="digital-wallet-image">
    <div style="margin-top: 5px;">
        <i [class]="'fab fa-paypal fa-lg'"></i>
    </div>
</span>
<span *ngIf="kind === 'Venmo'" class="digital-wallet-image">
    <img src="/assets/img/venmo-icon.png" height="32px" />
</span>
<lib-text-table-column *ngIf="!kind">
</lib-text-table-column>
