export class PermissionUtils {

    static hasPermissions(current: string[], required: string[]): boolean {

        if (!required) {
            required = [];
        }

        let firstMissing = null;

        if (!current && (required.length > 0)) {
            firstMissing = required[0];
        } else {
            for (const reqItem of required) {
                if (current.indexOf(reqItem) === -1) {
                    firstMissing = reqItem;
                    break;
                }
            }
        }

        // if (firstMissing) {
        //     console.log(`Permission missing : ${firstMissing}`);
        // }

        return !firstMissing;

    }

}
