import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getRolesState = (store: IStore) => store.roles;

export const getRoles = createSelector(
    getRolesState,
    rolesState => rolesState.record,
);

export const getRolesLoading = createSelector(
    getRolesState,
    rolesState => rolesState.loading,
);

export const getRolesError = createSelector(
    getRolesState,
    rolesState => rolesState.error,
);
