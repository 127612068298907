<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'adjustmentDate'">
        <lib-date-time-label [dateUtc]="element.adjustmentDate" [rawDateWithNoTime]="true"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'amount'">
        <lib-text-table-column>
            {{ element?.amount | vituCurrencyUsd }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.amount ?? 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'createdUtc'">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'reason'">
        <lib-text-table-column>
            {{ element?.reason | spaceBetweenCaps }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'type'">
        <lib-text-table-column>
            {{ element?.type | spaceBetweenCaps }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
