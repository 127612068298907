// Uses RFC 5322 Email Validation standard (see emailregex.com) with CASE-INSENSITIVITY applied.
// Disable lint security rule => Angular has template escaping & APIs should be sanitized.
// eslint-disable-next-line no-control-regex, max-len, security/detect-unsafe-regex
export const EmailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const EmailInvalid = "Requires valid email address";

// Enable a subset of allowed Rapid Connect range (^([0-9]{1,10})$)
export const TelephoneRegex = /^([2-9]{1}[0-9]{9})$/;
export const TelephoneInvalid = "Requires valid US phone number";

// TODO : Can we remove this?  Only needed to perform integrity checking on telephone number fields, because now they
// are restricted to 10 numeric digits and have a US telephone mask applied.  This 'imask' would otherwise clip legacy
// telephone numbers which were created with more than 10 digits and cause problems as user would think field was valid,
// while marked invalid. Better solution is to remove the need for this client-side fix by instead performing a database
// clean to reset any fields for telephone numbers which are more than 10 digits.
export const TelephoneMaxLength = 10;

// eslint-disable-next-line security/detect-unsafe-regex
export const ZipRegex = /^([0-9]{5}(-[0-9]{4})?)$/;
export const ZipInvalid = "Requires valid US ZIP code";

// eslint-disable-next-line security/detect-unsafe-regex
export const DomainNameRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
export const DomainNameInvalid = "Requires valid domain name";
