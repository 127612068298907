import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VituFormFieldSwitchComponent } from "./vitu-form-field-switch.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TextCommonModule } from "../../../text";
import { VituSlideToggleModule } from "../../../vitu-slide-toggle";

@NgModule({
    declarations: [
        VituFormFieldSwitchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        VituSlideToggleModule,
        MatCheckboxModule,
        TextCommonModule
    ],
    exports: [
        VituFormFieldSwitchComponent
    ]
})
export class VituFormFieldSwitchModule { }
