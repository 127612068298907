import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LoggerService {

    private readonly messagePrefix = "(VituPay)";

    constructor() {}

    error(...args: any[]) {
        // Can override (eg. different for different environments)
        console.error(...[this.messagePrefix, ...args]);
    }

    log(...args: any[]) {
        // Can override (eg. different for different environments)
        console.log(...[this.messagePrefix, ...args]);
    }

    warn(...args: any[]) {
        // Can override (eg. different for different environments)
        console.warn(...[this.messagePrefix, ...args]);
    }

}
