import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { getCurrentUser } from "@merchant_app/storage/current-user/current-user.selectors";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { IStore } from "@merchant_app/storage/store";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DynamicDefaultRouteGuard implements CanActivate {

    private currentUser: CurrentUserDto = null;

    currentUser$ = this.store.pipe(
        select(getCurrentUser)
    );

    constructor(private store: Store<IStore>, private router: Router) {
        this.currentUser$.subscribe(currentUser => {
            this.currentUser = currentUser;
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Assumes that currentUser is already loaded!
        return (this.currentUser ? this.router.createUrlTree([this.currentUser.defaultRoute]) : false);
    }

}
