import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getFundingState = (store: IStore) => store.funding;

export const getFunding = createSelector(
    getFundingState,
    fundingState => fundingState.record
);

export const getFundingLoading = createSelector(
    getFundingState,
    fundingState => fundingState.loading
);

export const getFundingError = createSelector(
    getFundingState,
    fundingState => fundingState.error
);

export const getFundingSorting = createSelector(
    getFundingState,
    fundingState => fundingState.sorting
);

export const getFundingPager = createSelector(
    getFundingState,
    fundingState => fundingState.pager
);

export const getFundingFilter = createSelector(
    getFundingState,
    fundingState => fundingState.filter
);
