import { Component } from "@angular/core";
import { IStore } from "@merchant_app/storage/store";
import { Store } from "@ngrx/store";
import * as ClientsSelectors from "@merchant_app/storage/clients/clients.selectors";
import { getCurrentUserPermissions } from "@merchant_app/storage/current-user/current-user.selectors";

@Component({
    selector: "app-clients",
    templateUrl: "./clients.component.html",
    styleUrls: [ "./clients.component.less" ]
})
export class ClientsComponent {

    clients$ = this.store.select(ClientsSelectors.getClients);
    loading$ = this.store.select(ClientsSelectors.getClientsLoading);
    error$ = this.store.select(ClientsSelectors.getClientsError);

    constructor(private store: Store<IStore>) {}

}
