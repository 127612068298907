/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientSecretDto } from '../models/client-secret-dto';
import { ClientSecretLookupsDto } from '../models/client-secret-lookups-dto';
import { CreateClientSecretDto } from '../models/create-client-secret-dto';


/**
 * API to manage client secrets.
 */
@Injectable({
  providedIn: 'root',
})
export class ClientSecretsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientSecretsSearch
   */
  static readonly ClientSecretsSearchPath = '/v1/client-secrets';

  /**
   * Permissions: core.clients.search-secret
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSecretsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsSearch$Response(params?: {
    ClientId?: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<ClientSecretDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSecretsService.ClientSecretsSearchPath, 'get');
    if (params) {
      rb.query('ClientId', params.ClientId, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSecretDto>>;
      })
    );
  }

  /**
   * Permissions: core.clients.search-secret
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSecretsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsSearch(params?: {
    ClientId?: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<ClientSecretDto>> {

    return this.clientSecretsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientSecretDto>>) => r.body as Array<ClientSecretDto>)
    );
  }

  /**
   * Path part for operation clientSecretsPost
   */
  static readonly ClientSecretsPostPath = '/v1/client-secrets';

  /**
   * Permissions: core.clients.create-secret
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSecretsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientSecretsPost$Response(params: {
    body: CreateClientSecretDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSecretsService.ClientSecretsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.clients.create-secret
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSecretsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientSecretsPost(params: {
    body: CreateClientSecretDto
  }): Observable<void> {

    return this.clientSecretsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation clientSecretsLookups
   */
  static readonly ClientSecretsLookupsPath = '/v1/client-secrets/lookups';

  /**
   * Permissions: core.clients.secret-lookups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSecretsLookups()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsLookups$Response(params?: {
  }): Observable<StrictHttpResponse<ClientSecretLookupsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSecretsService.ClientSecretsLookupsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSecretLookupsDto>;
      })
    );
  }

  /**
   * Permissions: core.clients.secret-lookups
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSecretsLookups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsLookups(params?: {
  }): Observable<ClientSecretLookupsDto> {

    return this.clientSecretsLookups$Response(params).pipe(
      map((r: StrictHttpResponse<ClientSecretLookupsDto>) => r.body as ClientSecretLookupsDto)
    );
  }

  /**
   * Path part for operation clientSecretsDelete
   */
  static readonly ClientSecretsDeletePath = '/v1/client-secrets/{id}';

  /**
   * Permissions: core.clients.delete-secret
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSecretsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSecretsService.ClientSecretsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.clients.delete-secret
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSecretsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientSecretsDelete(params: {
    id: number;
  }): Observable<void> {

    return this.clientSecretsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
