import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActionButtonKind } from "../../../action-button/action-button.component";

@Component({
    selector: "lib-vitu-form-abort-button",
    templateUrl: "./vitu-form-abort-button.component.html"
})
export class VituFormAbortButtonComponent implements OnInit, OnDestroy {

    @Input() isModal = false;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onAbort = new EventEmitter<void>();

    ActionButtonKind = ActionButtonKind;

    label: string;

    constructor(private hostElementRef: ElementRef) {}

    ngOnInit() {
        this.label = this.isModal ? "Cancel" : "Back";

        // use addEventListener as need to use capture mode
        // also listening on 'mousedown' (rather than 'click') works better for modals
        // as it will dismiss the modal before showing any other blur invalidated fields
        // which are present on the modal.
        this.hostElementRef.nativeElement.addEventListener("mousedown", this.handleAbort, true);
    }

    ngOnDestroy() {
        this.hostElementRef.nativeElement.removeEventListener("mousedown", this.handleAbort, true);
    }

    private handleAbort = (event: MouseEvent) => {
        event.preventDefault(); // extra safety
        this.onAbort.emit();
    };

}
