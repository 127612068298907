import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { forkJoin, of, Subject } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { NavigateAction, PageLoadFailAction } from "../router/router.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    LocalTimePoint, VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import {
    ActionTypes,
    AddClientSecretAction,
    CreateClientAction,
    DeleteClientAction,
    DeleteClientSecretAction,
    GetClientAction,
    GetClientFailAction,
    GetClientSucceedAction,
    GetNewClientAction,
    UpdateClientAction
} from "./client.actions";
import { GlobalSpinnerService } from "shared-lib";
import { ClientsService } from "@merchant_api/services/clients.service";
import { RolesService } from "@merchant_api/services/roles.service";
import { ClientSecretsService } from "@merchant_api/services/client-secrets.service";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getClientState } from "./client.selectors";
import { GetClientSecretsAction } from "../client-secrets/client-secrets.actions";
import { ClientDetailsDto } from "@merchant_api/models/client-details-dto";
import { CreateClientSecretModalComponent } from "@merchant_app/shared/create-client-secret-modal/create-client-secret-modal.component";
import { CreateClientSecretModalAction } from "@merchant_app/shared/create-client-secret-modal/create-client-secret-modal-action";
import { ModalActionType } from "@merchant_app/shared/modal-action";

@Injectable()
export class ClientEffects {

    constructor(
        private actions$: Actions,
        private clientsService: ClientsService,
        private clientSecretService: ClientSecretsService,
        private rolesService: RolesService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetClientAction>(ActionTypes.GetClient),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetClientFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return forkJoin({
                    client: this.clientsService.clientsGet({ id: action.id }),
                    roles: this.rolesService.rolesSearch(),
                    lookups: this.clientSecretService.clientSecretsLookups()
                }).pipe(
                    mergeMap(({ client, roles, lookups }) =>
                        of(new GetClientSucceedAction(client, roles, lookups))),
                    catchError((error) => of(new GetClientFailAction(error)))
                );
            })
        ),
    );

    getNewClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewClientAction>(ActionTypes.GetNewClient),
            switchMap(() =>
                forkJoin({
                    roles: this.rolesService.rolesSearch(),
                    lookups: this.clientSecretService.clientSecretsLookups()
                }).pipe(
                    mergeMap(({ roles, lookups }) =>
                        {
                            const client: ClientDetailsDto = {
                                name: "",
                                roleIds: []
                            };
                            return of(new GetClientSucceedAction(client, roles, lookups));
                        }
                    ),
                    catchError((error) => of(new GetClientFailAction(error)))
                )
            )
        )
    );

    getClientFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetClientFailAction>(ActionTypes.GetClientFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateClient$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateClientAction>(ActionTypes.UpdateClient),
        switchMap(({ id, client }) =>
            this.globalSpinner.apply(
                this.clientsService.clientsPut({ id, body: client }).pipe(
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/developer/clients"] } })))
                )
            )
        )
    ));

    deleteClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteClientAction>(ActionTypes.DeleteClient),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Client",
                        subtitle: "Are you sure you want to permanently delete this client?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id }) =>
                this.globalSpinner.apply(
                    this.clientsService.clientsDelete({ id }).pipe(
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/developer/clients"] } })))
                    )
                )
            )
        )
    );

    createClient$ = createEffect(() => this.actions$.pipe(
        ofType<CreateClientAction>(ActionTypes.CreateClient),
        switchMap(({ client }) =>
            this.globalSpinner.apply(
                this.clientsService.clientsPost({ body: client }).pipe(
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/developer/clients"] } })))
                )
            )
        )
    ));

    addClientSecret$ = createEffect((): any => this.actions$.pipe(
        ofType<AddClientSecretAction>(ActionTypes.AddClientSecret),
        withLatestFrom(this.store.select(getClientState)),
        switchMap(([_, state]) => {

            const errorMessageSubject = new Subject<string>();
            const modalActionSubject = new Subject<CreateClientSecretModalAction>();

            const dialogRef = this.dialog
                .open(CreateClientSecretModalComponent, {
                    width: "500px",
                    data: {
                        lookups: state.lookups,
                        client: state.client,
                        modalActionSubject,
                        errorMessageSubject
                    },
                    disableClose: true,
                    autoFocus: false
                });
            return modalActionSubject.pipe(
                switchMap(({action, params}: CreateClientSecretModalAction) => {
                    switch (action) {
                        case ModalActionType.CANCEL:
                        {
                            dialogRef.close();
                            return of();
                        }
                        case ModalActionType.CONFIRM:
                        {
                            const body: any = { ...params };
                            body.expirationUtc =
                                LocalTimePoint.convertLocalValueToUtcValue(body.localExpirationDate?.to);
                            delete body.localExpirationDate;
                            return this.globalSpinner.apply(this.clientSecretService.clientSecretsPost({ body })
                                .pipe(
                                    switchMap(() => {
                                        dialogRef.close();
                                        return of(new GetClientSecretsAction(state.client.id));
                                    }),
                                    catchError(error => {
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of();
                                    })
                                )
                            );
                        }
                    }
                })
            );
        })
    ));

    deleteClientSecret$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteClientSecretAction>(ActionTypes.DeleteClientSecret),

            switchMap(({ secretId }) => this.dialog.open(ConfirmationModalComponent, {
                data: {
                    title: "Delete Secret",
                    subtitle: "Are you sure you want to permanently delete this client secret?",
                    confirmButtonText: "Delete"
                } as ConfirmationModalData
            }).afterClosed().pipe(
                map((deleteConfirmed: boolean) => ({ secretId, deleteConfirmed })),
            )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ secretId }) =>
                this.globalSpinner.apply(
                    this.clientSecretService.clientSecretsDelete({
                        id: secretId,
                    }).pipe(
                        withLatestFrom(this.store.select(getClientState)),
                        map(([, state]) => new GetClientSecretsAction(state.client.id))
                    )
                )
            )
        ),
    );

}
