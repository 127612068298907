import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusChipModule } from "../status-chip";
import { FundingItemStatusChipComponent } from "./funding-item-status-chip.component";

@NgModule({
    declarations: [
        FundingItemStatusChipComponent
    ],
    imports: [
        CommonModule,
        StatusChipModule
    ],
    exports: [
        FundingItemStatusChipComponent
    ]
})
export class FundingItemStatusChipModule { }
