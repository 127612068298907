import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VituNumberFormatCurrencyPipe } from "./vitu-number-format-currency.pipe";
import { VituNumberFormatCurrencyUsdPipe } from "./vitu-number-format-currency-usd.pipe";
import { VituNumberFormatPercentagePipe } from "./vitu-number-format-percentage.pipe";

@NgModule({
    declarations: [
        VituNumberFormatCurrencyPipe,
        VituNumberFormatCurrencyUsdPipe,
        VituNumberFormatPercentagePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        VituNumberFormatCurrencyPipe,
        VituNumberFormatCurrencyUsdPipe,
        VituNumberFormatPercentagePipe
    ]
})
export class VituNumberFormatModule { }
