<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'id'">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'webhook'">
        <lib-text-table-column horizAlign="right">
            {{ element.webhookUrl }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'solution'">
        <lib-text-table-column>
            {{ element.webhookSolutionName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            class="event-delivery-statuses"
            [status]="element.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="'createdUtc'">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'resend'">
        <lib-vitu-tooltip text="Resend">
            <i class="far fa-redo global-table-cell-image-link" (click)="onClickResend()"></i>
        </lib-vitu-tooltip>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
