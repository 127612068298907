import { Injectable } from "@angular/core";
import {
    NgbDateParserFormatter,
    NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { DateTimeFormatUtils } from "./date-time-format-utils";

@Injectable({
    providedIn: "root"
})
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split("/");
            if (dateParts?.length === 1 && Number(dateParts[0])) {
                return { month: parseInt(dateParts[0], 10), day: null, year: null };
            } else if (
                dateParts?.length === 2 &&
                Number(dateParts[0]) &&
                Number(dateParts[1])
            ) {
                return {
                    month: parseInt(dateParts[0], 10),
                    day: parseInt(dateParts[1], 10),
                    year: null
                };
            } else if (
                dateParts?.length === 3 &&
                Number(dateParts[0]) &&
                Number(dateParts[1]) &&
                Number(dateParts[2])
            ) {
                return {
                    month: parseInt(dateParts[0], 10),
                    day: parseInt(dateParts[1], 10),
                    year: parseInt(dateParts[2], 10)
                };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        if (date) {
            return DateTimeFormatUtils.formatDateShort2(date.day, date.month, date.year);
        }
        return null;
    }
}
