import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VituTooltipModule } from "../tooltip";
import { UserStatusIconComponent } from "./user-status-icon.component";

@NgModule({
    declarations: [
        UserStatusIconComponent
    ],
    imports: [
        CommonModule,
        VituTooltipModule
    ],
    exports: [
        UserStatusIconComponent
    ]
})
export class UserStatusIconModule { }
