import { Action } from "@ngrx/store";
import { TransactionDetails } from "@merchant_api/models/transaction-details";

export enum ActionTypes {
    GetTransactionSiblingsDetail = "[Transaction Siblings] GetTransactionSiblingsDetail",
    GetTransactionSiblingsDetailSucceed = "[Transaction Siblings] GetTransactionSiblingsDetailSucceed",
    GetTransactionSiblingsDetailFail = "[Transaction Siblings] GetTransactionSiblingsDetailFail"
}

export class GetTransactionSiblingsDetailAction implements Action {

    constructor(public tokens: string[]) { }

    readonly type = ActionTypes.GetTransactionSiblingsDetail;
}

export class GetTransactionSiblingsDetailSucceedAction implements Action {

    constructor(public record: TransactionDetails[]) { }

    readonly type = ActionTypes.GetTransactionSiblingsDetailSucceed;
}

export class GetTransactionSiblingsDetailFailAction implements Action {

    constructor(public error: any) { }

    readonly type = ActionTypes.GetTransactionSiblingsDetailFail;
}
