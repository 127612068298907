<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Developer Tools' }, { 'label': 'Clients' , 'link': '/dashboard/developer/clients' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">
        <ng-container *ngIf="!client then spinner"></ng-container>
        <ng-container *ngIf="client then details"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #details>

    <form class="form" [formGroup]="clientForm" [libVituForm]="clientForm" (libVituFormSubmit)="onSubmit()">

        <div class="form-inner-container">

            <lib-vitu-form-field-input
                class="field"
                [formGroup]="clientForm"
                formFieldName="name"
                label="Name"
                [disabled]="!isCreate && !hasUpdateClientPermission">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                [formGroup]="clientForm"
                formFieldName="roleIds"
                multiple
                label="Role(s)"
                [options]="groupedRoleList"
                optionLabelKey="name"
                optionValueKey="id"
                [groups]="roleGroups"
                [disabled]="!isCreate && !hasUpdateClientPermission">
            </lib-vitu-form-field-select>

            <div class="actions-line" *ngIf="!isCreate">

                <div class="action-buttons">

                    <lib-action-button
                        *ngIf="hasDeleteClientPermission"
                        id="deleteClientButton"
                        class="action-button"
                        label="Delete Client"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="loading || !client"
                        (click)="onClickDeleteClient()">
                    </lib-action-button>

                    <lib-action-button
                        *ngIf="hasCreateSecretPermission"
                        id="createSecretButton"
                        class="action-button"
                        label="Create Secret"
                        [kind]="ActionButtonKind.SECONDARY"
                        fontImage="far fa-plus-circle fa-bg"
                        (click)="onClickCreateSecret()">
                    </lib-action-button>
        
                </div>

            </div>

            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onClickBack()">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    *ngIf="isCreate || hasUpdateClientPermission"
                    [label]="submitButtonText"
                    [neverDisable]="isCreate">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

            <div class="tabs-container">

                <mat-tab-group *ngIf="!isCreate" class="tab-group" [selectedIndex]="0">
        
                    <mat-tab [label]="getSecretsTabLabel()">
        
                        <lib-table-base
                            class="tab-container"
                            [columnDefs]="secretsColumnDefs"
                            [rowsData]="secrets ? secrets : []"
                            [rowsDataLoading]="secretsLoading"
                            [rowsDataError]="secretsError"
                            rowsDataErrorMessage="Error Loading Secrets"
                            [rowsDataShowWhileLoading]="true"
                            rowsDataEmptyMessage="No Secrets"
                            selectedRowProperty="id"
                            (rowSelected)="onSecretRowSelected($event)">
                
                            <ng-template let-element="element" let-columnId="columnId">
                                <app-client-secrets-table-cell-content
                                    [columnId]="columnId"
                                    [element]="element"
                                    (deleteClientSecret)="onDeleteClientSecret($event)">
                                </app-client-secrets-table-cell-content>
                            </ng-template>
                        </lib-table-base>
        
                    </mat-tab>
        
                </mat-tab-group>
        
            </div>

        </div>

    </form>

</ng-template>
