import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-busy-indicator",
    templateUrl: "./busy-indicator.component.html",
    styleUrls: ["./busy-indicator.component.less"],
})
export class BusyIndicatorComponent {

    @Input() alwaysInFront = false;

    dimBehind = true;
    maxCircleDiameterInPixels = 30;
    horizontalGapBetweenCirclesInPixels = this.maxCircleDiameterInPixels / 2;

}
