import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WebhookDto } from "@merchant_api/models/webhook-dto";

@Component({
    selector: "app-webhooks-table-cell-content",
    templateUrl: "./webhooks-table-cell-content.component.html",
    styleUrls: ["./webhooks-table-cell-content.component.less"],
})
export class WebhooksTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: WebhookDto;
    @Input() toggleWebhookLoading: boolean;
    @Output() activeToggled = new EventEmitter<{webhook: WebhookDto; undo: () => void}>();

    isActiveToggled(checked) {
        this.element.isActive = checked;

        const undo = () => {
            this.element.isActive = !this.element.isActive;
        };

        const updatedWebhook: WebhookDto = {
            ...this.element,
            isActive: checked
        };

        this.activeToggled.emit({
            webhook: updatedWebhook,
            undo
        });
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

}
