import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GroupedSwitchArrayComponent } from "./grouped-switch-array.component";
import { ActionButtonModule } from "../action-button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MultiStageExpansionPanelModule } from "../multi-stage-expansion-panel";

@NgModule({
    declarations: [
        GroupedSwitchArrayComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        ActionButtonModule,
        MultiStageExpansionPanelModule
    ],
    exports: [
        GroupedSwitchArrayComponent
    ]
})
export class GroupedSwitchArrayModule { }
