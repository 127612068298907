/* tslint:disable */
/* eslint-disable */
export enum TransactionType {
  Authorize = 'Authorize',
  Capture = 'Capture',
  PreAuthorize = 'PreAuthorize',
  Refund = 'Refund',
  Sale = 'Sale',
  Void = 'Void'
}
