import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardSidebarMenuItemComponent } from "./dashboard-sidebar-menu-item.component";
import { MatRippleModule } from "@angular/material/core";
import { TextModule } from "../../text";
import { VituTooltipModule } from "../../tooltip";

@NgModule({
    declarations: [
        DashboardSidebarMenuItemComponent
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        TextModule,
        VituTooltipModule
    ],
    exports: [
        DashboardSidebarMenuItemComponent
    ]
})
export class DashboardSidebarMenuItemModule { }
