import { DisputesState } from "./disputes.state";
import { ActionTypes } from "./disputes.actions";

export const DisputesReducer = (
    state: DisputesState = new DisputesState(),
    action: any,
  ): DisputesState => {

    switch (action.type) {

        case ActionTypes.GetDisputes: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetDisputesSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetDisputesFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        case ActionTypes.SortDisputes: {
            return {
                ...state,
                loading: true,
                sorting: action.sorting
            };
        }

        case ActionTypes.FilterDisputes: {
            return {
                ...state,
                loading: true,
                filter: action.filter
            };
        }

        default: {
            return state;
        }
    }

};
