import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getDisputeState = (store: IStore) => store.dispute;

export const getDispute = createSelector(
    getDisputeState,
    disputeState => disputeState.record,
);

export const getDisputeLoading = createSelector(
    getDisputeState,
    disputeState => disputeState.loading,
);
