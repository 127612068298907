<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Disputes' , 'link': '/dashboard/disputes' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="!dispute then spinner"></ng-container>
        <ng-container *ngIf="dispute then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <div class="details-common-container">

        <lib-text-field [label]="'Date Created (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="dispute.raiseDateUtc" [singleLine]="true" [dateUtc]="dispute.raiseDateUtc"></lib-date-time-label>
        </lib-text-field>

        <lib-text-field label="Case">
            {{ dispute.caseNumber }}
        </lib-text-field>

        <lib-text-field label="Type">
            {{ dispute.disputeType }}
        </lib-text-field>

        <lib-text-field label="Processor">
            {{ dispute.processor }}
        </lib-text-field>

        <lib-text-field label="MID">
            {{ dispute.mid }}
        </lib-text-field>

        <lib-text-field label="Reason Type">
            {{ dispute.reasonCodeDescription }}
        </lib-text-field>

        <lib-text-field label="Amount">
            {{ dispute.disputeAmount | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Status">
            {{ dispute.statusDescription }}
        </lib-text-field>

        <lib-text-field [label]="'Date Updated (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="dispute.statusDateUtc" [singleLine]="true" [dateUtc]="dispute.statusDateUtc"></lib-date-time-label>
        </lib-text-field>

        <lib-text-field label="Outcome">
            {{ dispute.disputeOutcome }}
        </lib-text-field>

        <lib-text-field [label]="'Date Due (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="dispute.dueDateUtc" [singleLine]="true" [dateUtc]="dispute.dueDateUtc"></lib-date-time-label>
        </lib-text-field>

        <lib-text-field label="Fees">
            {{ dispute.fees | vituCurrencyUsd }}
        </lib-text-field>

        <lib-text-field label="Payment Token">
            {{ dispute.paymentToken }}
        </lib-text-field>

        <lib-text-field label="Transaction ID">
            {{ dispute.publicTransactionId }}
        </lib-text-field>

        <lib-text-field label="Token">
            {{ dispute.token }}
        </lib-text-field>

    </div>

    <div class="form-buttons-line">
        <lib-action-button
            [kind]="ActionButtonKind.STROKED"
            largeButton
            label="Back"
            (click)="onCancel()">
        </lib-action-button>
    </div>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
