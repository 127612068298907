import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { WebhookEventTypesDto } from "@merchant_api/models/webhook-event-types-dto";
import { Filter } from "@admin_app/storage/events/events.state";

@Component({
    selector: "app-event-history-log-filters",
    templateUrl: "./event-history-log-filters.component.html",
    styleUrls: ["./event-history-log-filters.component.less"]
})
export class EventHistoryLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;
    @Input() webhookLookups: WebhookEventTypesDto;

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {}

    filterForm = this.fb.group({
        localDateInterval: [null],
        eventTypes: [null],
        mid: [null],
        tid: [null],
        fundingInstructionRef: [null],
        transactionId: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

}
