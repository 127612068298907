import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionTypes, GetTidsAction, GetTidsFailAction, GetTidsSucceedAction, PageChangedTidsAction } from "./tids.actions";
import { of } from "rxjs";
import { MerchantService } from "@merchant_api/services/merchant.service";
import { getTidsState } from "./tids.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { PagedDataDtoOfTidResult } from "@merchant_api/models/paged-data-dto-of-tid-result";

@Injectable()
export class TidsEffects {

    constructor(
        private actions$: Actions,
        private merchantService: MerchantService,
        private store: Store<IStore>
    ) {}

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedTidsAction>(ActionTypes.PageChangedTids),
            switchMap(action => of(new GetTidsAction(action.page)))
        ),
    );

    getTids = createEffect(() =>
        this.actions$.pipe(
            ofType<GetTidsAction>(ActionTypes.GetTids),
            withLatestFrom(this.store.select(getTidsState)),
            switchMap(([action, state]) => {

                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(pageSize, action.page);

                return this.merchantService.merchantGetTids(params).pipe(
                    switchMap((response) =>
                        of(new GetTidsSucceedAction(
                            response,
                            pageSize,
                            action.page)
                        )
                    ),
                    catchError((error) => of(new GetTidsFailAction(error)))
                );
            })
        ),
    );

    private getParams(pageSize: number, page: number): any {

        return {
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
