import { Component, Input } from "@angular/core";
import { CloverPosConfig } from "@merchant_api/models/clover-pos-config";
import { TidResult } from "@merchant_api/models/tid-result";

@Component({
    selector: "app-configurations-table-cell-content",
    templateUrl: "./configurations-table-cell-content.component.html",
    styleUrls: ["./configurations-table-cell-content.component.less"],
})
export class ConfigurationsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: TidResult;

    get cloverPosConfig(): null | CloverPosConfig {

        if (this.element?.config?.$type === "CloverPos") {
            return this.element?.config;
        }

        return null;
    }

}
