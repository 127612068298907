<div class="outer-container">

    <div [formGroup]="formGroup">
        <mat-form-field class="form-field" [ngClass]="{'global-form-field-highlight-non-empty': _isFilter}">

            <mat-label>
                <span>
                    {{ labelEmptyCondition ? labelEmpty : label }}
                </span>
                <lib-vitu-tooltip
                    *ngIf="!!infoTip?.length"
                    class="info-tip"
                    [text]="infoTip">
                        <i class="far fa-info-circle"></i>            
                </lib-vitu-tooltip>
            </mat-label>

            <mat-select [formControlName]="formFieldName" [formControl]="formControl"
                [multiple]="_multiple" [required]="hasRequiredValidator" [disableControl]="_disabled"
                [panelClass]="panelClass"
                disableOptionCentering ngDefaultControl
                (selectionChange)="onSelectionChanged($event)"
                (focus)="updateOutlineGap()"
                [sortComparator]="sortComparator">

                <ng-container *ngIf="_groups?.length > 0; else noGroups">

                    <ng-container *ngFor="let group of _groups; let groupIdx = index">

                        <mat-optgroup *ngIf="group.name?.length > 0; else groupNoLabel" [label]="group.name">

                            <mat-option *ngFor="let option of optionsForGroup(group); let idx = index" [value]="optionValue(option, idx, groupIdx)">
                                {{ spaceBetweenCapsOnLabels ? (optionLabel(option, idx, groupIdx) | spaceBetweenCaps) : optionLabel(option, idx, groupIdx) }}
                            </mat-option>

                        </mat-optgroup>

                        <ng-template #groupNoLabel>

                            <mat-option *ngFor="let option of optionsForGroup(group); let idx = index" [value]="optionValue(option, idx, groupIdx)">
                                {{ spaceBetweenCapsOnLabels ? (optionLabel(option, idx, groupIdx) | spaceBetweenCaps) : optionLabel(option, idx, groupIdx) }}
                            </mat-option>

                        </ng-template>

                    </ng-container>

                </ng-container>

                <ng-template #noGroups>

                    <mat-option *ngFor="let option of options; let idx = index" [value]="optionValue(option, idx)">
                        {{ spaceBetweenCapsOnLabels ? (optionLabel(option, idx) | spaceBetweenCaps) : optionLabel(option, idx) }}
                    </mat-option>

                </ng-template>

            </mat-select>

            <button *ngIf="showReset"
                mat-button matSuffix mat-icon-button
                libStopPropagation
                aria-label="Clear" (click)="handleReset.emit()">
                <mat-icon>close</mat-icon>
            </button>

            <mat-error *ngFor="let item of errors | keyvalue">{{ resolveError(errors, item.key) }}</mat-error>

        </mat-form-field>

    </div>

    <div *ngIf="labelEmptyCondition" class="empty-field-overlay">
        <lib-vitu-input
            [label]="labelOverlay"
            [value]="labelEmpty"
            [readonly]="true">
        </lib-vitu-input>    
    </div>

</div>
