import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PermissionUtils } from "../../misc";
import { SidebarItem } from "../sidebar-item";
import { isEqual } from "lodash";

@Component({
    selector: "lib-dashboard-submenu",
    templateUrl: "./dashboard-submenu.component.html",
    styleUrls: ["./dashboard-submenu.component.less"],
})
export class DashboardSubmenuComponent {

    @Input() items: Array<SidebarItem>;
    @Input() menuVertPosInPixels: number;
    @Input() menuShown = false;
    @Input() currentUserPermissions: any[] = [];

    @Output() showMenu = new EventEmitter<void>();
    @Output() hideMenu = new EventEmitter<void>();
    @Output() navigateTo = new EventEmitter<any>();

    subMenuParentItem: SidebarItem = null;
    subMenuVertPosInPixels: number;
    subMenuShown = false;
    private hideSubMenuTimer: any;

    hasPermissions(currentPermissions: string[], item: SidebarItem): boolean {
        let retVal: boolean;
        if (item.subItems?.length > 0) {
            retVal = false;
            for (const subItem of item.subItems) {
                if (PermissionUtils.hasPermissions(currentPermissions, subItem.permissions)) {
                    retVal = true;
                    break;
                }
            }
        }
        else {
            retVal = PermissionUtils.hasPermissions(currentPermissions, item.permissions);
        }
        return retVal;
    }

    onMouseOverItem(menuItem: SidebarItem, itemDiv: HTMLDivElement): void {
        this.subMenuParentItem = menuItem;
        if (this.subMenuParentItem?.subItems) {
            this.subMenuVertPosInPixels = itemDiv.offsetTop;
            this.showSubMenu();
        }
    }

    onMouseOutItem(): void {
        this.triggerHideSubMenu();
    }

    showSubMenu() {
        clearTimeout(this.hideSubMenuTimer);
        this.subMenuShown = true;
    }

    triggerHideSubMenu() {
        clearTimeout(this.hideSubMenuTimer);
        this.hideSubMenuTimer = setTimeout(() => {
            this.hideSubMenu();
        }, 200);
    }

    onShowSubMenu() {
        this.showSubMenu();
    }

    onHideSubMenu() {
        this.triggerHideSubMenu();
    }

    isItemSubMenuActive(item: SidebarItem) {
        const subMenuShownForItem = this.subMenuShown ? this.subMenuParentItem : null;
        return isEqual(subMenuShownForItem, item);
    }

    private hideSubMenu() {
        this.subMenuShown = false;
    }

}
