import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VituDateComponent } from "./vitu-date.component";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { DateInputsModule } from "../date-time";
import { VituInputModule } from "../vitu-input";

@NgModule({
    declarations: [
        VituDateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        DateInputsModule,
        MatInputModule,
        MatButtonModule,
        MatRippleModule,
        VituInputModule,
    ],
    exports: [
        VituDateComponent
    ]
})
export class VituDateModule { }
