<app-role-details
    [isCreate]="isCreate"
    [role]="role$ | async"
    [permissionList]="permissionList$ | async"
    [loading]="loading$ | async"
    (deleteRole)="deleteRole($event)"
    (updateRole)="updateRole($event)"
    (createRole)="createRole($event)"
    (back)="onBack()">
</app-role-details>
