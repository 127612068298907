import { FundingItemState } from "./funding-item.state";
import { ActionTypes } from "./funding-item.actions";

export const FundingItemReducer = (
    state: FundingItemState = new FundingItemState(),
    action: any,
  ): FundingItemState => {

    switch (action.type) {

        case ActionTypes.ResetFundingItem: {
            return new FundingItemState();
        }

        case ActionTypes.GetFundingItem: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetFundingItemSucceed: {
            return {
                ...state,
                record: action.fundingItem,
                error: null,
                loading: false
            };
        }

        case ActionTypes.GetFundingItemFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        case ActionTypes.GetFundingItemTransactions: {
            return {
                ...state,
                transactionsLoading: true
            };
        }

        case ActionTypes.GetFundingItemTransactionsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            const retVal = {
                ...state,
                transactions: action.response.items,
                transactionsPager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                transactionsLoading: false,
                ...action.stateExtensions
            };
            return retVal;
        }

        case ActionTypes.GetFundingItemTransactionsFail: {
            return {
                ...state,
                transactions: [],
                transactionsLoading: false,
                transactionsError: action.error
            };
        }

        case ActionTypes.SortFundingItemTransactions: {
            return {
                ...state,
                transactionsLoading: true,
                transactionsSorting: action.sorting
            };
        }

        case ActionTypes.FilterFundingItemTransactions: {
            return {
                ...state,
                transactionsLoading: true,
                transactionsFilter: action.filter
            };
        }

        case ActionTypes.ExportTransactionsToCsv: {
            return {
                ...state,
                exportLoading: true
            };
        }

        case ActionTypes.ExportTransactionsToCsvSucceed: {
            return {
                ...state,
                ...action.resource,
                exportLoading: false,
                exportError: null
            };
        }

        case ActionTypes.ExportTransactionsToCsvFail: {
            return {
                ...state,
                exportLoading: false,
                exportError: action.error
            };
        }

        case ActionTypes.GetFundingItemMidFees: {
            return {
                ...state,
                midFeesLoading: true
            };
        }

        case ActionTypes.GetFundingItemMidFeesSucceed: {
            return {
                ...state,
                midFees: action.response,
                midFeesError: null,
                midFeesLoading: false
            };
        }

        case ActionTypes.GetFundingItemMidFeesFail: {
            return {
                ...state,
                midFees: null,
                midFeesError: action.error,
                midFeesLoading: false
            };
        }

        case ActionTypes.GetFundingItemAdjustments: {
            return {
                ...state,
                adjustmentsLoading: true
            };
        }

        case ActionTypes.GetFundingItemAdjustmentsSucceed: {
            return {
                ...state,
                adjustments: action.response,
                adjustmentsError: null,
                adjustmentsLoading: false
            };
        }

        case ActionTypes.GetFundingItemAdjustmentsFail: {
            return {
                ...state,
                adjustments: null,
                adjustmentsError: action.error,
                adjustmentsLoading: false
            };
        }

        default: {
            return state;
        }
    }

};
