/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccrueCustomFeesRequest } from '../models/accrue-custom-fees-request';
import { ApplePayRequest } from '../models/apple-pay-request';
import { ApplePayStartSessionRequest } from '../models/apple-pay-start-session-request';
import { AppleSessionDto } from '../models/apple-session-dto';
import { Capture } from '../models/capture';
import { CardBrandEnum } from '../models/card-brand-enum';
import { CheckCreditCardResponse } from '../models/check-credit-card-response';
import { CreatePayPalOrderRequest } from '../models/create-pay-pal-order-request';
import { CreatePayPalOrderResponse } from '../models/create-pay-pal-order-response';
import { DigitalWalletEnum } from '../models/digital-wallet-enum';
import { DisputeTypeEnum } from '../models/dispute-type-enum';
import { GcpStorageResource } from '../models/gcp-storage-resource';
import { GatewayEnum } from '../models/gateway-enum';
import { GooglePayRequest } from '../models/google-pay-request';
import { NextPaymentAction } from '../models/next-payment-action';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfPayment } from '../models/paged-data-dto-of-payment';
import { PagedDataDtoOfTransaction } from '../models/paged-data-dto-of-transaction';
import { PayPalOrderRequest } from '../models/pay-pal-order-request';
import { PaymentDetails } from '../models/payment-details';
import { PaymentMethodType } from '../models/payment-method-type';
import { PaymentOrderBy } from '../models/payment-order-by';
import { PaymentRequest } from '../models/payment-request';
import { PaymentStatus } from '../models/payment-status';
import { Refund } from '../models/refund';
import { SurchargeVerifyRequest } from '../models/surcharge-verify-request';
import { TransactionDetails } from '../models/transaction-details';
import { TransactionFeesResult } from '../models/transaction-fees-result';
import { TransactionOrderBy } from '../models/transaction-order-by';
import { TransactionResult } from '../models/transaction-result';
import { TransactionStatus } from '../models/transaction-status';
import { TransactionType } from '../models/transaction-type';
import { Void } from '../models/void';


/**
 * API to create payments/transactions.
 */
@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation paymentsSearch
   */
  static readonly PaymentsSearchPath = '/v1/payments';

  /**
   * Retrieves a list of payments.
   *
   * Use this endpoint to retrieve a list of payments which have been created by a merchant.\
   * \
   * Permissions: core.payments.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsSearch$Response(params?: {
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfPayment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Amount', params.Amount, {});
      rb.query('Token', params.Token, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Description', params.Description, {});
      rb.query('CustomerName', params.CustomerName, {});
      rb.query('DisputeTypes', params.DisputeTypes, {"style":"form","explode":true});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfPayment>;
      })
    );
  }

  /**
   * Retrieves a list of payments.
   *
   * Use this endpoint to retrieve a list of payments which have been created by a merchant.\
   * \
   * Permissions: core.payments.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsSearch(params?: {
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfPayment> {

    return this.paymentsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfPayment>) => r.body as PagedDataDtoOfPayment)
    );
  }

  /**
   * Path part for operation paymentsCreatePayment
   */
  static readonly PaymentsCreatePaymentPath = '/v1/payments';

  /**
   * Creates a payment.
   *
   * Use this endpoint to authorize, or charge, a card (credit or debit) using a valid payment method token.\
   * You can generate the payment method token using the VituPay JavaScript SDK - Online Payment Method Form.\
   * \
   * Permissions: core.payments.create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsCreatePayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreatePayment$Response(params: {

    /**
     * Transaction details
     */
    body: PaymentRequest
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsCreatePaymentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Creates a payment.
   *
   * Use this endpoint to authorize, or charge, a card (credit or debit) using a valid payment method token.\
   * You can generate the payment method token using the VituPay JavaScript SDK - Online Payment Method Form.\
   * \
   * Permissions: core.payments.create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsCreatePayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreatePayment(params: {

    /**
     * Transaction details
     */
    body: PaymentRequest
  }): Observable<TransactionResult> {

    return this.paymentsCreatePayment$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsCapture
   */
  static readonly PaymentsCapturePath = '/v1/payments/{token}/capture';

  /**
   * Captures an authorize transaction.
   *
   * Use this endpoint to capture an auth transaction.\
   * An auth transaction can only be captured once, for an amount that is not greater than the authorized amount.\
   * \
   * Permissions: core.payments.capture
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsCapture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCapture$Response(params: {

    /**
     * A payment token, e.g. py_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * Transaction details
     */
    body: Capture
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsCapturePath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Captures an authorize transaction.
   *
   * Use this endpoint to capture an auth transaction.\
   * An auth transaction can only be captured once, for an amount that is not greater than the authorized amount.\
   * \
   * Permissions: core.payments.capture
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsCapture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCapture(params: {

    /**
     * A payment token, e.g. py_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * Transaction details
     */
    body: Capture
  }): Observable<TransactionResult> {

    return this.paymentsCapture$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsRefund
   */
  static readonly PaymentsRefundPath = '/v1/payments/{token}/refund';

  /**
   * Refund a payment.
   *
   * Use this endpoint to refund or void a charged payment. The refund amount cannot exceed the total charge amount,
   * Partial refunds are accepted provided the total refund amount of all the refunds does not exceed the total capture
   * amount.\
   * When a full charge amount of the payment is refunded within 25 minutes of payment creation, then the refund will be
   * processed as a void. In this instance, funds will not impact the cardholder's account."\
   * \
   * Permissions: core.payments.refund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsRefund()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsRefund$Response(params: {

    /**
     * A payment token, e.g. py_a0RSCekCe4mGIIbAbMCp2ue7
     */
    token: string;

    /**
     * Transaction details
     */
    body: Refund
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsRefundPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Refund a payment.
   *
   * Use this endpoint to refund or void a charged payment. The refund amount cannot exceed the total charge amount,
   * Partial refunds are accepted provided the total refund amount of all the refunds does not exceed the total capture
   * amount.\
   * When a full charge amount of the payment is refunded within 25 minutes of payment creation, then the refund will be
   * processed as a void. In this instance, funds will not impact the cardholder's account."\
   * \
   * Permissions: core.payments.refund
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsRefund$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsRefund(params: {

    /**
     * A payment token, e.g. py_a0RSCekCe4mGIIbAbMCp2ue7
     */
    token: string;

    /**
     * Transaction details
     */
    body: Refund
  }): Observable<TransactionResult> {

    return this.paymentsRefund$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsGet
   */
  static readonly PaymentsGetPath = '/v1/payments/{token}';

  /**
   * Retrieve a payment.
   *
   * Use this endpoint to retrieve information for a specific payment.\
   * \
   * Permissions: core.payments.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGet$Response(params: {

    /**
     * A payment token, e.g. py_YA3jgM9ytApOmZHdY1iCFUmt
     */
    token: string;
  }): Observable<StrictHttpResponse<PaymentDetails>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDetails>;
      })
    );
  }

  /**
   * Retrieve a payment.
   *
   * Use this endpoint to retrieve information for a specific payment.\
   * \
   * Permissions: core.payments.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGet(params: {

    /**
     * A payment token, e.g. py_YA3jgM9ytApOmZHdY1iCFUmt
     */
    token: string;
  }): Observable<PaymentDetails> {

    return this.paymentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentDetails>) => r.body as PaymentDetails)
    );
  }

  /**
   * Path part for operation paymentsExport
   */
  static readonly PaymentsExportPath = '/v1/payments/export';

  /**
   * Retrieves payments for current merchant.
   *
   * Permissions: core.payments.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsExport$Response(params?: {
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<GcpStorageResource>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsExportPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Amount', params.Amount, {});
      rb.query('Token', params.Token, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Description', params.Description, {});
      rb.query('CustomerName', params.CustomerName, {});
      rb.query('DisputeTypes', params.DisputeTypes, {"style":"form","explode":true});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GcpStorageResource>;
      })
    );
  }

  /**
   * Retrieves payments for current merchant.
   *
   * Permissions: core.payments.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsExport(params?: {
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<GcpStorageResource> {

    return this.paymentsExport$Response(params).pipe(
      map((r: StrictHttpResponse<GcpStorageResource>) => r.body as GcpStorageResource)
    );
  }

  /**
   * Path part for operation paymentsFees
   */
  static readonly PaymentsFeesPath = '/v1/payments/{token}/fees';

  /**
   * Retrieves all fees associated to a specific payment.
   *
   * Use this endpoint to retrieve all fees associated to a specific payment.\
   * \
   * Permissions: core.payments.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFees$Response(params: {

    /**
     * A payment token, e.g. py_YA3jgM9ytApOmZHdY1iCFUmt
     */
    token: string;
  }): Observable<StrictHttpResponse<TransactionFeesResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsFeesPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionFeesResult>;
      })
    );
  }

  /**
   * Retrieves all fees associated to a specific payment.
   *
   * Use this endpoint to retrieve all fees associated to a specific payment.\
   * \
   * Permissions: core.payments.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFees(params: {

    /**
     * A payment token, e.g. py_YA3jgM9ytApOmZHdY1iCFUmt
     */
    token: string;
  }): Observable<TransactionFeesResult> {

    return this.paymentsFees$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionFeesResult>) => r.body as TransactionFeesResult)
    );
  }

  /**
   * Path part for operation paymentsGetNextPaymentActions
   */
  static readonly PaymentsGetNextPaymentActionsPath = '/v1/payments/get-next-payment-actions/{token}';

  /**
   * Permissions: core.payments.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGetNextPaymentActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetNextPaymentActions$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<Array<NextPaymentAction>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetNextPaymentActionsPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NextPaymentAction>>;
      })
    );
  }

  /**
   * Permissions: core.payments.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGetNextPaymentActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetNextPaymentActions(params: {
    token: string;
  }): Observable<Array<NextPaymentAction>> {

    return this.paymentsGetNextPaymentActions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NextPaymentAction>>) => r.body as Array<NextPaymentAction>)
    );
  }

  /**
   * Path part for operation paymentsGooglePay
   */
  static readonly PaymentsGooglePayPath = '/v1/payments/google-pay';

  /**
   * Create a Google Pay Payment.
   *
   * Use this endpoint to charge a card (credit or debit) using the Google Pay payment method token.
   * You can generate the Google Pay payment method token using the VituPay JavaScript SDK.\
   * \
   * Permissions: core.payments.process-googlepay
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGooglePay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsGooglePay$Response(params: {

    /**
     * Google Pay payload
     */
    body: GooglePayRequest
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGooglePayPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Create a Google Pay Payment.
   *
   * Use this endpoint to charge a card (credit or debit) using the Google Pay payment method token.
   * You can generate the Google Pay payment method token using the VituPay JavaScript SDK.\
   * \
   * Permissions: core.payments.process-googlepay
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGooglePay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsGooglePay(params: {

    /**
     * Google Pay payload
     */
    body: GooglePayRequest
  }): Observable<TransactionResult> {

    return this.paymentsGooglePay$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsGooglePaySurchargeVerify
   */
  static readonly PaymentsGooglePaySurchargeVerifyPath = '/v1/payments/google-pay-surcharge-verify';

  /**
   * Permissions: core.payments.process-googlepay
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGooglePaySurchargeVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsGooglePaySurchargeVerify$Response(params: {
    body: SurchargeVerifyRequest
  }): Observable<StrictHttpResponse<CheckCreditCardResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGooglePaySurchargeVerifyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckCreditCardResponse>;
      })
    );
  }

  /**
   * Permissions: core.payments.process-googlepay
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGooglePaySurchargeVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsGooglePaySurchargeVerify(params: {
    body: SurchargeVerifyRequest
  }): Observable<CheckCreditCardResponse> {

    return this.paymentsGooglePaySurchargeVerify$Response(params).pipe(
      map((r: StrictHttpResponse<CheckCreditCardResponse>) => r.body as CheckCreditCardResponse)
    );
  }

  /**
   * Path part for operation paymentsStartApplePaySession
   */
  static readonly PaymentsStartApplePaySessionPath = '/v1/payments/start-apple-pay-session';

  /**
   * Start Apple Pay session.
   *
   * Permissions: core.payments.process-applepay
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsStartApplePaySession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsStartApplePaySession$Response(params: {
    body: ApplePayStartSessionRequest
  }): Observable<StrictHttpResponse<AppleSessionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsStartApplePaySessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppleSessionDto>;
      })
    );
  }

  /**
   * Start Apple Pay session.
   *
   * Permissions: core.payments.process-applepay
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsStartApplePaySession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsStartApplePaySession(params: {
    body: ApplePayStartSessionRequest
  }): Observable<AppleSessionDto> {

    return this.paymentsStartApplePaySession$Response(params).pipe(
      map((r: StrictHttpResponse<AppleSessionDto>) => r.body as AppleSessionDto)
    );
  }

  /**
   * Path part for operation paymentsApplePay
   */
  static readonly PaymentsApplePayPath = '/v1/payments/apple-pay';

  /**
   * Create an Apple Pay Payment.
   *
   * Use this endpoint to charge a card (credit or debit) using the Apple Pay payment method token.
   * You can generate the Apple Pay payment method token using the VituPay JavaScript SDK.\
   * \
   * Permissions: core.payments.process-applepay
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsApplePay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsApplePay$Response(params: {

    /**
     * Apple Pay payload
     */
    body: ApplePayRequest
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsApplePayPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Create an Apple Pay Payment.
   *
   * Use this endpoint to charge a card (credit or debit) using the Apple Pay payment method token.
   * You can generate the Apple Pay payment method token using the VituPay JavaScript SDK.\
   * \
   * Permissions: core.payments.process-applepay
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsApplePay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsApplePay(params: {

    /**
     * Apple Pay payload
     */
    body: ApplePayRequest
  }): Observable<TransactionResult> {

    return this.paymentsApplePay$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsCreatePayPalOrder
   */
  static readonly PaymentsCreatePayPalOrderPath = '/v1/payments/create-pay-pal-order';

  /**
   * Create a PayPal order.
   *
   * Use this endpoint to create a PayPal order which, when created, must be approved by the customer and then captured
   * using POST/v1/payments/capture-PayPal-order.\
   * \
   * Permissions: core.payments.create-paypal-order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsCreatePayPalOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreatePayPalOrder$Response(params: {
    body: CreatePayPalOrderRequest
  }): Observable<StrictHttpResponse<CreatePayPalOrderResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsCreatePayPalOrderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePayPalOrderResponse>;
      })
    );
  }

  /**
   * Create a PayPal order.
   *
   * Use this endpoint to create a PayPal order which, when created, must be approved by the customer and then captured
   * using POST/v1/payments/capture-PayPal-order.\
   * \
   * Permissions: core.payments.create-paypal-order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsCreatePayPalOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreatePayPalOrder(params: {
    body: CreatePayPalOrderRequest
  }): Observable<CreatePayPalOrderResponse> {

    return this.paymentsCreatePayPalOrder$Response(params).pipe(
      map((r: StrictHttpResponse<CreatePayPalOrderResponse>) => r.body as CreatePayPalOrderResponse)
    );
  }

  /**
   * Path part for operation paymentsCapturePayPalOrder
   */
  static readonly PaymentsCapturePayPalOrderPath = '/v1/payments/capture-pay-pal-order';

  /**
   * Capture PayPal order.
   *
   * Use this endpoint to capture a PayPal order. The PayPal order can only be captured when the buyer has approved the
   * order.\
   * \
   * Permissions: core.payments.capture-paypal-order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsCapturePayPalOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  paymentsCapturePayPalOrder$Response(params: {
    body: PayPalOrderRequest
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsCapturePayPalOrderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Capture PayPal order.
   *
   * Use this endpoint to capture a PayPal order. The PayPal order can only be captured when the buyer has approved the
   * order.\
   * \
   * Permissions: core.payments.capture-paypal-order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsCapturePayPalOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  paymentsCapturePayPalOrder(params: {
    body: PayPalOrderRequest
  }): Observable<TransactionResult> {

    return this.paymentsCapturePayPalOrder$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation paymentsProcessPayPalOrder
   */
  static readonly PaymentsProcessPayPalOrderPath = '/v1/payments/process-pay-pal-order';

  /**
   * Capture PayPal order.
   *
   * Use this endpoint to capture a PayPal order. The PayPal order can only be captured when the buyer has approved the
   * order.\
   * \
   * Permissions: core.payments.capture-paypal-order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsProcessPayPalOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsProcessPayPalOrder$Response(params: {
    body: PayPalOrderRequest
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsProcessPayPalOrderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Capture PayPal order.
   *
   * Use this endpoint to capture a PayPal order. The PayPal order can only be captured when the buyer has approved the
   * order.\
   * \
   * Permissions: core.payments.capture-paypal-order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsProcessPayPalOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsProcessPayPalOrder(params: {
    body: PayPalOrderRequest
  }): Observable<TransactionResult> {

    return this.paymentsProcessPayPalOrder$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation refundsVoid
   */
  static readonly RefundsVoidPath = '/v1/payments/refunds/{token}/void';

  /**
   * Void a refund transaction.
   *
   * Permissions: core.payments.void-refund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refundsVoid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refundsVoid$Response(params: {

    /**
     * Referenced refund transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * Transaction details
     */
    body: Void
  }): Observable<StrictHttpResponse<TransactionResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.RefundsVoidPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionResult>;
      })
    );
  }

  /**
   * Void a refund transaction.
   *
   * Permissions: core.payments.void-refund
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refundsVoid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refundsVoid(params: {

    /**
     * Referenced refund transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * Transaction details
     */
    body: Void
  }): Observable<TransactionResult> {

    return this.refundsVoid$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionResult>) => r.body as TransactionResult)
    );
  }

  /**
   * Path part for operation transactionsSearch
   */
  static readonly TransactionsSearchPath = '/v1/payments/transactions';

  /**
   * Retrieve a transaction.
   *
   * A payment is made up for one or multiple individual transactions, include auth, capture, sale, refund or void.
   * Use this endpoint to retrieve a information for a specific transaction.\
   * \
   * Permissions: core.transactions.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsSearch$Response(params?: {
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<StrictHttpResponse<PagedDataDtoOfTransaction>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsSearchPath, 'get');
    if (params) {
      rb.query('PaymentMethod', params.PaymentMethod, {});
      rb.query('Amount', params.Amount, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Token', params.Token, {});
      rb.query('Types', params.Types, {"style":"form","explode":true});
      rb.query('Description', params.Description, {});
      rb.query('Customer', params.Customer, {});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('AuthCode', params.AuthCode, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('TransactionId', params.TransactionId, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('IsServiceFee', params.IsServiceFee, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('meta', params.meta, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfTransaction>;
      })
    );
  }

  /**
   * Retrieve a transaction.
   *
   * A payment is made up for one or multiple individual transactions, include auth, capture, sale, refund or void.
   * Use this endpoint to retrieve a information for a specific transaction.\
   * \
   * Permissions: core.transactions.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsSearch(params?: {
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<PagedDataDtoOfTransaction> {

    return this.transactionsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfTransaction>) => r.body as PagedDataDtoOfTransaction)
    );
  }

  /**
   * Path part for operation transactionsGet
   */
  static readonly TransactionsGetPath = '/v1/payments/transactions/{token}';

  /**
   * Retrieve a transaction.
   *
   * A payment is made up for one or multiple individual transactions, include auth, capture, sale, refund or void.
   * Use this endpoint to retrieve a information for a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGet$Response(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<StrictHttpResponse<TransactionDetails>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionDetails>;
      })
    );
  }

  /**
   * Retrieve a transaction.
   *
   * A payment is made up for one or multiple individual transactions, include auth, capture, sale, refund or void.
   * Use this endpoint to retrieve a information for a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGet(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<TransactionDetails> {

    return this.transactionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionDetails>) => r.body as TransactionDetails)
    );
  }

  /**
   * Path part for operation transactionsExport
   */
  static readonly TransactionsExportPath = '/v1/payments/transactions/export';

  /**
   * Retrieves transactions for current merchant.
   *
   * Permissions: core.transactions.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsExport$Response(params?: {
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<StrictHttpResponse<GcpStorageResource>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsExportPath, 'get');
    if (params) {
      rb.query('PaymentMethod', params.PaymentMethod, {});
      rb.query('Amount', params.Amount, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Token', params.Token, {});
      rb.query('Types', params.Types, {"style":"form","explode":true});
      rb.query('Description', params.Description, {});
      rb.query('Customer', params.Customer, {});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('AuthCode', params.AuthCode, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('TransactionId', params.TransactionId, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('IsServiceFee', params.IsServiceFee, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('meta', params.meta, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GcpStorageResource>;
      })
    );
  }

  /**
   * Retrieves transactions for current merchant.
   *
   * Permissions: core.transactions.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsExport(params?: {
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<GcpStorageResource> {

    return this.transactionsExport$Response(params).pipe(
      map((r: StrictHttpResponse<GcpStorageResource>) => r.body as GcpStorageResource)
    );
  }

  /**
   * Path part for operation transactionsGetFees
   */
  static readonly TransactionsGetFeesPath = '/v1/payments/transactions/{token}/fees';

  /**
   * Retrieves all fees associated to a specific transaction.
   *
   * Use this endpoint to retrieve all fees associated to a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsGetFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetFees$Response(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<StrictHttpResponse<TransactionFeesResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsGetFeesPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionFeesResult>;
      })
    );
  }

  /**
   * Retrieves all fees associated to a specific transaction.
   *
   * Use this endpoint to retrieve all fees associated to a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsGetFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetFees(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<TransactionFeesResult> {

    return this.transactionsGetFees$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionFeesResult>) => r.body as TransactionFeesResult)
    );
  }

  /**
   * Path part for operation transactionsAccrueCustomFees
   */
  static readonly TransactionsAccrueCustomFeesPath = '/v1/payments/transactions/{token}/fees';

  /**
   * Use this endpoint to accrue custom fees to a transaction.
   * Custom fees can only be added to a successful sale or capture transaction which is not yet settled.
   *
   * Permissions: core.transactions.add-fee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsAccrueCustomFees()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionsAccrueCustomFees$Response(params: {

    /**
     * The transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * The object containing fees you want to add to the transaction.
     */
    body: AccrueCustomFeesRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsAccrueCustomFeesPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Use this endpoint to accrue custom fees to a transaction.
   * Custom fees can only be added to a successful sale or capture transaction which is not yet settled.
   *
   * Permissions: core.transactions.add-fee
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsAccrueCustomFees$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionsAccrueCustomFees(params: {

    /**
     * The transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;

    /**
     * The object containing fees you want to add to the transaction.
     */
    body: AccrueCustomFeesRequest
  }): Observable<void> {

    return this.transactionsAccrueCustomFees$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation transactionsReceipt
   */
  static readonly TransactionsReceiptPath = '/v1/payments/transactions/{token}/receipt';

  /**
   * Retrieve a transaction receipt.
   *
   * Use this endpoint to receive a receipt URL for a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsReceipt()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsReceipt$Response(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<StrictHttpResponse<GcpStorageResource>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsReceiptPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GcpStorageResource>;
      })
    );
  }

  /**
   * Retrieve a transaction receipt.
   *
   * Use this endpoint to receive a receipt URL for a specific transaction.\
   * \
   * Permissions: core.transactions.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsReceipt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsReceipt(params: {

    /**
     * A transaction token, e.g. tr_ILgWHJSfcYL0Wir4bBNfRUuf6eZ0
     */
    token: string;
  }): Observable<GcpStorageResource> {

    return this.transactionsReceipt$Response(params).pipe(
      map((r: StrictHttpResponse<GcpStorageResource>) => r.body as GcpStorageResource)
    );
  }

}
