import { Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[libTwoDigitDecimalNumber]"
})
export class TwoDigitDecimalNumberDirective implements OnInit {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("libTwoDigitDecimalNumber") enabled: boolean;
    @Input("libTwoDigitDecimalNumberAllowNegative") allowNegative: boolean;
    @Input("libTwoDigitDecimalNumberAllowZeroNotNegative") allowZeroNotNegative: boolean;
    @Input("libTwoDigitDecimalNumberEmptyIsNull") emptyIsNull: boolean;

    private regex: RegExp;
    // Allow key codes for special events.
    private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Del", "Delete"];

    constructor(private el: ElementRef, private control: NgControl) { }

    ngOnInit() {
        if (this.allowNegative) {
            // eslint-disable-next-line security/detect-unsafe-regex
            this.regex = new RegExp(/^(-?)((0|[1-9]\d*)(\.\d{0,2})?)?$/g);
        }
        else {
            // eslint-disable-next-line security/detect-unsafe-regex
            this.regex = new RegExp(/^(0|[1-9]\d*)(\.\d{0,2})?$/g);
        }
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (!this.enabled) {
            // Allows the entire directive to be conditionally applied (in which case its methods just have no effect)
            return;
        }
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == "Decimal" ? "." : event.key, current.slice(position)].join("");
        if ((next && !String(next).match(this.regex))) {
            event.preventDefault();
        }
    }

    @HostListener("blur", ["$event"])
    onBlur() {
        if (!this.enabled) {
            // Allows the entire directive to be conditionally applied (in which case its methods just have no effect)
            return;
        }

        const origValue = this.el.nativeElement.value;
        let value = origValue;

        if (this.emptyIsNull) {
            // Editor can change an initial null value into empty string so force it back to null here
            if ((value === "") || (value === undefined)) {
                value = null;
            }
        }

        if (value?.length > 0) {
            if (value.indexOf(".") < 0) {
                value = `${value}.00`;
            }
            while (value.indexOf(".") !== value.length - 3) {
                value = `${value}0`;
            }
            if ((value === "-0.00")
                || (value === "-.00")
                || (value === "0.00")
                || (value === ".00")) {
                value = (this.allowZeroNotNegative) ? "0.00" : "";
            }
        }

        if (origValue !== value) {
            // Required to sync the DOM value back to the Angular Form.
            this.control.control.setValue(value);
        }
    }

}
