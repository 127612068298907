<div class="datepicker">

    <div class="datepicker-main-body">

        <div [ngClass]="{'datepicker-two-month-container' : !singleDateMode, 'datepicker-one-month-container' : singleDateMode}">

            <ngb-datepicker #dp
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="singleDateMode ? 1 : 2"
                [dayTemplate]="dayTemplate"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [startDate]="fromDate!"
                outsideDays="hidden">
            </ngb-datepicker>

            <ng-template #dayTemplate let-date let-focused="focused">
                <span class="custom-day"
                    [class.focused]="focused"
                    [class.today]="isToday(date)"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>

        </div>

        <div class="datepicker-side-actions" [ngClass]="{'hide-datepicker-side-actions': singleDateMode}">
            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.TODAY)"
                type="button">
                {{ DateLabel.TODAY }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.YESTERDAY)"
                type="button">
                {{ DateLabel.YESTERDAY }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.THIS_WEEK)"
                type="button">
                {{ DateLabel.THIS_WEEK }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.LAST_7_DAYS)"
                type="button">
                {{ DateLabel.LAST_7_DAYS }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.LAST_30_DAYS)"
                type="button">
                {{ DateLabel.LAST_30_DAYS }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.THIS_MONTH)"
                type="button">
                {{ DateLabel.THIS_MONTH }}
            </button>

            <button class="btn btn-secondary btn-block side-action-button"
                (click)="selectDateByLabel(DateLabel.LAST_MONTH)"
                type="button">
                {{ DateLabel.LAST_MONTH }}
            </button>

        </div>

    </div>

    <div mat-dialog-actions class="datepicker-actions">

        <lib-action-button largeButton [kind]="ActionButtonKind.STROKED" label="Cancel" (click)="cancel()">
        </lib-action-button>

        <lib-action-button largeButton [kind]="ActionButtonKind.SECONDARY" label="Clear" (click)="clearSelectedDateAndClose()">
        </lib-action-button>

        <lib-action-button largeButton label="Apply" (click)="chooseSelectedDateAndClose()">
        </lib-action-button>

    </div>

</div>
