import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getUserState = (store: IStore) => store.user;

export const getUserInfo = createSelector(
    getUserState,
    userState => userState.user,
);

export const getUserRoleList = createSelector(
    getUserState,
    userState => userState.roleList,
);

export const getUserLoading = createSelector(
    getUserState,
    userState => userState.loading,
);
