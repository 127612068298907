import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { Subscription } from "rxjs";
import { DomainNameInvalid, EmailInvalid, TelephoneInvalid, TelephoneMaxLength, ZipInvalid } from "../../vitu-form/validators-config";

@Component({
    selector: "lib-vitu-form-field-input",
    templateUrl: "./vitu-form-field-input.component.html",
    styleUrls: ["./vitu-form-field-input.component.less"]
})
export class VituFormFieldInputComponent implements OnChanges, OnDestroy {

    @Input() formGroup: UntypedFormGroup;
    @Input() formFieldName: string;

    @Input() errorOverrides = [];

    @Input() set isFilter(value: any) {
        this._isFilter = !(value === false);
    };

    @Input() set disabled(value: any) {
        this._disabled = !(value === false);
    };

    @Input() set readonly(value: any) {
        this._readonly = !(value === false);
    };

    @Input() label: string;

    @Input() set type(value: string) {
        this._type = value;
        this.inputType = value;
        if (value === "obscured") {
            this.inputType = this.showObscured ? "text" : "password";
        }
        if ((value === "twoDigitDecimal") || (value === "twoDigitDecimalAllowNegative")
                || (value === "twoDigitDecimalAllowZeroNotNegative")) {
            this.inputType = "text";
        }
        if (value === "telephone") {
            this.inputType = "text";
            this.mask = {
                mask: "(#00) 000-0000",
                definitions: {"#": /[2-9]/}
            };
        }
        if (value === "zip") {
            this.inputType = "text";
            this.mask = {
                mask: "00000[-0000]"
            };
        }
        if (value === "domainName") {
            this.inputType = "text";
        }
    };
    get type() {
        return this._type;
    }
    _type = "text";
    inputType = "text";

    @Input() showReset = false;
    @Output() handleReset = new EventEmitter<void>();

    @Input() showButton;
    @Output() handleButton = new EventEmitter<void>();

    @Input() autocomplete = "on";       // html input default
    @Input() maxlength = 524288;        // html input default
    @Input() mask = null;

    @Input() infoTip: string;

    @Output() changedBlurred = new EventEmitter<void>();
    @Output() blurred = new EventEmitter<void>();

    private subscription: Subscription;

    formControl: UntypedFormControl;

    optionLabels: Array<string>;
    optionValues: Array<any>;

    @ViewChild("inputElement", { static: false }) inputElement: ElementRef;
    @ViewChild(MatFormField) formField: MatFormField;

    focus() {
        if (this.inputElement?.nativeElement) {
            this.inputElement?.nativeElement.focus();
        }
    }

    set value(value: any) {
        if (this.inputElement?.nativeElement) {
            this.inputElement.nativeElement.value = value;
        }
    }
    get value() {
        return this.inputElement?.nativeElement?.value;
    }

    @Input() set showObscured(value: boolean) {
        this._showObscured = value;
        this.inputType = this._showObscured ? "text" : "password";
    }
    get showObscured() {
        return this._showObscured;
    }
    @Output() showObscuredChange = new EventEmitter<boolean>();
    _showObscured = false;

    @Input() prefix: string;

    _isFilter = false;
    _disabled = false;
    _readonly = false;

    get errors() {
        return (this.formGroup?.controls[this.formFieldName] as UntypedFormControl)?.errors;
    }

    get hasRequiredValidator() {
        const abstractControl = this.formGroup?.controls[this.formFieldName];
        if (abstractControl?.validator) {
          const validator = abstractControl.validator({} as AbstractControl);
          if (validator && validator.required) {
            return true;
          }
        }
        return false;
    }

    ngOnChanges() {
        if (this.formGroup && this.formFieldName) {
            const oldFormControl = this.formControl;
            this.formControl = this.formGroup.controls[this.formFieldName] as UntypedFormControl;

            if (this.formControl !== oldFormControl) {
                this.subscription?.unsubscribe();
                this.subscription = this.formControl.valueChanges.subscribe(value => {
                    if ((this._type === "telephone") && (value?.length > TelephoneMaxLength)) {
                        // Perform integrity checking on telephone number fields, because now they are restricted to
                        // 10 numeric digits and have a US telephone mask applied.  This 'imask' would otherwise clip legacy
                        // telephone numbers which were created with more than 10 digits and cause problems as user would
                        // think field was valid, while marked invalid. Better solution is to remove the need for this
                        // client-side fix by instead performing a database clean to reset any fields for telephone numbers
                        // which are more than 10 digits.
                        this.formControl.setValue("");
                    }
                    else if ((this._type === "text") && (value?.length > 0)) {
                        // Enforce that no input field begins with whitespace
                        // This corrects an annoying issue when pasting table cell contents into filter fields
                        // that the paste from the table sometimes includes leading space chars, which means
                        // that the filter doesn't match.
                        // This solution seems effective at removing that, and there should be no need to have leading
                        // whitespace in any of our text fields.
                        const oldValue = this.value;
                        const newValue = this.value?.trimLeft();
                        if (oldValue && newValue && (newValue !== oldValue)) {
                            this.formControl.setValue(newValue);
                        }
                    }
                });
            }
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    updateOutlineGap() {
        // Workaround: Angular Bug
        // Label on outline form fields doesn't align properly
        // Fix adapted from : https://github.com/angular/components/issues/15027
        this.formField.updateOutlineGap();
    }

    onClickToggleShowObscured() {
        this.showObscured = !this.showObscured;
    }

    onChange(event: any) {
        this.changedBlurred.emit();
    }

    onBlur(event: any) {
        this.blurred.emit();
    }

    resolveError(errors: any, key: string) {

        // (1) First apply error override (if exists)

        if (this.errorOverrides?.length) {
            const foundOverrides = this.errorOverrides.filter(errorOverride => !!errorOverride[key]);
            if (foundOverrides?.length) {
                return foundOverrides[0][key];
            }
        }

        // (2) If no error override then apply fallback message

        if ((this.type === "email") && (key === "pattern")) {
            return EmailInvalid;
        }

        if ((this.type === "telephone") && (key === "pattern")) {
            return TelephoneInvalid;
        }

        if ((this.type === "zip") && (key === "pattern")) {
            return ZipInvalid;
        }

        if ((this.type === "domainName") && (key === "pattern")) {
            return DomainNameInvalid;
        }

        if (key === "required") {
            return "Field is required";
        }

        if (key === "min") {
            return `Min value is ${errors[key].min}`;
        }

        if (key === "max") {
            return `Max value is ${errors[key].max}`;
        }

        if (key === "minlength") {
            return `Min length is ${errors[key].requiredLength} chars`;
        }

        if (key === "maxlength") {
            return `Max length is ${errors[key].requiredLength} chars`;
        }

        if (key === "pattern") {
            return "Invalid value";
        }

        if (key === "vituValidHttpsUrl") {
            return "Must be a valid https:// URL";
        }

        if (key === "vituValidHttpHttpsUrl") {
            return "Must be a valid http:// or https:// URL";
        }

        return null;
    }

    get unmaskInputType(): boolean {
        // 'unmasking' an input is where it uses a 'mask':
        // - return 'true' :  if we want to propagate the unmasked value as value. (i.e. raw value)
        // - return 'false' : if we want to propagate the masked value as value.
        // 'zip' returns the masked value (eg. "98765-4321"), whereas 'telephone' returns the unmasked value (eg. "9876543210")
        return (this.type === "telephone");
    }

}
