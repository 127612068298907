/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateUserDto } from '../models/create-user-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { SendPasswordResetLinkDto } from '../models/send-password-reset-link-dto';
import { UpdateUserDto } from '../models/update-user-dto';
import { UpdateUserPasswordDto } from '../models/update-user-password-dto';
import { UserDetailsDto } from '../models/user-details-dto';
import { UserDto } from '../models/user-dto';
import { UsersOrderBy } from '../models/users-order-by';


/**
 * API to manage merchant's individual users. A user can be associated with roles.
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation usersSearch
   */
  static readonly UsersSearchPath = '/v1/users';

  /**
   * Permissions: core.users.search
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch$Response(params?: {
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSearchPath, 'get');
    if (params) {
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('OrderBy', params.OrderBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Permissions: core.users.search
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch(params?: {
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<Array<UserDto>> {

    return this.usersSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation usersPost
   */
  static readonly UsersPostPath = '/v1/users';

  /**
   * Permissions: core.users.create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost$Response(params: {
    body: CreateUserDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Permissions: core.users.create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost(params: {
    body: CreateUserDto
  }): Observable<UserDto> {

    return this.usersPost$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation usersGet
   */
  static readonly UsersGetPath = '/v1/users/{id}';

  /**
   * Permissions: core.users.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsDto>;
      })
    );
  }

  /**
   * Permissions: core.users.get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet(params: {
    id: string;
  }): Observable<UserDetailsDto> {

    return this.usersGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsDto>) => r.body as UserDetailsDto)
    );
  }

  /**
   * Path part for operation usersPut
   */
  static readonly UsersPutPath = '/v1/users/{id}';

  /**
   * Permissions: core.users.update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut$Response(params: {
    id: string;
    body: UpdateUserDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.users.update
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut(params: {
    id: string;
    body: UpdateUserDto
  }): Observable<void> {

    return this.usersPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersDelete
   */
  static readonly UsersDeletePath = '/v1/users/{id}';

  /**
   * Permissions: core.users.delete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.users.delete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete(params: {
    id: string;
  }): Observable<void> {

    return this.usersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersUpdatePassword
   */
  static readonly UsersUpdatePasswordPath = '/v1/users/{id}/update-password';

  /**
   * Permissions: core.users.update-password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword$Response(params: {
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdatePasswordPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.users.update-password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersUpdatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword(params: {
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<void> {

    return this.usersUpdatePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersResetPassword
   */
  static readonly UsersResetPasswordPath = '/v1/users/{id}/reset-password';

  /**
   * Permissions: core.users.reset-password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersResetPassword$Response(params: {
    id: string;
    body: SendPasswordResetLinkDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersResetPasswordPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Permissions: core.users.reset-password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersResetPassword(params: {
    id: string;
    body: SendPasswordResetLinkDto
  }): Observable<void> {

    return this.usersResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
