import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { ActionButtonAlertTone, ActionButtonKind } from "../../action-button/action-button.component";
import { VituToastTone } from "../vitu-toast-tone";

@Component({
    selector: "lib-snackbar-content",
    templateUrl: "./snackbar-content.component.html",
    styleUrls: ["./snackbar-content.component.less"],
})
export class SnackBarContentComponent {

    VituToastTone = VituToastTone;
    ActionButtonKind = ActionButtonKind;

    text: Array<string> = [];
    tone: VituToastTone = VituToastTone.Neutral;
    buttonText: string;
    closeFn: () => void;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

        if (Array.isArray(data?.text)) {
            this.text = data.text;
        }
        else if (typeof data?.text === "string") {
            this.text = [data.text];
        }
        if (data?.tone) {
            this.tone = data.tone;
        }
        if (data?.buttonText) {
            this.buttonText = data.buttonText;
        }
        if (data?.closeFn) {
            this.closeFn = data.closeFn;
        }

    }

    onButtonClick() {
        if (this.closeFn) {
            this.closeFn();
        }
    }

    get alertTone() {
        return this.tone as unknown as ActionButtonAlertTone;
    }

}
