/* tslint:disable */
/* eslint-disable */
export enum CardBrandEnum {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  Amex = 'Amex',
  Discover = 'Discover',
  JCB = 'JCB',
  Diners = 'Diners',
  Maestro = 'Maestro',
  Solo = 'Solo',
  CUP = 'CUP',
  Laser = 'Laser',
  CarteBlanche = 'CarteBlanche',
  GiftCard = 'GiftCard',
  EBT = 'EBT',
  GiroCard = 'GiroCard',
  Interac = 'Interac',
  Other = 'Other',
  Unknown = 'Unknown'
}
