import { MerchantEffects } from "./merchant/merchant.effects";
import { TransactionsEffects } from "./transactions/transactions.effects";
import { TransactionEffects } from "./transaction/transaction.effects";
import { PaymentsEffects } from "./payments/payments.effects";
import { PaymentEffects } from "./payment/payment.effects";
import { UsersEffects } from "./users/users.effects";
import { RouterEffects } from "./router/router.effects";
import { TransactionSiblingsEffects } from "./transaction-siblings/transaction-siblings.effects";
import { UserEffects } from "./user/user.effects";
import { NextPaymentActionsEffects } from "./next-payment-actions/next-payment-actions.effects";
import { CurrentUserEffects } from "./current-user/current-user.effects";
import { AppEffects } from "./app/app.effects";
import { RolesEffects } from "./roles/roles.effects";
import { RoleEffects } from "./role/role.effects";
import { WebhooksEffects } from "./webhooks/webhooks.effects";
import { WebhookEffects } from "./webhook/webhook.effects";
import { ReportEffects } from "./report/report.effects";
import { StatementsEffects } from "./statements/statements.effects";
import { ClientsEffects } from "./clients/clients.effects";
import { ClientEffects } from "./client/client.effects";
import { ClientSecretsEffects } from "./client-secrets/client-secrets.effects";
import { TidsEffects } from "./tids/tids.effects";
import { MidsEffects } from "./mids/mids.effects";
import { FundingEffects } from "./funding/funding.effects";
import { FundingItemEffects } from "./funding-item/funding-item.effects";
import { EventEffects } from "./event/event.effects";
import { EventsEffects } from "./events/events.effects";
import { EventDeliveryEffects } from "./event-delivery/event-delivery.effects";
import { EventDeliveriesEffects } from "./event-deliveries/event-deliveries.effects";
import { DisputesEffects } from "./disputes/disputes.effects";
import { DisputeEffects } from "./dispute/dispute.effects";

export const EffectsConfiguration = [
    AppEffects,
    MerchantEffects,
    TransactionsEffects,
    TransactionEffects,
    TransactionSiblingsEffects,
    WebhookEffects,
    WebhooksEffects,
    PaymentsEffects,
    PaymentEffects,
    NextPaymentActionsEffects,
    UserEffects,
    UsersEffects,
    RouterEffects,
    RoleEffects,
    RolesEffects,
    ClientsEffects,
    ClientEffects,
    CurrentUserEffects,
    ReportEffects,
    StatementsEffects,
    ClientSecretsEffects,
    TidsEffects,
    MidsEffects,
    FundingEffects,
    FundingItemEffects,
    EventEffects,
    EventsEffects,
    EventDeliveryEffects,
    EventDeliveriesEffects,
    DisputesEffects,
    DisputeEffects
];
