import { Component, HostListener, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalSpinnerData, GlobalSpinnerService } from "./global-spinner.service";

@Component({
    selector: "lib-global-spinner",
    templateUrl: "./global-spinner.component.html"
})
export class GlobalSpinnerComponent implements OnDestroy {

    private subscription = new Subscription();
    data: GlobalSpinnerData;

    constructor(private globalSpinner: GlobalSpinnerService) {
        this.subscription.add(globalSpinner.data$.subscribe(data => {
            this.data = data;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        /* DISABLE ALL KEY EVENTS BEHIND MODAL IF IT IS SHOWN */
        /* (NOTE THAT MOUSE EVENTS WILL BE BLOCKED IF NECESSARY TEMPLATE UNDER LAYER) */
        if (this.data.show) {
            event.returnValue = false;
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
