import { Action } from "@ngrx/store";
import { RoleDto } from "@merchant_api/models/role-dto";

export enum ActionTypes {
    GetRoles = "[Roles] GetRoles",
    GetRolesSucceed = "[Roles] GetRolesSucceed",
    GetRolesFail = "[Roles] GetRolesFail"
}

export class GetRolesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetRoles;
}

export class GetRolesSucceedAction implements Action {

    constructor(public roles: Array<RoleDto>) {}

    readonly type = ActionTypes.GetRolesSucceed;
}

export class GetRolesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetRolesFail;
}
