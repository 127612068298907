<div class="outer-container">
    <app-transaction-detail
        [transaction]="transaction$ | async"
        [transactionLoading]="transactionLoading$ | async"
        [payment]="payment$ | async"
        [paymentLoading]="paymentLoading$ | async"
        [paymentError]="paymentError$ | async"
        [paymentFees]="(paymentFees$ | async)?.fees"
        [paymentFeesLoading]="paymentFeesLoading$ | async"
        [paymentFeesError]="paymentFeesError$ | async"
        [nextPaymentActions]="nextPaymentActions$ | async"
        [nextPaymentActionsLoading]="nextPaymentActionsLoading$ | async"
        [transactionSiblings]="transactionSiblings$ | async"
        [transactionSiblingsLoading]="transactionSiblingsLoading$ | async"
        (refundPayment)="refundPayment($event)"
        (voidPayment)="voidPayment($event)"
        (capturePayment)="capturePayment($event)"
        (downloadReceipt)="downloadReceipt($event)">
    </app-transaction-detail>
</div>
