import { Action } from "@ngrx/store";
import { MerchantProcessorDto } from "@merchant_api/models/merchant-processor-dto";

export enum ActionTypes {
    ResetMids = "[MIDS] ResetMids",
    GetMids = "[MIDS] GetMids",
    GetMidsSucceed = "[MIDS] GetMidsSucceed",
    GetMidsFail = "[MIDS] GetMidsFail"
}

export class ResetMidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetMids;
}

export class GetMidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetMids;
}

export class GetMidsSucceedAction implements Action {

    constructor(public mids: Array<MerchantProcessorDto>) {}

    readonly type = ActionTypes.GetMidsSucceed;
}

export class GetMidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetMidsFail;
}
