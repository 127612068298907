import { Component, Input, Output, EventEmitter, OnInit, ContentChild,
    TemplateRef, ViewChildren, QueryList, ViewContainerRef, ViewChild,
    ChangeDetectorRef, AfterViewChecked, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { Sort, SortDirection } from "@angular/material/sort";
import { Pager } from "../misc/pager";

export interface ColumnDef {
    id: string;
    title: string;
    flexWidthBasisInPixels: number;
    flexWidthGrow: number;
    canSort?: boolean;
    infoTip?: string;
}

export interface TableSorting {
    orderBy: null | string;
    // Workaround : Angular library can't reference files outside its root so can't use OrderDirectionEnum type
    // (NOTE: Could resolve this issue by moving required model into further shared library)
    orderDirection: null | any;
}

export enum TableScrollIntoViewMode {
    CenterWithinView,
    BringWithinViewAtNearestSide
}

@Component({
    selector: "lib-table-base",
    templateUrl: "./table-base.component.html",
    styleUrls: ["./table-base.component.less"],
})
export class TableBaseComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input() columnDefs: ColumnDef[] = null;

    @Input() set rowsData(value: any[]) {
        this._rowsData = value;
        this.resetInternalSorting();
    }
    get rowsData() {
        return this._rowsData;
    }

    @Input() rowsDataLoading = false;
    @Input() rowsDataError: Error;
    @Input() rowsDataEmptyMessage = "No Results Found";
    @Input() rowsDataErrorMessage = "Error Loading Results";
    @Input() rowsDataShowWhileLoading = false;

    @Input() selectedRowProperty: string = null;
    @Input() selectedRowValue = null;

    @Input() sorting: TableSorting;
    @Input() pager: Pager;

    @Output() pageChanged = new EventEmitter<number>();
    @Output() sortingChanged = new EventEmitter<TableSorting>();
    @Output() rowSelected = new EventEmitter<any>();

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    @ViewChild("outerContainer", {read: ViewContainerRef}) outerContainer: ViewContainerRef;
    @ViewChild("tableVerticalScrollContainer", {read: ViewContainerRef}) tableVerticalScrollContainer: ViewContainerRef;
    @ViewChild("materialTable", {read: ViewContainerRef}) materialTable: ViewContainerRef;
    @ViewChild("pagerContainer", {read: ViewContainerRef}) pagerContainer: ViewContainerRef;
    @ViewChild("headerRow", {read: ViewContainerRef}) headerRow: ViewContainerRef;
    @ViewChildren("tableCell", {read: ViewContainerRef}) tableCells: QueryList<ViewContainerRef>;
    @ViewChildren("tableRow", {read: ViewContainerRef}) tableRows: QueryList<ViewContainerRef>;

    minRequiredHeightInPixels: number;
    _rowsData: any[];

    internalSortingDirection: SortDirection;
    internalSortingOrderBy: string;
    internalSortingModel: object;

    get internalSorting(): boolean {
        return (!this.sorting && !this.pager);
    }

    get moreThanOnePage(): boolean {
        return this.pager && ((this.pager.page > 1) || (this.pager.pageCount > 1));
    }

    scrollRowIntoViewIfNecessary = (rowIndex: number, mode = TableScrollIntoViewMode.CenterWithinView): boolean => {

        let didScroll = false;

        // NOTE: Can't use tableRows ViewContainerRef here as it can't guarantee proper order after sorting!
        const materialTableElement = this.materialTable?.element?.nativeElement;
        const rows = materialTableElement?.getElementsByClassName("vitu-table-row");

        if (!rows?.length || !((rowIndex >= 0) && (rowIndex < rows.length))) {
            return didScroll;
        }

        const rowElement = rows[rowIndex];

        const containerElement = this.tableVerticalScrollContainer.element.nativeElement;
        const rowElementRect = rowElement.getBoundingClientRect();
        const containerElementRect = containerElement.getBoundingClientRect();

        const headerElement = this.headerRow?.element?.nativeElement;
        const headerElementHeight = headerElement ? headerElement.offsetHeight : 0;

        switch (mode) {
            case TableScrollIntoViewMode.CenterWithinView:
            {
                const rowElementMidY = rowElementRect.top + (rowElementRect.height / 2);
                const containerElementMidY = containerElementRect.top + (containerElementRect.height / 2) + (headerElementHeight / 2);
                const rowElementScrollTopDelta = rowElementMidY - containerElementMidY;
                containerElement.scrollTop = containerElement.scrollTop + rowElementScrollTopDelta;
                didScroll = (rowElementScrollTopDelta !== 0);
                break;
            }
            case TableScrollIntoViewMode.BringWithinViewAtNearestSide:
            {
                let yDelta = rowElementRect.bottom - containerElementRect.bottom;

                // Target is outside the viewport from the bottom
                if (yDelta >= 1) {
                    //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
                    containerElement.scroll({
                        top: containerElement.scrollTop + yDelta,
                        behavior: "smooth"
                    });
                    didScroll = true;
                }
                // Target is outside the view from the top
                if (!didScroll) {
                    yDelta = (containerElementRect.top + headerElementHeight) - rowElementRect.top;
                    if (yDelta >= 1) {
                        // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
                        containerElement.scroll({
                            top: containerElement.scrollTop - yDelta,
                            behavior: "smooth"
                        });
                        didScroll = true;
                    }
                }
                break;
            }
        }

        return didScroll;
    };

    usesVertScrollbar = false;

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "rowsData": {
                        this.scrollTableToTop();
                    }
                }
            }
        }
    }

    ngAfterViewChecked() {
        this.calculateIfScrollbarRequired();
        this.calculateMinRequiredHeight();
        this.cd.detectChanges();
    }

    constructor(private cd: ChangeDetectorRef, private elementRef: ElementRef) {
        this.resetInternalSorting();
    }

    private mouseDownX: number;
    private mouseDownY: number;

    columnIds: string[];
    minimumTotalColumnWidthInPixels: number;
    filterVisible: boolean;

    get rowsDataForDisplay(): any[] {

        if (!this.internalSorting || !this.internalSortingOrderBy ||
            ((this.internalSortingDirection !== "asc") && (this.internalSortingDirection !== "desc"))) {
            return this.rowsData;
        }

        const sortedColumnCells = this.internalSortingModel[this.internalSortingOrderBy][this.internalSortingDirection];
        const sortedRowsData = [];
        sortedColumnCells.forEach(sortedColumnCell => sortedRowsData.push(this.rowsData[sortedColumnCell.index]));
        return sortedRowsData;
    }

    get sortOrderBy() {

        if (this.internalSorting) {
            return this.internalSortingOrderBy;
        }

        return this.sorting?.orderBy;
    }

    get sortDirection() {

        if (this.internalSorting) {
            return this.internalSortingDirection;
        }

        return this.sorting?.orderDirection?.toLowerCase() as SortDirection;
    }

    onSortChanged(sort: Sort) {

        if (this.internalSorting) {
            if (!this.rowsData || !this.tableCells) {
                return;
            }
            if (!this.internalSortingModel) {
                this.createInternalSortingModel();
            }
            this.internalSortingOrderBy = sort.active;
            this.internalSortingDirection = sort.direction;
            return;
        }

        // Workaround : Angular library can't reference files outside its root so can't use OrderDirectionEnum type
        // (NOTE: Could resolve this issue by moving required model into further shared library)
        const direction = ((sort.direction.length > 0) ? sort.direction.charAt(0).toUpperCase() +
                                                sort.direction.slice(1) : "");
        const newSorting: TableSorting = {
            orderBy: sort.active,
            orderDirection: direction
        };
        this.sortingChanged.emit(newSorting);
    }

    ngOnInit() {
        this.columnIds = this.columnDefs.map(columnDef => columnDef.id);
        this.calcMinimumTotalColumnWidthInPixels();
    }

    getTitleForColumn(columnId: string): string {
        const matchedColumnDef = this.columnDefs.find(columnDef => (columnDef.id === columnId));
        return matchedColumnDef.title;
    }

    getInfoTipForColumn(columnId: string): string {
        const matchedColumnDef = this.columnDefs.find(columnDef => (columnDef.id === columnId));
        return matchedColumnDef.infoTip;
    }

    getFlexForColumn(columnId: string): string {
        const matchedColumnDef = this.columnDefs.find(columnDef => (columnDef.id === columnId));
        return `${matchedColumnDef.flexWidthGrow} 0 ${matchedColumnDef.flexWidthBasisInPixels}px`;
    }

    canSortColumn(columnId: string, index: number) {
        const matchedColumnDef = this.columnDefs.find(columnDef => (columnDef.id === columnId));
        return (matchedColumnDef.title?.length) && !!matchedColumnDef.canSort;
    }

    isRowSelected(row: any) {
        return (this.selectedRowValue === (this.selectedRowProperty ? row[this.selectedRowProperty] : row));
    }

    onPageChanged(page: number) {
        this.pageChanged.emit(page);
    }

    onRowMouseDown(event: MouseEvent) {
        this.mouseDownX = event.x;
        this.mouseDownY = event.y;
    }

    onRowMouseUp(event: MouseEvent, row: any, rowIndex: number) {
        const didScroll = this.scrollRowIntoViewIfNecessary(rowIndex, TableScrollIntoViewMode.BringWithinViewAtNearestSide);
        if (!didScroll) {
            const mousePositionTolerance = 3;
            if ((event.button === 0) &&
                (Math.abs(event.x - this.mouseDownX) < mousePositionTolerance) &&
                (Math.abs(event.y - this.mouseDownY) < mousePositionTolerance)) {
                this.rowSelected.emit(this.selectedRowProperty ? row[this.selectedRowProperty] : row);
            }
        }
    }

    get emptyRowsData(): boolean {
        return !this.rowsData || (Array.isArray(this.rowsData) && !this.rowsData.length);
    }

    private calcMinimumTotalColumnWidthInPixels(): void {
        let value = 0;
        this.columnDefs.forEach(columnDef => value += columnDef.flexWidthBasisInPixels);
        this.minimumTotalColumnWidthInPixels = value;
    }

    private scrollTableToTop(): void {
        const tableContainer = this.tableVerticalScrollContainer?.element?.nativeElement;
        if (tableContainer) {
            tableContainer.scrollTop = 0;
        }
    }

    private calculateIfScrollbarRequired() {
        this.usesVertScrollbar = false;
        const containerElement = this.tableVerticalScrollContainer?.element?.nativeElement;
        if (containerElement) {
            const scrollDeltaY = containerElement.scrollHeight - containerElement.offsetHeight;
            if (scrollDeltaY > 0) {
                this.usesVertScrollbar = true;
            }
        }
    }

    private calculateMinRequiredHeight() {
        // NOTE: not currently sensistive to a client of table-base which would apply padding
        // to host element (as that could give extra height)
        const containerElement = this.tableVerticalScrollContainer?.element?.nativeElement;
        const materialTableElement = this.materialTable?.element?.nativeElement;
        const pagerContainerElement = this.pagerContainer?.element?.nativeElement;
        if (containerElement && materialTableElement) {
            this.minRequiredHeightInPixels = materialTableElement.offsetHeight;
            if (pagerContainerElement) {
                this.minRequiredHeightInPixels += pagerContainerElement.offsetHeight;
            }
        }
    }

    private resetInternalSorting(): void {
        if (this.internalSorting) {
            this.internalSortingDirection = "";
            this.internalSortingOrderBy = null;
            this.internalSortingModel = null;
        }
    }

    private createInternalSortingModel(): void {
        this.internalSortingModel = {};
        this.columnDefs?.forEach((columnDef) => {
            this.internalSortingModel[columnDef.id] = {};
            this.attachInternalSortingModel(this.internalSortingModel[columnDef.id], columnDef.id, "asc");
            this.attachInternalSortingModel(this.internalSortingModel[columnDef.id], columnDef.id, "desc");
        });
    }

    private attachInternalSortingModel(model: any, orderBy: string, direction: string) {

        const columnIndex = this.columnDefs.findIndex(columnDef => columnDef.id === orderBy);
        const columnCount = this.columnDefs.length;

        // NOTE: Can't use tableCells ViewContainerRef here as it can't guarantee proper order after sorting!
        const materialTableElement = this.materialTable?.element?.nativeElement;
        const cells = materialTableElement?.getElementsByClassName("vitu-table-cell");

        const columnCells = [];
        Array.from(cells).forEach((cell, index) => {
            if (index % columnCount === columnIndex) {
                columnCells.push(cell);
            }
        });

        const unsortedColumnCells = columnCells.map((columnCell: any, index) => {
            let cellKey = columnCell.textContent?.trim();   // useful to trim
            const cellHtml = columnCell.innerHTML;
            const columnSortingKey = cellHtml.match(/START_TABLE_COLUMN_SORTING_KEY_(.*?)_END_TABLE_COLUMN_SORTING_KEY/);
            if (Array.isArray(columnSortingKey) && (columnSortingKey.length > 0)) {
                cellKey = parseFloat(columnSortingKey[1]);
            }
            return ({index, cellKey});
        });

        const sortedColumnCells = unsortedColumnCells.sort((a: any, b: any) => this.compareItems(a, b, direction === "asc"));
        model[direction] = sortedColumnCells;
    }

    private compareItems(a: any, b: any, isAsc: boolean): number {

        let valueA = a.cellKey;
        let valueB = b.cellKey;

        // Ensure case insensitive comparison (for string values)
        if (typeof valueA === "string") {
            valueA = valueA.toLowerCase();
        }
        if (typeof valueB === "string") {
            valueB = valueB.toLowerCase();
        }

        if (valueA > valueB) {
            return isAsc ? 1 : -1;
        }
        else if (valueA < valueB) {
            return isAsc ? -1 : 1;
        }
        else {
            return 0;
        }
    }

}
