import { Directive, Input } from "@angular/core";
import { AbstractControl, ControlContainer, FormControlName, NgControl } from "@angular/forms";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[disableControl]"
})
export class DisableControlDirective {

  @Input() set disableControl( condition: boolean ) {

    let control: AbstractControl;
    if (this.ngControl instanceof FormControlName) {
        // Attempt to get form control (using container) when component created without [formControl] applied (and only [formControlName]).
        const formControlName = (this.ngControl as FormControlName).name;
        if (typeof formControlName === "string") {
            control = this.controlContainer?.control?.get(formControlName);
        }
    }
    else {
        control = this.ngControl?.control;
    }
    if (control) {
        if (condition) {
            control.disable();
        }
        else {
            control.enable();
        }
    }
  }

  constructor(private ngControl: NgControl, private controlContainer: ControlContainer) {}

}
