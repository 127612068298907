<div class="outer-container">

    <div class="submenu-anchor">

        <div class="submenu-container" [ngStyle]="{'top': menuVertPosInPixels + 'px', 'display': menuShown ? 'flex' : 'none'}"
            (mouseover)="showMenu.emit()" (mouseout)="hideMenu.emit()">

            <div class="items-container">

                <ng-container *ngFor="let item of items">

                    <div #itemDiv (mouseover)="onMouseOverItem(item, itemDiv)" (mouseout)="onMouseOutItem()">

                        <ng-container *ngIf="hasPermissions(currentUserPermissions, item)">

                            <lib-dashboard-sidebar-menu-item
                                [item]="item"
                                [sidebarOpen]="true"
                                [isSubmenuItem]="true"
                                [isItemActionable]="true"
                                [isItemActive]="false"
                                (navigateTo)="navigateTo.emit($event)"
                                [subMenuActive]="isItemSubMenuActive(item)">
                            </lib-dashboard-sidebar-menu-item>

                        </ng-container>

                    </div>
    
                </ng-container>

            </div>

            <lib-dashboard-submenu
                *ngIf="items?.length > 0"
                [items]="subMenuParentItem?.subItems"
                [menuVertPosInPixels]="subMenuVertPosInPixels"
                [menuShown]="subMenuShown"
                [currentUserPermissions]="currentUserPermissions"
                (showMenu)="onShowSubMenu()"
                (hideMenu)="onHideSubMenu()"
                (navigateTo)="navigateTo.emit($event)">
            </lib-dashboard-submenu>

        </div>

    </div>

</div>
