<div class="outer-container" [ngClass]="{'field-empty': label.length === 0}">
    <div class="label">
        <lib-text-common [infoTip]="infoTip">
            {{ label }}
        </lib-text-common>
    </div>
    <div *ngIf="hasFontImage; else noFontImage" class="value-font-image">
        <i [class]="fontImage"></i>
    </div>
    <ng-template #noFontImage>
        <div *ngIf="fontImage !== false; else noImage" class="value-no-font-image">
            <lib-text-common>
            </lib-text-common>
        </div>
        <ng-template #noImage>
            <div class="value-no-font-image">
                <ng-content></ng-content>
            </div>
        </ng-template>
    </ng-template>
</div>
