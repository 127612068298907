import { Action } from "@ngrx/store";
import { Sorting, Filter } from "./payments.state";
import { GcpStorageResource } from "@merchant_api/models/gcp-storage-resource";
import { PagedDataDtoOfPayment } from "@merchant_api/models/paged-data-dto-of-payment";

export enum ActionTypes {
    FilterPayments = "[Payments] FilterPayments",
    SortPayments = "[Payments] SortPayments",
    PageChangedPayments = "[Payments] PageChangedPayments",
    GetPayments = "[Payments] GetPayments",
    GetPaymentsSucceed = "[Payments] GetPaymentsSucceed",
    GetPaymentsFail = "[Payments] GetPaymentsFail",
    ExportPaymentsToCsv = "[Payments] ExportPaymentsToCSV",
    ExportPaymentsToCsvSucceed = "[Payments] ExportPaymentsToCSVSucceed",
    ExportPaymentsToCsvFail = "[Payments] ExportPaymentsToCSVFail"
}

export class FilterPaymentsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterPayments;
}

export class SortPaymentsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortPayments;
}

export class PageChangedPaymentsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedPayments;
}

export class GetPaymentsAction implements Action {

    constructor(public page: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetPayments;
}

export class GetPaymentsSucceedAction implements Action {

    constructor(public response: PagedDataDtoOfPayment, public pageSize: number,
        public page: number, public stateExtensions?: any) {}

    readonly type = ActionTypes.GetPaymentsSucceed;
}

export class GetPaymentsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetPaymentsFail;
}

export class ExportPaymentsToCsvAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ExportPaymentsToCsv;
}

export class ExportPaymentsToCsvSucceedAction implements Action {

    constructor(public resource: GcpStorageResource) {}

    readonly type = ActionTypes.ExportPaymentsToCsvSucceed;
}

export class ExportPaymentsToCsvFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.ExportPaymentsToCsvFail;
}
