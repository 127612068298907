import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "lib-vitu-input",
    templateUrl: "./vitu-input.component.html",
    styleUrls: ["./vitu-input.component.less"]
})
export class VituInputComponent implements OnInit {

    @Input() label: string;
    @Input() readonly: boolean;

    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();

    @Input() inputType = "text";

    @Input() min: number;
    @Input() max: number;

    animateLabel = false;

    onChange(event: any) {
        this.value = event;
        this.valueChange.emit(this.value);
    }

    ngOnInit() {
        setTimeout(() => { this.animateLabel = true;}, 1000);
    }

}
