<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Reports' }]">
    </lib-title-bar>

    <lib-table-base
        class="reports-table"
        [columnDefs]="columnDefs"
        [rowsData]="reports">

        <ng-template let-element="element" let-columnId="columnId">
            <app-reports-table-cell-content
                [columnId]="columnId"
                [report]="element"
                (downloadReport)="onDownloadReport($event)">
            </app-reports-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
