import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { map, take } from "rxjs/operators";
import {
    getCurrentUser,
    getCurrentUserLoading,
    getCurrentUserPermissions
} from "@merchant_app/storage/current-user/current-user.selectors";
import { IStore } from "@merchant_app/storage/store";
import { ActionTypes, GetCurrentUserAction, GetCurrentUserSucceedAction } from "@merchant_app/storage/current-user/current-user.actions";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { GetMerchantAction } from "@merchant_app/storage/merchant/merchant.actions";
import { PermissionUtils } from "shared-lib";

@Injectable({ providedIn: "root" })
export class PermissionsGuard implements CanActivateChild {

    currentUserLoading$ = this.store.pipe(
        select(getCurrentUserLoading)
    );

    currentUser$ = this.store.pipe(
        select(getCurrentUser)
    );

    currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    private currentUserPermissions = [];
    private currentUser: CurrentUserDto = null;
    private currentUserLoading = false;

    constructor(private store: Store<IStore>, private actions: Actions, private router: Router) {
        this.currentUserPermissions$.subscribe(currentUserPermissions => {
            this.currentUserPermissions = currentUserPermissions;
        });
        this.currentUser$.subscribe(currentUser => {
            this.currentUser = currentUser;
        });
        this.currentUserLoading$.subscribe(currentUserLoading => {
            this.currentUserLoading = currentUserLoading;
        });
    }

    canActivateChild(route: ActivatedRouteSnapshot) {
        if (!this.currentUserLoading && this.currentUser) {
            const requiredPermissions =  route.data?.requiredPermissions ? route.data.requiredPermissions : [];
            return this.resolveCanActivate(this.currentUserPermissions, requiredPermissions);
        }
        else {
            this.store.dispatch(new GetCurrentUserAction());
            return this.actions
                .pipe(ofType<GetCurrentUserSucceedAction>(ActionTypes.GetCurrentUserSucceed),
                    take(1),
                    map(action => {
                        this.store.dispatch(new GetMerchantAction());
                        const currentUserPermissions = action.record.permissions;
                        const requiredPermissions =  route.data?.requiredPermissions ? route.data.requiredPermissions : [];
                        return this.resolveCanActivate(currentUserPermissions, requiredPermissions);
                    })
                ).toPromise();
        }
    }

    private resolveCanActivate(currentUserPermissions: string[], requiredPermissions: string[]): boolean {
        const canActivate = PermissionUtils.hasPermissions(currentUserPermissions, requiredPermissions);
        if (!canActivate) {
            // Workaround : Angular won't do this by default
            if (location.pathname != "/") {

                this.router.navigateByUrl("/");
            }
        }
        return canActivate;
    }

}
