import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-client-secrets-table-cell-content",
    templateUrl: "./client-secrets-table-cell-content.component.html",
    styleUrls: ["./client-secrets-table-cell-content.component.less"],
})
export class ClientSecretsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    @Output() deleteClientSecret = new EventEmitter<number>();

    showSecret = false;

    ActionButtonKind = ActionButtonKind;

    onClickToggleShowSecret() {
        this.showSecret = !this.showSecret;
    }

    onClickDeleteSecret() {
        this.deleteClientSecret.emit(this.element.id);
    }

    get sortingKeyForExpirationDate(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.expirationUtc).getTime();
    }
}
