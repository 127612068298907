<div class="top-bar-container">

    <div class="top-bar-icons-container">

        <div class="hamburger-menu">

            <button mat-button (click)="onClickHamburger()">
                <div class="hamburger-menu-inner">
                    <svg focusable="false" viewBox="0 0 24 24" class="hamburger-icon"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
                </div>
            </button>

        </div>

        <button mat-button routerLink='/' class="title-button">
            <img [src]="brandIconPath" class="title-button-image" [ngStyle]="{'top': brandIconOffsetInPixelsY + 'px'}" />
        </button>

    </div>

    <div class="user-menu-container">
        <div class="display-name-container" [ngClass]="{ 'display-name-container-hide': hideDashboardUserName }">
            {{ userName }}
        </div>
        <button mat-button class="user-menu-button" [matMenuTriggerFor]="userMenu" (menuOpened)="onUserMenuShowChange(true)" (menuClosed)="onUserMenuShowChange(false)">
            <span class="user-menu-button-inner">
                <span class="user-menu-user-icon"><i class="fas fa-user-circle"></i></span>
                <span class="user-menu-caret-icon"><i class="far fa-chevron-down"></i></span>
            </span>
        </button>
        <mat-menu #userMenu="matMenu">
            <div class="user-menu-details-container">
                <div class="user-menu-username">
                    {{ userName }}
                </div>
                <div *ngIf="email?.length" class="user-menu-email">
                    {{ email }}
                </div>
                <div *ngIf="merchantName?.length" class="user-menu-merchant">
                    {{ merchantName }}
                </div>
            </div>
            <button mat-menu-item class="user-menu-option-button" (click)="onLogout()">
                <span class="user-menu-option-icon"><i class="far fa-sign-out-alt"></i></span>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>

</div>

<div class="main-container">

    <div class="sidebar-container">

        <lib-multi-stage-expansion-panel class="sidebar-inner-container" [isVertical]="false" [midStageSizesInPixels]="[60]" [stage]="sidebarOpen ? 2 : 1">

            <lib-dashboard-sidebar
                [userName]="userName"
                [currentUserPermissions]="currentUserPermissions"
                [sidebarOpen]="sidebarOpen"
                [sidebarItems]="sidebarItems"
                (mouseOverItem)="onMouseOverItem($event)"
                (mouseOutItem)="onMouseOutItem()"
                [subMenuShownForItem]="subMenuShown ? subMenuParentItem : null"
                (navigateTo)="onNavigateTo($event)">
            </lib-dashboard-sidebar>

        </lib-multi-stage-expansion-panel>

        <lib-dashboard-submenu
            [items]="subMenuParentItem?.subItems"
            [menuVertPosInPixels]="subMenuVertPosInPixels"
            [menuShown]="subMenuShown"
            [currentUserPermissions]="currentUserPermissions"
            (showMenu)="onShowSubMenu()"
            (hideMenu)="onHideSubMenu()"
            (navigateTo)="onNavigateTo($event)">
        </lib-dashboard-submenu>

    </div>

    <div class="page-container">

        <ng-content></ng-content>

    </div>

</div>
