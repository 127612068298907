<div class="outer-container">

    <div class="top-bar">

        <lib-title-bar
            [crumbs]="[{ 'label': 'Transactions' , 'link': '/dashboard/transactions' }, { 'label': transaction?.token }]">

<!--

    AC_todo : Determine VOID & CAPTURE conditions & advance the below early prototype implementation. (eg. likely need refund style modals)

            <lib-action-button
                *ngIf="payment?.status == 'Captured' || payment?.status == 'Authorized'"
                class="void-button"
                label="Void"
                [kind]="ActionButtonKind.PRIMARY"
                (click)="voidPayment.emit(payment)">
            </lib-action-button>

            <lib-action-button
                *ngIf="payment?.status == 'Authorized'"
                class="capture-button"
                label="Capture"
                [kind]="ActionButtonKind.PRIMARY"
                (click)="capturePayment.emit(payment)">
            </lib-action-button>

-->

            <lib-action-button
                *ngIf="hasRefundActionAvailable()"
                class="refund-button"
                label="Refund"
                (click)="onClickRefund()"
                fontImage="far fa-dollar-sign fa-bg"
                fontImageExtra="fas fa-arrow-left"
                [minWidthInPixels]="90">
            </lib-action-button>

        </lib-title-bar>

    </div>

    <div class="transaction-container">

        <div class="transaction-details-outer-container">

            <div class="transaction-details-middle-container">

                <div class="transaction-details-title">

                    <div class="title-container">
                        <div class="title">
                            <lib-text-common>
                                <span class="title-prefix">Transaction Token: </span>{{ transaction?.token }}
                            </lib-text-common>
                        </div>
                        <div class="title-status-chip-container">
                            <lib-transaction-status-chip
                                [status]="transaction?.status">
                            </lib-transaction-status-chip>
                        </div>
                    </div>

                    <div class="transaction-buttons-container">
                        <lib-action-button
                            *ngIf="transaction"
                            class="receipt-button"
                            label="Receipt"
                            [kind]="ActionButtonKind.SECONDARY"
                            (click)="downloadReceipt.emit(transaction)"
                            fontImage="fas fa-receipt fa-bg"
                            [minWidthInPixels]="90">
                        </lib-action-button>
                    </div>

                </div>

                <div class="transaction-details-inner-container">

                    <ng-container *ngIf="!transactionLoading; else spinner">

                        <lib-text-field [label]="'Date (' + LocalTimePoint.formatZ() + ')'">
                            <lib-date-time-label *ngIf="transaction.dateUtc" [singleLine]="true" [dateUtc]="transaction.dateUtc"></lib-date-time-label>
                        </lib-text-field>

                        <lib-text-field label="Amount">
                            {{ transaction.amount | vituCurrencyUsd }}
                        </lib-text-field>

                        <lib-text-field label="Payer Name">
                            {{ transaction.paymentMethod?.payer?.fullName | uppercase }}
                        </lib-text-field>

                        <lib-text-field label="Card">
                            {{ transaction.card ? (transaction.card.cardBrand + ' (' + transaction.card.cardType + ')' | uppercase) : "" }}
                        </lib-text-field>

                        <lib-text-field label="Card Number">
                            {{ getCardNumber() }}
                        </lib-text-field>

                        <lib-text-field label="Card Expiration">
                            {{ transaction.card?.expirationDate }}
                        </lib-text-field>

                        <lib-text-field label="Type">
                            {{ displayTextForTransactionType }}
                        </lib-text-field>

                        <lib-text-field label="Auth Code">
                            {{ transaction.authCode }}
                        </lib-text-field>

                        <lib-digital-wallet-image-field [kind]="transaction?.digitalWalletIndicator">
                        </lib-digital-wallet-image-field>

                        <lib-text-field id="avsTextField" label="AVS">
                            {{ getAvsDetailFieldValue(transaction.avsResult, transaction.avsResultDescription) }}
                        </lib-text-field>

                        <lib-text-field label="CVV">
                            {{ getCvvDetailFieldValue(transaction.cvvResult, transaction.cvvResultDescription) }}
                        </lib-text-field>

                        <lib-text-field [label]="'Date Settled (' + LocalTimePoint.formatZ() + ')'">
                            <lib-date-time-label *ngIf="transaction.settlementDateUtc" [singleLine]="true" [dateUtc]="transaction.settlementDateUtc"></lib-date-time-label>
                        </lib-text-field>

                        <lib-text-field label="MID">
                            {{ transaction.mid }}
                        </lib-text-field>

                        <lib-text-field label="TID">
                            {{ transaction.tid }}
                        </lib-text-field>

                        <lib-text-field label="Gateway">
                            {{ transaction.gateway }}
                        </lib-text-field>

                        <lib-text-field label="Funding ID">
                            {{ transaction.fundingRef }}
                        </lib-text-field>

                        <lib-text-field label="Date Funded">
                            <lib-date-time-label *ngIf="transaction.fundingDateUtc"
                                [rawDateWithNoTime]="true"
                                [singleLine]="true"
                                [dateUtc]="transaction.fundingDateUtc">
                            </lib-date-time-label>
                        </lib-text-field>

                        <lib-text-field id="processingInfoTextField" label="Processing Info">
                            {{ transaction.processingInfo }}
                        </lib-text-field>

                        <lib-text-field label="Description">
                            {{ transaction.description }}
                        </lib-text-field>

                        <lib-text-field label="Payment Token">
                            {{ transaction.paymentToken }}
                        </lib-text-field>

                    </ng-container>

                </div>

            </div>

            <div class="transaction-details-fees-container">
                <div class="transaction-details-fees-title">
                    Transaction Fees
                </div>
                <div class="transaction-details-fees-inner-container">
                    <ng-container *ngIf="(!relatedPaymentLoading && !paymentFeesLoading); else spinner">

                        <lib-text-field *ngFor="let feePart of feeParts" [label]="feePart.category">
                            {{ feePart.value | vituCurrencyUsd }}
                        </lib-text-field>

                        <lib-text-field label="Total Fees">
                            {{ feeTotal | vituCurrencyUsd }}
                        </lib-text-field>

                        <lib-text-field label="% of Amount">
                            {{ (payment?.amount ? ((100 * feeTotal) / payment?.amount) : 0) | vituPercentage }}
                        </lib-text-field>

                        <lib-text-field *ngIf="transaction?.surcharge?.amount" label="Surcharge">
                            {{ transaction.surcharge.amount | vituCurrencyUsd }}
                        </lib-text-field>

                        <lib-text-field *ngIf="serviceFeeAmount > 0" label="Service Fee">
                            {{ serviceFeeAmount | vituCurrencyUsd }}&nbsp;&nbsp;&nbsp;{{ '(' + (serviceFeePercent  | vituPercentage) + ')' }}
                        </lib-text-field>

                        <lib-text-field *ngIf="serviceFeeRefundAmount > 0" label="Service Fee Refund">
                            {{ serviceFeeRefundAmount | vituCurrencyUsd }}
                        </lib-text-field>

                        <lib-text-field *ngIf="serviceFeeRefundAmount > 0 && serviceFeeRefundAmount > 0" label="Total Service Fee">
                            {{ (serviceFeeAmount - serviceFeeRefundAmount) | vituCurrencyUsd }}
                        </lib-text-field>

                    </ng-container>
                </div>
            </div>

        </div>

    </div>

    <div class="divider"></div>

    <div class="payment-container">

        <mat-tab-group class="payment-tab-group" [selectedIndex]="loadContext">

            <mat-tab [label]="getHistoryTabLabel()">

                <app-transactions-table
                    class="tab-container"
                    [rowsData]="payment.transactions"
                    [rowsDataLoading]="relatedPaymentLoading"
                    selectedRowProperty="token"
                    [selectedRowValue]="transaction.token"
                    (rowSelected)="onTransactionSelected($event)">
                </app-transactions-table>

            </mat-tab>

            <mat-tab [label]="getChargebacksTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="disputeColumnDefs"
                    [rowsData]="chargebacks"
                    [rowsDataLoading]="!paymentError && relatedPaymentLoading"
                    [rowsDataError]="paymentError"
                    rowsDataErrorMessage="Error Loading Chargebacks"
                    rowsDataEmptyMessage="No Chargebacks"
                    selectedRowProperty="token"
                    (rowSelected)="onChargebackRowSelected($event)">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-disputes-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-disputes-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getRetrievalsTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="disputeColumnDefs"
                    [rowsData]="retrievals"
                    [rowsDataLoading]="!paymentError && relatedPaymentLoading"
                    [rowsDataError]="paymentError"
                    rowsDataErrorMessage="Error Loading Retrievals"
                    rowsDataEmptyMessage="No Retrievals"
                    selectedRowProperty="token"
                    (rowSelected)="onRetrievalRowSelected($event)">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-disputes-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-disputes-table-cell-content>
                    </ng-template>

                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getFeesTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="feeColumnDefs"
                    [rowsData]="paymentFees"
                    [rowsDataLoading]="relatedPaymentLoading"
                    [rowsDataError]="paymentFeesError"
                    rowsDataErrorMessage="Error Loading Fees"
                    rowsDataEmptyMessage="No Fees">

                    <ng-template let-element="element" let-columnId="columnId">
                        <app-fees-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            [paymentAmount]="payment?.amount">
                        </app-fees-table-cell-content>
                    </ng-template>

                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getAdditionalInformationTabLabel()">
                <ng-container *ngIf="(getCountOfAdditionalInformation() === 0) then noAdditionalInformation"></ng-container>
                <ng-container *ngIf="(getCountOfAdditionalInformation() > 0) then additionalInformation"></ng-container>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #noAdditionalInformation>
    <div class="centered-message">
        No Additional Information
    </div>
</ng-template>

<ng-template #additionalInformation>
    <div class="additional-information-container">
        <table>
            <tr *ngFor="let item of transaction.meta | keyvalue">
                <td class="additional-information-item additional-information-key">{{ item.key }} :</td>
                <td class="additional-information-item additional-information-value">{{ item.value }}</td>
            </tr>
        </table>
    </div>
</ng-template>
