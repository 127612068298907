/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { EventsService } from './services/events.service';
import { MerchantService } from './services/merchant.service';
import { PaymentMethodsService } from './services/payment-methods.service';
import { DisputesService } from './services/disputes.service';
import { PaymentFormService } from './services/payment-form.service';
import { PaymentsService } from './services/payments.service';
import { CloverPosService } from './services/clover-pos.service';
import { ClientsService } from './services/clients.service';
import { ClientSecretsService } from './services/client-secrets.service';
import { PermissionsService } from './services/permissions.service';
import { RolesService } from './services/roles.service';
import { UsersService } from './services/users.service';
import { WebhooksService } from './services/webhooks.service';
import { BillingService } from './services/billing.service';
import { ReportsService } from './services/reports.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    EventsService,
    MerchantService,
    PaymentMethodsService,
    DisputesService,
    PaymentFormService,
    PaymentsService,
    CloverPosService,
    ClientsService,
    ClientSecretsService,
    PermissionsService,
    RolesService,
    UsersService,
    WebhooksService,
    BillingService,
    ReportsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
