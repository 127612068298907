import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as UserActions from "@merchant_app/storage/user/user.actions";
import * as UserSelectors from "@merchant_app/storage/user/user.selectors";
import * as CurrentUserSelectors from "@merchant_app/storage/current-user/current-user.selectors";
import { UserDetailsDto } from "@merchant_api/models/user-details-dto";
import { CreateUserDto } from "@merchant_api/models/create-user-dto";
import { GoBackAction } from "@merchant_app/storage/router/router.actions";

@Component({
    selector: "app-user",
    templateUrl: "./user.component.html",
    styleUrls: ["./user.component.less"]
})
export class UserComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    user$ = this.store.select(UserSelectors.getUserInfo);
    roleList$ = this.store.select(UserSelectors.getUserRoleList);
    loading$ = this.store.select(UserSelectors.getUserLoading);
    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;

        if (this.isCreate) {
            this.store.dispatch(new UserActions.GetNewUserAction());
        }
        else {
            this.store.dispatch(new UserActions.GetUserAction(this.route.snapshot.params.id));
        }
    }

    updateUser(user: UserDetailsDto) {

        this.store.dispatch(new UserActions.UpdateUserAction(user));
    }

    createUser(user: CreateUserDto) {

        this.store.dispatch(new UserActions.CreateUserAction(user));
    }

    deleteUser(userId: string) {

        this.store.dispatch(new UserActions.DeleteUserAction(userId));
    }

    resetUserPassword(userId: string) {

        this.store.dispatch(new UserActions.ResetUserPasswordAction(userId));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
