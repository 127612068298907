<div [formGroup]="formGroup">

    <mat-form-field class="form-field" [ngClass]="{'global-form-field-highlight-non-empty': _isFilter}">

        <mat-label>
            <span>
                {{ displayLabel }}
            </span>
            <lib-vitu-tooltip
                *ngIf="!!infoTip?.length"
                class="info-tip"
                [text]="infoTip">
                    <i class="far fa-info-circle"></i>            
            </lib-vitu-tooltip>
        </mat-label>

        <input
            matInput
            dateRangePicker
            [formControlName]="formFieldName"
            [formControl]="formControl"
            #datePicker="appDateRangePicker"
            [restrictPast]="_restrictPast"
            [restrictFuture]="_restrictFuture"
            [restrictToPastMonth]="_restrictToPastMonth"
            [restrictToDateOfBirth]="_restrictToDateOfBirth"
            [clearValue]="clearValue"
            [readonly]="_readonly"
            [singleDateMode]="_singleDateMode"
            [required]="hasRequiredValidator"
            [disableControl]="_disabled"
            (focus)="updateOutlineGap()">

        <button
            mat-button matSuffix mat-icon-button
            libStopPropagation
            (click)="onClickIcon(datePicker)">
            <div class="datepicker-suffix-image"></div>
        </button>

        <mat-error *ngIf="errors?.required">Field is required</mat-error>

    </mat-form-field>

</div>
