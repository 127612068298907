import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getEventsState = (store: IStore) => store.events;

export const getEvents = createSelector(
    getEventsState,
    eventsState => eventsState.rows,
);

export const getEventsLoading = createSelector(
    getEventsState,
    eventsState => eventsState.loading,
);

export const getEventsError = createSelector(
    getEventsState,
    eventsState => eventsState.error,
);
