import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

@Component({
    selector: "lib-vitu-form-submit-button",
    templateUrl: "./vitu-form-submit-button.component.html",
    styleUrls: ["./vitu-form-submit-button.component.less"]
})
export class VituFormSubmitButtonComponent implements OnInit, OnDestroy {

    @Input() label = "";
    @Input() neverDisable = false;

    @Output() clicked = new EventEmitter<void>();

    set disabled(value: boolean) {
        if (!this.neverDisable) {
            this._disabled = value;
        }
    }
    get disabled() {
        return this._disabled;
    }
    private _disabled = true;

    private handleClick = (event: MouseEvent) => {
        if (this.disabled) {
            event.stopPropagation();
        }
        else {
            this.clicked.emit();
        }
    };

    constructor(private hostElementRef: ElementRef) {}

    ngOnInit() {
        this._disabled = !this.neverDisable;
        // use addEventListener as need to use capture mode
        this.hostElementRef.nativeElement.addEventListener("click", this.handleClick, true);
    }

    ngOnDestroy() {
        this.hostElementRef.nativeElement.removeEventListener("click", this.handleClick, true);
    }

}
