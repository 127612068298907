import { Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { IStore } from "@merchant_app/storage/store";
import { map } from "rxjs/operators";
import { DownloadReceiptAction } from "@merchant_app/storage/transaction/transaction.actions";
import {
    VoidPaymentTransactionAction, CapturePaymentTransactionAction, RefundPaymentTransactionAction
} from "@merchant_app/storage/payment/payment.actions";
import { getNextPaymentActionsState } from "@merchant_app/storage/next-payment-actions/next-payment-actions.selectors";
import { getPaymentState } from "@merchant_app/storage/payment/payment.selectors";
import { getTransactionState } from "@merchant_app/storage/transaction/transaction.selectors";
import { getTransactionSiblingsState } from "@merchant_app/storage/transaction-siblings/transaction-siblings.selectors";
import { PaymentDetails } from "@merchant_api/models/payment-details";
import { TransactionDetails } from "@merchant_api/models/transaction-details";
import { getCurrentUserPermissions } from "@merchant_app/storage/current-user/current-user.selectors";

@Component({
    selector: "app-transaction",
    templateUrl: "./transaction.component.html",
    styleUrls: ["./transaction.component.less"]
})
export class TransactionComponent {

    transaction$ = this.store.pipe(
        select(getTransactionState),
        map(i => i.record),
    );

    transactionLoading$ = this.store.pipe(
        select(getTransactionState),
        map(i => i.loading),
    );

    payment$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.record),
    );

    paymentLoading$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.loading),
    );

    paymentError$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.error),
    );

    paymentFees$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.fees),
    );

    paymentFeesLoading$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.feesLoading),
    );

    paymentFeesError$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.feesError),
    );

    nextPaymentActions$ = this.store.pipe(
        select(getNextPaymentActionsState),
        map(i => i.record),
    );

    nextPaymentActionsLoading$ = this.store.pipe(
        select(getNextPaymentActionsState),
        map(i => i.loading),
    );

    transactionSiblings$ = this.store.pipe(
        select(getTransactionSiblingsState),
        map(i => i.record),
    );

    transactionSiblingsLoading$ = this.store.pipe(
        select(getTransactionSiblingsState),
        map(i => i.loading),
    );

    constructor(
        private store: Store<IStore>,
    ) { }

    refundPayment(refund: {payment: PaymentDetails; amount: number; serviceFeeAmount: number|null}) {
        this.store.dispatch(new RefundPaymentTransactionAction(refund));
    }

    voidPayment(payment: PaymentDetails) {

        // const voidObj = {
        //     amount: payment.amount,
        //     transactionToken: payment.transactionToken,
        //     description: "Void Payment demo",
        // };

        // this.store.dispatch(new VoidPaymentTransactionAction(voidObj, payment.transactionToken));
    }

    capturePayment(payment: PaymentDetails) {

        // const capture = {

        //     amount: payment.amount,
        //     transactionToken: payment.transactionToken,
        //     description: "Capture Payment demo",
        // };

        // this.store.dispatch(new CapturePaymentTransactionAction(capture, payment.transactionToken));
    }

    downloadReceipt(transaction: TransactionDetails) {

        this.store.dispatch(DownloadReceiptAction({ transaction }));
    }
}
