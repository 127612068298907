import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "lib-multi-stage-expansion-panel",
  templateUrl: "./multi-stage-expansion-panel.component.html",
  styleUrls: ["./multi-stage-expansion-panel.component.less"]
})
export class MultiStageExpansionPanelComponent implements AfterViewInit {

    @Input() isVertical = true;
    @Input() midStageSizesInPixels: number[] = [];
    @Input() stage: number;

    @ViewChild("contentWrapper") contentWrapper: ElementRef;

    get panelSizeInPixels() {
        const stageSizesInPixels = [0];
        stageSizesInPixels.push(...this.midStageSizesInPixels);
        stageSizesInPixels.push(this.maxSizeInPixels);
        return ((this.stage >= 0) && (this.stage < stageSizesInPixels.length)) ? stageSizesInPixels[this.stage] : 0;
    }

    // Set the following value to a deliberately large value, as it gets overwritten by the real value
    // in ngAfterViewInit, but if we set it to 0 there is a visual 'growing' of a panel which is initialized
    // in the max stage (i.e. initing it to this high value means the panel appears instantly in the max state)
    maxSizeInPixels = 9999;

    constructor(private cd: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.maxSizeInPixels = this.isVertical
                                    ? this.contentWrapper.nativeElement.scrollHeight
                                    : this.contentWrapper.nativeElement.scrollWidth;
        this.cd.detectChanges();
    }

}
