<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'name'">
        <lib-text-table-column>
            {{ element?.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'category'">
        <lib-text-table-column>
            {{ element?.category | spaceBetweenCaps }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'amount'">
        <lib-text-table-column>
            {{ element?.amount | vituCurrencyUsd  }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.amount ?? 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'isAbsorbed'">
        <lib-binary-status-icon
            [value]="element?.isAbsorbed"
            [showPositiveOnly]="true">
        </lib-binary-status-icon>
        <lib-table-column-sort [key]="element?.isAbsorbed ? 1 : 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
