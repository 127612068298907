import { ErrorHandler, Injectable } from "@angular/core";
import { VituHttpError } from "../misc/vitu-http-errors";
import { LoggerService } from "./logger.service";
import StackdriverErrorReporter from "stackdriver-errors-js";
import { CurrentUser, Environment } from "../misc";

enum ErrorReportingMode {
    UnhandledErrorsOnly,
    UnhandledErrorsAndHttpErrors
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    public currentUser: CurrentUser = null;

    private errorReportingMode = ErrorReportingMode.UnhandledErrorsAndHttpErrors;
    private errorHandler: StackdriverErrorReporter;

    constructor(
        private loggerService: LoggerService,
        private environment: Environment) {

        this.errorHandler = new StackdriverErrorReporter();
        this.errorHandler.start({
            key: environment?.stackDriverErrorReporting?.key,
            projectId: environment?.stackDriverErrorReporting?.projectId,
            context: {}     // Important!
        });

    }

    // Override platform callback for unhandled exceptions
    handleError(error: Error) {

        const httpError = error instanceof VituHttpError;
        const reportUnhandledAndHttpErrors = (this.errorReportingMode === ErrorReportingMode.UnhandledErrorsAndHttpErrors);
        // DON'T log HTTP errors here if we're in UnhandledErrorsAndHttpErrors mode. (Avoids duplicate log)
        if (!(httpError && reportUnhandledAndHttpErrors)) {
            this.logError(error);
        }

    }

    logHttpErrorIfNecessary(error: VituHttpError) {

        // DO log HTTP errors here if we're in UnhandledErrorsAndHttpErrors mode.
        if (this.errorReportingMode === ErrorReportingMode.UnhandledErrorsAndHttpErrors) {
            this.logError(error);
        }

    }

    private logError(error: Error) {

        // Log to browser console on all environments
        this.loggerService.error(error);

        // Don't log to cloud if dev environment
        if (this.environment && !this.environment.isDevelopment) {
            const appName = this.environment?.stackDriverErrorReporting?.appName?.toUpperCase();
            const env = this.environment?.descriptor?.toUpperCase();

            // Give it a message prefix which can easily be searched for to isolate all related errors
            error.message = `UI_PANEL_${appName}_${env}: ${error.message}`;
            // 'setUser' can hold an arbitrary string of 'context' info, so add id, name & permissions into it
            const userPayload =
                `ID[${this.currentUser?.id}]:` +
                `NAME[${this.currentUser?.name}]:` +
                `PERMISSIONS[${this.currentUser?.permissions?.toString()}]`;
            this.errorHandler.setUser(userPayload);
            this.errorHandler.report(error);
        }

    }

}
