import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, TableSorting, LocalTimePoint, Pager, ActionButtonKind } from "shared-lib";
import { PaymentOrderBy } from "@merchant_api/models/payment-order-by";

import { Sorting, Filter } from "@merchant_app/storage/payments/payments.state";
import { Payment } from "@merchant_api/models/payment";
import { CurrentUserDto } from "@merchant_app/storage/current-user/current-user.state";
import { CurrentUserPermissions } from "@merchant_app/shared/current-user-permissions";

type RowsData = Payment[];

@Component({
    selector: "app-payment-log",
    templateUrl: "./payment-log.component.html",
    styleUrls: ["./payment-log.component.less"],
})
export class PaymentLogComponent {

    @Input() currentUser: CurrentUserDto;
    @Input() loading = false;
    @Input() error: Error;
    @Input() exportLoading = false;
    @Input() rowsData: RowsData;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;
    @Input() filter: Filter;

    @Output() filterChanged = new EventEmitter<Filter>();
    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() exportToCsv = new EventEmitter<void>();

    ActionButtonKind = ActionButtonKind;

    readonly paymentsColumnDefs: ColumnDef[];

    constructor(private router: Router, private currPerms: CurrentUserPermissions) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.paymentsColumnDefs = [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: PaymentOrderBy.DateAndTime, title: `Date (${LocalTimePoint.formatZ()})`, flexWidthBasisInPixels: 100,
                flexWidthGrow: 1, canSort: true},
            { id: "Token", title: "Token", flexWidthBasisInPixels: 163, flexWidthGrow: 4},
            { id: "TransactionStatus", title: "Status", flexWidthBasisInPixels: 150, flexWidthGrow: 0},
            { id: PaymentOrderBy.Amount, title: "Amount", flexWidthBasisInPixels: 96, flexWidthGrow: 1, canSort: true},
            { id: "CardBrand", title: "Card", flexWidthBasisInPixels: 50, flexWidthGrow: 2},
            { id: PaymentOrderBy.CardNumber, title: "Card Number", flexWidthBasisInPixels: 130, flexWidthGrow: 2, canSort: true},
            { id: "DigitalWallet", title: "Digital Wallet", flexWidthBasisInPixels: 75, flexWidthGrow: 2},
            { id: PaymentOrderBy.PayerName, title: "Payer Name", flexWidthBasisInPixels: 80, flexWidthGrow: 8, canSort: true},
            { id: "Fees", title: "Fees", flexWidthBasisInPixels: 96, flexWidthGrow: 1},
            { id: "DisputeType", title: "Dispute Type", flexWidthBasisInPixels: 80, flexWidthGrow: 2},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sort: Sorting|TableSorting) {
        const sorting = sort as TableSorting;
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as (null | PaymentOrderBy),
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(rowData: string|Payment): void {
        if (!this.hasTransactionsGetAndPaymentsGetPermissions) {
            return;
        }
        const payment = rowData as Payment;
        this.router.navigate([`/dashboard/transactions/${payment.recentTransaction}`]);
    }

    exportButtonDisabled() {
        return this.exportLoading
            || !this.filter?.localDateInterval?.from
            || !this.filter?.localDateInterval?.to;
    }

    get hasTransactionsGetAndPaymentsGetPermissions(): boolean {
        return this.currPerms.has(["core.payments.get", "core.transactions.get"]);
    }

}
