import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextCommonComponent } from "./text-common.component";
import { RouterModule } from "@angular/router";
import { TextTruncateSafeLengthPipe } from "./text-truncate-safe-length.pipe";
import { VituTooltipModule } from "../../tooltip";

@NgModule({
    declarations: [
        TextCommonComponent,
        TextTruncateSafeLengthPipe
    ],
    imports: [
        CommonModule,
        VituTooltipModule,
        RouterModule
    ],
    exports: [
        TextCommonComponent,
        TextTruncateSafeLengthPipe
    ]
})
export class TextCommonModule { }
