<div class="outer-container" [ngClass]="{'tone-positive' : tone === VituToastTone.Positive, 'tone-negative' : tone === VituToastTone.Negative, 'tone-neutral' : tone === VituToastTone.Neutral, 'has-button': !!buttonText }">

    <div class="icon-container" [ngClass]="{'tone-positive' : tone === VituToastTone.Positive, 'tone-negative' : tone === VituToastTone.Negative, 'tone-neutral' : tone === VituToastTone.Neutral}">

        <ng-container [ngSwitch]="tone">

            <ng-container *ngSwitchCase="VituToastTone.Positive">
                <i class="fas fa-check-circle"></i>
            </ng-container>
        
            <ng-container *ngSwitchCase="VituToastTone.Negative">
                <i class="fas fa-exclamation-circle"></i>
            </ng-container>
        
            <ng-container *ngSwitchDefault>
                <i class="far fa-comment"></i>
            </ng-container>
        
        </ng-container>

    </div>

    <div class="text-container">
        <div *ngFor="let textLine of text" class="text-line">
            {{ textLine }}
        </div>
    </div>

    <div *ngIf="buttonText" class="button-container">
        <lib-action-button
            [kind]="ActionButtonKind.ALERT"
            [alertTone]="alertTone"
            largeButton
            [label]="buttonText"
            (click)="onButtonClick()">
        </lib-action-button>
    </div>

</div>
