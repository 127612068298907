import { RoleState } from "./role.state";
import { ActionTypes } from "./role.actions";

export const RoleReducer = (
    state: RoleState = new RoleState(),
    action: any,
  ): RoleState => {

    switch (action.type) {

        case ActionTypes.GetRole:
        case ActionTypes.GetNewRole: {
            return {
                ...state,
                role: null,
                permissionList: null,
                loading: true
            };
        }

        case ActionTypes.GetRoleSucceed: {
            return {
                ...state,
                role: action.role,
                permissionList: action.permissionList,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetRoleFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        default: {
            return state;
        }
    }

};
