import { Action } from "@ngrx/store";

export enum ActionTypes {
    NoOp = "[Generic] NoOp",
}
export class GenericNoOpAction implements Action {

    constructor() { }

    readonly type = ActionTypes.NoOp;
}
