<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="TransactionOrderBy.Amount">
        <lib-text-table-column>
            {{ element.amount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="TransactionOrderBy.TransactionId">
        <lib-text-table-column>
            {{ element.transactionId }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="TransactionOrderBy.TransactionType">
        <lib-text-table-column>
            {{ displayTextForTransactionType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'TransactionStatus'">
        <lib-transaction-status-chip
            [status]="element?.status">
        </lib-transaction-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="TransactionOrderBy.PayerName">
        <lib-text-table-column>
            {{ getDisplayTextForPayerName() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'CardBrand'">
        <span *ngIf="element.card?.cardBrand" class="card-image">
            <i [class]="'fab fa-cc-' + element.card.cardBrand.toLowerCase() + ' fa-2x'"></i>
        </span>
        <lib-text-table-column *ngIf="!element.card?.cardBrand">
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="TransactionOrderBy.CardNumber">
        <lib-text-table-column>
            {{ getDisplayTextForCardNumber() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="TransactionOrderBy.DateAndTime">
        <lib-date-time-label [dateUtc]="element.dateUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'AuthCode'">
        <lib-text-table-column>
            {{ element.authCode }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'Fees'">
        <lib-text-table-column>
            {{ element.fees | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'DigitalWallet'">
        <lib-digital-wallet-image-table-column
            [kind]="element.digitalWallet">
        </lib-digital-wallet-image-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'TransactionToken'">
        <lib-text-table-column>
            {{ element.token }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





