import { Directive, Renderer2, ElementRef, HostListener, Input } from "@angular/core";
import { DateRangeModal } from "./date-range-modal/date-range-modal.service";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { DateRange } from "./date-range";
import { DateLabel } from "./date-range-modal/date-label";
import { DateTimeFormatUtils } from "../../date-time-format-utils";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[dateRangePicker]",
    exportAs: "appDateRangePicker",
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DateRangePickerDirective,
        multi: true,
    }],
})
export class DateRangePickerDirective implements ControlValueAccessor {

    @Input() singleDateMode: boolean;
    @Input() restrictPast: boolean;
    @Input() restrictFuture: boolean;
    @Input() restrictToPastMonth: boolean;
    @Input() restrictToDateOfBirth: boolean;
    @Input() clearValue: DateRange;

    constructor(
        private dateRangeModalService: DateRangeModal,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    @HostListener("focus")
    onTouched: () => void;
    onChange: (value: any) => void;

    private currentValue: DateRange;
    disabled: boolean;

    @HostListener("click")
    async openModal() {

        this.onTouched();

        try {

            const newValue = await this.dateRangeModalService.create(
                                        this.clearValue,
                                        this.currentValue,
                                        {
                                            restrictPast: this.restrictPast,
                                            restrictFuture: this.restrictFuture,
                                            restrictToPastMonth: this.restrictToPastMonth,
                                            restrictToDateOfBirth: this.restrictToDateOfBirth,
                                            singleDateMode: this.singleDateMode
                                        }
                                    ).toPromise();

            const isDefaultClearData = ((this.clearValue == null) && (newValue === undefined));

            if (newValue || isDefaultClearData) {
                this.onChange(newValue);
                this.doWriteValue(newValue);
            }

        } catch {}
    }

    @HostListener("change")
    handleInputChange() {

        const input = this.elementRef.nativeElement;

        if (input.value == "") {

            this.onChange(null);
            this.doWriteValue(null);
        }
        else {

            this.renderValue(this.currentValue);
        }
    }

    writeValue(value: DateRange) {

        this.doWriteValue(value);
    }

    renderValue(value: DateRange) {
        let formattedValue = "";

        if (value) {

            const hasLabel = value?.label && value?.label != DateLabel.CUSTOM;

            const singleDate = DateTimeFormatUtils.isSameDay(value.from, value.to);

            if (hasLabel && !this.singleDateMode) {

                formattedValue = value.label;
            }
            else if (singleDate) {

                formattedValue = DateTimeFormatUtils.formatDateShort(value.from);
            }
            else {

                formattedValue =
                `${DateTimeFormatUtils.formatDateShort(value.from)} - ${DateTimeFormatUtils.formatDateShort(value.to)}`;
            }
        }

        this.renderer.setProperty(this.elementRef.nativeElement, "value", formattedValue);
    }

    registerOnChange(onChange) {

        this.onChange = onChange;
    }

    registerOnTouched(onTouched) {

        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {

        this.disabled = disabled;
        this.renderer.setProperty(this.elementRef.nativeElement, "disabled", disabled);
    }

    private doWriteValue(value: DateRange) {
        this.currentValue = value;
        this.renderValue(value);
    }
}
