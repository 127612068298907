import { Pipe, PipeTransform } from "@angular/core";
import { VituNumberFormatCommon } from "./vitu-number-format-common";

@Pipe({
  name: "vituPercentage"
})
export class VituNumberFormatPercentagePipe implements PipeTransform {

    // arg1 : (optional) => pass "showZero" if you want to show zero values (i.e. "0.00 %")
    transform(value: any, arg1?: string): string {

        let retVal;

        try {
            retVal = VituNumberFormatCommon.transform(value, true, arg1);
            if (retVal !== "—") {
                retVal = `${retVal} %`;
            }
        }
        catch (error) {
            retVal = "ERROR!";
        }

        return retVal;
    }

}

