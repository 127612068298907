/* eslint-disable max-len */
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { AuthConfig, OAuthModule } from "angular-oauth2-oidc";

import { AppRoutingModule } from "./app-routing.module";

import { ApiModule as CoreApiModule } from "@merchant_api/api.module";
import { ApiModule as PortalApiModule } from "@Portal/VituPayApi/api.module";

import { SharedModule } from "./shared/shared.module";

import { environment } from "@merchant_environments/environment";
import { EffectsConfiguration } from "./storage/effects-configuration";
import { ReducerConfiguration, ReducerMap } from "./storage/reducer-configuration";

import { AppComponent } from "./app.component";
import { TransactionsComponent } from "./pages/transactions/transactions.component";

import { TransactionLogComponent } from "./pages/transactions/transaction-log/transaction-log.component";
import { TransactionComponent } from "./pages/transaction/transaction.component";
import { TransactionDetailComponent } from "./pages/transaction/transaction-detail/transaction-detail.component";
import { UsersComponent } from "./pages/users/users.component";
import { UserLogComponent } from "./pages/users/user-log/user-log.component";

import {
    TransactionLogFiltersComponent
} from "./pages/transactions/transaction-log/transaction-log-filters/transaction-log-filters.component";
import {
    DisputesTableCellContentComponent
} from "./pages/transaction/transaction-detail/disputes-table-cell-content/disputes-table-cell-content.component";
import { UserComponent } from "./pages/user/user.component";
import { UserDetailsComponent } from "./pages/user/user-details/user-details.component";
import { UsersTableCellContentComponent } from "./pages/users/user-log/users-table-cell-content/users-table-cell-content.component";
import {
    FeesTableCellContentComponent
} from "./pages/transaction/transaction-detail/fees-table-cell-content/fees-table-cell-content.component";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { PageUnavailableComponent } from "./pages/page-unavailable/page-unavailable.component";
import { RolesComponent } from "./pages/roles/roles.component";
import { RoleLogComponent } from "./pages/roles/role-log/role-log.component";
import { RolesTableCellContentComponent } from "./pages/roles/role-log/roles-table-cell-content/roles-table-cell-content.component";
import { RoleDetailsComponent } from "./pages/role/role-details/role-details.component";
import { RoleComponent } from "./pages/role/role.component";
import { WebhooksComponent } from "./pages/webhooks/webhooks.component";
import { WebhookLogComponent } from "./pages/webhooks/webhook-log/webhook-log.component";
import {
    WebhooksTableCellContentComponent
} from "./pages/webhooks/webhook-log/webhooks-table-cell-content/webhooks-table-cell-content.component";
import { WebhookComponent } from "./pages/webhook/webhook.component";
import { WebhookDetailsComponent } from "./pages/webhook/webhook-details/webhook-details.component";
import { ReportsLogComponent } from "./pages/reports/reports-log/reports-log.component";
import {
    ReportsTableCellContentComponent
} from "./pages/reports/reports-log/reports-table-cell-content/reports-table-cell-content.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { DownloadReportModalComponent } from "./shared/download-report-modal/download-report-modal.component";
import { ClientsComponent } from "./pages/clients/clients.component";
import { ClientLogComponent } from "./pages/clients/client-log/client-log.component";
import {
    ClientsTableCellContentComponent
} from "./pages/clients/client-log/clients-table-cell-content/clients-table-cell-content.component";
import { ClientDetailsComponent } from "./pages/client/client-details/client-details.component";
import { ClientComponent } from "./pages/client/client.component";
import {
    ClientSecretsTableCellContentComponent
} from "./pages/client/client-details/client-secrets-table-cell-content/client-secrets-table-cell-content.component";
import { CreateClientSecretModalComponent } from "./shared/create-client-secret-modal/create-client-secret-modal.component";
import { authConfig } from "./auth-config";
import { AuthService } from "shared-lib";
import { DashboardModule } from "./pages/dashboard/dashboard.module";
import { ConfigurationLogComponent } from "./pages/configurations/configuration-log/configuration-log.component";
import {
    ConfigurationsTableCellContentComponent
} from "./pages/configurations/configuration-log/configurations-table-cell-content/configurations-table-cell-content.component";
import { ConfigurationsComponent } from "./pages/configurations/configurations.component";
import { FundingComponent } from "./pages/funding/funding.component";
import { FundingLogComponent } from "./pages/funding/funding-log/funding-log.component";
import {
    FundingTableCellContentComponent
} from "./pages/funding/funding-log/funding-table-cell-content/funding-table-cell-content.component";
import { FundingLogFiltersComponent } from "./pages/funding/funding-log/funding-log-filters/funding-log-filters.component";
import { FundingItemComponent } from "./pages/funding-item/funding-item.component";
import { FundingItemDetailsComponent } from "./pages/funding-item/funding-item-details/funding-item-details.component";
import {
    FundingItemTransactionFiltersComponent
} from "./pages/funding-item/funding-item-details/funding-item-transaction-filters/funding-item-transaction-filters.component";
import {
    FundingItemTransactionsTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-transactions-table-cell-content/funding-item-transactions-table-cell-content.component";
import {
    FundingItemMidFeesTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-mid-fees-table-cell-content/funding-item-mid-fees-table-cell-content.component";
import {
    FundingItemSettlementTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-settlement-table-cell-content/funding-item-settlement-table-cell-content.component";
import { HelpComponent } from "./pages/help/help.component";
import { HelpDetailsComponent } from "./pages/help/help-details/help-details.component";
import { EventComponent } from "./pages/event/event.component";
import { EventDetailsComponent } from "./pages/event/event-details/event-details.component";
import { EventDeliveriesTableCellContentComponent } from "./pages/event/event-details/event-deliveries-table-cell-content/event-deliveries-table-cell-content.component";
import { EventHistoryComponent } from "./pages/event-history/event-history.component";
import { EventHistoryLogComponent } from "./pages/event-history/event-history-log/event-history-log.component";
import { EventHistoryTableCellContentComponent } from "./pages/event-history/event-history-log/event-history-table-cell-content/event-history-table-cell-content.component";
import { EventHistoryLogFiltersComponent } from "./pages/event-history/event-history-log/event-history-log-filters/event-history-log-filters.component";
import {
    FundingItemAdjustmentsTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-adjustments-table-cell-content/funding-item-adjustments-table-cell-content.component";
import {
    DisputesLogComponent
} from "./pages/disputes/disputes-log/disputes-log.component";
import {
    DisputesComponent
} from "./pages/disputes/disputes.component";
import { DisputesLogFiltersComponent } from "./pages/disputes/disputes-log/disputes-log-filters/disputes-log-filters.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { DisputeDetailsComponent } from "./pages/dispute/dispute-details/dispute-details.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { PaymentLogComponent } from "./pages/payments/payment-log/payment-log.component";
import { GroupTransactionsTableCellContentComponent } from "./pages/payments/payment-log/group-transactions-table-cell-content/group-transactions-table-cell-content.component";
import { GroupTransactionLogFiltersComponent } from "./pages/payments/payment-log/group-transaction-log-filters/group-transaction-log-filters.component";


@NgModule({
    declarations: [
        AppComponent,
        PaymentsComponent,
        PaymentLogComponent,
        GroupTransactionsTableCellContentComponent,
        GroupTransactionLogFiltersComponent,
        TransactionsComponent,
        TransactionLogComponent,
        TransactionComponent,
        TransactionDetailComponent,
        DisputesTableCellContentComponent,
        FeesTableCellContentComponent,
        WebhooksComponent,
        WebhookLogComponent,
        WebhooksTableCellContentComponent,
        WebhookComponent,
        WebhookDetailsComponent,
        UsersComponent,
        UserLogComponent,
        UsersTableCellContentComponent,
        RolesComponent,
        RoleLogComponent,
        RolesTableCellContentComponent,
        ClientComponent,
        ClientDetailsComponent,
        ClientsComponent,
        ClientLogComponent,
        ClientsTableCellContentComponent,
        TransactionLogFiltersComponent,
        UserComponent,
        UserDetailsComponent,
        RoleComponent,
        RoleDetailsComponent,
        ForbiddenComponent,
        PageUnavailableComponent,
        ReportsComponent,
        ReportsLogComponent,
        ReportsTableCellContentComponent,
        DownloadReportModalComponent,
        ClientSecretsTableCellContentComponent,
        CreateClientSecretModalComponent,
        ConfigurationsComponent,
        ConfigurationLogComponent,
        ConfigurationsTableCellContentComponent,
        FundingComponent,
        FundingLogComponent,
        FundingTableCellContentComponent,
        FundingLogFiltersComponent,
        FundingItemComponent,
        FundingItemDetailsComponent,
        FundingItemTransactionsTableCellContentComponent,
        FundingItemMidFeesTableCellContentComponent,
        FundingItemSettlementTableCellContentComponent,
        FundingItemAdjustmentsTableCellContentComponent,
        FundingItemTransactionFiltersComponent,
        HelpComponent,
        HelpDetailsComponent,
        EventComponent,
        EventDetailsComponent,
        EventDeliveriesTableCellContentComponent,
        EventHistoryComponent,
        EventHistoryLogComponent,
        EventHistoryTableCellContentComponent,
        EventHistoryLogFiltersComponent,
        DisputesLogFiltersComponent,
        DisputesLogComponent,
        DisputesComponent,
        DisputeDetailsComponent,
        DisputeComponent
    ],
    imports: [
        SharedModule,
        AppRoutingModule,
        StoreModule.forRoot(ReducerMap, ReducerConfiguration),
        EffectsModule.forRoot(EffectsConfiguration),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !environment.isDevelopment,
        }),
        // TODO: Can we delete the following line as StoreRouterConnectingModule
        // is also included without the custom serializer below?
        // (Can we delete CustomRouterSerializer if its not giving us anything)
//        StoreRouterConnectingModule.forRoot({ serializer: CustomRouterSerializer }),
        CoreApiModule.forRoot({ rootUrl: environment.coreApiRootUrl }),
        PortalApiModule.forRoot({ rootUrl: environment.portalApiRootUrl }),
        StoreRouterConnectingModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.coreApiRootUrl, environment.portalApiRootUrl],
                sendAccessToken: true,
            },
        }),
        DashboardModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => async () => {
                    await authService.configure();
                    return authService.validateToken().then((result) => {
                        if (result) {
                            // Only enable token refreshing if we have valid token
                            authService.initTokenRefreshListener();
                            return null;
                        }

                        return new Promise(() => {}); // return unresolvable promise to prevent applcation bootstrap
                    });
                },
            deps: [AuthService],
            multi: true,
        },
        { provide: AuthConfig, useValue: authConfig }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
