<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Roles' }]">
        <lib-action-button *ngIf="hasCreateRolePermission"
            label="New Role" fontImage="far fa-plus-circle fa-bg" (click)="onClickCreateRole()">
        </lib-action-button>
    </lib-title-bar>

    <lib-table-base
        class="roles-table"
        [columnDefs]="roleColumnDefs"
        [rowsData]="roles"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Roles"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-roles-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-roles-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
